import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import instructorManagementApi from '../../imt/api/instructorManagementApi';
import { convertTo24HourFormat } from '../components/Activity/Create/ActivityCreateForm';
import { RecommendedInstructorProfileData } from '../interfaces/recommendedInstructorProfile';
import { LookupData } from '../interfaces/lookup';
import {
    BlockedTime,
    BlockedTimeFormData,
    BlockedTimeTimestamp,
    BlockTimeTimeAttributeEditorItem,
} from '../../common/interfaces/blockedTime';
import { MeridiemFieldValue } from '../interfaces/meridiem';
import { DeliverySession } from '../interfaces/activity';
import { InstructorEventTypes } from '../interfaces/resourceCalendarProps';
import { CALENDAR_EVENT_COLOR } from '../constants';
import {
    convertTimeStampToLocalUnix,
    formatUnixTimeInTimezone,
    getUnixTimeInTimezoneFromDatestring,
} from '../../common/utils/date-time.utils';

dayjs.extend(utc);
dayjs.extend(timezone);

const formatBlockedTimeAttributes = ({
    blockedTime,
    timezone,
}: {
    blockedTime: BlockTimeTimeAttributeEditorItem;
    timezone: string | undefined;
}) => {
    if (!timezone) {
        timezone = dayjs.tz.guess();
    }

    let endTimestamp = convertTo24HourFormat(
        blockedTime.endTime,
        blockedTime.endTimeMeridiemFieldOption,
    );

    let startTimestamp = convertTo24HourFormat(
        blockedTime.startTime,
        blockedTime.startTimeMeridiemFieldOption,
    );
    return {
        end_timestamp: getUnixTimeInTimezoneFromDatestring({
            datestring: `${blockedTime.endDateString} ${endTimestamp}`,
            timezone,
        }),
        start_timestamp: getUnixTimeInTimezoneFromDatestring({
            datestring: `${blockedTime.startDateString} ${startTimestamp}`,
            timezone,
        }),
    };
};

export const formatFreelancerBlockedTimeAttributes = ({
    id,
    blockedTime,
    timezone,
    forceDelete,
}: {
    id: string;
    blockedTime: BlockTimeTimeAttributeEditorItem;
    timezone: string;
    forceDelete: boolean;
}) => {
    const endTimeDateString = `${blockedTime.endDateString} 23:59:59`;
    const startTimeDateString = `${blockedTime.startDateString} 00:00:00`;

    return {
        id,
        end_timestamp: getUnixTimeInTimezoneFromDatestring({
            datestring: endTimeDateString,
            timezone,
        }),
        start_timestamp: getUnixTimeInTimezoneFromDatestring({
            datestring: startTimeDateString,
            timezone,
        }),
        removeFlag: forceDelete ? forceDelete : blockedTime.removeFlag,
    };
};

export const normalizeBlockedTimeAttributes = (
    instructor: RecommendedInstructorProfileData,
    blockedTimeNormalizedData: BlockedTimeFormData,
) => {
    const blockTime = {
        blocked_time_type: blockedTimeNormalizedData.blocked_time_type,
        blocked_time_name: blockedTimeNormalizedData.blocked_time_name,
        blocked_time_notes: blockedTimeNormalizedData.blocked_time_notes,
        pk: blockedTimeNormalizedData.pk,
    };
    const timezone = instructor.city_timezone;
    const timestamps = blockedTimeNormalizedData.blockedTimes.map(
        (blockedTime) => {
            return formatBlockedTimeAttributes({ blockedTime, timezone });
        },
    );
    return {
        instructor_pk: instructor.pk,
        ...blockTime,
        blocked_time_timestamps: timestamps,
    };
};

export const deleteBlockedTimeForInstructor = async (
    blockedTimeType: BlockedTime,
) => {
    try {
        const { pk } = blockedTimeType;
        if (pk && blockedTimeType.instructor_pk) {
            const response = await instructorManagementApi.deleteBlockedTime({
                instructorId: blockedTimeType.instructor_pk,
                blockedTimeId: blockedTimeType.pk,
            });
            if (response.status === 200) {
                return {
                    status: response.status,
                    blockedTimeType,
                };
            }
        }
    } catch (error: any) {
        return new Error(`Error deleting:${blockedTimeType.instructor_pk}`);
    }
};

export const addBlockedTimesForInstructor = (
    blockedTimes: Array<BlockedTime>,
) => {
    return blockedTimes.map(async (blockedTime) => {
        try {
            const instructorId = blockedTime.instructor_pk;
            const {
                blocked_time_name,
                blocked_time_timestamps,
                blocked_time_type,
                blocked_time_notes,
                instructor_pk,
            } = blockedTime;
            let response = await instructorManagementApi.addBlockedTime({
                instructorId,
                blockedTime: {
                    blocked_time_name,
                    blocked_time_timestamps,
                    blocked_time_type,
                    blocked_time_notes,
                    instructor_pk,
                },
            });
            if (response.status === 200) {
                return {
                    status: response.status,
                    blockedTimeType: {
                        ...blockedTime,
                        pk: response.result.blocked_time_id,
                    },
                };
            }
        } catch (error: any) {
            return new Error(`Error updating:${blockedTime.instructor_pk}`);
        }
    });
};

export const updateBlockedTimeForInstructor = async (
    blockedTimeObj: BlockedTime,
) => {
    try {
        let timestamps = [] as BlockedTimeTimestamp[];
        timestamps = blockedTimeObj.blocked_time_timestamps;
        const blockedTime = {
            blocked_time_name: blockedTimeObj.blocked_time_name,
            blocked_time_type: blockedTimeObj.blocked_time_type,
            blocked_time_notes: blockedTimeObj.blocked_time_notes,
            instructor_pk: blockedTimeObj.instructor_pk,
            blocked_time_timestamps: timestamps.map((timestamp) => ({
                start_timestamp: timestamp.start_timestamp,
                end_timestamp: timestamp.end_timestamp,
            })),
        };
        const response = await instructorManagementApi.updateBlockedTime({
            instructorId: blockedTimeObj.instructor_pk,
            blockedTimeId: blockedTimeObj.pk,
            blockedTime,
        });
        if (response.status === 200) {
            return {
                status: response.status,
                blockedTimeType: blockedTimeObj,
                blocked_time_id: response.result.blocked_time_id,
            };
        }
    } catch (error: any) {
        return new Error(`Error updating:${blockedTimeObj.instructor_pk}`);
    }
};

interface CalendarUpdateEvents {
    blockedTimeData: BlockedTime;
}

export const normalizeInstructorsWithBlockedTimeTimeData = ({
    selectedInstructors,
    blockedTimeFormValues,
    instructorLookup,
}: {
    selectedInstructors: Array<RecommendedInstructorProfileData>;
    blockedTimeFormValues: BlockedTimeFormData;
    instructorLookup: LookupData<RecommendedInstructorProfileData>;
}) =>
    Array.from(selectedInstructors).reduce(
        (acc, instructor) => {
            if (instructor.pk && instructorLookup[instructor.pk]) {
                let blockedTimeData = normalizeBlockedTimeAttributes(
                    instructor,
                    blockedTimeFormValues,
                );
                acc.blockedTimes.push(blockedTimeData);
                acc.calendarEventsLookup[instructor.pk as string] = {
                    blockedTimeData,
                };
            }
            return acc;
        },
        {
            blockedTimes: [] as Array<BlockedTime>,
            calendarEventsLookup: {} as LookupData<CalendarUpdateEvents>,
        },
    );

export const buildEditTimesForInstructorType = ({
    timestamp,
    city_timezone,
    blockedTime,
    isFreelancer,
}: {
    timestamp: BlockedTimeTimestamp;
    city_timezone: string;
    blockedTime: BlockedTime;
    isFreelancer: boolean;
}) => {
    let editTimes = {
        endTimeMeridiemFieldOption: MeridiemFieldValue.Pm,
        startTimeMeridiemFieldOption: MeridiemFieldValue.Am,
        id: blockedTime.pk,
    } as unknown as BlockTimeTimeAttributeEditorItem;

    editTimes = {
        ...editTimes,
        startTime: formatUnixTimeInTimezone({
            timestamp: timestamp.start_timestamp,
            timezone: city_timezone,
            format: 'hh:mm',
        }),
        endTime: formatUnixTimeInTimezone({
            timestamp: timestamp.end_timestamp,
            timezone: city_timezone,
            format: 'hh:mm',
        }),
        startDateString: formatUnixTimeInTimezone({
            timestamp: timestamp.start_timestamp,
            timezone: city_timezone,
            format: 'YYYY-MM-DD',
        }),
        endDateString: formatUnixTimeInTimezone({
            timestamp: timestamp.end_timestamp,
            timezone: city_timezone,
            format: 'YYYY-MM-DD',
        }),
    };

    if (isFreelancer) {
        editTimes = {
            ...editTimes,
            removeFlag: false,
        };
    }

    return editTimes;
};

export const buildCalendarEventsFromDeliverySessions = (
    {
        sessions,
        timezone,
    }: {
        sessions: Array<DeliverySession>;
        timezone: string;
    },
    getEventObj: any,
) => {
    let events: Array<any> = [];

    let pendingSession: DeliverySession | null = null;
    let startingSession: DeliverySession | null = null;
    // if the event has more than one session and the dates are not back to back
    // we want a separate event for each session
    for (let sessionIndex in sessions) {
        let deliverySession: DeliverySession = sessions[sessionIndex];
        let shouldPushEvent: boolean = false;

        if (!pendingSession) {
            pendingSession = deliverySession;
        }

        if (!startingSession) {
            startingSession = deliverySession;
        }

        // see if this session start is not within 24h of the pending session start, if not
        // we should push this event
        if (
            deliverySession.start_timestamp &&
            Math.abs(
                ((pendingSession.start_timestamp as number) -
                    deliverySession.start_timestamp) as number,
            ) >
                60 * 60 * 24
        ) {
            shouldPushEvent = true;
        }
        if (pendingSession && startingSession && shouldPushEvent) {
            events.push({
                ...getEventObj(),
                ...{
                    start:
                        convertTimeStampToLocalUnix({
                            timestamp:
                                startingSession.start_timestamp as number,
                            timezone: timezone,
                        }) * 1000,
                    end:
                        convertTimeStampToLocalUnix({
                            timestamp: pendingSession.end_timestamp as number,
                            timezone: timezone,
                        }) * 1000,
                    backgroundColor: CALENDAR_EVENT_COLOR,
                    eventType: InstructorEventTypes.activity,
                },
            });

            startingSession = deliverySession;
        }

        if (sessions.length === parseInt(sessionIndex) + 1) {
            // this is the last loop, if we have no starting session, it means a one off outlier
            events.push({
                ...getEventObj(sessionIndex),
                ...{
                    start:
                        convertTimeStampToLocalUnix({
                            timestamp:
                                startingSession.start_timestamp as number,
                            timezone: timezone,
                        }) * 1000,
                    end:
                        convertTimeStampToLocalUnix({
                            timestamp: deliverySession.end_timestamp as number,
                            timezone: timezone,
                        }) * 1000,
                    backgroundColor: CALENDAR_EVENT_COLOR,
                    eventType: InstructorEventTypes.activity,
                },
            });
        }

        pendingSession = deliverySession;
    }

    return events;
};
