import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ColumnLayout,
    FormField,
    FormSection,
    Input,
    Select,
} from '@amzn/awsui-components-react';

import { BasicActivityFormSectionProps } from '../Common/Common';
import {
    getActivityStatusesList,
    resetActivityStatusesSlice,
    selectAllActiveActivityStatuses,
    selectError as selectActivityStatusListError,
    selectIsLoaded as selectIsActivityStatusListLoaded,
    selectIsLoading as selectIsActivityStatusListLoading,
} from '../../../../common/store/slices/activityStatusesSlice';
import {
    getActivityAudiencesList,
    resetActivityAudiencesSlice,
    selectAllActiveActivityAudiences,
    selectError as selectActivityAudienceListError,
    selectIsLoaded as selectIsActivityAudienceListLoaded,
    selectIsLoading as selectIsActivityAudienceListLoading,
} from '../../../../common/store/slices/activityAudiencesSlice';
import {
    getActivityModalitiesList,
    resetActivityModalitiesSlice,
    selectAllActiveActivityModalities,
    selectError as selectActivityModalityListError,
    selectIsLoaded as selectIsActivityModalityListLoaded,
    selectIsLoading as selectIsActivityModalityListLoading,
} from '../../../../common/store/slices/activityModalitiesSlice';
import {
    FORM_ERROR_SELECTOR,
    getOptionsAndLookupForSelectInput,
    getStatusType,
} from '../../../../imt/components/Instructor/FormSections/FormSections.common';
import { ActivityStatusItemData } from '../../../../common/interfaces/businessDataItem/activityStatusItem';
import { ActivityAudienceItemData } from '../../../../common/interfaces/businessDataItem/activityAudienceItem';
import { ActivityModalityItemData } from '../../../../common/interfaces/businessDataItem/activityModalityItem';

const DeliveryAddressUpdateFormSection = ({
    formValues,
    errors,
    handleFieldEvent,
}: BasicActivityFormSectionProps) => (
    <>
        <div className="grimsby-sub-section-divider" />
        <div className="grimsby-sub-section-header">Delivery Address</div>
        <p>
            To save an activity as active, enter a delivery address and postal
            code.
        </p>
        <FormField
            label={<span>Delivery address 1</span>}
            errorText={errors?.delivery_address_1}
        >
            <Input
                className={errors?.delivery_address_1 && FORM_ERROR_SELECTOR}
                value={formValues.delivery_address_1}
                onInput={(e) =>
                    handleFieldEvent({
                        delivery_address_1: e.detail.value,
                    })
                }
                data-testid={`DeliveryAddress1`}
            />
        </FormField>
        <FormField
            label={
                <span>
                    Delivery address 2 <i>- optional</i>
                </span>
            }
            errorText={errors?.delivery_address_2}
        >
            <Input
                className={errors?.delivery_address_2 && FORM_ERROR_SELECTOR}
                value={formValues.delivery_address_2}
                onInput={(e) =>
                    handleFieldEvent({
                        delivery_address_2: e.detail.value,
                    })
                }
                data-testid={`DeliveryAddress2`}
            />
        </FormField>
        <FormField
            label={<span>Delivery postal code</span>}
            errorText={errors?.delivery_postal_code}
        >
            <Input
                className={errors?.delivery_postal_code && FORM_ERROR_SELECTOR}
                value={formValues.delivery_postal_code}
                onInput={(e) =>
                    handleFieldEvent({
                        delivery_postal_code: e.detail.value,
                    })
                }
                data-testid={`DeliveryPostalCode`}
            />
        </FormField>
        <FormField
            label={
                <span>
                    Room <i>- optional</i>
                </span>
            }
            errorText={errors?.room}
        >
            <Input
                className={errors?.room && FORM_ERROR_SELECTOR}
                value={formValues.room}
                onInput={(e) =>
                    handleFieldEvent({
                        room: e.detail.value,
                    })
                }
                data-testid={`Room`}
            />
        </FormField>
        <div className="grimsby-sub-section-divider" />
    </>
);

const DeliveryCompletedStudentNumberFormSection = ({
    formValues,
    errors,
    handleFieldEvent,
}: BasicActivityFormSectionProps) => {
    const isAttendedOptional = (formValues.program === 'Commercial' && formValues.activity_audience === 'Public');

    const attendedLabel = isAttendedOptional ? (
        <span>Number of students attended <em>- optional</em></span>
    ) : (
        <span>Number of students attended</span>
    )

    return (
        <>
            <div className="grimsby-sub-section-divider" />
            <div className="grimsby-sub-section-header">Registration details</div>
            <p>
                To save a completed activity, enter the number of students attended.
            </p>
            <FormField
                label={attendedLabel}
                errorText={errors?.attended}
            >
                <Input
                    className={errors?.attended && FORM_ERROR_SELECTOR}
                    value={`${formValues.attended}`}
                    type="number"
                    onInput={(e) =>
                        handleFieldEvent({
                            attended: !!e.detail.value
                                ? parseFloat(e.detail.value)
                                : null,
                        })
                    }
                    data-testid={`EditAttended`}
                />
            </FormField>
            <div className="grimsby-sub-section-divider" />
        </>
    );
}

const EditBaseDetailsFormSection = ({
    formValues,
    errors,
    handleFieldEvent,
    validateAndHandleFieldEvent,
    showAdditionalFields,
}: BasicActivityFormSectionProps) => {
    const isActivityStatusListLoading = useSelector(
        selectIsActivityStatusListLoading,
    );
    const isActivityStatusListLoaded = useSelector(
        selectIsActivityStatusListLoaded,
    );
    const activityStatusListError = useSelector(selectActivityStatusListError);
    const isActivityAudienceListLoading = useSelector(
        selectIsActivityAudienceListLoading,
    );
    const isActivityAudienceListLoaded = useSelector(
        selectIsActivityAudienceListLoaded,
    );
    const activityAudienceListError = useSelector(
        selectActivityAudienceListError,
    );
    const isActivityModalityListLoading = useSelector(
        selectIsActivityModalityListLoading,
    );
    const isActivityModalityListLoaded = useSelector(
        selectIsActivityModalityListLoaded,
    );
    const activityModalityListError = useSelector(
        selectActivityModalityListError,
    );
    const activityStatusList = useSelector(selectAllActiveActivityStatuses);
    const activityAudienceList = useSelector(selectAllActiveActivityAudiences);
    const activityModalityList = useSelector(selectAllActiveActivityModalities);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            // reset business data slices
            // this code block should only run once
            [
                resetActivityStatusesSlice,
                resetActivityAudiencesSlice,
                resetActivityModalitiesSlice,
            ].forEach((resetFunction) => dispatch(resetFunction()));
        };
    }, [dispatch]);

    // lifecycle method to fetch (and re- fetch) business data
    useEffect(() => {
        ([
            [
                !isActivityStatusListLoaded && !isActivityStatusListLoading,
                getActivityStatusesList,
            ],
            [
                !isActivityAudienceListLoaded && !isActivityAudienceListLoading,
                getActivityAudiencesList,
            ],
            [
                !isActivityModalityListLoaded && !isActivityModalityListLoading,
                getActivityModalitiesList,
            ],
        ] as ReadonlyArray<[boolean, Function]>).forEach(
            ([shouldFetch, getList]) => {
                if (shouldFetch) {
                    dispatch(getList());
                }
            },
        );
    });

    const {
        valueLookup: activityStatusLookup,
        valueOptions: activityStatusOptions,
    } = getOptionsAndLookupForSelectInput<ActivityStatusItemData>(
        activityStatusList,
        (activityStatus: ActivityStatusItemData) => ({
            label: activityStatus.activity_status,
            id: activityStatus.pk as string,
        }),
    );

    const {
        valueLookup: activityAudienceLookup,
        valueOptions: activityAudienceOptions,
    } = getOptionsAndLookupForSelectInput<ActivityAudienceItemData>(
        activityAudienceList,
        (activityAudience: ActivityAudienceItemData) => ({
            label: activityAudience.activity_audience,
            id: activityAudience.pk as string,
        }),
    );

    const {
        valueLookup: activityModalityLookup,
        valueOptions: activityModalityOptions,
    } = getOptionsAndLookupForSelectInput<ActivityModalityItemData>(
        activityModalityList,
        (activityModality: ActivityModalityItemData) => ({
            label: activityModality.activity_modality,
            id: activityModality.pk as string,
        }),
    );

    return (
        <FormSection
            data-testid="EditBaseDetailsFormSection"
            header="Activity details"
        >
            <ColumnLayout>
                <div data-awsui-column-layout-root="true">
                    <FormField
                        label="Activity name"
                        errorText={errors?.activity_name}
                    >
                        <Input
                            className={
                                errors?.activity_name && FORM_ERROR_SELECTOR
                            }
                            value={formValues.activity_name}
                            placeholder="Enter an activity name"
                            onInput={(e) =>
                                handleFieldEvent({
                                    activity_name: e.detail.value,
                                })
                            }
                            data-testid={`EditActivityName`}
                        />
                    </FormField>
                    <FormField
                        label={
                            <div className="awsui-util-status-inactive">
                                Activity type
                            </div>
                        }
                    >
                        {formValues.activity_type}
                    </FormField>
                    <FormField
                        label={
                            <div className="awsui-util-status-inactive">
                                Program
                            </div>
                        }
                    >
                        {formValues.program}
                    </FormField>

                    <FormField
                        label={
                            <div className="awsui-util-status-inactive">
                                Provider
                            </div>
                        }
                    >
                        {formValues.provider}
                    </FormField>
                    <FormField
                        errorText={errors?.activity_status}
                        label="Activity status"
                    >
                        <Select
                            className={
                                errors?.activity_status && FORM_ERROR_SELECTOR
                            }
                            placeholder={
                                isActivityStatusListLoading
                                    ? 'Loading activity statuses'
                                    : 'Select an activity status'
                            }
                            options={activityStatusOptions}
                            selectedOption={
                                activityStatusLookup[formValues.activity_status]
                            }
                            onChange={(e) => {
                                validateAndHandleFieldEvent({
                                    activity_status:
                                        e.detail.selectedOption.label,
                                });
                            }}
                            onRecoveryClick={() => {
                                dispatch(resetActivityStatusesSlice());
                                dispatch(getActivityStatusesList());
                            }}
                            loadingText="Loading activity statuses"
                            errorText="An error occurred while loading activity statuses"
                            recoveryText="Retry"
                            empty="No activity statuses found"
                            statusType={getStatusType(
                                isActivityStatusListLoading,
                                isActivityStatusListLoaded,
                                activityStatusListError,
                            )}
                            disabled={!isActivityStatusListLoaded}
                            data-testid={`EditBaseDetailsActivityStatus`}
                        />
                    </FormField>
                    {(() => {
                        if (showAdditionalFields?.showDeliveryAddress) {
                            return (
                                <DeliveryAddressUpdateFormSection
                                    {...{
                                        validateAndHandleFieldEvent,
                                        handleFieldEvent,
                                        formValues,
                                        errors,
                                    }}
                                />
                            );
                        }

                        if (showAdditionalFields?.showNumberOfStudents) {
                            return (
                                <DeliveryCompletedStudentNumberFormSection
                                    {...{
                                        validateAndHandleFieldEvent,
                                        handleFieldEvent,
                                        formValues,
                                        errors,
                                    }}
                                />
                            );
                        }
                    })()}

                    <FormField
                        errorText={errors?.activity_audience}
                        label="Audience"
                    >
                        <Select
                            className={
                                errors?.activity_audience && FORM_ERROR_SELECTOR
                            }
                            placeholder={
                                isActivityAudienceListLoading
                                    ? 'Loading audiences'
                                    : 'Select an audience'
                            }
                            options={activityAudienceOptions}
                            selectedOption={
                                activityAudienceLookup[
                                    formValues.activity_audience
                                ]
                            }
                            onChange={(e) =>
                                handleFieldEvent({
                                    activity_audience:
                                        e.detail.selectedOption.label,
                                })
                            }
                            onRecoveryClick={() => {
                                dispatch(resetActivityAudiencesSlice());
                                dispatch(getActivityAudiencesList());
                            }}
                            loadingText="Loading audiences"
                            errorText="An error occurred while loading audiences"
                            recoveryText="Retry"
                            empty="No audiences found"
                            statusType={getStatusType(
                                isActivityAudienceListLoading,
                                isActivityAudienceListLoaded,
                                activityAudienceListError,
                            )}
                            disabled={!isActivityAudienceListLoaded}
                            data-testid={`EditBaseDetailsActivityAudience`}
                        />
                    </FormField>
                    <FormField
                        errorText={errors?.activity_modality}
                        label="Modality"
                    >
                        <Select
                            className={
                                errors?.activity_modality && FORM_ERROR_SELECTOR
                            }
                            placeholder={
                                isActivityModalityListLoading
                                    ? 'Loading modalities'
                                    : 'Select a modality'
                            }
                            options={activityModalityOptions}
                            selectedOption={
                                activityModalityLookup[
                                    formValues.activity_modality
                                ]
                            }
                            onChange={(e) =>
                                handleFieldEvent({
                                    activity_modality:
                                        e.detail.selectedOption.label,
                                })
                            }
                            onRecoveryClick={() => {
                                dispatch(resetActivityModalitiesSlice());
                                dispatch(getActivityModalitiesList());
                            }}
                            loadingText="Loading modalities"
                            errorText="An error occurred while loading modalities"
                            recoveryText="Retry"
                            empty="No modalities found"
                            statusType={getStatusType(
                                isActivityModalityListLoading,
                                isActivityModalityListLoaded,
                                activityModalityListError,
                            )}
                            disabled={!isActivityModalityListLoaded}
                            data-testid={`EditBaseDetailsActivityModality`}
                        />
                    </FormField>
                </div>
            </ColumnLayout>
        </FormSection>
    );
};

export default EditBaseDetailsFormSection;
