import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ActivityTypesResponseData } from '../../interfaces/businessDataResponse/activityTypesResponse';
import { HandleRequestData } from '../../interfaces/handleRequest';
import businessDataApi from '../../api/businessDataApi';
import { BusinessDataApiQueryParams } from '../../interfaces/queryParams';
import { AppDispatch, GlobalState } from '../../../main/store';
import { ExtendedBusinessDataStoreState } from '../../interfaces/businessDataStoreState';
import { ActivityTypeItemData } from '../../interfaces/businessDataItem/activityTypeItem';
import parseBoolean from '../../utils/parseBoolean';
import {
    ExtendedBusinessDataStoreInitialState,
    getActiveBusinessDataValues,
    resetBasicBusinessDataStoreState,
    resetExtendedBusinessDataStoreState,
    initializeBusinessDataListQueryParamsStoreState,
} from '../store.common';

/**
 * activityTypesSlice manages all activityTypes state, and contains activityTypes actions as well as activityTypes state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const activityTypesSlice = createSlice({
    name: 'activityTypes',
    initialState: {
        ...ExtendedBusinessDataStoreInitialState,
    } as ExtendedBusinessDataStoreState<ActivityTypeItemData>,
    reducers: {
        setActivityTypesList: (
            state,
            action: PayloadAction<ActivityTypeItemData[]>,
        ) => {
            const byActivityTypeName = action.payload.reduce(
                (
                    byActivityTypeName: {
                        [key: string]: ActivityTypeItemData;
                    },
                    activityType: ActivityTypeItemData,
                ) => {
                    byActivityTypeName[activityType.activity_type] = {
                        ...activityType,
                        active: parseBoolean(activityType.active),
                    };
                    return byActivityTypeName;
                },
                {},
            );
            state.entities = byActivityTypeName;
            state.keysList = Object.keys(byActivityTypeName);
        },
        setActivityType: (
            state,
            action: PayloadAction<ActivityTypeItemData>,
        ) => {
            // if the item is new, add a new key for it and update the count.
            if (!state.entities[action.payload.activity_type]) {
                state.keysList = [
                    ...state.keysList,
                    action.payload.activity_type,
                ];
                state.count = state.keysList.length;
            }
            state.entities[action.payload.activity_type] = action.payload;
        },
        setSelectedActivityType: (
            state,
            action: PayloadAction<string | null>,
        ) => {
            state.selectedItemKey = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
        setCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload;
        },
        setSearchText: (state, action: PayloadAction<string | null>) => {
            state.searchText = action.payload;
        },
        setActive: (
            state,
            action: PayloadAction<BusinessDataApiQueryParams.Active>,
        ) => {
            state.active = action.payload;
        },
        setFrom: (state, action: PayloadAction<number>) => {
            state.from = action.payload;
        },
        setPagesCount: (state, action: PayloadAction<number>) => {
            state.pagesCount = action.payload;
        },
        setCurrentPageIndex: (state, action: PayloadAction<number>) => {
            state.currentPageIndex = action.payload;
        },
        setSize: (state, action: PayloadAction<number>) => {
            state.size = action.payload;
        },
        resetPartialActivityTypesSlice: resetBasicBusinessDataStoreState,
        resetActivityTypesSlice: resetExtendedBusinessDataStoreState,
        initializeActivityTypesListQueryParams: initializeBusinessDataListQueryParamsStoreState,
    },
});

export const {
    setIsLoading,
    setIsLoaded,
    setActivityTypesList,
    setActivityType,
    setSelectedActivityType,
    setError,
    setCount,
    setSearchText,
    setActive,
    setFrom,
    setPagesCount,
    setCurrentPageIndex,
    setSize,
    resetPartialActivityTypesSlice,
    resetActivityTypesSlice,
    initializeActivityTypesListQueryParams,
} = activityTypesSlice.actions;

const handleActivityTypesListRequest = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        const {
            from,
            size,
            searchText: activity_type,
            active,
        } = state.activityTypes;

        const params: BusinessDataApiQueryParams.GetActivityTypes = {
            active,
        };

        // if both "from" and "size" equal to 0, avoid adding them into the query parameters
        if (from !== 0 || size !== 0) {
            params.from = from;
            params.size = size;
        }
        if (activity_type) {
            params.activity_type = activity_type;
        }

        try {
            const {
                result: { ACTIVITY_TYPE, total_count },
            }: HandleRequestData<ActivityTypesResponseData> = await businessDataApi.getActivityTypes(
                params,
            );

            dispatch(setActivityTypesList(ACTIVITY_TYPE));
            dispatch(setCount(total_count));
            dispatch(setPagesCount(Math.ceil(total_count / size)));
        } catch (error: any) {
            dispatch(setError(error?.message || 'getActivityTypes API error'));
        }
    };
};

export const getActivityTypesList = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));
        await dispatch(handleActivityTypesListRequest());
        if (!state.activityTypes.isLoaded) {
            dispatch(setIsLoaded(true));
        }
        dispatch(setIsLoading(false));
    };
};

/**
 * action to update program
 * before update api call, fetch program types and prepare payload containing only the changed fields
 * after update api call, fetch program types and update the current item in that keysList with the item data we updated
 * @param data
 */
export const updateActivityType = (data: ActivityTypeItemData) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        // immediately put the keysList into loading while we finish updating the item/fetching the keysList
        dispatch(setIsLoading(true));
        if (!data.pk || data?.pk.length <= 0) {
            // eslint-disable-next-line no-console
            console.error(
                `Error updating new program: required field pk was missing`,
            );
            dispatch(setIsLoading(false));
            return false;
        }
        try {
            await businessDataApi.updateActivityType(data.pk, data.active);
            await dispatch(handleActivityTypesListRequest());
            // force-update the item in the keysList for now, since the keysList we fetched may not have this item's update yet
            dispatch(
                setActivityType({
                    ...state.activityTypes.entities[data.activity_type],
                    active: data.active,
                }),
            );
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const addActivityType = (active = true, activityTypeName: string) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        dispatch(setIsLoading(true));
        try {
            await businessDataApi.addActivityType(activityTypeName, active);
            // now refresh the keysList
            await dispatch(handleActivityTypesListRequest());
            if (!state.activityTypes?.entities[activityTypeName]) {
                // shove the item into the keysList, but only if it's not there already
                const newActivityTypeData: ActivityTypeItemData = {
                    activity_type: activityTypeName,
                    active: active,
                };
                dispatch(setActivityType(newActivityTypeData));
            }
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const selectAllActivityTypes = (state: GlobalState) => {
    return state.activityTypes.keysList.map(
        (activityTypeName) => state.activityTypes.entities[activityTypeName],
    );
};

export const selectAllActiveActivityTypes = (state: GlobalState) => {
    return getActiveBusinessDataValues(
        state.activityTypes.keysList,
        state.activityTypes.entities,
    );
};

export const selectIsLoading = (state: GlobalState) =>
    state.activityTypes.isLoading;

export const selectIsLoaded = (state: GlobalState) =>
    state.activityTypes.isLoaded;

export const selectSelectedActivityType = (state: GlobalState) => {
    return state?.activityTypes?.selectedItemKey
        ? state?.activityTypes?.entities[state?.activityTypes?.selectedItemKey]
        : null;
};

export const selectError = (state: GlobalState) => state.activityTypes.error;

export const selectCount = (state: GlobalState) => state.activityTypes.count;

export const selectSearchText = (state: GlobalState) =>
    state.activityTypes.searchText;

export const selectPagesCount = (state: GlobalState) =>
    state.activityTypes.pagesCount;

export const selectCurrentPageIndex = (state: GlobalState) =>
    state.activityTypes.currentPageIndex;

export default activityTypesSlice.reducer;
