export enum Stage {
    DEV = 'DEV',
    BETA = 'BETA',
    GAMMA = 'GAMMA',
    PROD = 'PROD',
}

export enum ViasNamespaceRoleProps {
    DEV = 'aws/grimsby/dev:roles',
    BETA = 'aws/grimsby/beta:roles',
    GAMMA = 'aws/grimsby/gamma:roles',
    PROD = 'aws/grimsby/prod:roles',
}

export interface GandalfJwt {
    [ViasNamespaceRoleProps.DEV]?: Stage.DEV;
    [ViasNamespaceRoleProps.BETA]?: Stage.BETA;
    [ViasNamespaceRoleProps.GAMMA]?: Stage.GAMMA;
    [ViasNamespaceRoleProps.PROD]?: Stage.PROD;
}
