import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { GenericStoreState } from '../../../common/interfaces/genericStoreState';
import { HandleRequestData } from '../../../common/interfaces/handleRequest';
import { AppDispatch, GlobalState } from '../../../main/store';
import scheduleManagementApi from '../../api/scheduleManagementApi';
import { CALENDAR_EVENT_COLOR, UNAVAILABLE_EVENT_COLOR } from '../../constants';
import { ActivityData } from '../../interfaces/activity';
import { InstructorListResponseData } from '../../interfaces/instructorsResponse';
import { LookupData } from '../../interfaces/lookup';
import { ScheduleManagementAPIQueryParams } from '../../interfaces/queryParams';
import { RecommendedInstructorProfileData } from '../../interfaces/recommendedInstructorProfile';
import {
    CalendarEvent,
    CalendarResource,
    InstructorEventTypes,
} from '../../interfaces/resourceCalendarProps';
import { buildCalendarEventsFromDeliverySessions } from '../../services/schedule-service';
import {
    BlockedTimeCalendarEvent,
    BlockedTime,
} from '../../../common/interfaces/blockedTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { getBlockedTimeStartAndEndTimesByInstructorType } from '../../services/calendar-service';

dayjs.extend(utc);
dayjs.extend(timezone);

interface InstructorPayloadState {
    instructorLookup: LookupData<RecommendedInstructorProfileData>;
}

export interface SMTInstructorListState extends GenericStoreState {
    isLoaded: boolean;
    instructorListAsResources: Array<CalendarResource>;
    instructorActivitiesAsEvents: Array<CalendarEvent>;
    instructorActivityLookup: LookupData<ActivityData>;
    blockedTimeLookup: LookupData<BlockedTime>;
    blockedTimesAsEvents: Array<CalendarEvent>;
    instructorLookup: LookupData<RecommendedInstructorProfileData>;
    start_timestamp: number;
    end_timestamp: number;
    addblockedTimesAsEvents: Array<CalendarEvent>;
    pagesCount: number;
    currentPageIndex: number;
    count: number;
    from: number;
    size: number;
}

export const RESERVED_TEXT = 'Reserved';
export const instructorListState = (
    smtInstructorsList: Array<RecommendedInstructorProfileData>,
) =>
    smtInstructorsList.reduce(
        (acc, instructor) => {
            const { pk, full_name, activities, blocked_times, is_freelancer } =
                instructor;
            acc.instructorListAsResources.push({
                id: pk as string,
                title: `${full_name}`,
            });
            acc.instructorLookup[pk as string] = instructor;

            for (const [activity] of activities) {
                const {
                    delivery_sessions,
                    delivery_timezone,
                    activity_name,
                    pk: activityPk,
                } = activity;
                let events = buildCalendarEventsFromDeliverySessions(
                    {
                        sessions: delivery_sessions,
                        timezone: delivery_timezone,
                    },
                    () => ({
                        id: activityPk as string,
                        title: activity_name,
                        resourceId: pk as string,
                    }),
                );
                acc.activitiesAsEvents = [...acc.activitiesAsEvents, ...events];
                acc.activityLookup[activityPk as string] = activity;
            }

            if (blocked_times && blocked_times.length > 0) {
                let instructorBlockedTimesWithId = [];
                for (const blockedTimeObj of blocked_times) {
                    for (const blockedTimestamps of blockedTimeObj.blocked_time_timestamps) {
                        const backgroundColor = is_freelancer
                            ? UNAVAILABLE_EVENT_COLOR
                            : CALENDAR_EVENT_COLOR;
                        const { start, end } =
                            getBlockedTimeStartAndEndTimesByInstructorType(
                                blockedTimestamps,
                                instructor,
                            );
                        acc.blockedTimesAsEvents.push({
                            id: blockedTimeObj.pk,
                            start,
                            end,
                            resourceId: pk as string,
                            backgroundColor,
                            title: `${RESERVED_TEXT} - ${blockedTimeObj.blocked_time_name}`,
                            eventType: InstructorEventTypes.blockedTime,
                        });
                        acc.blockedTimeLookup[blockedTimeObj.pk as string] = {
                            ...blockedTimeObj,
                            instructor_pk: pk,
                        };
                        instructorBlockedTimesWithId.push({
                            id: blockedTimeObj.pk,
                            ...blockedTimestamps,
                        });
                    }
                }
            }
            return acc;
        },
        {
            instructorListAsResources: [] as Array<CalendarResource>,
            activitiesAsEvents: [] as Array<CalendarEvent>,
            activityLookup: {} as LookupData<ActivityData>,
            instructorLookup:
                {} as LookupData<RecommendedInstructorProfileData>,
            blockedTimesAsEvents: [] as Array<CalendarEvent>,
            blockedTimeLookup: {} as LookupData<BlockedTime>,
        },
    );
const browserTimezone = dayjs.tz.guess();
const initialDateTime = dayjs
    .tz(dayjs(), browserTimezone)
    .startOf('week')
    .hour(0)
    .minute(0)
    .second(0);
const start_timestamp = initialDateTime.unix();
const end_timestamp = initialDateTime.add(7, 'day').unix();

export const smtInstructorListSlice = createSlice({
    name: 'smtInstructorList',
    initialState: {
        instructorListAsResources: [],
        instructorActivitiesAsEvents: [],
        instructorActivityLookup: {},
        instructorLookup: {},
        error: null,
        isLoaded: false,
        isLoading: false,
        start_timestamp,
        end_timestamp,
        blockedTimesAsEvents: [],
        blockedTimeLookup: {},
        addblockedTimesAsEvents: [],
        pagesCount: 1,
        currentPageIndex: 1,
        count: 0,
        from: 0,
        size: 25,
    } as SMTInstructorListState,
    reducers: {
        setInstructorList: (
            state: SMTInstructorListState,
            action: PayloadAction<RecommendedInstructorProfileData[]>,
        ) => {
            const {
                instructorListAsResources,
                activitiesAsEvents,
                activityLookup,
                instructorLookup,
                blockedTimeLookup,
                blockedTimesAsEvents,
            } = instructorListState(action.payload);
            state.instructorListAsResources = instructorListAsResources;
            state.instructorActivitiesAsEvents = activitiesAsEvents;
            state.instructorActivityLookup = activityLookup;
            state.instructorLookup = instructorLookup;
            state.blockedTimeLookup = blockedTimeLookup;
            state.blockedTimesAsEvents = blockedTimesAsEvents;
        },
        setIsLoading: (
            state: SMTInstructorListState,
            action: PayloadAction<any>,
        ) => {
            state.isLoading = action.payload;
        },
        setIsLoaded: (
            state: SMTInstructorListState,
            action: PayloadAction<boolean>,
        ) => {
            state.isLoaded = action.payload;
        },
        setEndTimeStamp: (
            state: SMTInstructorListState,
            action: PayloadAction<number>,
        ) => {
            state.end_timestamp = action.payload;
        },
        setStartTimeStamp: (
            state: SMTInstructorListState,
            action: PayloadAction<number>,
        ) => {
            state.start_timestamp = action.payload;
        },
        setFrom: (
            state: SMTInstructorListState,
            action: PayloadAction<number>,
        ) => {
            state.from = action.payload;
        },
        setCount: (
            state: SMTInstructorListState,
            action: PayloadAction<number>,
        ) => {
            state.count = action.payload;
        },
        setPagesCount: (
            state: SMTInstructorListState,
            action: PayloadAction<number>,
        ) => {
            state.pagesCount = action.payload;
        },
        setCurrentPageIndex: (
            state: SMTInstructorListState,
            action: PayloadAction<number>,
        ) => {
            state.currentPageIndex = action.payload;
        },
        setSize: (
            state: SMTInstructorListState,
            action: PayloadAction<number>,
        ) => {
            state.size = action.payload;
        },
        addBlockedTimesTimeAsEvent: (
            state: SMTInstructorListState,
            action: PayloadAction<BlockedTimeCalendarEvent>,
        ) => {
            for (const blockedTime of action.payload.blockedTime
                .blocked_time_timestamps) {
                const backgroundColor = action.payload.instructor.is_freelancer
                    ? UNAVAILABLE_EVENT_COLOR
                    : CALENDAR_EVENT_COLOR;
                const { start, end } =
                    getBlockedTimeStartAndEndTimesByInstructorType(
                        blockedTime,
                        action.payload.instructor,
                    );
                state.blockedTimesAsEvents.push({
                    id: action.payload.blockedTime.pk as string,
                    start,
                    end,
                    resourceId: action.payload.instructor.pk as string,
                    title: `${RESERVED_TEXT} - ${action.payload.blockedTime.blocked_time_name}`,
                    backgroundColor,
                    eventType: InstructorEventTypes.blockedTime,
                });
                state.blockedTimeLookup[
                    action.payload.blockedTime.pk as string
                ] = action.payload.blockedTime;
                state.instructorLookup[
                    action.payload.blockedTime.instructor_pk
                ].blocked_times?.push(action.payload.blockedTime);
            }
        },
        getInstructors: (
            state: InstructorPayloadState,
            action: PayloadAction<any>,
        ) => {
            return action.payload.instructors.reduce(
                (
                    acc: Array<RecommendedInstructorProfileData>,
                    instructor: RecommendedInstructorProfileData,
                ) => {
                    if (
                        instructor.pk &&
                        state.instructorLookup[instructor.pk]
                    ) {
                        acc.push(instructor);
                    }
                    return acc;
                },
                [],
            );
        },
        updateCalendarBlockedTime: (
            state: SMTInstructorListState,
            action: PayloadAction<BlockedTimeCalendarEvent>,
        ) => {
            const filteredBlockedTimeState = state.blockedTimesAsEvents.filter(
                (time) => time.id !== action.payload.blockedTime.pk,
            );
            for (const blockedTime of action.payload.blockedTime
                .blocked_time_timestamps) {
                const backgroundColor = action.payload.instructor.is_freelancer
                    ? UNAVAILABLE_EVENT_COLOR
                    : CALENDAR_EVENT_COLOR;
                const { start, end } =
                    getBlockedTimeStartAndEndTimesByInstructorType(
                        blockedTime,
                        action.payload.instructor,
                    );
                filteredBlockedTimeState.push({
                    id: action.payload.blockedTime.pk as string,
                    start,
                    end,
                    resourceId: action.payload.instructor.pk as string,
                    title: `${RESERVED_TEXT} - ${action.payload.blockedTime.blocked_time_name}`,
                    backgroundColor,
                    eventType: InstructorEventTypes.blockedTime,
                });
                state.blockedTimeLookup[
                    action.payload.blockedTime.pk as string
                ] = action.payload.blockedTime;
            }
            state.blockedTimesAsEvents = filteredBlockedTimeState;
        },

        deleteCalendarInstructorReservedTime: (
            state: SMTInstructorListState,
            action: PayloadAction<BlockedTimeCalendarEvent>,
        ) => {
            state.instructorLookup[action.payload.instructor.pk] =
                action.payload.instructor;

            const nonDeleteEvents = state.blockedTimesAsEvents.filter(
                (reservedTimeEvent) =>
                    reservedTimeEvent.id !== action.payload.blockedTime.pk,
            );
            const eventLookup = state.blockedTimeLookup;
            delete eventLookup[action.payload.blockedTime.pk];
            state.blockedTimesAsEvents = nonDeleteEvents;
            state.blockedTimeLookup = eventLookup;
        },
    },
});

export const {
    setIsLoading,
    setIsLoaded,
    setInstructorList,
    setEndTimeStamp,
    setStartTimeStamp,
    addBlockedTimesTimeAsEvent,
    getInstructors,
    deleteCalendarInstructorReservedTime,
    updateCalendarBlockedTime,
    setCurrentPageIndex,
    setPagesCount,
    setCount,
    setFrom,
    setSize,
} = smtInstructorListSlice.actions;

export const getInstructorList = (
    params: ScheduleManagementAPIQueryParams.InstructorWithActivitesParams,
) => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        const { size, from } = state.smtInstructorList;
        dispatch(setIsLoading(true));
        try {
            const {
                result: { instructors, total_instructors },
            }: HandleRequestData<InstructorListResponseData> = await scheduleManagementApi.getInstructorsWithActivitiesList(
                {
                    ...params,
                    from,
                    size,
                } as ScheduleManagementAPIQueryParams.InstructorWithActivitesParams,
            );
            dispatch(setInstructorList(instructors));
            dispatch(setCount(total_instructors));
            dispatch(setPagesCount(Math.ceil(total_instructors / size)));
            dispatch(setIsLoading(false));
        } catch (error: any) {}
    };
};

export const selectInstructorList = (state: GlobalState) =>
    state.smtInstructorList.instructorListAsResources;
export const selectInstructorActivitiesAsEvents = (state: GlobalState) =>
    state.smtInstructorList.instructorActivitiesAsEvents;
export const selectInstructorActivityLookup = (state: GlobalState) =>
    state.smtInstructorList.instructorActivityLookup;
export const selectInstructorLookup = (state: GlobalState) =>
    state.smtInstructorList.instructorLookup;
export const selectIsLoading = (state: GlobalState) =>
    state.smtInstructorList.isLoading;
export const selectIsLoaded = (state: GlobalState) =>
    state.smtInstructorList.isLoaded;
export const selectStartTimeStamp = (state: GlobalState) =>
    state.smtInstructorList.start_timestamp;
export const selectEndTimeStamp = (state: GlobalState) =>
    state.smtInstructorList.end_timestamp;
export const selectBlockedTimeLookup = (state: GlobalState) =>
    state.smtInstructorList.blockedTimeLookup;
export const selectBlockedTimesAsEvents = (state: GlobalState) =>
    state.smtInstructorList.blockedTimesAsEvents;
export const selectPagesCount = (state: GlobalState) =>
    state.smtInstructorList.pagesCount;
export const selectCurrentPageIndex = (state: GlobalState) =>
    state.smtInstructorList.currentPageIndex;
export const selectSize = (state: GlobalState) => state.smtInstructorList.size;
export default smtInstructorListSlice.reducer;
