import React from 'react';
import {
    Box,
    Modal,
    Button,
    SpaceBetween,
    Input,
    FormField,
    Icon,
} from '@amzn/awsui-components-react-v3';
import { ActivityData } from '../../../../interfaces/activity';

import { useNotifications } from '../../../../../common/context/grimsbyNotifications';
import { createKikuClassForActivity } from '../../../../services/activity-service';
import { CreateLMSClassResponse } from '../../../../interfaces/lmsServiceResponse';

interface CreateKikuClassDialogProps {
    activity: ActivityData;
    onSave: (response: CreateLMSClassResponse) => void;
}

const CreateKikuClassDialog = ({
    activity,
    onSave,
}: CreateKikuClassDialogProps) => {
    const [showCreateKikuModal, setShowCreateKikuModal] = React.useState(false);
    const [classSize, setClassSize] = React.useState(
        `${activity.class_size ?? ''}`,
    );
    const [lmsId, setLmsId] = React.useState(activity.lms_id);
    const [creatingKikuClass, setCreatingKikuClass] = React.useState(false);
    const [errors, setErrors] = React.useState<any>({
        lmsId: null,
        classSize: null,
    });
    const { addNotification } = useNotifications();

    const isCustomClass = activity.course_name === 'Custom Class';

    const handleCreateKikuClass = async () => {
        setCreatingKikuClass(true);
        setErrors(null);
        let newErrors: any = {};

        if (!classSize) {
            newErrors = {
                ...errors,
                classSize: 'Please enter a class size to create a Kiku class.',
            };
        }
        if (!lmsId && isCustomClass) {
            newErrors = {
                ...newErrors,
                lmsId: 'Please enter an LMS ID to create a Kiku class.',
            };
        }

        const isValid = !newErrors.classSize && !newErrors.lmsId;

        if (!isValid) {
            setCreatingKikuClass(false);
            setErrors(newErrors);
            return;
        }

        const createResponse = await createKikuClassForActivity(
            activity.pk,
            classSize,
            lmsId,
        );

        if (onSave) {
            onSave(createResponse);
        }

        const isSuccessful = createResponse.errors?.length === 0;

        if (!isSuccessful) {
            setCreatingKikuClass(false);
            setShowCreateKikuModal(false);
            return;
        }

        addNotification({
            id: `create-kiku-class`,
            type: 'success',
            content: <span>Operations details have been updated</span>,
        });

        setShowCreateKikuModal(false);
        setCreatingKikuClass(false);
    };

    const isLinkedToKiku = !!activity.lms_id && activity.lms_type?.toLowerCase() === 'kiku';

    return (
        <>
            {!!isLinkedToKiku && (
                <div
                    className={`awsui-util-status-positive nowrap synced-kiku`}
                >
                    <Icon name={'status-positive'} />
                    &nbsp;Linked to Kiku
                </div>
            )}
            {!isLinkedToKiku && !activity.lms_id && (
                <>
                    <Button
                        data-testid="OperationsDetailsCreateKikuClassButton"
                        onClick={() => {
                            setShowCreateKikuModal(true);
                        }}
                    >
                        Create Kiku class
                    </Button>
                    <Modal
                        onDismiss={() => setShowCreateKikuModal(false)}
                        visible={showCreateKikuModal}
                        closeAriaLabel="Close modal"
                        footer={
                            <Box float="right">
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button
                                        variant="link"
                                        data-testid="cancel-kiku-create"
                                        onClick={() => {
                                            setShowCreateKikuModal(false);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="primary"
                                        data-testid="create-kiku-button"
                                        onClick={handleCreateKikuClass}
                                        disabled={creatingKikuClass}
                                    >
                                        {creatingKikuClass
                                            ? 'Saving...'
                                            : 'Create Kiku class'}
                                    </Button>
                                </SpaceBetween>
                            </Box>
                        }
                        header="Create Kiku class?"
                    >
                        <p>
                            Are you sure you want to create a class in Kiku?
                            Please confirm the class size to continue.
                        </p>
                        <div className="h-line" />
                        <SpaceBetween direction="vertical" size="m">
                            <FormField
                                label="Class size"
                                errorText={errors?.classSize}
                            >
                                <Input
                                    onChange={({ detail }) =>
                                        setClassSize(detail.value)
                                    }
                                    type="number"
                                    value={`${classSize ?? ''}`}
                                />
                            </FormField>
                            <FormField
                                label={
                                    <span>
                                        Reference LMS ID
                                        {!isCustomClass ? (
                                            <em> - optional</em>
                                        ) : (
                                            ''
                                        )}
                                    </span>
                                }
                                description="Will be used to copy class details."
                                errorText={errors?.lmsId}
                            >
                                <Input
                                    onChange={({ detail }) =>
                                        setLmsId(detail.value)
                                    }
                                    value={lmsId ?? ''}
                                />
                            </FormField>
                        </SpaceBetween>
                    </Modal>
                </>
            )}
        </>
    );
};

export default CreateKikuClassDialog;
