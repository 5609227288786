import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GeographiesResponseData } from '../../interfaces/businessDataResponse/geographiesResponse';
import { HandleRequestData } from '../../interfaces/handleRequest';
import businessDataApi from '../../api/businessDataApi';
import { AppDispatch, GlobalState } from '../../../main/store';
import { BusinessDataStoreState } from '../../interfaces/businessDataStoreState';
import parseBoolean from '../../utils/parseBoolean';
import { GeographyItemData } from '../../interfaces/businessDataItem/geographyItem';
import {
    BusinessDataStoreInitialState,
    getActiveBusinessDataValues,
    resetBasicBusinessDataStoreState,
} from '../store.common';

/**
 * geographiesSlice manages all geographies state, and contains geographies actions as well as geographies state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const geographiesSlice = createSlice({
    name: 'geographies',
    initialState: {
        ...BusinessDataStoreInitialState,
    } as BusinessDataStoreState<GeographyItemData>,
    reducers: {
        setGeographiesList: (
            state,
            action: PayloadAction<GeographyItemData[]>,
        ) => {
            const byGeographyName = action.payload.reduce(
                (
                    byGeographyName: {
                        [key: string]: GeographyItemData;
                    },
                    geography: GeographyItemData,
                ) => {
                    byGeographyName[geography.geo] = {
                        ...geography,
                        active: parseBoolean(geography.active),
                    };
                    return byGeographyName;
                },
                {},
            );
            state.entities = byGeographyName;
            state.keysList = Object.keys(byGeographyName);
        },
        setGeography: (state, action: PayloadAction<GeographyItemData>) => {
            // this reducer may be used when adding a new program or updating an existing one.
            // only add to keysList and udpate count if adding a new program
            if (!state.entities[action.payload.geo]) {
                state.keysList = [...state.keysList, action.payload.geo];
                state.count = state.keysList.length;
            }
            state.entities[action.payload.geo] = action.payload;
        },
        setSelectedGeography: (state, action: PayloadAction<string | null>) => {
            state.selectedItemKey = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
        setCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload;
        },
        resetGeographiesSlice: resetBasicBusinessDataStoreState,
    },
});

export const {
    setIsLoading,
    setIsLoaded,
    setGeographiesList,
    setGeography,
    setSelectedGeography,
    setError,
    setCount,
    resetGeographiesSlice,
} = geographiesSlice.actions;

const handleGeographiesListRequest = () => {
    return async (dispatch: AppDispatch) => {
        try {
            const {
                result: { GEO, total_count },
            }: HandleRequestData<GeographiesResponseData> = await businessDataApi.getGeographies(
                { active: 'all' },
            );
            dispatch(setGeographiesList(GEO));
            dispatch(setCount(total_count));
        } catch (error: any) {
            dispatch(setError(error?.message || 'getGeographies API error'));
        }
    };
};

export const getGeographiesList = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));
        await dispatch(handleGeographiesListRequest());
        if (!state.geographies.isLoaded) {
            dispatch(setIsLoaded(true));
        }
        dispatch(setIsLoading(false));
    };
};

export const selectAllGeographies = (state: GlobalState) => {
    return state.geographies.keysList.map(
        (geographyName) => state.geographies.entities[geographyName],
    );
};

export const selectAllActiveGeographies = (
    state: GlobalState,
): Array<GeographyItemData> => {
    return getActiveBusinessDataValues(
        state.geographies.keysList,
        state.geographies.entities,
    );
};

export const selectIsLoading = (state: GlobalState) =>
    state.geographies.isLoading;

export const selectIsLoaded = (state: GlobalState) =>
    state.geographies.isLoaded;

export const selectSelectedGeography = (state: GlobalState) => {
    return state?.geographies?.selectedItemKey
        ? state?.geographies?.entities[state?.geographies?.selectedItemKey]
        : null;
};

export const selectError = (state: GlobalState) => state.geographies.error;

export const selectCount = (state: GlobalState) => state.geographies.count;

export default geographiesSlice.reducer;
