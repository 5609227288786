import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RegionsResponseData } from '../../interfaces/businessDataResponse/regionsResponse';
import { HandleRequestData } from '../../interfaces/handleRequest';
import businessDataApi from '../../api/businessDataApi';
import { AppDispatch, GlobalState } from '../../../main/store';
import { BusinessDataStoreState } from '../../interfaces/businessDataStoreState';
import parseBoolean from '../../utils/parseBoolean';
import { RegionItemData } from '../../interfaces/businessDataItem/regionItem';
import {
    BusinessDataStoreInitialState,
    getActiveBusinessDataValues,
    resetBasicBusinessDataStoreState,
} from '../store.common';

/**
 * regionsSlice manages all regions state, and contains regions actions as well as regions state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const regionsSlice = createSlice({
    name: 'regions',
    initialState: {
        ...BusinessDataStoreInitialState,
    } as BusinessDataStoreState<RegionItemData>,
    reducers: {
        setRegionsList: (state, action: PayloadAction<RegionItemData[]>) => {
            const byRegionName = action.payload.reduce(
                (
                    byRegionName: {
                        [key: string]: RegionItemData;
                    },
                    region: RegionItemData,
                ) => {
                    byRegionName[region.region] = {
                        ...region,
                        active: parseBoolean(region.active),
                    };
                    return byRegionName;
                },
                {},
            );
            state.entities = byRegionName;
            state.keysList = Object.keys(byRegionName);
        },
        setRegion: (state, action: PayloadAction<RegionItemData>) => {
            // this reducer may be used when adding a new program or updating an existing one.
            // only add to keysList and update count if adding a new item
            if (!state.entities[action.payload.region]) {
                state.keysList = [...state.keysList, action.payload.region];
                state.count = state.keysList.length;
            }
            state.entities[action.payload.region] = action.payload;
        },
        setSelectedRegion: (state, action: PayloadAction<string | null>) => {
            state.selectedItemKey = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
        setCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload;
        },
        resetRegionsSlice: resetBasicBusinessDataStoreState,
    },
});

export const {
    setIsLoading,
    setIsLoaded,
    setRegionsList,
    setRegion,
    setSelectedRegion,
    setError,
    setCount,
    resetRegionsSlice,
} = regionsSlice.actions;

const handleRegionsListRequest = () => {
    return async (dispatch: AppDispatch) => {
        try {
            const {
                result: { REGION, total_count },
            }: HandleRequestData<RegionsResponseData> = await businessDataApi.getRegions(
                { active: 'all' },
            );
            dispatch(setRegionsList(REGION));
            dispatch(setCount(total_count));
        } catch (error: any) {
            dispatch(setError(error?.message || 'getRegions API error'));
        }
    };
};

export const getRegionsList = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));
        await dispatch(handleRegionsListRequest());
        if (!state.regions.isLoaded) {
            dispatch(setIsLoaded(true));
        }
        dispatch(setIsLoading(false));
    };
};

export const selectAllRegions = (state: GlobalState) => {
    return state.regions.keysList.map(
        (regionName) => state.regions.entities[regionName],
    );
};

export const selectAllActiveRegions = (
    state: GlobalState,
): Array<RegionItemData> => {
    return getActiveBusinessDataValues(
        state.regions.keysList,
        state.regions.entities,
    );
};

export const selectIsLoading = (state: GlobalState) => state.regions.isLoading;

export const selectIsLoaded = (state: GlobalState) => state.regions.isLoaded;

export const selectSelectedRegion = (state: GlobalState) => {
    return state?.regions?.selectedItemKey
        ? state?.regions?.entities[state?.regions?.selectedItemKey]
        : null;
};

export const selectActivePlusUserSelections = (state: GlobalState) => {
    const userSelectedRegions: ReadonlySet<string> = new Set(
        state.selectedUser.selectedUser?.user?.regions
            ? state.selectedUser.selectedUser?.user?.regions
            : [],
    );

    return state.regions.keysList.reduce(
        (acc: Array<RegionItemData>, key: string) => {
            if (
                state.regions.entities[key].active ||
                userSelectedRegions.has(key)
            ) {
                acc.push(state.regions.entities[key]);
            }
            return acc;
        },
        [],
    );
};

export const selectError = (state: GlobalState) => state.regions.error;

export const selectCount = (state: GlobalState) => state.regions.count;

export default regionsSlice.reducer;
