import '@amzn/awsui-components-react/index.css';
import React, { useEffect, useState } from 'react';
import { AppLayout, Spinner, Flashbar } from '@amzn/awsui-components-react';
import { BrowserRouter as Router, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import './App.scss';
import Header from '../../common/components/Header';
import Navigation from '../../common/components/Navigation';
import Routes from '../Routes';
import { useNotifications } from '../../common/context/grimsbyNotifications';
import {
    getUser,
    selectUser,
    selectError,
    selectStatus,
    selectIsLoaded,
} from '../../common/store/slices/userSlice';
import { RedirectionData } from '../../common/interfaces/redirection';
import { checkUserDetails } from '../../common/utils/auth';
import Footer from '../../common/components/Footer';
import { useGrimsbyNav } from '../../common/context/grimsbyNavigation';
import { hasValidToken, redirectToLoginPage } from '../../common/utils/amplify';
import Authenticator from '../../main/Authenticator/Authenticator';

const App = () => {
    const [authUser, setAuthUser] = useState(null);
    const userDetails = useSelector(selectUser);
    const userDetailsError = useSelector(selectError);
    const userDetailsStatusCode = useSelector(selectStatus);
    const userDetailsAreLoaded = useSelector(selectIsLoaded);
    const { notifications } = useNotifications();
    const { sideNavEnabled, isNavOpened } = useGrimsbyNav();
    const dispatch = useDispatch();

    const redirectToErrorPage = (data: RedirectionData): JSX.Element | null => {
        if (data.redirect) {
            return (
                <Redirect
                    to={{
                        pathname: data.path || '/error',
                        state: {
                            children: data.message,
                        },
                    }}
                />
            );
        }
        return null;
    };

    useEffect(() => {
        if (!authUser) {
            hasValidToken()
                .then((authUser: any) => {
                    if (!authUser) {
                        redirectToLoginPage();
                    } else {
                        setAuthUser(authUser);
                        if (!userDetails) {
                            dispatch(getUser());
                        }
                    }
                })
                // eslint-disable-next-line no-console
                .catch((err) => console.error(err && err.message, err));
        }
    }, [authUser, userDetails, dispatch]);

    const redirection = checkUserDetails(
        userDetailsAreLoaded,
        userDetailsError,
        userDetailsStatusCode,
        userDetails,
    );

    return (
        <Router>
            <Authenticator globals={window}>
                <div className="awsui" data-testid="App">
                    <Header />
                    <AppLayout
                        data-testid="MainContent"
                        toolsHide
                        className="fullscreen"
                        content={
                            userDetailsAreLoaded ? (
                                <Routes />
                            ) : (
                                <div className="awsui-util-status-inactive awsui-util-t-c awsui-util-p-l">
                                    <Spinner /> Loading
                                </div>
                            )
                        }
                        headerSelector=".grimsby-header"
                        footerSelector=".grimsby-footer"
                        navigation={userDetailsAreLoaded && <Navigation />}
                        navigationHide={!sideNavEnabled}
                        navigationOpen={isNavOpened}
                        notifications={<Flashbar items={notifications} />}
                    />
                    {redirectToErrorPage(redirection)}
                    <Footer />
                </div>
            </Authenticator>
        </Router>
    );
};

export default App;
