import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Form } from '@amzn/awsui-components-react';

import {
    ActivityData,
    DeliveryInstructor,
    EditActivityData,
} from '../../../interfaces/activity';
import EditDeliveryDetailsFormSection from '../FormSections/EditDeliveryDetailsFormSection';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useNotifications } from '../../../../common/context/grimsbyNotifications';
import { useDispatch, useSelector } from 'react-redux';
import useFormValidation, {
    ValidationType,
} from '../../../../common/utils/formValidation';
import { OptionDefinition } from '@amzn/awsui-components-react-v3/polaris/internal/components/option/interfaces';
import {
    ActivityControlArrayValues,
    ActivityDeliveryDetailsFormSectionProps,
    ActivityInstructorControlArrayFormValues,
    ActivitySessionControlArrayFormValues,
    getSessionAttributeEditorItem,
    HYBRID_MODALITY,
    ILT_MODALITY,
    InstructorAvailabilityData,
    SessionAttributeEditorItem,
} from '../Common/Common';
import { FormSectionMode } from '../../../../common/constants/forms';
import CancelModal, {
    CancelModalProps,
} from '../../../../common/components/CancelModal/CancelModal';
import { getDeliverySession } from '../Create/ActivityCreateForm';
import { updateSelectedActivity } from '../../../store/slices/selectedActivitySlice';
import { FORM_ERROR_SELECTOR } from '../../../../imt/components/Instructor/FormSections/FormSections.common';
import {
    validateInstructorsForActivity,
    handleCheckInstructorActivityValidation,
    doesActivityHavePrimaryInstructor,
    doesActivityHaveOnlyOnePrimaryInstructor,
    doesActivityHaveSameDeliveryDetails,
    doesActivityHaveDifferentPrimaryInstructor,
} from '../../../services/activity-service';

import EditDeliveryDetailsRescheduleModal from './EditDeliveryDetailsModals/EditDeliveryDetailsRescheduleModal';
import EditDeliveryDetailsAdjacentActivitiesModal from './EditDeliveryDetailsModals/EditDeliveryDetailsAdjacentActivitiesModal';
import EditDeliveryDetailsQualificationsModal from './EditDeliveryDetailsModals/EditDeliveryDetailsQualificationsModal';
import EditDeliveryDetailsConfirmNoPrimaryModal from './EditDeliveryDetailsModals/EditDeliveryDetailsConfirmNoPrimaryModal';
import EditDeliveryDetailsReassignModal from './EditDeliveryDetailsModals/EditDeliveryDetailsReassignModal';
import { UpdateActivityErrorMessage } from '../../Common/constants/flashContent';
import { selectFeatures, selectIsLoaded as selectIsFeaturesLoaded } from "../../../../common/store/slices/featureSlice";
import { checkFeature } from "../../../../common/utils/featureFlag";
import EditDeliveryDetailsOverrideAutoAssignmentModel
    from "./EditDeliveryDetailsModals/EditDeliveryDetailsOverrideAutoAssignmentModel";

const editvILTDeliveryDetailsValidationConfig: {
    [key in ValidationType]?: Array<
        keyof Omit<ActivityData, 'delivery_sessions'>
    >;
} = {
    required: [
        'course_name',
        'delivery_timezone',
        'delivery_city',
        'delivery_country',
        'delivery_region',
        'delivery_geo',
        'delivery_language',
    ],
};

const editILTDeliveryDetailsValidationConfig: {
    [key in ValidationType]?: Array<
        keyof Omit<ActivityData, 'delivery_sessions'>
    >;
} = {
    required: [
        'course_name',
        'delivery_timezone',
        'delivery_city',
        'delivery_country',
        'delivery_region',
        'delivery_geo',
        'delivery_language',
        'delivery_address_1',
        'delivery_postal_code',
    ],
};

export interface ActivityConfirmation {
    instructor?: any;
    type: string;
}

export const deliveryDetailsAttributeValidationConfig: {
    [activityControlArrayFormValuesKey in keyof ActivityControlArrayValues]: {
        [key in ValidationType]?: Array<
            keyof ActivityControlArrayValues[activityControlArrayFormValuesKey]
        >;
    };
} = {
    instructorAttributeEditorItems: {
        required: ['role', 'do_not_shuffle'],
    },
    sessionAttributeEditorItems: {
        required: [
            'dateString',
            'startTime',
            'endTime',
            'endTimeMeridiemFieldOption',
            'startTimeMeridiemFieldOption',
        ],
    },
};

const EditDeliveryDetailsForm = ({
    initialFormState,
}: {
    initialFormState: ActivityData;
}) => {
    const match = useRouteMatch<{ id: string }>();
    const history = useHistory();
    const { addNotification, removeAllNotifications } = useNotifications();
    const dispatch = useDispatch();
    const [
        rescheduleReason,
        setRescheduleReason,
    ] = useState<OptionDefinition | null>(null);
    const [
        requiresInstructorActivityValidation,
        setRequiresInstructorActivityValidation,
    ] = useState(false);
    const [formValues, setFormValues] = useState(initialFormState);
    const [submitting, setSubmitting] = useState(false);
    const [requiresAutoAssignPrimaryInstructor, setRequiresAutoAssignPrimaryInstructor] = useState(false);
    const [cancelModalVisible, setCancelModalVisible] = useState(false);
    const {
        isInvalid,
        isControlArrayInvalid,
        errors,
        controlArrayErrors,
        validateForm,
        validateFormControlArray,
    } = useFormValidation<
        Omit<ActivityData, 'delivery_sessions'>,
        ActivitySessionControlArrayFormValues &
            ActivityInstructorControlArrayFormValues
    >();
    const [validatedInstructors, setValidatedInstructors] = useState<
        Array<any>
    >(initialFormState?.instructors?.map((instructor) => instructor.pk) ?? []);

    const [
        sessionAttributeEditorItems,
        setSessionAttributeEditorItems,
    ] = useState(
        formValues.delivery_sessions.map((item) =>
            getSessionAttributeEditorItem(item, formValues.delivery_timezone),
        ) || [],
    );

    const [
        instructorValidationResponse,
        setInstructorValidationResponse,
    ] = useState<Array<InstructorAvailabilityData>>([]);

    const [
        instructorAttributeEditorItems,
        setInstructorAttributeEditorItems,
    ] = useState<Array<DeliveryInstructor>>(
        formValues.instructors?.map((instructor) => ({ ...instructor })) || [],
    );

    const [confirmationModals, setConfirmationModals] = useState<
        Array<ActivityConfirmation>
    >([]);

    const [confirmationModalIndex, setConfirmationModalIndex] = useState<
        number | null
    >(null);

    const [instructorItemErrors, setInstructorItemErrors] = useState<any>({});

    const featureFlags = useSelector(selectFeatures);
    const featuresLoaded = useSelector(selectIsFeaturesLoaded);
    const autoAssignInstructorFeatureFlag = useMemo(() => {
        return  featuresLoaded && checkFeature(
            '',
            { featureName: 'auto-assign-instructor' },
            featureFlags?.features,
        )
    }, [featuresLoaded, featureFlags]);

    const [autoAssignInstructor, setAutoAssignInstructor] = useState<boolean | undefined>(initialFormState.auto_assign_instructor);

    const [overrideReason, setOverrideReason] =
        useState<OptionDefinition | null>(null);

    useEffect(() => {
        removeAllNotifications();
    }, [removeAllNotifications]);

    const handleFieldEvent = useCallback(
        (changes: Partial<Omit<EditActivityData, 'delivery_sessions'>>) => {
            setFormValues((values) => ({
                ...values,
                ...changes,
            }));
        },
        [],
    );

    const handleSessionItemEvent = (
        sessionItems: Array<SessionAttributeEditorItem>,
    ) => {
        setRequiresInstructorActivityValidation(true);
        setValidatedInstructors([]);
        setSessionAttributeEditorItems(sessionItems);
    };

    const handleInstructorItemEvent = (
        instructorItems: Array<DeliveryInstructor>,
    ) => {
        setInstructorAttributeEditorItems(instructorItems);
    };

    const handleAddInstructor = (instructorPk: string) => {
        setValidatedInstructors([...validatedInstructors, instructorPk]);
    };

    const handleScrollToError = () => {
        const topMostError = document.querySelector(`.${FORM_ERROR_SELECTOR}`);

        topMostError?.scrollIntoView({
            behavior: 'smooth',
        });
    };

    const handleAutoAssignInstructorChange = (autoAssignInstructor: boolean | undefined) => {
        setAutoAssignInstructor(autoAssignInstructor);
    };

    const validateInstructorAvailability = async ({
        delivery_country,
        delivery_timezone,
        delivery_sessions,
        delivery_language,
        course_name,
    }: ActivityData) => {
        const instructorValidationResponse = await validateInstructorsForActivity(
            {
                id: match.params.id,
                instructors: instructorAttributeEditorItems,
                delivery_language: delivery_language,
                delivery_country: delivery_country,
                delivery_sessions,
                delivery_timezone: delivery_timezone,
                course_name: course_name,
            },
        );
        setInstructorValidationResponse(instructorValidationResponse);
        return instructorValidationResponse;
    };

    const areSessionsInvalid = () => {
        const sessionsInvalid = validateFormControlArray(
            { sessionAttributeEditorItems, instructorAttributeEditorItems },
            deliveryDetailsAttributeValidationConfig,
        );

        return sessionsInvalid;
    }

    const handleSubmitEvent = async () => {
        setInstructorValidationResponse([]);
        setSubmitting(true);
        setInstructorItemErrors(null);
        const modality = formValues.activity_modality;
        let invalid = false;

        if (modality === ILT_MODALITY || modality === HYBRID_MODALITY) {
            invalid = validateForm(
                formValues,
                editILTDeliveryDetailsValidationConfig,
            );
        } else {
            invalid = validateForm(
                formValues,
                editvILTDeliveryDetailsValidationConfig,
            );
        }
        //for ILT, we need to validate address line 1 and postalcode
        const sessionArrayInvalid = areSessionsInvalid();
        // Validates that the instructor has no booked activities and available for dates.
        const validationResponse = await validateInstructorAvailability({
            ...formValues,
            delivery_sessions: sessionAttributeEditorItems
                .filter(
                    (item) => item.startTime && item.endTime && item.dateString,
                )
                .map((item) =>
                    getDeliverySession(item, formValues.delivery_timezone),
                ),
        });

        const isInstructorAvailable =
            validationResponse.filter((instructor) => {
                let availability = ['do_not_shuffle', 'reserved'];

                if (availability.includes(instructor.availability)) {
                    return true;
                }

                return false;
            }).length === 0;

        const duplicatePrimaryKey = doesActivityHaveOnlyOnePrimaryInstructor(
            instructorAttributeEditorItems,
        );

        if (duplicatePrimaryKey) {
            setInstructorItemErrors({
                ...instructorItemErrors,
                [duplicatePrimaryKey as string]: {
                    role: 'Limit of one primary instructor.',
                },
            });
        }

        const isActivityFormInvalid =
            invalid ||
            sessionArrayInvalid ||
            !isInstructorAvailable ||
            duplicatePrimaryKey !== null;

        if (isActivityFormInvalid) {
            handleScrollToError();
            setSubmitting(false);
        } else {
            // make sure last check validations are good
            let pendingActivityConfirmations = handleCheckInstructorActivityValidation(
                validationResponse,
                requiresInstructorActivityValidation,
                formValues,
                validatedInstructors,
            );

            // Remove reassign confirmation if primary instructor has a schedule conflict.
            // In this case, AIA will take care of assigning a new instructor.
            if (autoAssignInstructor && autoAssignInstructorFeatureFlag) {
                let primary_instructor_pk = null;
                for (let instructor of instructorAttributeEditorItems) {
                    const {role: instructorRole, pk: instructorPk} = instructor;
                    if (instructorRole === 'Primary') {
                        primary_instructor_pk = instructorPk;
                    }
                }
                if (primary_instructor_pk != null) {
                    const indexToRemove = pendingActivityConfirmations.findIndex(
                        confirmation => confirmation.type === "reassign"
                            && confirmation.instructor.pk === primary_instructor_pk
                    )
                    if (indexToRemove !== -1) {
                        pendingActivityConfirmations.splice(indexToRemove, 1);
                        setRequiresAutoAssignPrimaryInstructor(true);
                    }
                }
            }

            if (
                !doesActivityHavePrimaryInstructor(
                    instructorAttributeEditorItems,
                )
            ) {
                pendingActivityConfirmations.push({
                    type: 'no_primary',
                });
            }

            if (requiresInstructorActivityValidation && !rescheduleReason) {
                pendingActivityConfirmations.push({
                    type: 'reschedule',
                    instructor: null,
                });
            }

            // Validate whether previously auto-assigned instructor has a manual override
            const isInstructorPreviouslyAutoAssigned = initialFormState.auto_assign_instructor
            const isAutoAssignInstructorToggleDisabled = autoAssignInstructor === false

            const isAutoAssignedInstructorOverridden = doesActivityHaveSameDeliveryDetails(initialFormState, formValues)
            && doesActivityHaveDifferentPrimaryInstructor(initialFormState, instructorAttributeEditorItems)
            && isInstructorPreviouslyAutoAssigned
            && isAutoAssignInstructorToggleDisabled
            && autoAssignInstructorFeatureFlag

            if (isAutoAssignedInstructorOverridden) {
                pendingActivityConfirmations.push({
                        type: 'auto_assigned_instructor_overridden',
                        instructor: null,
                });
            }

            if (pendingActivityConfirmations.length === 0) {
                handleConfirmSave();
            } else {
                // we have confirmations
                setConfirmationModals(pendingActivityConfirmations);
                setConfirmationModalIndex(0);
            }
        }
    };

    const handleConfirmationNext = () => {
        if (confirmationModalIndex === null) {
            handleConfirmSave();
            return;
        }

        if (confirmationModalIndex === confirmationModals.length - 1) {
            // we are good, confirm
            handleConfirmSave();
        } else {
            const nextIndex = confirmationModalIndex + 1;
            setConfirmationModalIndex(nextIndex);
        }
    };

    const cancelConfirmationModal = () => {
        handleScrollToError();
        setSubmitting(false);
        setConfirmationModals([]);
        setConfirmationModalIndex(null);
    };

    const handleConfirmSave = async () => {
        const activityData: Partial<ActivityData> = {
            ...formValues,
            delivery_sessions: sessionAttributeEditorItems
                .filter(
                    (item) => item.startTime && item.endTime && item.dateString,
                )
                .map((item) =>
                    getDeliverySession(item, formValues.delivery_timezone),
                ),
            instructors: instructorAttributeEditorItems,
            auto_assign_instructor: requiresAutoAssignPrimaryInstructor,
        };

        const isSuccessful = await dispatch<any>(
            updateSelectedActivity(match.params.id, activityData),
        );

        removeAllNotifications();

        addNotification({
            id: `update-activity`,
            ...(isSuccessful
                ? {
                      type: 'success',
                      content: 'The activity has been updated.',
                  }
                : {
                      type: 'error',
                      content: <UpdateActivityErrorMessage />,
                  }),
        });

        if (isSuccessful) {
            navigateToDetailPage();
        } else {
            setSubmitting(false);
        }
    };

    const editDeliveryDetailsFormProps: ActivityDeliveryDetailsFormSectionProps & {
        handleAutoAssignInstructorChange: (autoAssignInstructor: boolean) => void;
    } = {
        formValues,
        errors,
        controlArrayErrors,
        handleFieldEvent,
        mode: FormSectionMode.Edit,
        handleSessionItemEvent,
        sessionAttributeEditorItems,
        handleInstructorItemEvent,
        instructorAttributeEditorItems,
        instructorValidationResponse,
        handleAddInstructor,
        instructorItemErrors,
        areSessionsInvalid,
        handleAutoAssignInstructorChange,
    };

    const navigateToDetailPage = () => {
        history.push({
            pathname: `/activities/${match.params.id}`,
        });
    };

    const cancelModalProps: CancelModalProps = {
        cancelModalVisible,
        setCancelModalVisible,
        submitting,
        onCancelConfirm: navigateToDetailPage,
        testPrefix: 'EditDeliveryDetails',
    };

    const handleChangeRescheduleReason = (e: any) => {
        handleFieldEvent({
            reschedule_reason: e.label,
        });
        setRescheduleReason(e);
    };

    const handleOverrideReason = (e: any) => {
        handleFieldEvent({
            instructor_assignment_override_reason: e.label,
        });
        setOverrideReason(e);
    };

    useEffect(() => {
        if (isInvalid) {
            const modality = formValues.activity_modality;
            if (modality === ILT_MODALITY || modality === HYBRID_MODALITY) {
                validateForm(
                    formValues,
                    editILTDeliveryDetailsValidationConfig,
                );
            } else {
                validateForm(
                    formValues,
                    editvILTDeliveryDetailsValidationConfig,
                );
            }
        }

        if (isControlArrayInvalid) {
            validateFormControlArray(
                { sessionAttributeEditorItems, instructorAttributeEditorItems },
                deliveryDetailsAttributeValidationConfig,
            );
        }
    }, [
        isInvalid,
        formValues,
        validateForm,
        validateFormControlArray,
        sessionAttributeEditorItems,
        instructorAttributeEditorItems,
        isControlArrayInvalid,
    ]);

    return (
        <>
            <Form
                header={initialFormState.activity_name}
                data-testid="ActivityEditDeliveryDetailsForm"
                actions={
                    <div className="awsui-util-pt-xl">
                        <Button
                            variant="link"
                            data-testid="EditDeliveryDetailsFormCancel"
                            disabled={submitting}
                            onClick={() => {
                                setCancelModalVisible(true);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            data-testid="EditDeliveryDetailsFormSave"
                            formAction="submit"
                            disabled={submitting}
                            onClick={handleSubmitEvent}
                        >
                            {submitting ? 'Saving' : 'Save'}
                        </Button>
                    </div>
                }
            >
                <EditDeliveryDetailsFormSection
                    {...editDeliveryDetailsFormProps}
                />
            </Form>
            <CancelModal {...cancelModalProps} />
            {confirmationModalIndex !== null &&
                confirmationModals.length > 0 &&
                confirmationModals.map((confirmation: any, index: number) => {
                    const { instructor } = confirmation;

                    switch (confirmation.type) {
                        case 'reschedule':
                            return (
                                <EditDeliveryDetailsRescheduleModal
                                    instructorConflict={{
                                        instructor: null,
                                    }}
                                    visible={index === confirmationModalIndex}
                                    onCancel={() => {
                                        cancelConfirmationModal();
                                    }}
                                    onConfirm={handleConfirmationNext}
                                    rescheduleReason={rescheduleReason}
                                    setRescheduleReason={
                                        handleChangeRescheduleReason
                                    }
                                    key={`reschedule-reasons-modal`}
                                />
                            );
                        case 'adjacent':
                            return (
                                <EditDeliveryDetailsAdjacentActivitiesModal
                                    instructorConflict={{
                                        instructor,
                                    }}
                                    visible={index === confirmationModalIndex}
                                    onCancel={() => {
                                        cancelConfirmationModal();
                                    }}
                                    onConfirm={() => {
                                        handleConfirmationNext();
                                    }}
                                    key={`delivery-details-modal`}
                                />
                            );
                        case 'reassign':
                            return (
                                <EditDeliveryDetailsReassignModal
                                    instructorConflict={{
                                        instructor,
                                    }}
                                    visible={index === confirmationModalIndex}
                                    onCancel={() => {
                                        cancelConfirmationModal();
                                    }}
                                    onConfirm={() => {
                                        handleConfirmationNext();
                                    }}
                                    handleFieldEvent={handleFieldEvent}
                                    key={`delivery-details-modal-${instructor.pk}-${index}`}
                                />
                            );
                        case 'qualification':
                            return (
                                <EditDeliveryDetailsQualificationsModal
                                    instructorConflict={{
                                        instructor,
                                    }}
                                    visible={index === confirmationModalIndex}
                                    onCancel={() => {
                                        cancelConfirmationModal();
                                    }}
                                    onConfirm={() => {
                                        handleConfirmationNext();
                                    }}
                                    formValues={formValues}
                                    missingQualifications={
                                        confirmation.missingQualifications
                                    }
                                    key={`qualifications-modal-${index}`}
                                />
                            );
                        case 'no_primary':
                            return (
                                <EditDeliveryDetailsConfirmNoPrimaryModal
                                    instructorConflict={{
                                        instructor,
                                    }}
                                    visible={index === confirmationModalIndex}
                                    onCancel={() => {
                                        cancelConfirmationModal();
                                    }}
                                    onConfirm={() => {
                                        handleConfirmationNext();
                                    }}
                                    key={`no-primary-modal-${index}`}
                                />
                            );
                         case 'auto_assigned_instructor_overridden':
                            return (
                                <EditDeliveryDetailsOverrideAutoAssignmentModel
                                    visible={index === confirmationModalIndex}
                                    onCancel={() => {
                                        cancelConfirmationModal();
                                    }}
                                    onConfirm={() => {
                                        handleConfirmationNext();
                                    }}
                                    overrideReason={overrideReason}
                                    setOverrideReason={
                                        handleOverrideReason
                                    }
                                    key={`auto-assignment-override-modal-${index}`}
                                />
                            );
                        default:
                            return <></>;
                    }
                })}
        </>
    );
};

export default EditDeliveryDetailsForm;
