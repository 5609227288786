import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CostTypesResponseData } from '../../interfaces/businessDataResponse/costTypesResponse';
import { HandleRequestData } from '../../interfaces/handleRequest';
import businessDataApi from '../../api/businessDataApi';
import { BusinessDataApiQueryParams } from '../../interfaces/queryParams';
import { AppDispatch, GlobalState } from '../../../main/store';
import { ExtendedBusinessDataStoreState } from '../../interfaces/businessDataStoreState';
import { CostTypeItemData } from '../../interfaces/businessDataItem/costTypeItem';
import parseBoolean from '../../utils/parseBoolean';
import {
    ExtendedBusinessDataStoreInitialState,
    getActiveBusinessDataValues,
    resetBasicBusinessDataStoreState,
    resetExtendedBusinessDataStoreState,
    initializeBusinessDataListQueryParamsStoreState,
} from '../store.common';

/**
 * costTypesSlice manages all costTypes state, and contains costTypes actions as well as costTypes state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const costTypesSlice = createSlice({
    name: 'costTypes',
    initialState: {
        ...ExtendedBusinessDataStoreInitialState,
    } as ExtendedBusinessDataStoreState<CostTypeItemData>,
    reducers: {
        setCostTypesList: (
            state,
            action: PayloadAction<CostTypeItemData[]>,
        ) => {
            const byCostTypeName = action.payload.reduce(
                (
                    byCostTypeName: {
                        [key: string]: CostTypeItemData;
                    },
                    costType: CostTypeItemData,
                ) => {
                    byCostTypeName[costType.cost_type] = {
                        ...costType,
                        active: parseBoolean(costType.active),
                    };
                    return byCostTypeName;
                },
                {},
            );
            state.entities = byCostTypeName;
            state.keysList = Object.keys(byCostTypeName);
        },
        setCostType: (state, action: PayloadAction<CostTypeItemData>) => {
            // if the item is new, add a new key for it and update the count.
            if (!state.entities[action.payload.cost_type]) {
                state.keysList = [...state.keysList, action.payload.cost_type];
                state.count = state.keysList.length;
            }
            state.entities[action.payload.cost_type] = action.payload;
        },
        setSelectedCostType: (state, action: PayloadAction<string | null>) => {
            state.selectedItemKey = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
        setCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload;
        },
        setSearchText: (state, action: PayloadAction<string | null>) => {
            state.searchText = action.payload;
        },
        setActive: (
            state,
            action: PayloadAction<BusinessDataApiQueryParams.Active>,
        ) => {
            state.active = action.payload;
        },
        setFrom: (state, action: PayloadAction<number>) => {
            state.from = action.payload;
        },
        setPagesCount: (state, action: PayloadAction<number>) => {
            state.pagesCount = action.payload;
        },
        setCurrentPageIndex: (state, action: PayloadAction<number>) => {
            state.currentPageIndex = action.payload;
        },
        setSize: (state, action: PayloadAction<number>) => {
            state.size = action.payload;
        },
        resetPartialCostTypesSlice: resetBasicBusinessDataStoreState,
        resetCostTypesSlice: resetExtendedBusinessDataStoreState,
        initializeCostTypesListQueryParams: initializeBusinessDataListQueryParamsStoreState,
    },
});

export const {
    setIsLoading,
    setIsLoaded,
    setCostTypesList,
    setCostType,
    setSelectedCostType,
    setError,
    setCount,
    setSearchText,
    setActive,
    setFrom,
    setPagesCount,
    setCurrentPageIndex,
    setSize,
    resetPartialCostTypesSlice,
    resetCostTypesSlice,
    initializeCostTypesListQueryParams,
} = costTypesSlice.actions;

const handleCostTypesListRequest = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        const { from, size, searchText: cost_type, active } = state.costTypes;

        const params: BusinessDataApiQueryParams.GetCostTypes = {
            active,
        };

        // if both "from" and "size" equal to 0, avoid adding them into the query parameters
        if (from !== 0 || size !== 0) {
            params.from = from;
            params.size = size;
        }
        if (cost_type) {
            params.cost_type = cost_type;
        }

        try {
            const {
                result: { COST_TYPE, total_count },
            }: HandleRequestData<CostTypesResponseData> = await businessDataApi.getCostTypes(
                params,
            );

            dispatch(setCostTypesList(COST_TYPE));
            dispatch(setCount(total_count));
            dispatch(setPagesCount(Math.ceil(total_count / size)));
        } catch (error: any) {
            dispatch(setError(error?.message || 'getCostTypes API error'));
        }
    };
};

export const getCostTypesList = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));
        await dispatch(handleCostTypesListRequest());
        if (!state.costTypes.isLoaded) {
            dispatch(setIsLoaded(true));
        }
        dispatch(setIsLoading(false));
    };
};

/**
 * action to update cost type
 * before update api call, fetch cost types and prepare payload containing only the changed fields
 * after update api call, fetch cost types and update the current item in that keysList with the item data we updated
 * @param data
 */
export const updateCostType = (data: CostTypeItemData) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        // immediately put the keysList into loading while we finish updating the item/fetching the keysList
        dispatch(setIsLoading(true));
        if (!data.pk || data?.pk.length <= 0) {
            // eslint-disable-next-line no-console
            console.error(
                `Error updating cost type: required field pk was missing`,
            );
            dispatch(setIsLoading(false));
            return false;
        }
        try {
            await businessDataApi.updateCostType(data.pk, data.active);
            await dispatch(handleCostTypesListRequest());
            // force-update the item in the keysList for now, since the keysList we fetched may not have this item's update yet
            dispatch(
                setCostType({
                    ...state.costTypes.entities[data.cost_type],
                    active: data.active,
                }),
            );
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const addCostType = (active = true, costTypeName: string) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        dispatch(setIsLoading(true));
        try {
            await businessDataApi.addCostType(costTypeName, active);
            // now refresh the keysList
            await dispatch(handleCostTypesListRequest());
            if (!state.costTypes?.entities[costTypeName]) {
                // shove the item into the keysList, but only if it's not there already
                const newCostTypeData: CostTypeItemData = {
                    cost_type: costTypeName,
                    active: active,
                };
                dispatch(setCostType(newCostTypeData));
            }
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const selectAllCostTypes = (state: GlobalState) => {
    return state.costTypes.keysList.map(
        (costTypeName) => state.costTypes.entities[costTypeName],
    );
};

export const selectAllActiveCostTypes = (state: GlobalState) => {
    return getActiveBusinessDataValues(
        state.costTypes.keysList,
        state.costTypes.entities,
    );
};

export const selectIsLoading = (state: GlobalState) =>
    state.costTypes.isLoading;

export const selectIsLoaded = (state: GlobalState) => state.costTypes.isLoaded;

export const selectSelectedCostType = (state: GlobalState) => {
    return state?.costTypes?.selectedItemKey
        ? state?.costTypes?.entities[state?.costTypes?.selectedItemKey]
        : null;
};

export const selectError = (state: GlobalState) => state.costTypes.error;

export const selectCount = (state: GlobalState) => state.costTypes.count;

export const selectSearchText = (state: GlobalState) =>
    state.costTypes.searchText;

export const selectPagesCount = (state: GlobalState) =>
    state.costTypes.pagesCount;

export const selectCurrentPageIndex = (state: GlobalState) =>
    state.costTypes.currentPageIndex;

export default costTypesSlice.reducer;
