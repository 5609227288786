import React, { useEffect } from 'react';
import {
    Route,
    Switch,
    Redirect,
    useLocation,
    matchPath,
} from 'react-router-dom';

import AlertPage from '../../common/components/AlertPage';
import UserProfile from '../../common/components/User/UserProfile';
import RedirectedAlertPage from '../../common/components/AlertPage/RedirectedAlertPage';
import { getUserRoles } from '../../common/utils/auth';
import { Actions } from '../../common/constants/auth';
import Can from '../../common/components/Can';
import Admin from '../../admin/components/Admin';
import Instructors from '../../imt/components/Instructor/Instructors';
import { useNotifications } from '../../common/context/grimsbyNotifications';
import { BASE_PATH } from '../../common/constants/path';
import Activities from '../../smt/components/Activity/Activities';
import { ACTIVITY_PATH } from '../../smt/constants/path';
import { INSTRUCTOR_PATH } from '../../imt/constants/path';
import { ADMIN_PATH } from '../../admin/constants/path';
import rumClient from '../../common/utils/CloudWatchRUM';
import Onboard from '../../common/components/User/Onboard';

const urlsToKeepNotificationsWhenMounting = [
    INSTRUCTOR_PATH.CREATE,
    INSTRUCTOR_PATH.DETAIL,
    INSTRUCTOR_PATH.EDIT_BASIC_INFO,
    INSTRUCTOR_PATH.EDIT_INSTRUCTOR_DETAILS,
    INSTRUCTOR_PATH.EDIT_SKILLS_AND_COURSES,
    ACTIVITY_PATH.DETAIL,
    ACTIVITY_PATH.CREATE,
    ACTIVITY_PATH.INSTRUCTORS_LIST,
    ACTIVITY_PATH.CREATE_INVOICE,
    ACTIVITY_PATH.EDIT_DELIVERY_DETAILS,
    ADMIN_PATH.USER_CREATE,
    ADMIN_PATH.USER_DETAIL,
    ADMIN_PATH.USER_EDIT,
];

const urlsToRemoveNotificationsWhenUnmounting = [
    INSTRUCTOR_PATH.LIST,
    ACTIVITY_PATH.LIST,
];

/**
 * Sets the Routes and renders the appropriate
 * component for the route
 */
const Routes = () => {
    const location = useLocation();
    const { removeAllNotifications } = useNotifications();

    // if the current url matches one url of "urlsToKeepNotificationsWhenMounting",
    // the component will keep the notifications when mounting, i.e.
    // "removeNotificationsWhenMounting" will be "false"; vice versa.
    const removeNotificationsWhenMounting =
        !urlsToKeepNotificationsWhenMounting.some((url) =>
            matchPath(location.pathname, {
                path: url,
                exact: true,
                strict: false,
            }),
        );

    // if the current url matches one url of "removeNotificationsWhenUnmounting",
    // the component will remove the notifications when un-mounting, i.e.
    // "removeNotificationsWhenUnmounting" will be "true"; vice versa.
    const removeNotificationsWhenUnmounting =
        urlsToRemoveNotificationsWhenUnmounting.some((url) =>
            matchPath(location.pathname, {
                path: url,
                exact: true,
                strict: false,
            }),
        );

    useEffect(() => {
        if (removeNotificationsWhenMounting) {
            removeAllNotifications();
        }

        if (location && rumClient) {
            rumClient.recordPageView({ pageId: location.pathname });
        }

        return () => {
            if (removeNotificationsWhenUnmounting) {
                removeAllNotifications();
            }
        };
    }, [
        location,
        removeNotificationsWhenMounting,
        removeNotificationsWhenUnmounting,
        removeAllNotifications,
    ]);

    return (
        <Switch>
            <Route exact path="/signin">
                <Can
                    roles={getUserRoles()}
                    perform={Actions.ACTIVITY_VIEW}
                    yes={() => <Redirect to={ACTIVITY_PATH.LIST} />}
                    no={() => (
                        <Can
                            roles={getUserRoles()}
                            perform={Actions.INSTRUCTOR_VIEW}
                            yes={() => <Redirect to={INSTRUCTOR_PATH.LIST} />}
                            no={() => <Redirect to={BASE_PATH.ERROR} />}
                        />
                    )}
                />
            </Route>
            <Route exact path="/onboard">
                <Onboard />
            </Route>
            {/* Home route */}
            <Route exact path="/">
                <Can
                    roles={getUserRoles()}
                    perform={Actions.ACTIVITY_VIEW}
                    yes={() => <Redirect to={ACTIVITY_PATH.LIST} />}
                    no={() => (
                        <Can
                            roles={getUserRoles()}
                            perform={Actions.INSTRUCTOR_VIEW}
                            yes={() => <Redirect to={INSTRUCTOR_PATH.LIST} />}
                            no={() => <Redirect to={BASE_PATH.ERROR} />}
                        />
                    )}
                />
            </Route>
            {/* Instructor Management routes*/}
            <Route path={INSTRUCTOR_PATH.LIST}>
                <Can
                    roles={getUserRoles()}
                    perform={Actions.INSTRUCTOR_VIEW}
                    yes={() => <Instructors />}
                    no={() => <Redirect to={BASE_PATH.ERROR} />}
                />
            </Route>
            {/* User Profile page*/}
            <Route path={BASE_PATH.USER_PROFILE}>
                <UserProfile />
            </Route>
            {/* Admin page */}
            <Route path={ADMIN_PATH.BASE}>
                <Can
                    roles={getUserRoles()}
                    perform={Actions.SYSTEM_ADMINISTRATION}
                    yes={() => <Admin />}
                    no={() => <Redirect to={BASE_PATH.ERROR} />}
                />
            </Route>
            {/* Activity page */}
            <Route path={ACTIVITY_PATH.BASE}>
                <Can
                    roles={getUserRoles()}
                    perform={Actions.ACTIVITY_VIEW}
                    yes={() => <Activities />}
                    no={() => <Redirect to={BASE_PATH.ERROR} />}
                />
            </Route>
            {/* Welcome page*/}
            <Route path={BASE_PATH.WELCOME} component={RedirectedAlertPage} />
            {/* Error page */}
            <Route path={BASE_PATH.ERROR} component={RedirectedAlertPage} />
            <Route>
                <AlertPage />
            </Route>
        </Switch>
    );
};

export default Routes;
