import React, { ReactNode, useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import {
    CalendarViewPropsData,
    InstructorEventTypes,
} from '../../../interfaces/resourceCalendarProps';
import {
    Alert,
    Box,
    Button,
    Link,
    Modal,
    SpaceBetween,
} from '@amzn/awsui-components-react-v3';

import { AJAX_CALL_ERROR } from '../../../../common/constants/grimsby';
import './CalendarView.scss';
import { INITIAL_CALENDAR_VIEW_TYPE } from '../../../constants';
import ActivityPopoverTemplate from './Popovers/ActivityPopover';
import RadioResourceLabel from './ResourceLabels/RadioResourceLabel';
import CheckboxResourceLabel from './ResourceLabels/CheckboxResourceLabel';
import { RecommendedInstructorProfileData } from '../../../interfaces/recommendedInstructorProfile';
import { LookupData } from '../../../interfaces/lookup';
import { ACTIVITY_PATH } from '../../../constants/path';
import { environment } from '../../../../common/utils/amplify';

const { CALENDER_LICENSE_KEY } = environment(window);

const FREELANCER_TITLE = 'Unavailable';

interface EventInfoProps {
    eventSource: any;
    children: ReactNode;
    isReservedTimeModalVisible?: boolean;
    handleIsVisibleChange?: (isVisible: boolean) => void;
}

const EventInfoTemplate = ({ eventSource, children }: EventInfoProps) => {
    const [showEventModal, setShowEventModal] = useState<boolean>(false);
    return (
        <>
            <Modal
                header={eventSource.activity_name}
                size="large"
                visible={showEventModal}
                onDismiss={() => {
                    setShowEventModal(false);
                }}
                footer={
                    <div className="resource-quickview-footer">
                        <div className="full-details">
                            <Link
                                data-testid={
                                    'AssignInstructorResourceViewInstructorLink'
                                }
                                external
                                externalIconAriaLabel="Opens in a new tab"
                                href={`${window.location.origin}${ACTIVITY_PATH.BASE}/${eventSource.pk}`}
                            >
                                Full activity details
                            </Link>
                        </div>
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button
                                    variant="primary"
                                    onClick={() => {
                                        setShowEventModal(false);
                                    }}
                                >
                                    Close
                                </Button>
                            </SpaceBetween>
                        </Box>
                    </div>
                }
            >
                <ActivityPopoverTemplate {...eventSource} />
            </Modal>
            <div
                onClick={() => {
                    setShowEventModal(true);
                }}
            >
                {children}
            </div>
        </>
    );
};

const ResourceCheckboxComponent = ({
    resourceData,
    resourceSource,
    selectedInstructors,
    handleSelectedInstructorsChange,
    index,
}: {
    resourceData: any;
    resourceSource: RecommendedInstructorProfileData;
    selectedInstructors: Set<RecommendedInstructorProfileData>;
    handleSelectedInstructorsChange: (
        instructors: Set<RecommendedInstructorProfileData>,
    ) => void;
    index: string;
}) => {
    return (
        <CheckboxResourceLabel
            key={index}
            {...{
                selectedInstructors,
                resourceData,
                resourceSource,
                handleSelectedInstructorsChange,
            }}
        />
    );
};

const resourceLabelComponent = ({
    resourceData,
    resourceLookup,
    resourceLabelType,
    selectedInstructors,
    handleSelectedInstructorsChange,
}: {
    resourceData: any;
    resourceLookup: LookupData<RecommendedInstructorProfileData>;
    resourceLabelType: string;
    selectedInstructors: Set<RecommendedInstructorProfileData>;
    handleSelectedInstructorsChange: (
        instructors: Set<RecommendedInstructorProfileData>,
    ) => void;
}) => {
    const resourceSource = resourceLookup[resourceData.resource.id];
    if (resourceLabelType === 'radio') {
        return (
            <RadioResourceLabel
                {...{
                    selectedInstructors,
                    resourceData,
                    resourceSource,
                    handleSelectedInstructorsChange,
                }}
            />
        );
    } else {
        return (
            <ResourceCheckboxComponent
                {...{
                    resourceData,
                    resourceSource,
                    selectedInstructors,
                    handleSelectedInstructorsChange,
                    index: resourceData.resource.id,
                }}
            />
        );
    }
};

const CalendarResourceView = ({
    handleCalendarRefChange,
    events,
    resources,
    eventLookup,
    resourceLookup,
    selectedInstructors,
    handleSelectedInstructorsChange,
    error,
    initialDate,
    resourceLabelType,
    calendarHeight,
    isBlockedTimeInfoModalVisible,
    handleEventModalChange = (eventSource) => {},
}: CalendarViewPropsData) => {
    const [calendarLicense, setCalendarLicense] = useState('');
    useEffect(() => {
        const fetchConfig = async () => {
            setCalendarLicense(CALENDER_LICENSE_KEY);
        };
        fetchConfig();
    }, [calendarLicense]);
    if (error) {
        return (
            <Alert
                data-testid="AssignInstructorResourceViewError"
                header="Error"
                type="error"
            >
                {AJAX_CALL_ERROR}
            </Alert>
        );
    } else {
        return (
            <div data-testid={`AssignInstructorResourceView`}>
                <FullCalendar
                    schedulerLicenseKey={calendarLicense}
                    ref={(newRef) =>
                        newRef ? handleCalendarRefChange(newRef) : null
                    }
                    plugins={[resourceTimelinePlugin, interactionPlugin]}
                    initialView={INITIAL_CALENDAR_VIEW_TYPE}
                    headerToolbar={false}
                    resources={{
                        resources,
                    }}
                    resourceOrder="title"
                    resourceAreaHeaderContent={'Instructors'}
                    slotDuration={{
                        days: 1,
                    }}
                    initialDate={initialDate}
                    height={calendarHeight}
                    slotLabelFormat={[
                        { weekday: 'short', day: 'numeric', month: 'short' }, // lower level of text
                    ]}
                    progressiveEventRendering
                    resourceAreaHeaderClassNames={'column-header'}
                    slotLabelClassNames={'column-header'}
                    resourceLabelContent={(resourceData: any) =>
                        resourceLabelComponent({
                            resourceData,
                            resourceLookup,
                            resourceLabelType,
                            selectedInstructors,
                            handleSelectedInstructorsChange,
                        })
                    }
                    eventBorderColor={'transparent'}
                    eventContent={(eventInfo) => {
                        const eventSource = eventLookup[eventInfo.event.id];
                        if (
                            eventInfo.event.extendedProps.eventType ===
                            InstructorEventTypes.blockedTime
                        ) {
                            const eventSourceWithInstructor = {
                                ...eventSource,
                                instructor:
                                    resourceLookup[eventSource.instructor_pk],
                            };
                            const isFreelancer =
                                !!eventSourceWithInstructor.instructor
                                    .is_freelancer;
                            return (
                                !!eventSourceWithInstructor && (
                                    <>
                                        <Button
                                            variant="link"
                                            onClick={() => {
                                                handleEventModalChange(
                                                    true,
                                                    eventSourceWithInstructor,
                                                );
                                            }}
                                            data-testid="reserveTimeInfoModal"
                                            className={
                                                isFreelancer
                                                    ? 'btn-unavailable-time-link'
                                                    : 'btn-reserved-time-link'
                                            }
                                        >
                                            {isFreelancer
                                                ? FREELANCER_TITLE
                                                : eventInfo.event.title}
                                        </Button>
                                    </>
                                )
                            );
                        }
                        if (
                            eventInfo.event.extendedProps.eventType ===
                            InstructorEventTypes.activity
                        ) {
                            return (
                                !!eventSource && (
                                    <EventInfoTemplate
                                        eventSource={eventSource}
                                        isReservedTimeModalVisible={
                                            isBlockedTimeInfoModalVisible
                                        }
                                    >
                                        <div
                                            style={{
                                                borderRadius: '5px',
                                                padding: '5px',
                                            }}
                                        >
                                            {eventInfo.event.title}
                                        </div>
                                    </EventInfoTemplate>
                                )
                            );
                        }
                    }}
                    events={events}
                />
            </div>
        );
    }
};

export default CalendarResourceView;
