import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Form } from '@amzn/awsui-components-react';

import {
    adminUpdateUser,
    selectIsLoading,
} from '../../../store/slices/selectedUserSlice';
import { useNotifications } from '../../../../common/context/grimsbyNotifications';
import handleEditUserNotification from '../../../../common/utils/handleEditUserNotification';
import useFormValidation, {
    ValidationType,
} from '../../../../common/utils/formValidation';
import { UserProfileData } from '../../../../common/interfaces/userProfile';
import { FormSectionProps } from '../../../../common/interfaces/formSectionProps';
import AdminUserManagementFormSection from '../FormSections/AdminUserManagementFormSection';
import { FormSectionMode } from '../../../../common/constants/forms';
import { UserFormData } from '../../../interfaces/userForm';

const updateUserValidationConfig: {
    [key in ValidationType]?: Array<keyof UserFormData>;
} = {
    required: ['programs', 'regions'],
};

const AdminUserManagementEditForm = ({ user }: { user: UserProfileData }) => {
    const isLoading = useSelector(selectIsLoading);
    const history = useHistory();
    const dispatch = useDispatch();
    const { addNotification } = useNotifications();

    const { isInvalid, errors, validateForm } =
        useFormValidation<UserFormData>();

    const [formValues, setFormValues] = useState<UserFormData>({
        programs: user.programs,
        regions: user.regions,
        vias_roles: user.vias_roles,
    });

    const handleFieldEvent = (changes: Partial<UserFormData>) =>
        setFormValues((values) => ({
            ...values,
            ...changes,
        }));

    const editUserProps: Omit<
        FormSectionProps<UserFormData>,
        'validateAndHandleFieldEvent'
    > & {
        name: string;
    } = {
        formValues,
        errors,
        handleFieldEvent,
        name: user.full_name,
        mode: FormSectionMode.Edit,
    };

    useEffect(() => {
        if (isInvalid) {
            validateForm(formValues, updateUserValidationConfig);
        }
    }, [isInvalid, formValues, validateForm]);

    return (
        <section data-testid="AdminUserManagementEditForm">
            <Form
                header="Edit User"
                actions={
                    <div className="awsui-util-action-stripe awsui-util-mb-m">
                        <div className="awsui-util-action-stripe-group">
                            <Button
                                variant="link"
                                className="admin-user-cancel"
                                data-testid="AdminUserManagementEditCancel"
                                onClick={() => {
                                    history.push({
                                        pathname: `/admin/user-management/users/${user.pk}`,
                                    });
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                className="admin-user-save"
                                data-testid="AdminUserManagementEditSave"
                                loading={isLoading}
                                onClick={async () => {
                                    const invalid = validateForm(
                                        formValues,
                                        updateUserValidationConfig,
                                    );
                                    if (!invalid) {
                                        const dispatchPromise = dispatch<any>(
                                            adminUpdateUser(
                                                user.pk,
                                                formValues,
                                            ),
                                        );
                                        const isSuccessful =
                                            await handleEditUserNotification(
                                                dispatchPromise,
                                                addNotification,
                                            );

                                        if (isSuccessful) {
                                            history.push({
                                                pathname: `/admin/user-management/users/${user.pk}`,
                                            });
                                        }
                                    }
                                }}
                            >
                                {isLoading ? 'Saving' : 'Save'}
                            </Button>
                        </div>
                    </div>
                }
            >
                <AdminUserManagementFormSection {...editUserProps} />
            </Form>
        </section>
    );
};

export default AdminUserManagementEditForm;
