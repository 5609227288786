import React, { useEffect, useState } from 'react';
import {
    AttributeEditor,
    ColumnLayout,
    FormField,
    FormSection,
    Input,
    Select,
    Textarea,
    DatePicker,
} from '@amzn/awsui-components-react';
import {
    FORM_ERROR_SELECTOR,
    getOptionsAndLookupForSelectInput,
    getStatusType,
} from '../../../../../imt/components/Instructor/FormSections/FormSections.common';
import {
    ActivityData,
    CommercialPrivateCustomer,
    Revenue,
} from '../../../../interfaces/activity';
import { currencyCodesOptions } from '../../../../constants/currencyCodes';
import { DEFAULT_LANGUAGE, TOF_STATUS_OPTIONS } from '../../../../constants';
import dayjs from 'dayjs';

import './CustomerForm.scss';
import {
    getRevenueTypesList,
    selectIsLoaded as selectIsRevenueTypesLoaded,
    selectIsLoading as selectIsRevenueTypesLoading,
    selectAllActiveRevenueTypes,
} from '../../../../../common/store/slices/revenueTypesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AudienceType } from '../../Common/Common';
import {
    getDeliveryLanguagesList as getLanguagesList,
    resetDeliveryLanguagesSlice as resetLanguagesSlice,
    selectAllActiveDeliveryLanguages as selectAllActiveLanguages,
    selectError as selectLanguageListError,
    selectIsLoaded as selectIsLanguageListLoaded,
    selectIsLoading as selectIsLanguageListLoading,
} from '../../../../../common/store/slices/deliveryLanguagesSlice';
import { DeliveryLanguageItemData as LanguageItemData } from '../../../../../common/interfaces/businessDataItem/deliveryLanguageItem';

interface CustomerFormSectionProps {
    formValues: CommercialPrivateCustomer;
    errors: { [key in keyof Partial<CommercialPrivateCustomer>]: string };
    handleFieldEvent: (values: Partial<CommercialPrivateCustomer>) => void;
    activity?: ActivityData;
}

const currencyOptions = currencyCodesOptions;

export const CustomerFormSection = ({
    formValues,
    errors,
    handleFieldEvent,
    activity,
}: CustomerFormSectionProps) => {
    const [tofExpirationDate, setTofExpirationDate] = useState<string>('');
    const isRevenueTypesLoaded = useSelector(selectIsRevenueTypesLoaded);
    const isRevenueTypesLoading = useSelector(selectIsRevenueTypesLoading);
    const revenueTypeList = useSelector(selectAllActiveRevenueTypes);
    const [billedRevenueItems, setBilledRevenueItems] = useState<
        Array<Revenue>
    >([]);
    const [nonBilledRevenueItems, setNonBilledRevenueItems] = useState<
        Array<Revenue>
    >([]);
    const [hasSetFromRevenues, setHasSetFromRevenues] = useState(false);
    const dispatch = useDispatch();

    const isPublic = activity?.activity_audience
        ? activity.activity_audience as AudienceType === AudienceType.Public
        : false;

    useEffect(() => {
        ([
            [
                !isRevenueTypesLoaded && !isRevenueTypesLoading,
                getRevenueTypesList,
            ],
        ] as ReadonlyArray<[boolean, Function]>).forEach(
            ([shouldFetch, getList]) => {
                if (shouldFetch) {
                    dispatch(getList());
                }
            },
        );
    });

    useEffect(() => {
        if (formValues.revenues && !hasSetFromRevenues) {
            const billedItems = formValues.revenues?.filter((r) =>
                ['Billed', 'TnE'].includes(r.type),
            );
            setBilledRevenueItems(billedItems ? billedItems : []);

            const nonBilledItems = formValues.revenues?.filter(
                (r) => !['Billed', 'TnE'].includes(r.type),
            );
            setNonBilledRevenueItems(nonBilledItems ? nonBilledItems : []);

            setHasSetFromRevenues(true);
        }
    }, [
        formValues.revenues,
        billedRevenueItems,
        nonBilledRevenueItems,
        hasSetFromRevenues,
    ]);

    useEffect(() => {
        if (tofExpirationDate !== '') {
            // set the formValue to timestamp
            handleFieldEvent({
                tof_expiration_date: dayjs(tofExpirationDate).unix(),
            });
        }
    }, [tofExpirationDate, handleFieldEvent]);

    useEffect(() => {
        if (formValues.tof_expiration_date && tofExpirationDate === '') {
            setTofExpirationDate(
                dayjs.unix(formValues.tof_expiration_date).format('YYYY/MM/DD'),
            );
        }
    }, [formValues, tofExpirationDate]);

    const revenueItemOptions = revenueTypeList.map((r) => {
        return {
            id: r.revenue_type,
            label: r.revenue_type,
        };
    });

    const handleUpdateLineItemType = (itemType: string, index: number) => {
        let updatedRevenueItems = [...(billedRevenueItems ?? [])];
        let updatedItem = { ...updatedRevenueItems[index] };
        updatedRevenueItems[index] = updatedItem;

        updatedItem.type = itemType;

        setBilledRevenueItems(updatedRevenueItems);
    };

    const handleUpdateLineItemAmount = (itemAmount: string, index: number) => {
        let updatedRevenueItems = [...(billedRevenueItems ?? [])];
        let revenueItem = { ...updatedRevenueItems[index] };
        revenueItem.amount = parseFloat(itemAmount);
        updatedRevenueItems[index] = revenueItem;
        setBilledRevenueItems(updatedRevenueItems);
    };

    const handleUpdateNonBilledLineItemType = (
        itemType: string,
        index: number,
    ) => {
        let updatedRevenueItems = [...(nonBilledRevenueItems ?? [])];
        let updatedItem = { ...updatedRevenueItems[index] };
        updatedItem.type = itemType;
        updatedRevenueItems[index] = updatedItem;

        setNonBilledRevenueItems(updatedRevenueItems);
    };

    const handleUpdateNonBilledLineItemAmount = (
        itemAmount: string,
        index: number,
    ) => {
        let updatedRevenueItems = [...(nonBilledRevenueItems ?? [])];
        let revenueItem = { ...updatedRevenueItems[index] };
        revenueItem.amount = parseFloat(itemAmount);
        updatedRevenueItems[index] = revenueItem;

        setNonBilledRevenueItems(updatedRevenueItems);
    };

    const handleUpdateNonBilledLineItemID = (itemID: string, index: number) => {
        let updatedRevenueItems = [...(nonBilledRevenueItems ?? [])];
        let updatedRevenueItem = {...updatedRevenueItems[index]};
        updatedRevenueItem.investment_request_id = itemID;
        updatedRevenueItems[index] = updatedRevenueItem;

        setNonBilledRevenueItems(updatedRevenueItems);
    };

    const isLanguageListLoading = useSelector(
        selectIsLanguageListLoading,
    );
    const isLanguageListLoaded = useSelector(
        selectIsLanguageListLoaded,
    );
    const languageListError = useSelector(
        selectLanguageListError,
    );
    const languageList = useSelector(selectAllActiveLanguages);

    // lifecycle method to fetch (and re- fetch) business data
    useEffect(() => {
        (
            [
                [
                    !isLanguageListLoaded &&
                        !isLanguageListLoading,
                    getLanguagesList,
                ],
            ] as ReadonlyArray<[boolean, Function]>
        ).forEach(([shouldFetch, getList]) => {
            if (shouldFetch) {
                dispatch(getList());
            }
        });
    });

    const {
        valueLookup: languageLookup,
        valueOptions: languageOptions,
    } = getOptionsAndLookupForSelectInput<LanguageItemData>(
        languageList,
        (language: LanguageItemData) => ({
            label: language.delivery_language,
            id: language.pk as string,
        }),
    );

    useEffect(() => {
        if (hasSetFromRevenues) {
            handleFieldEvent({
                revenues: [...billedRevenueItems, ...nonBilledRevenueItems],
            });
        }
    }, [
        handleFieldEvent,
        nonBilledRevenueItems,
        billedRevenueItems,
        hasSetFromRevenues,
    ]);

    return (
        <FormSection
            data-testid="EditCustomerDetailsFormSection"
            header="Customer details"
        >
            <ColumnLayout>
                <div data-awsui-column-layout-root="true">
                    <FormField
                        errorText={errors?.customer_name}
                        label="Customer name"
                    >
                        <Input
                            className={
                                errors?.customer_name && FORM_ERROR_SELECTOR
                            }
                            value={formValues.customer_name}
                            placeholder="Customer name"
                            onInput={(e) =>
                                handleFieldEvent({
                                    customer_name: e.detail.value,
                                })
                            }
                            data-testid={`EditCustomerName`}
                        />
                    </FormField>
                    <FormField
                        errorText={errors?.sfdc_opportunity_id}
                        label={
                            <span>
                                SFDC opportunity ID - <em>optional</em>
                            </span>
                        }
                    >
                        <Input
                            className={
                                errors?.sfdc_opportunity_id &&
                                FORM_ERROR_SELECTOR
                            }
                            value={formValues.sfdc_opportunity_id}
                            placeholder="SFDC opportunity ID"
                            onInput={(e) =>
                                handleFieldEvent({
                                    sfdc_opportunity_id: e.detail.value,
                                })
                            }
                            data-testid={`EditCSFDCID`}
                        />
                    </FormField>
                    <FormField
                        errorText={errors?.customer_aws_account_id}
                        label={
                            <span>
                                Customer AWS Account ID - <em>optional</em>
                            </span>
                        }
                    >
                        <Input
                            className={
                                errors?.customer_aws_account_id &&
                                FORM_ERROR_SELECTOR
                            }
                            value={formValues.customer_aws_account_id}
                            placeholder="Customer AWS Account ID"
                            onInput={(e) =>
                                handleFieldEvent({
                                    customer_aws_account_id: e.detail.value,
                                })
                            }
                            data-testid={`EditCustomerAWSAccountID`}
                        />
                    </FormField>
                    {!isPublic && (
                        <FormField
                            errorText={errors?.number_of_students_committed}
                            label={
                                <span>
                                    Number of students committed -{' '}
                                    <em>optional</em>
                                </span>
                            }
                        >
                            <Input
                                className={
                                    errors?.number_of_students_committed &&
                                    FORM_ERROR_SELECTOR
                                }
                                value={`${formValues.number_of_students_committed}`}
                                placeholder="Number of students committed"
                                type="number"
                                onInput={(e) =>
                                    handleFieldEvent({
                                        number_of_students_committed: !!e.detail
                                            .value
                                            ? parseInt(e.detail.value)
                                            : null,
                                    })
                                }
                                data-testid={`EditNumberOfStudentsCommmitted`}
                            />
                        </FormField>
                    )}
                    <FormField
                        errorText={errors?.number_of_students_attended}
                        label={
                            <span>
                                Number of students attended - <em>optional</em>
                            </span>
                        }
                    >
                        <Input
                            className={
                                errors?.number_of_students_attended &&
                                FORM_ERROR_SELECTOR
                            }
                            value={`${formValues.number_of_students_attended}`}
                            placeholder="Number of students attended"
                            type="number"
                            onInput={(e) =>
                                handleFieldEvent({
                                    number_of_students_attended: !!e.detail
                                        .value
                                        ? parseInt(e.detail.value)
                                        : null,
                                })
                            }
                            data-testid={`EditNumberOfStudentsAttended`}
                        />
                    </FormField>
                    {!isPublic && (
                        <>
                            <FormField
                                errorText={errors?.tof_status}
                                label="TOF status"
                            >
                                <Select
                                    placeholder={'TOF status'}
                                    options={TOF_STATUS_OPTIONS.map(
                                        (tofStatusOption) => {
                                            return {
                                                id: tofStatusOption,
                                                label: tofStatusOption,
                                            };
                                        },
                                    )}
                                    selectedOption={
                                        formValues.tof_status
                                            ? {
                                                  label: formValues.tof_status,
                                                  id: formValues.tof_status,
                                              }
                                            : {
                                                  label: TOF_STATUS_OPTIONS[0],
                                                  id: TOF_STATUS_OPTIONS[0],
                                              }
                                    }
                                    onChange={(e) => {
                                        handleFieldEvent({
                                            tof_status: e.detail.selectedId,
                                        });
                                    }}
                                    data-testid={`EditCustomerTOFStatus`}
                                />
                            </FormField>
                            <FormField
                                errorText={errors?.tof_expiration_date}
                                label={
                                    <span>
                                        TOF expiration date - <em>optional</em>
                                    </span>
                                }
                            >
                                <DatePicker
                                    className={
                                        `date-picker-fullwidth` &&
                                        errors?.tof_expiration_date &&
                                        FORM_ERROR_SELECTOR
                                    }
                                    value={tofExpirationDate}
                                    placeholder="YYYY/MM/DD"
                                    onChange={(e) => {
                                        setTofExpirationDate(e.detail.value);
                                    }}
                                    data-testid={`EditTOFExpiration`}
                                />
                            </FormField>
                            <FormField
                                label="Customer communication language"
                                errorText={errors?.customer_communication_language}
                            >
                                <Select
                                    className={
                                        errors?.customer_communication_language && FORM_ERROR_SELECTOR
                                    }
                                    placeholder={
                                        isLanguageListLoading
                                            ? 'Loading languages'
                                            : 'Select a language'
                                    }
                                    options={languageOptions}
                                    selectedOption={
                                        languageLookup[
                                            formValues.customer_communication_language || DEFAULT_LANGUAGE
                                        ]
                                    }
                                    onChange={(e) => {
                                        handleFieldEvent({
                                            customer_communication_language:
                                                e.detail.selectedOption.label,
                                        });
                                    }}
                                    onRecoveryClick={() => {
                                        dispatch(resetLanguagesSlice());
                                        dispatch(getLanguagesList());
                                    }}
                                    loadingText="Loading languages"
                                    errorText="An error occurred while loading languages"
                                    recoveryText="Retry"
                                    empty="No languages found"
                                    statusType={getStatusType(
                                        isLanguageListLoading,
                                        isLanguageListLoaded,
                                        languageListError,
                                    )}
                                    data-testid={`CustomerCommunicationLanguage`}
                                    disabled={
                                        !isLanguageListLoaded
                                    }
                                />
                            </FormField>
                            <FormField
                                errorText={errors?.customer_notes}
                                label={
                                    <span>
                                        Customer notes - <em>optional</em>
                                    </span>
                                }
                            >
                                <Textarea
                                    className={
                                        errors?.customer_notes &&
                                        FORM_ERROR_SELECTOR
                                    }
                                    value={formValues.customer_notes}
                                    placeholder=""
                                    onInput={(e) =>
                                        handleFieldEvent({
                                            customer_notes: e.detail.value,
                                        })
                                    }
                                    data-testid={`EditCustomerNotes`}
                                />
                            </FormField>
                            <div className="grimsby-sub-section-divider" />
                            <h3>Delivery contact</h3>
                            <FormField
                                errorText={errors?.delivery_contact_name}
                                label={
                                    <span>
                                        Delivery contact name -{' '}
                                        <em>optional</em>
                                    </span>
                                }
                            >
                                <Input
                                    className={
                                        errors?.delivery_contact_name &&
                                        FORM_ERROR_SELECTOR
                                    }
                                    value={formValues.delivery_contact_name}
                                    placeholder="Delivery contact name"
                                    onInput={(e) =>
                                        handleFieldEvent({
                                            delivery_contact_name:
                                                e.detail.value,
                                        })
                                    }
                                    data-testid={`EditDeliveryContactName`}
                                />
                            </FormField>
                            <FormField
                                errorText={errors?.delivery_contact_email}
                                label={
                                    <span>
                                        Delivery contact email -{' '}
                                        <em>optional</em>
                                    </span>
                                }
                            >
                                <Input
                                    className={
                                        errors?.delivery_contact_email &&
                                        FORM_ERROR_SELECTOR
                                    }
                                    value={formValues.delivery_contact_email}
                                    placeholder="Delivery contact email"
                                    onInput={(e) =>
                                        handleFieldEvent({
                                            delivery_contact_email:
                                                e.detail.value,
                                        })
                                    }
                                    data-testid={`EditDeliveryContactEmail`}
                                />
                            </FormField>
                            <FormField
                                errorText={
                                    errors?.delivery_contact_phone_number
                                }
                                label={
                                    <span>
                                        Delivery contact phone number -{' '}
                                        <em>optional</em>
                                    </span>
                                }
                            >
                                <Input
                                    className={
                                        errors?.delivery_contact_phone_number &&
                                        FORM_ERROR_SELECTOR
                                    }
                                    value={
                                        formValues.delivery_contact_phone_number
                                    }
                                    placeholder="Delivery contact phone number"
                                    onInput={(e) =>
                                        handleFieldEvent({
                                            delivery_contact_phone_number:
                                                e.detail.value,
                                        })
                                    }
                                    data-testid={`EditDeliveryContactPhone`}
                                />
                            </FormField>
                        </>
                    )}
                    <div className="grimsby-sub-section-divider" />
                    <h3>Revenue</h3>
                    <FormField label="Currency" errorText={errors?.currency}>
                        <Select
                            className={errors?.currency && FORM_ERROR_SELECTOR}
                            placeholder={'Currency'}
                            options={currencyOptions}
                            filteringType="auto"
                            selectedOption={
                                !!formValues.currency
                                    ? {
                                          label: formValues.currency,
                                          id: formValues.currency,
                                      }
                                    : { label: 'USD', id: 'USD' }
                            }
                            onChange={(e) => {
                                handleFieldEvent({
                                    currency: e.detail.selectedOption.label,
                                });
                            }}
                            onRecoveryClick={() => {}}
                            data-testid={`EditInvoiceCurrency`}
                        />
                    </FormField>
                    <h3>Invoiced revenue</h3>
                    <span>
                        Revenue items that will display on the customer invoice.
                    </span>
                    <AttributeEditor
                        onAddButtonClick={() =>
                            setBilledRevenueItems([
                                ...(billedRevenueItems ?? []),
                                {
                                    type: 'Billed',
                                    amount: 0,
                                    investment_request_id: '',
                                },
                            ])
                        }
                        onRemoveButtonClick={({ detail: { itemIndex } }) => {
                            const tmpItems = [...(billedRevenueItems ?? [])];
                            tmpItems.splice(itemIndex, 1);
                            setBilledRevenueItems(tmpItems);
                        }}
                        items={billedRevenueItems}
                        definition={[
                            {
                                label: 'Type',
                                control: (item, itemIndex) => (
                                    <Select
                                        placeholder={'Type'}
                                        options={revenueItemOptions.filter(
                                            (revenueOption) =>
                                                ['Billed', 'TnE'].includes(
                                                    revenueOption.label,
                                                ),
                                        )}
                                        selectedOption={{
                                            label: item.type,
                                            id: item.type,
                                        }}
                                        onChange={(e) => {
                                            handleUpdateLineItemType(
                                                e?.detail.selectedId,
                                                itemIndex,
                                            );
                                        }}
                                        data-testid={`EditCustomerInvoiceRevType-${itemIndex}`}
                                    />
                                ),
                            },
                            {
                                label: 'Amount',
                                control: (item, itemIndex) => (
                                    <Input
                                        value={`${item.amount}`}
                                        placeholder="Amount"
                                        onChange={(e) => {
                                            handleUpdateLineItemAmount(
                                                e.detail.value
                                                    ? e.detail.value
                                                    : '0',
                                                itemIndex,
                                            );
                                        }}
                                        type="number"
                                        data-testid={`EditCustomerInvoiceRevAmount-${itemIndex}`}
                                    />
                                ),
                            },
                        ]}
                        addButtonText="Add revenue item"
                        removeButtonText="Remove"
                        empty="No revenue items for this customer."
                    />
                    <h3>Non-billed revenue</h3>
                    <p>
                        Discounts or funding that has reduced the overall amount
                        we bill customers.
                    </p>
                    <AttributeEditor
                        onAddButtonClick={() =>
                            setNonBilledRevenueItems([
                                ...nonBilledRevenueItems,
                                {
                                    type: '',
                                    amount: 0,
                                    investment_request_id: '',
                                },
                            ])
                        }
                        onRemoveButtonClick={({ detail: { itemIndex } }) => {
                            const tmpItems = [...nonBilledRevenueItems];
                            tmpItems.splice(itemIndex, 1);
                            setNonBilledRevenueItems(tmpItems);
                        }}
                        items={nonBilledRevenueItems}
                        definition={[
                            {
                                label: 'Revenue type',
                                control: (item, itemIndex) => (
                                    <Select
                                        placeholder={'Revenue type'}
                                        options={revenueItemOptions.filter(
                                            (revenueOption) =>
                                                !['Billed', 'TnE'].includes(
                                                    revenueOption.label,
                                                ),
                                        )}
                                        selectedOption={{
                                            label: item.type,
                                            id: item.type,
                                        }}
                                        onChange={(e) => {
                                            handleUpdateNonBilledLineItemType(
                                                e?.detail.selectedId,
                                                itemIndex,
                                            );
                                        }}
                                        data-testid={`EditCustomerRevType-${itemIndex}`}
                                    />
                                ),
                            },
                            {
                                label: 'Revenue amount',
                                control: (item, itemIndex) => (
                                    <Input
                                        value={`${item.amount}`}
                                        placeholder="Revenue amount"
                                        onChange={(e) => {
                                            handleUpdateNonBilledLineItemAmount(
                                                e.detail.value
                                                    ? e.detail.value
                                                    : '0',
                                                itemIndex,
                                            );
                                        }}
                                        type="number"
                                        data-testid={`EditCustomerRevAmount-${itemIndex}`}
                                    />
                                ),
                            },
                            {
                                label: (
                                    <span>
                                        Investment request ID -{' '}
                                        <em>optional</em>
                                    </span>
                                ),
                                control: (item, itemIndex) => (
                                    <Input
                                        value={`${item.investment_request_id}`}
                                        placeholder="Investment request ID"
                                        onChange={(e) => {
                                            handleUpdateNonBilledLineItemID(
                                                e.detail.value
                                                    ? e.detail.value
                                                    : '0',
                                                itemIndex,
                                            );
                                        }}
                                        data-testid={`EditCustomerRevID-${itemIndex}`}
                                    />
                                ),
                            },
                        ]}
                        addButtonText="Add revenue item"
                        removeButtonText="Remove"
                        empty="No revenue items for this customer."
                    />
                </div>
            </ColumnLayout>
        </FormSection>
    );
};
