import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ActivityModalitiesResponseData } from '../../interfaces/businessDataResponse/activityModalitiesResponse';
import { HandleRequestData } from '../../interfaces/handleRequest';
import businessDataApi from '../../api/businessDataApi';
import { BusinessDataApiQueryParams } from '../../interfaces/queryParams';
import { AppDispatch, GlobalState } from '../../../main/store';
import { ExtendedBusinessDataStoreState } from '../../interfaces/businessDataStoreState';
import { ActivityModalityItemData } from '../../interfaces/businessDataItem/activityModalityItem';
import parseBoolean from '../../utils/parseBoolean';
import {
    ExtendedBusinessDataStoreInitialState,
    getActiveBusinessDataValues,
    resetBasicBusinessDataStoreState,
    resetExtendedBusinessDataStoreState,
    initializeBusinessDataListQueryParamsStoreState,
} from '../store.common';

/**
 * activityModalitiesSlice manages all activityModalities state, and contains activityModalities actions as well as activityModalities state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const activityModalitiesSlice = createSlice({
    name: 'activityModalities',
    initialState: {
        ...ExtendedBusinessDataStoreInitialState,
    } as ExtendedBusinessDataStoreState<ActivityModalityItemData>,
    reducers: {
        setActivityModalitiesList: (
            state,
            action: PayloadAction<ActivityModalityItemData[]>,
        ) => {
            const byActivityModalityName = action.payload.reduce(
                (
                    byActivityModalityName: {
                        [key: string]: ActivityModalityItemData;
                    },
                    activityModality: ActivityModalityItemData,
                ) => {
                    byActivityModalityName[
                        activityModality.activity_modality
                    ] = {
                        ...activityModality,
                        active: parseBoolean(activityModality.active),
                    };
                    return byActivityModalityName;
                },
                {},
            );
            state.entities = byActivityModalityName;
            state.keysList = Object.keys(byActivityModalityName);
        },
        setActivityModality: (
            state,
            action: PayloadAction<ActivityModalityItemData>,
        ) => {
            // if the item is new, add a new key for it and update the count.
            if (!state.entities[action.payload.activity_modality]) {
                state.keysList = [
                    ...state.keysList,
                    action.payload.activity_modality,
                ];
                state.count = state.keysList.length;
            }
            state.entities[action.payload.activity_modality] = action.payload;
        },
        setSelectedActivityModality: (
            state,
            action: PayloadAction<string | null>,
        ) => {
            state.selectedItemKey = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
        setCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload;
        },
        setSearchText: (state, action: PayloadAction<string | null>) => {
            state.searchText = action.payload;
        },
        setActive: (
            state,
            action: PayloadAction<BusinessDataApiQueryParams.Active>,
        ) => {
            state.active = action.payload;
        },
        setFrom: (state, action: PayloadAction<number>) => {
            state.from = action.payload;
        },
        setPagesCount: (state, action: PayloadAction<number>) => {
            state.pagesCount = action.payload;
        },
        setCurrentPageIndex: (state, action: PayloadAction<number>) => {
            state.currentPageIndex = action.payload;
        },
        setSize: (state, action: PayloadAction<number>) => {
            state.size = action.payload;
        },
        resetPartialActivityModalitiesSlice: resetBasicBusinessDataStoreState,
        resetActivityModalitiesSlice: resetExtendedBusinessDataStoreState,
        initializeActivityModalitiesListQueryParams: initializeBusinessDataListQueryParamsStoreState,
    },
});

export const {
    setIsLoading,
    setIsLoaded,
    setActivityModalitiesList,
    setActivityModality,
    setSelectedActivityModality,
    setError,
    setCount,
    setSearchText,
    setActive,
    setFrom,
    setPagesCount,
    setCurrentPageIndex,
    setSize,
    resetPartialActivityModalitiesSlice,
    resetActivityModalitiesSlice,
    initializeActivityModalitiesListQueryParams,
} = activityModalitiesSlice.actions;

const handleActivityModalitiesListRequest = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        const {
            from,
            size,
            searchText: activity_modality,
            active,
        } = state.activityModalities;

        const params: BusinessDataApiQueryParams.GetActivityModalities = {
            active,
        };

        // if both "from" and "size" equal to 0, avoid adding them into the query parameters
        if (from !== 0 || size !== 0) {
            params.from = from;
            params.size = size;
        }
        if (activity_modality) {
            params.activity_modality = activity_modality;
        }

        try {
            const {
                result: { ACTIVITY_MODALITY, total_count },
            }: HandleRequestData<ActivityModalitiesResponseData> = await businessDataApi.getActivityModalities(
                params,
            );

            dispatch(setActivityModalitiesList(ACTIVITY_MODALITY));
            dispatch(setCount(total_count));
            dispatch(setPagesCount(Math.ceil(total_count / size)));
        } catch (error: any) {
            dispatch(
                setError(error?.message || 'getActivityModalities API error'),
            );
        }
    };
};

export const getActivityModalitiesList = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));
        await dispatch(handleActivityModalitiesListRequest());
        if (!state.activityModalities.isLoaded) {
            dispatch(setIsLoaded(true));
        }
        dispatch(setIsLoading(false));
    };
};

/**
 * action to update program
 * before update api call, fetch program types and prepare payload containing only the changed fields
 * after update api call, fetch program types and update the current item in that keysList with the item data we updated
 * @param data
 */
export const updateActivityModality = (data: ActivityModalityItemData) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        // immediately put the keysList into loading while we finish updating the item/fetching the keysList
        dispatch(setIsLoading(true));
        if (!data.pk || data?.pk.length <= 0) {
            // eslint-disable-next-line no-console
            console.error(
                `Error updating new program: required field pk was missing`,
            );
            dispatch(setIsLoading(false));
            return false;
        }
        try {
            await businessDataApi.updateActivityModality(data.pk, data.active);
            await dispatch(handleActivityModalitiesListRequest());
            // force-update the item in the keysList for now, since the keysList we fetched may not have this item's update yet
            dispatch(
                setActivityModality({
                    ...state.activityModalities.entities[
                        data.activity_modality
                    ],
                    active: data.active,
                }),
            );
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const addActivityModality = (
    active = true,
    activityModalityName: string,
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        dispatch(setIsLoading(true));
        try {
            await businessDataApi.addActivityModality(
                activityModalityName,
                active,
            );
            // now refresh the keysList
            await dispatch(handleActivityModalitiesListRequest());
            if (!state.activityModalities?.entities[activityModalityName]) {
                // shove the item into the keysList, but only if it's not there already
                const newActivityModalityData: ActivityModalityItemData = {
                    activity_modality: activityModalityName,
                    active: active,
                };
                dispatch(setActivityModality(newActivityModalityData));
            }
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const selectAllActivityModalities = (state: GlobalState) => {
    return state.activityModalities.keysList.map(
        (activityModalityName) =>
            state.activityModalities.entities[activityModalityName],
    );
};

export const selectAllActiveActivityModalities = (state: GlobalState) => {
    return getActiveBusinessDataValues(
        state.activityModalities.keysList,
        state.activityModalities.entities,
    );
};

export const selectIsLoading = (state: GlobalState) =>
    state.activityModalities.isLoading;

export const selectIsLoaded = (state: GlobalState) =>
    state.activityModalities.isLoaded;

export const selectSelectedActivityModality = (state: GlobalState) => {
    return state?.activityModalities?.selectedItemKey
        ? state?.activityModalities?.entities[
              state?.activityModalities?.selectedItemKey
          ]
        : null;
};

export const selectError = (state: GlobalState) =>
    state.activityModalities.error;

export const selectCount = (state: GlobalState) =>
    state.activityModalities.count;

export const selectSearchText = (state: GlobalState) =>
    state.activityModalities.searchText;

export const selectPagesCount = (state: GlobalState) =>
    state.activityModalities.pagesCount;

export const selectCurrentPageIndex = (state: GlobalState) =>
    state.activityModalities.currentPageIndex;

export default activityModalitiesSlice.reducer;
