import { Box, Button, SpaceBetween } from '@amzn/awsui-components-react-v3';
import React from 'react';
import {
    BlockedTime,
    BlockedTimeFormData,
    BlockTimeTimeAttributeEditorItem,
} from '../../../../common/interfaces/blockedTime';
import { BLOCK_TIME_TEXT } from './BlockedTimeModal';
import EditModalFooter from './EditModalFooter';

export interface BlockedTimeModalFooterProps {
    handleIsVisibleChange: (isVisible: boolean) => void;
    clearBlockedTimeValues: () => void;
    submitting: boolean;
    hasMixedInstructors: boolean;
    isEditForm: boolean;
    handleBlockedTimeDelete: (
        blockTimeFormValues: BlockedTimeFormData,
        clearBlockedTimeValues: () => void,
    ) => Promise<void>;
    isFreelancer: boolean;
    handleBlockedTimeEdit: (
        blockedTimeFormValues: BlockedTimeFormData,
        clearBlockedTimeValues: () => void,
        forceDelete: boolean,
    ) => Promise<void | Error>;
    formValues: BlockedTimeFormData;
    eventSource: BlockedTime;
    setSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
    validateForm: (
        formValues: Partial<BlockedTime>,
        validationConfig: {
            email?: (keyof BlockedTime)[] | undefined;
            required?: (keyof BlockedTime)[] | undefined;
        },
    ) => boolean;
    getValidationConfig: () => {
        email?: (keyof BlockedTime)[] | undefined;
        required?: (keyof BlockedTime)[] | undefined;
    };
    validateFormControlArray: (
        formValues: {
            [x: string]: any[];
        },
        validationConfig: {
            [x: string]: {
                email?: (string | number | symbol)[] | undefined;
                required?: (string | number | symbol)[] | undefined;
            };
        },
    ) => boolean;
    blockedTimeAttributeEditorItems: BlockTimeTimeAttributeEditorItem[];
    blockedTimeAttributeValidationConfig: {
        blockedTimeAttributeEditorItems: {
            email?: (keyof BlockTimeTimeAttributeEditorItem)[] | undefined;
            required?: (keyof BlockTimeTimeAttributeEditorItem)[] | undefined;
        };
    };
    handleBlockedTimeSubmit: ({
        blockedTimeFormValues,
        clearBlockedTimeValues,
    }: {
        blockedTimeFormValues: BlockedTimeFormData;
        clearBlockedTimeValues: () => void;
    }) => void;
}

const BlockedTimeModalFooter = ({
    handleIsVisibleChange,
    clearBlockedTimeValues,
    submitting,
    hasMixedInstructors,
    isEditForm,
    handleBlockedTimeDelete,
    isFreelancer,
    handleBlockedTimeEdit,
    formValues,
    eventSource,
    setSubmitting,
    validateForm,
    getValidationConfig,
    validateFormControlArray,
    blockedTimeAttributeEditorItems,
    blockedTimeAttributeValidationConfig,
    handleBlockedTimeSubmit,
}: BlockedTimeModalFooterProps) => {
    const handleDelete = (formValues: BlockedTimeFormData) => {
        if (isFreelancer) {
            handleBlockedTimeEdit(formValues, clearBlockedTimeValues, true);
        } else {
            handleBlockedTimeDelete(formValues, clearBlockedTimeValues);
        }
    };

    const handleEdit = (formValues: BlockedTimeFormData) => {
        handleBlockedTimeEdit(formValues, clearBlockedTimeValues, false);
    };

    const handleSubmit = () => {
        setSubmitting(true);
        const invalid = validateForm(formValues, getValidationConfig());
        const isFormControlArrayInvalid = validateFormControlArray(
            { blockedTimeAttributeEditorItems },
            blockedTimeAttributeValidationConfig,
        );
        if (invalid || isFormControlArrayInvalid) {
            setSubmitting(false);
        } else {
            handleBlockedTimeSubmit({
                blockedTimeFormValues: formValues,
                clearBlockedTimeValues,
            });
        }
    };

    return (
        <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
                <Button
                    variant="link"
                    onClick={() => {
                        handleIsVisibleChange(false);
                        clearBlockedTimeValues();
                    }}
                    data-testid={'ReserveTimeCancel'}
                    disabled={submitting}
                >
                    Cancel
                </Button>
                {!hasMixedInstructors &&
                    (() => {
                        if (!!isEditForm) {
                            return (
                                <EditModalFooter
                                    submitting={submitting}
                                    handleEdit={handleEdit}
                                    handleDelete={handleDelete}
                                    formValues={formValues}
                                    eventSource={eventSource}
                                />
                            );
                        } else {
                            return (
                                <Button
                                    variant="primary"
                                    data-testid={'ReserveTimeSave'}
                                    onClick={() => {
                                        handleSubmit();
                                    }}
                                    disabled={submitting}
                                >
                                    {submitting ? 'Saving' : BLOCK_TIME_TEXT}
                                </Button>
                            );
                        }
                    })()}
            </SpaceBetween>
        </Box>
    );
};

export default BlockedTimeModalFooter;
