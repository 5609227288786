import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { HandleRequestData } from '../../../common/interfaces/handleRequest';
import instructorManagementApi from '../../api/instructorManagementApi';
import { AppDispatch, GlobalState } from '../../../main/store';
import { GenericStoreState } from '../../../common/interfaces/genericStoreState';
import { InstructorListResponseData } from '../../interfaces/instructorListResponse';
import { InstructorProfileData } from '../../interfaces/instructorProfile';
import { selectInstructorListPreference } from '../../../common/store/slices/userSlice';
import { InstructorManagementAPIQueryParams } from '../../interfaces/queryParams';

export interface InstructorListState extends GenericStoreState {
    instructorList: InstructorProfileData[];
    from: number;
    pagesCount: number;
    currentPageIndex: number;
    totalInstructorsCount: number;
    size: number;
    searchText: string | null;
    savedFilter: InstructorManagementAPIQueryParams.PropertyFilterParams | null;
    sourceFields: Array<
        InstructorManagementAPIQueryParams.SourceFieldsItem
    > | null;
    sortFields: Array<InstructorManagementAPIQueryParams.SortFieldsItem> | null;
    isLoaded: boolean;
    visibleColumns: Array<string> | null;
}

/**
 * instructorListSlice manages all app instructor list state, and contains instructor list actions as well as instructor list state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const instructorListSlice = createSlice({
    name: 'instructorList',
    initialState: {
        instructorList: [],
        error: null,
        isLoading: false,
        from: 0,
        pagesCount: 0,
        currentPageIndex: 1,
        totalInstructorsCount: 0,
        size: 25,
        searchText: null,
        savedFilter: null,
        sourceFields: null,
        sortFields: null,
        isLoaded: false,
        visibleColumns: null,
    } as InstructorListState,
    reducers: {
        setInstructorList: (
            state,
            action: PayloadAction<InstructorProfileData[]>,
        ) => {
            state.instructorList = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setFrom: (state, action: PayloadAction<number>) => {
            state.from = action.payload;
        },
        setPagesCount: (state, action: PayloadAction<number>) => {
            state.pagesCount = action.payload;
        },
        setCurrentPageIndex: (state, action: PayloadAction<number>) => {
            state.currentPageIndex = action.payload;
        },
        setTotalInstructorsCount: (state, action: PayloadAction<number>) => {
            state.totalInstructorsCount = action.payload;
        },
        setSize: (state, action: PayloadAction<number>) => {
            state.size = action.payload;
        },
        setSearchText: (state, action: PayloadAction<string | null>) => {
            state.searchText = action.payload;
        },
        setSavedFilter: (
            state,
            action: PayloadAction<InstructorManagementAPIQueryParams.PropertyFilterParams | null>,
        ) => {
            state.savedFilter = action.payload;
        },
        setSourceFields: (
            state,
            action: PayloadAction<
                InstructorManagementAPIQueryParams.SourceFieldsItem[] | null
            >,
        ) => {
            state.sourceFields = action.payload;
        },
        setSortFields: (
            state,
            action: PayloadAction<
                InstructorManagementAPIQueryParams.SortFieldsItem[] | null
            >,
        ) => {
            state.sortFields = action.payload;
        },
        setIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
        setVisibleColumns: (state, action: PayloadAction<Array<string>>) => {
            state.visibleColumns = action.payload;
        },
    },
});

export const {
    setInstructorList,
    setError,
    setIsLoading,
    setFrom,
    setPagesCount,
    setCurrentPageIndex,
    setTotalInstructorsCount,
    setSize,
    setSearchText,
    setSavedFilter,
    setSourceFields,
    setSortFields,
    setIsLoaded,
    setVisibleColumns,
} = instructorListSlice.actions;

export const initializeUserPreference = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));

        // Since the user slice is loaded first, it can be directly retrieved here
        const preference = selectInstructorListPreference(state);
        if (preference?.page_size) {
            dispatch(setSize(preference.page_size));
        }
        if (preference?.visible_columns) {
            dispatch(setVisibleColumns(preference.visible_columns));
        }
        dispatch(setIsLoading(false));
    };
};

/**
 * getInstructorList is an async action used to fetch instructor list data.
 * There is no explicit inclusion of redux-thunk logic, as the redux toolkit takes care of this for us.
 */
export const getInstructorList = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));

        const {
            from,
            size,
            searchText: search_text,
            savedFilter: saved_filter,
            sourceFields: source_fields,
            sortFields: sort_fields,
        } = state.instructorList;

        let filters = {
            ...saved_filter,
        } as any;

        if (saved_filter?.is_freelancer?.length) {
            filters.is_freelancer = saved_filter.is_freelancer[0] === 'Yes';
        }

        try {
            const {
                result: { instructors = [], total_instructors = 0 },
            }: HandleRequestData<InstructorListResponseData> = await instructorManagementApi.getInstructors(
                {
                    from,
                    size,
                    search_text,
                    ...filters,
                    source_fields,
                    sort_fields,
                } as InstructorManagementAPIQueryParams.GetInstructors,
            );
            dispatch(setInstructorList(instructors));
            dispatch(setTotalInstructorsCount(total_instructors));
            dispatch(setPagesCount(Math.ceil(total_instructors / size)));
        } catch (error: any) {
            dispatch(setError(error.toString()));
        } finally {
            if (!state.instructorList.isLoaded) {
                dispatch(setIsLoaded(true));
            }
            dispatch(setIsLoading(false));
        }
    };
};

export const selectInstructorList = (state: GlobalState) =>
    state.instructorList.instructorList;
export const selectError = (state: GlobalState) => state.instructorList.error;
export const selectIsLoading = (state: GlobalState) =>
    state.instructorList.isLoading;
export const selectIsLoaded = (state: GlobalState) =>
    state.instructorList.isLoaded;
export const selectPagesCount = (state: GlobalState) =>
    state.instructorList.pagesCount;
export const selectCurrentPageIndex = (state: GlobalState) =>
    state.instructorList.currentPageIndex;
export const selectTotalInstructorsCount = (state: GlobalState) =>
    state.instructorList.totalInstructorsCount;
export const selectSize = (state: GlobalState) => state.instructorList.size;
export const selectVisibleColumns = (state: GlobalState) =>
    state.instructorList.visibleColumns;
export const selectSearchText = (state: GlobalState) =>
    state.instructorList.searchText;
export const selectSavedFilter = (state: GlobalState) =>
    state.instructorList.savedFilter;

export default instructorListSlice.reducer;
