import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { InstructorTypesResponseData } from '../../interfaces/businessDataResponse/instructorTypesResponse';
import { HandleRequestData } from '../../interfaces/handleRequest';
import businessDataApi from '../../api/businessDataApi';
import { AppDispatch, GlobalState } from '../../../main/store';
import { InstructorTypeItemData } from '../../interfaces/businessDataItem/instructorTypeItem';
import parseBoolean from '../../utils/parseBoolean';
import { BusinessDataStoreState } from '../../interfaces/businessDataStoreState';
import {
    BusinessDataStoreInitialState,
    getActiveBusinessDataValues,
    resetBasicBusinessDataStoreState,
} from '../store.common';

/**
 * instructorTypesSlice manages all instructorTypes state, and contains instructorTypes actions as well as instructorTypes state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const instructorTypesSlice = createSlice({
    name: 'instructorTypes',
    initialState: {
        ...BusinessDataStoreInitialState,
    } as BusinessDataStoreState<InstructorTypeItemData>,
    reducers: {
        setInstructorTypesList: (
            state,
            action: PayloadAction<InstructorTypeItemData[]>,
        ) => {
            const byInstructorTypeName = action.payload.reduce(
                (
                    byInstructorTypeName: {
                        [key: string]: InstructorTypeItemData;
                    },
                    instructorType: InstructorTypeItemData,
                ) => {
                    byInstructorTypeName[instructorType.instructor_type] = {
                        ...instructorType,
                        active: parseBoolean(instructorType.active),
                    };
                    return byInstructorTypeName;
                },
                {},
            );
            state.entities = byInstructorTypeName;
            state.keysList = Object.keys(byInstructorTypeName);
        },
        setInstructorType: (
            state,
            action: PayloadAction<InstructorTypeItemData>,
        ) => {
            // this reducer may be used when adding a new instructor or updating an existing one.
            // only add to list and update count if adding a new instructor
            if (!state.entities[action.payload.instructor_type]) {
                state.keysList = [
                    ...state.keysList,
                    action.payload.instructor_type,
                ];
                state.count = state.keysList.length;
            }
            state.entities[action.payload.instructor_type] = action.payload;
        },
        setSelectedInstructorType: (
            state,
            action: PayloadAction<string | null>,
        ) => {
            state.selectedItemKey = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
        setCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload;
        },
        resetInstructorTypesSlice: resetBasicBusinessDataStoreState,
    },
});

export const {
    setIsLoading,
    setIsLoaded,
    setInstructorTypesList,
    setInstructorType,
    setSelectedInstructorType,
    setError,
    setCount,
    resetInstructorTypesSlice,
} = instructorTypesSlice.actions;

const handleInstructorTypesListRequest = () => {
    return async (dispatch: AppDispatch) => {
        try {
            const {
                result: { INSTRUCTOR_TYPE, total_count },
            }: HandleRequestData<InstructorTypesResponseData> = await businessDataApi.getInstructorTypes(
                { active: 'all' },
            );
            dispatch(setInstructorTypesList(INSTRUCTOR_TYPE));
            dispatch(setCount(total_count));
        } catch (error: any) {
            dispatch(
                setError(error?.message || 'getInstructorTypes API error'),
            );
        }
    };
};

export const getInstructorTypesList = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));
        await dispatch(handleInstructorTypesListRequest());
        if (!state.instructorTypes.isLoaded) {
            dispatch(setIsLoaded(true));
        }
        dispatch(setIsLoading(false));
    };
};

/**
 * action to update instructor
 * before update api call, fetch instructor types and prepare payload containing only the changed fields
 * after update api call, fetch instructor types and update the current item in that list with the item data we updated
 * @param data
 */
export const updateInstructorType = (data: InstructorTypeItemData) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        // immediately put the list into loading while we finish updating the item/fetching the list
        dispatch(setIsLoading(true));
        if (!data.pk || data?.pk.length <= 0) {
            // eslint-disable-next-line no-console
            console.error(
                `Error updating new instructor: required field pk was missing`,
            );
            dispatch(setIsLoading(false));
            return false;
        }
        try {
            await businessDataApi.updateInstructorType(data.pk, data.active);
            await dispatch(handleInstructorTypesListRequest());
            // force-update the item in the list for now, since the list we fetched may not have this item's update yet
            dispatch(
                setInstructorType({
                    ...state.instructorTypes.entities[data.instructor_type],
                    active: data.active,
                }),
            );
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const addInstructorType = (
    active = true,
    instructorTypeName: string,
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        dispatch(setIsLoading(true));
        try {
            await businessDataApi.addInstructorType(instructorTypeName, active);
            // now refresh the list
            await dispatch(handleInstructorTypesListRequest());
            if (!state.instructorTypes?.entities[instructorTypeName]) {
                // shove the item into the list, but only if it's not there already
                const newInstructorData: InstructorTypeItemData = {
                    instructor_type: instructorTypeName,
                    active: active,
                };
                dispatch(setInstructorType(newInstructorData));
            }
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const selectAllInstructorTypes = (state: GlobalState) => {
    return state.instructorTypes.keysList.map(
        (instructorTypeName) =>
            state.instructorTypes.entities[instructorTypeName],
    );
};

export const selectAllActiveInstructorTypes = (
    state: GlobalState,
): Array<InstructorTypeItemData> => {
    return getActiveBusinessDataValues(
        state.instructorTypes.keysList,
        state.instructorTypes.entities,
    );
};

export const selectIsLoading = (state: GlobalState) =>
    state.instructorTypes.isLoading;

export const selectIsLoaded = (state: GlobalState) =>
    state.instructorTypes.isLoaded;

export const selectSelectedInstructorType = (state: GlobalState) => {
    return state?.instructorTypes?.selectedItemKey
        ? state?.instructorTypes?.entities[
              state?.instructorTypes?.selectedItemKey
          ]
        : null;
};

export const selectError = (state: GlobalState) => state.instructorTypes.error;

export const selectCount = (state: GlobalState) => state.instructorTypes.count;

export default instructorTypesSlice.reducer;
