import { Select } from '@amzn/awsui-components-react';

export enum MeridiemFieldValue {
    Am = 'AM',
    Pm = 'PM',
}

export interface MeridiemFieldOption extends Select.Option {
    readonly label: MeridiemFieldValue;
    readonly id: string;
}

export const meridiemFieldOptions: Array<MeridiemFieldOption> = [
    {
        label: MeridiemFieldValue.Am,
        id: '0',
    },
    {
        label: MeridiemFieldValue.Pm,
        id: '1',
    },
];
export const meridiemFieldOptionLookup = meridiemFieldOptions.reduce(
    (acc, opt) => {
        acc[opt.label] = opt;
        return acc;
    },
    {} as {
        [key in MeridiemFieldValue]: MeridiemFieldOption;
    },
);
