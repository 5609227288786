import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AtpCompaniesResponseData } from '../../interfaces/businessDataResponse/atpCompaniesResponse';
import { HandleRequestData } from '../../interfaces/handleRequest';
import businessDataApi from '../../api/businessDataApi';
import { BusinessDataApiQueryParams } from '../../interfaces/queryParams';
import { AppDispatch, GlobalState } from '../../../main/store';
import { ExtendedBusinessDataStoreState } from '../../interfaces/businessDataStoreState';
import { AtpCompanyItemData } from '../../interfaces/businessDataItem/atpCompanyItem';
import parseBoolean from '../../utils/parseBoolean';
import {
    ExtendedBusinessDataStoreInitialState,
    getActiveBusinessDataValues,
    resetBasicBusinessDataStoreState,
    resetExtendedBusinessDataStoreState,
    initializeBusinessDataListQueryParamsStoreState,
} from '../store.common';

/**
 * atpCompaniesSlice manages all atpCompanies state, and contains atpCompanies actions as well as atpCompanies state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const atpCompaniesSlice = createSlice({
    name: 'atpCompanies',
    initialState: {
        ...ExtendedBusinessDataStoreInitialState,
    } as ExtendedBusinessDataStoreState<AtpCompanyItemData>,
    reducers: {
        setAtpCompaniesList: (
            state,
            action: PayloadAction<AtpCompanyItemData[]>,
        ) => {
            const byAtpCompanyName = action.payload.reduce(
                (
                    byAtpCompanyName: {
                        [key: string]: AtpCompanyItemData;
                    },
                    atpCompany: AtpCompanyItemData,
                ) => {
                    byAtpCompanyName[atpCompany.atp_company] = {
                        ...atpCompany,
                        active: parseBoolean(atpCompany.active),
                    };
                    return byAtpCompanyName;
                },
                {},
            );
            state.entities = byAtpCompanyName;
            state.keysList = Object.keys(byAtpCompanyName);
        },
        setAtpCompany: (state, action: PayloadAction<AtpCompanyItemData>) => {
            // if the item is new, add a new key for it and update the count.
            if (!state.entities[action.payload.atp_company]) {
                state.keysList = [
                    ...state.keysList,
                    action.payload.atp_company,
                ];
                state.count = state.keysList.length;
            }
            state.entities[action.payload.atp_company] = action.payload;
        },
        setSelectedAtpCompany: (
            state,
            action: PayloadAction<string | null>,
        ) => {
            state.selectedItemKey = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
        setCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload;
        },
        setSearchText: (state, action: PayloadAction<string | null>) => {
            state.searchText = action.payload;
        },
        setActive: (
            state,
            action: PayloadAction<BusinessDataApiQueryParams.Active>,
        ) => {
            state.active = action.payload;
        },
        setFrom: (state, action: PayloadAction<number>) => {
            state.from = action.payload;
        },
        setPagesCount: (state, action: PayloadAction<number>) => {
            state.pagesCount = action.payload;
        },
        setCurrentPageIndex: (state, action: PayloadAction<number>) => {
            state.currentPageIndex = action.payload;
        },
        setSize: (state, action: PayloadAction<number>) => {
            state.size = action.payload;
        },
        resetPartialAtpCompaniesSlice: resetBasicBusinessDataStoreState,
        resetAtpCompaniesSlice: resetExtendedBusinessDataStoreState,
        initializeAtpCompaniesListQueryParams: initializeBusinessDataListQueryParamsStoreState,
    },
});

export const {
    setIsLoading,
    setIsLoaded,
    setAtpCompaniesList,
    setAtpCompany,
    setSelectedAtpCompany,
    setError,
    setCount,
    setSearchText,
    setActive,
    setFrom,
    setPagesCount,
    setCurrentPageIndex,
    setSize,
    resetPartialAtpCompaniesSlice,
    resetAtpCompaniesSlice,
    initializeAtpCompaniesListQueryParams,
} = atpCompaniesSlice.actions;

const handleAtpCompaniesListRequest = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        const {
            from,
            size,
            searchText: atp_company,
            active,
        } = state.atpCompanies;

        const params: BusinessDataApiQueryParams.GetAtpCompanies = {
            active,
        };

        // if both "from" and "size" equal to 0, avoid adding them into the query parameters
        if (from !== 0 || size !== 0) {
            params.from = from;
            params.size = size;
        }
        if (atp_company) {
            params.atp_company = atp_company;
        }

        try {
            const {
                result: { ATP_COMPANY, total_count },
            }: HandleRequestData<AtpCompaniesResponseData> = await businessDataApi.getAtpCompanies(
                params,
            );

            dispatch(setAtpCompaniesList(ATP_COMPANY));
            dispatch(setCount(total_count));
            dispatch(setPagesCount(Math.ceil(total_count / size)));
        } catch (error: any) {
            dispatch(setError(error?.message || 'getAtpCompanies API error'));
        }
    };
};

export const getAtpCompaniesList = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));
        await dispatch(handleAtpCompaniesListRequest());
        if (!state.atpCompanies.isLoaded) {
            dispatch(setIsLoaded(true));
        }
        dispatch(setIsLoading(false));
    };
};

/**
 * action to update program
 * before update api call, fetch program types and prepare payload containing only the changed fields
 * after update api call, fetch program types and update the current item in that keysList with the item data we updated
 * @param data
 * @param originalAtpCompany
 */
export const updateAtpCompany = (
    data: AtpCompanyItemData,
    originalAtpCompany: string | undefined,
) => {
    if (!originalAtpCompany) {
        throw new Error('orignial ATP company is required');
    }
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        // immediately put the keysList into loading while we finish updating the item/fetching the keysList
        dispatch(setIsLoading(true));
        if (!data.pk || data?.pk.length <= 0) {
            // eslint-disable-next-line no-console
            console.error(
                `Error updating new program: required field pk was missing`,
            );
            dispatch(setIsLoading(false));
            return false;
        }
        const {
            pk: uuid,
            atp_company,
            atp_company_display_name,
            active,
            is_atp,
            is_freelance_agency,
            is_reseller,
            spms_id,
            gilmore_license_id,
            training_coordinators_emails,
            associated_programs,
            funding_program_list,
        } = data;
        try {
            await businessDataApi.updateAtpCompany({
                uuid,
                atp_company,
                atp_company_display_name,
                active,
                is_reseller,
                is_freelance_agency,
                is_atp,
                spms_id,
                gilmore_license_id,
                training_coordinators_emails,
                associated_programs,
                funding_program_list,
            });
            await dispatch(handleAtpCompaniesListRequest());
            // force-update the atp list and keylist with by removing the old atp company and inserting the updated one.
            const originalIndex = state.atpCompanies.keysList.indexOf(
                originalAtpCompany,
            );

            const atpCompaniesList = selectAllAtpCompanies(state);

            atpCompaniesList.splice(originalIndex, 1, data);

            dispatch(setAtpCompaniesList(atpCompaniesList));
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const addAtpCompany = (data: AtpCompanyItemData) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        dispatch(setIsLoading(true));
        try {
            const {
                atp_company,
                atp_company_display_name,
                active,
                is_atp,
                is_freelance_agency,
                is_reseller,
                spms_id,
                gilmore_license_id,
                training_coordinators_emails,
                funding_program_list,
            } = data;
            await businessDataApi.addAtpCompany({
                atp_company,
                atp_company_display_name,
                active,
                is_atp,
                is_freelance_agency,
                is_reseller,
                spms_id,
                gilmore_license_id,
                training_coordinators_emails,
                funding_program_list,
            });
            // now refresh the keysList
            await dispatch(handleAtpCompaniesListRequest());
            if (!state.atpCompanies?.entities[atp_company]) {
                // shove the item into the keysList, but only if it's not there already
                const newProgramData: AtpCompanyItemData = {
                    atp_company,
                    atp_company_display_name,
                    active,
                    is_atp,
                    is_freelance_agency,
                    is_reseller,
                    spms_id,
                    gilmore_license_id,
                    training_coordinators_emails,
                };
                dispatch(setAtpCompany(newProgramData));
            }
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const selectAllAtpCompanies = (state: GlobalState) => {
    return state.atpCompanies.keysList.map(
        (atpCompanyName) => state.atpCompanies.entities[atpCompanyName],
    );
};

export const selectAllActiveAtpCompanies = (state: GlobalState) => {
    return getActiveBusinessDataValues(
        state.atpCompanies.keysList,
        state.atpCompanies.entities,
    );
};

export const selectIsLoading = (state: GlobalState) =>
    state.atpCompanies.isLoading;

export const selectIsLoaded = (state: GlobalState) =>
    state.atpCompanies.isLoaded;

export const selectSelectedAtpCompany = (state: GlobalState) => {
    return state?.atpCompanies?.selectedItemKey
        ? state?.atpCompanies?.entities[state?.atpCompanies?.selectedItemKey]
        : null;
};

export const selectError = (state: GlobalState) => state.atpCompanies.error;

export const selectCount = (state: GlobalState) => state.atpCompanies.count;

export const selectSearchText = (state: GlobalState) =>
    state.atpCompanies.searchText;

export const selectPagesCount = (state: GlobalState) =>
    state.atpCompanies.pagesCount;

export const selectCurrentPageIndex = (state: GlobalState) =>
    state.atpCompanies.currentPageIndex;

export default atpCompaniesSlice.reducer;
