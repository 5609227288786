/*
    Header component with the aws training & certification and Grimsby logo
*/
import React from 'react';

import { APP_NAME } from '../../constants/grimsby';
import AppSwitcher from '../AppSwitcher';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slices/userSlice';
import { Link } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';

const Header = () => {
    const user = useSelector(selectUser);

    const handleLogout = () => {
        Auth.signOut();
    }

    return (
        <div className="grimsby-header">
            <div className="awsui-row">
                <div className="grimsby-header--box">
                    <img
                        src="/aws.png"
                        alt="aws"
                        width="110px"
                        data-testid="Header_Logo"
                    />
                    <div className="app-name">{APP_NAME}</div>

                    <AppSwitcher />
                </div>

                {user?.profile && (
                    <div className="grimsby-header--box app-user">
                        <span>Logged in as</span>
                        <Link className="awsui-util-ml-xs" to="/user-profile">
                            {user?.profile?.alias}
                        </Link>
                        <button onClick={handleLogout} style={{ marginLeft: 5, cursor: 'pointer', background: 'none', border: 0, color: "white" }} data-testid="logout-button">(Logout)</button>
                    </div>
                )}
                {!user?.profile && (
                    <button onClick={handleLogout} style={{ marginLeft: 5, cursor: 'pointer', background: 'none', border: 0, color: "white" }} data-testid="logout-button">(Logout)</button>
                )}
            </div>
        </div>
    );
};

export default Header;
