import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserResponseData } from '../../interfaces/userResponse';
import { HandleRequestData } from '../../interfaces/handleRequest';
import userManagementApi from '../../api/userManagementApi';
import { assignRoles } from '../../utils/auth';
import { AppDispatch, GlobalState } from '../../../main/store';
import { GenericStoreState } from '../../interfaces/genericStoreState';
import { UpdateUserPayloadData } from '../../interfaces/updateUserPayload';
import { Mutex } from '@aws-amplify/core';
import { InstructorFilters } from '../../../imt/interfaces/instructorFilters';
import { ActivityFilters } from '../../../smt/interfaces/activityFilters';
import { getFeatureFlags } from './featureSlice';
import { Roles } from '../../constants/auth';
import { SmtInstructorFilters } from '../../../smt/interfaces/smtInstructorFilters';

const mutex = new Mutex();

export interface UserState extends GenericStoreState {
    status: number | null;
    user: UserResponseData | null;
    isLoaded: boolean;
    roles: Array<Roles>;
}

/**
 * userSlice manages all user state, and contains user actions as well as user state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const userSlice = createSlice({
    name: 'user',
    initialState: {
        status: null,
        user: null,
        error: null,
        isLoading: false,
        isLoaded: false,
        roles: [],
    } as UserState,
    reducers: {
        setStatus: (state, action: PayloadAction<number>) => {
            state.status = action.payload;
        },
        setUser: (state, action: PayloadAction<UserResponseData>) => {
            state.user = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
        setRoles: (state, action: PayloadAction<Array<Roles>>) => {
            state.roles = action.payload;
        },
    },
});

export const {
    setStatus,
    setUser,
    setError,
    setIsLoading,
    setIsLoaded,
    setRoles,
} = userSlice.actions;

/**
 * getUser is an async action used to fetch user data.
 * There is no explicit inclusion of redux-thunk logic, as the redux toolkit takes care of this for us.
 */
export const getUser: any = () => {
    return async (dispatch: AppDispatch) => {
        dispatch(setIsLoading(true));
        try {
            const { status, result }: HandleRequestData<UserResponseData> =
                await userManagementApi.getUserProfile();
            // is 200 status even checked anywhere, or do we only care about the unsuccessful get user status codes?
            dispatch(setStatus(status));
            const userRoles = assignRoles(result?.profile?.vias_roles);
            dispatch(setRoles(userRoles));
            dispatch(setUser(result));
            dispatch(getFeatureFlags());
        } catch (error: any) {
            dispatch(setStatus(error?.statusCode));
            dispatch(setError(error.toString()));
        } finally {
            dispatch(setIsLoading(false));
            dispatch(setIsLoaded(true));
        }
    };
};

/**
 * Update user preferences
 * @param id
 * @param preferences
 * @param saved_filters when adding/updating the saved_filters, complete updated list is expected to be passed
 * @param smt_saved_filters when adding/updating schedule management filters
 */
export const updatePreferencesAndSavedFilters = (
    id: string,
    {
        preferences,
        saved_filters,
        saved_filters_smt,
        saved_filters_smt_instructors,
    }: UpdateUserPayloadData,
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        return mutex.runExclusive<boolean>(async () => {
            if (
                !preferences &&
                !saved_filters &&
                !saved_filters_smt &&
                !saved_filters_smt_instructors
            ) {
                throw Error('Empty user preferences and saved filters');
            }

            const state = getState();
            const user = state.user.user as UserResponseData;

            dispatch(setIsLoading(true));

            const newPreference = {
                ...user.profile.preferences,
                instructor_list: {
                    ...user.profile.preferences?.instructor_list,
                    ...preferences?.instructor_list,
                },
                activity_list: {
                    ...user.profile.preferences?.activity_list,
                    ...preferences?.activity_list,
                },
                sfdc_list: {
                    ...user.profile.preferences?.sfdc_list,
                    ...preferences?.sfdc_list,
                },
            };

            // when updating saved filters pass the completed updated filters otherwise it will not update.
            // This allows to handle adding, deleting and updating the saved_filters in the same place
            const newFilters = saved_filters
                ? saved_filters
                : user.profile.saved_filters
                ? user.profile.saved_filters
                : [];

            const smtFilters = saved_filters_smt
                ? saved_filters_smt
                : user.profile.saved_filters_smt
                ? user.profile.saved_filters_smt
                : [];

            const smtInstructorFilters = saved_filters_smt_instructors
                ? saved_filters_smt_instructors
                : user.profile.saved_filters_smt_instructors
                ? user.profile.saved_filters_smt_instructors
                : [];

            const newSMTSavedFilters = smtFilters;

            try {
                await userManagementApi.updateUser(id, {
                    preferences: newPreference,
                    saved_filters: newFilters,
                    saved_filters_smt: newSMTSavedFilters,
                    saved_filters_smt_instructors: smtInstructorFilters,
                });

                dispatch(
                    setUser({
                        message: user.message,
                        profile: {
                            ...user.profile,
                            preferences: newPreference,
                            saved_filters: newFilters,
                            saved_filters_smt: newSMTSavedFilters,
                            saved_filters_smt_instructors: smtInstructorFilters,
                        },
                    }),
                );
                dispatch(setIsLoading(false));
                return true;
            } catch (error: any) {
                dispatch(setError(error.toString()));
                dispatch(setIsLoading(false));
                return false;
            }
        });
    };
};

export const selectStatus = (state: GlobalState) => state.user.status;
export const selectUser = (state: GlobalState) => state.user.user;
export const selectError = (state: GlobalState) => state.user.error;
export const selectRoles = (state: GlobalState) => state.user.roles;
export const selectIsLoading = (state: GlobalState) => state.user.isLoading;
export const selectIsLoaded = (state: GlobalState) => state.user.isLoaded;
export const selectInstructorListPreference = (state: GlobalState) =>
    state.user.user?.profile?.preferences?.instructor_list;
export const selectActivityListPreference = (state: GlobalState) =>
    state.user.user?.profile?.preferences?.activity_list;
export const selectSFDCListPreference = (state: GlobalState) =>
    state.user.user?.profile?.preferences?.sfdc_list;
export const selectInstructorListSavedFilters = (state: GlobalState) =>
    state.user.user?.profile?.saved_filters?.length
        ? state.user.user?.profile?.saved_filters
        : [];
export const selectInstructorListSavedFilterLookup = (state: GlobalState) =>
    state.user.user?.profile?.saved_filters?.length
        ? state.user.user.profile.saved_filters.reduce((acc, item) => {
              acc[item.name] = item.filter;
              return acc;
          }, {} as { [key: string]: InstructorFilters })
        : null;
export const selectActivityListSavedFilters = (state: GlobalState) =>
    state.user.user?.profile?.saved_filters_smt?.length
        ? state.user.user?.profile?.saved_filters_smt
        : [];
export const selectActivityListSavedFilterLookup = (state: GlobalState) =>
    state.user.user?.profile?.saved_filters_smt?.length
        ? state.user.user.profile.saved_filters_smt.reduce((acc, item) => {
              acc[item.name] = item.filter;
              return acc;
          }, {} as { [key: string]: ActivityFilters })
        : null;
export const selectSMTInstructorListSavedFilters = (state: GlobalState) =>
    state.user.user?.profile?.saved_filters_smt_instructors?.length
        ? state.user.user?.profile?.saved_filters_smt_instructors
        : [];
export const selectSMTInstructorListSavedFilterLookup = (state: GlobalState) =>
    state.user.user?.profile?.saved_filters_smt_instructors?.length
        ? state.user.user.profile.saved_filters_smt_instructors.reduce(
              (acc, item) => {
                  acc[item.name] = item.filter;
                  return acc;
              },
              {} as { [key: string]: SmtInstructorFilters },
          )
        : null;
export const selectUserId = (state: GlobalState) => state.user.user?.profile.pk;

export default userSlice.reducer;
