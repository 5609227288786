import { Link, useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Alert,
    Spinner,
    Icon,
    Table,
    TableFiltering,
    TablePagination,
    Button,
} from '@amzn/awsui-components-react';

import { UserProfileData } from '../../../../common/interfaces/userProfile';
import TableHeader from '../../../../common/components/TableHeader/TableHeader';
import {
    AJAX_CALL_ERROR,
    GRIMSBY_PAGE_COUNT,
    LOADING_TEXT,
} from '../../../../common/constants/grimsby';
import {
    getUserList,
    selectUserList,
    selectError,
    selectIsLoading,
    selectIsLoaded,
    selectPagesCount,
    selectCurrentPageIndex,
    selectTotalUsersCount,
    setFrom,
    setSearchText,
    setUserList,
    setCurrentPageIndex,
    resetUserListSlice,
} from '../../../../common/store/slices/userListSlice';
import { setSelectedUser } from '../../../store/slices/selectedUserSlice';
import { Actions } from '../../../../common/constants/auth';
import Can from '../../../../common/components/Can';

export const USER_TABLE_TITLE = 'Grimsby users';

export const USER_TABLE_FILTER_TEXT = 'Search users by email or full name';

export const columnDefinitions: Array<Table.ColumnDefinition> = [
    {
        id: 'email',
        header: 'Email Address',
        cell: (user: UserProfileData) => (
            <Link to={`/admin/user-management/users/${user.pk}`}>
                {user.email}
            </Link>
        ),
    },
    {
        id: 'status',
        header: 'Status',
        cell: (user: UserProfileData) =>
            user.active ? (
                <span className="awsui-util-status-positive">
                    <Icon name="status-positive" /> Active
                </span>
            ) : (
                <span className="awsui-util-status-inactive">
                    <Icon name="status-stopped" /> Inactive
                </span>
            ),
    },
    {
        id: 'name',
        header: 'Name',
        cell: (user: UserProfileData) => user.full_name,
    },
    {
        id: 'programs',
        header: 'Programs',
        cell: (user: UserProfileData) =>
            user.programs && user.programs.join(', '),
    },
    {
        id: 'regions',
        header: 'Regions',
        cell: (user: UserProfileData) =>
            user.regions && user.regions.join(', '),
    },
];

const AdminUserManagementList = () => {
    const error = useSelector(selectError);
    const isLoading = useSelector(selectIsLoading);
    const isLoaded = useSelector(selectIsLoaded);
    const userList = useSelector(selectUserList);
    const pagesCount = useSelector(selectPagesCount);
    const currentPageIndex = useSelector(selectCurrentPageIndex);
    const totalUsersCount = useSelector(selectTotalUsersCount);
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUserList());
        dispatch(setSelectedUser(null));

        return () => {
            dispatch(resetUserListSlice());
        };
    }, [dispatch]);
    if (error) {
        return (
            <Alert
                data-testid="AdminUserManagementListError"
                header="Error"
                type="error"
            >
                {AJAX_CALL_ERROR}
            </Alert>
        );
    } else if (isLoaded) {
        return (
            <Table
                data-testid="AdminUserManagementList"
                loading={isLoading}
                loadingText={LOADING_TEXT}
                columnDefinitions={columnDefinitions}
                items={userList}
                header={
                    <TableHeader
                        title={USER_TABLE_TITLE}
                        actions={
                            <Can
                                perform={Actions.INSTRUCTOR_MODIFY}
                                yes={() => (
                                    <Button
                                        icon="add-plus"
                                        variant="primary"
                                        data-testid="AdminUserManagementListActionsCreate"
                                        onClick={() =>
                                            history.push({
                                                pathname: `/admin/user-management/users/create`,
                                            })
                                        }
                                    >
                                        Add new user
                                    </Button>
                                )}
                            />
                        }
                        count={totalUsersCount ? `${totalUsersCount}` : ''}
                    />
                }
                empty={
                    <div className="awsui-util-t-c">
                        <div className="awsui-util-pt-s awsui-util-mb-xs">
                            <b>No users</b>
                        </div>
                        <p className="awsui-util-mb-s">No users to display.</p>
                    </div>
                }
            >
                <TableFiltering
                    filteringFunction={null}
                    filteringPlaceholder={USER_TABLE_FILTER_TEXT}
                    filteringLabel={USER_TABLE_FILTER_TEXT}
                    onFilteringDelayedInput={(event) => {
                        dispatch(setFrom(0));
                        dispatch(
                            setSearchText(
                                event.detail.filteringText
                                    ? event.detail.filteringText
                                    : null,
                            ),
                        );
                        dispatch(setUserList([]));
                        dispatch(getUserList());
                    }}
                />
                <TablePagination
                    pageSize={GRIMSBY_PAGE_COUNT}
                    pagesCount={pagesCount}
                    disabled={isLoading}
                    currentPageIndex={currentPageIndex}
                    onPaginationChange={(event) => {
                        dispatch(
                            setCurrentPageIndex(event.detail.currentPageIndex),
                        );
                        const from =
                            (event.detail.currentPageIndex - 1) *
                            GRIMSBY_PAGE_COUNT;
                        dispatch(setFrom(from));
                        dispatch(getUserList());
                    }}
                />
            </Table>
        );
    } else {
        return <Spinner data-testid="AdminUserManagementListSpinner" />;
    }
};

export default AdminUserManagementList;
