import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ColumnLayout, Table, Icon } from '@amzn/awsui-components-react';

import {
    Box,
    Link as HTTPLink,
    Modal,
    Popover,
    StatusIndicator,
    Button,
    Badge,
    Alert,
} from '@amzn/awsui-components-react-v3';

import {
    formatNumber,
    formatGrimsbyDate,
    formatDeliverySessionTime,
    showEditButton,
    ILT_MODALITY,
    CANCELLED_STATUS,
    currencyFormatter,
    AudienceType,
    HYBRID_MODALITY,
} from '../../Common/Common';
import Section, {
    SectionProps,
    ColumnContentData,
    KeyValueData,
} from '../../../../../common/components/Section/Section';
import {
    EMPTY_STRING,
    SectionContentType,
} from '../../../../../common/constants/grimsby';
import { Actions } from '../../../../../common/constants/auth';
import Can from '../../../../../common/components/Can';
import { getUserRoles } from '../../../../../common/utils/auth';
import TableHeader from '../../../../../common/components/TableHeader/TableHeader';
import {
    ActivityData,
    CostItem,
    Customer,
    PaymentStatus,
} from '../../../../interfaces/activity';
import CreateKikuClassDialog from './CreateKikuClassDialog';
import { formatStringArray } from '../../../../../common/utils/formatStringArray';
import { formatString } from '../../../../../common/utils/formatString';
import { formatBoolean } from '../../../../../common/utils/formatBoolean';

import './DetailsTab.css';
import { CreateLMSClassResponse } from '../../../../interfaces/lmsServiceResponse';
import getKikuURL from '../../../../../common/utils/getKikuURL';

export interface DeliverySessionItem {
    date: string;
    time: string;
}

export interface RegistrationDetailsItem {
    classSize: number;
    registered: number;
    waitListed: number;
    lmsType: string;
    lmsId: string;
}

export interface CostTableItem {
    costType: string;
    costAmount: number | null;
    paymentPoNumber: string;
    paymentStatus: JSX.Element;
}

export interface InstructorItem {
    pk: string;
    name: string;
    role: string;
    email: string;
    type: string;
    location: string;
    doNotShuffle: JSX.Element;
}

interface RegistrationLinkDisplayInfo {
    readonly description: string;
    readonly attributeName: keyof ActivityData;
}

export const deliverySessionColumnDefinition: Array<Table.ColumnDefinition> = [
    {
        id: 'Date',
        header: 'Date',
        cell: (item: DeliverySessionItem) => formatString(item.date),
    },
    {
        id: 'Time',
        header: 'Time',
        cell: (item: DeliverySessionItem) => formatString(item.time),
    },
];

export const registrationDetailsColumnDefinition: Array<Table.ColumnDefinition> =
    [
        {
            id: 'ClassSize',
            header: 'Class size',
            cell: (item: RegistrationDetailsItem) =>
                formatString(`${item.classSize}`),
        },
        {
            id: 'Registered',
            header: 'Registered',
            cell: (item: RegistrationDetailsItem) =>
                formatString(`${item.registered}`),
        },
        {
            id: 'Waitlisted',
            header: 'Waitlisted',
            cell: (item: RegistrationDetailsItem) =>
                formatString(`${item.waitListed}`),
        },
        {
            id: 'LMSType',
            header: 'LMS type',
            cell: (item: RegistrationDetailsItem) => formatString(item.lmsType),
        },
        {
            id: 'LMSId',
            header: 'LMS ID',
            cell: (item: RegistrationDetailsItem) =>
                formatString(`${item.lmsId}`),
        },
        {
            id: 'RoleLinks',
            header: 'Role specific registration links',
            cell: (item: RegistrationDetailsItem) => {
                return (
                    <>
                        <span>View links</span>
                    </>
                );
            },
        },
    ];

export const instructorsColumnDefinition: Array<Table.ColumnDefinition> = [
    {
        id: 'Name',
        header: 'Name',
        cell: (item: InstructorItem) => (
            <Link to={'/instructors/' + item.pk}>
                {formatString(item.name)}
                {item.type === 'External' && (
                    <Badge className="fl-badge" color="blue">
                        FL
                    </Badge>
                )}
            </Link>
        ),
    },
    {
        id: 'Role',
        header: 'Role',
        cell: (item: InstructorItem) => formatString(item.role),
    },
    {
        id: 'Email',
        header: 'Email',
        cell: (item: InstructorItem) => formatString(item.email),
    },
    {
        id: 'Type',
        header: 'Type',
        cell: (item: InstructorItem) => formatString(item.type),
    },
    {
        id: 'Location',
        header: 'Location',
        cell: (item: InstructorItem) => formatString(item.location),
    },
    {
        id: 'DoNotShuffle',
        header: 'Do not shuffle',
        cell: (item: InstructorItem) => item.doNotShuffle,
    },
];

export const costItemsColumnDefinition: Array<Table.ColumnDefinition> = [
    {
        id: 'CostType',
        header: 'Cost Type',
        cell: (item: CostTableItem) => formatString(item.costType),
    },
    {
        id: 'CostAmount',
        header: 'Cost Amount',
        cell: (item: CostTableItem) => formatNumber(item.costAmount),
    },
    {
        id: 'PaymentPO',
        header: 'Payment PO# or Pcard',
        cell: (item: CostTableItem) => formatString(item.paymentPoNumber),
    },
    {
        id: 'PaymentStatus',
        header: 'Payment status',
        cell: (item: CostTableItem) => item.paymentStatus,
    },
];

export const formatPaymentStatus = (
    costPaymentStatus: PaymentStatus | null,
): JSX.Element => {
    if (costPaymentStatus === PaymentStatus.PendingPayment) {
        return (
            <span className="awsui-util-status-pending nowrap">
                <Icon name="status-pending" />
                &nbsp;{costPaymentStatus}
            </span>
        );
    } else if (costPaymentStatus === PaymentStatus.VendorPaid) {
        return (
            <span className="awsui-util-status-stopped nowrap">
                <Icon name="status-stopped" />
                &nbsp;{costPaymentStatus}
            </span>
        );
    } else {
        return (
            <span className="awsui-util-status-positive nowrap">
                <Icon name="status-positive" />
                &nbsp;{PaymentStatus.PiggyBank}
            </span>
        );
    }
};

const formatCustomers = (customers: Array<Customer> | null) =>
    customers?.reduce((acc, customer, index) => {
        if (customer.customer_name) {
            acc = `${acc}${index === 0 ? '' : ', '}${customer.customer_name}`;
        }
        return acc;
    }, '') || EMPTY_STRING;

const formatActivityColumnData = (
    keyValueData: KeyValueData,
    activity: ActivityData,
    selectedModality: 'vILT' | 'ILT' | 'HYBRID_MODALITY',
) => {
    if (selectedModality === 'HYBRID_MODALITY') {
        return [keyValueData];
    }
    return activity.activity_modality === selectedModality
        ? [keyValueData]
        : [];
};

const DetailsTab = ({ activity, onActivityDetailsUpdated }: { activity: ActivityData, onActivityDetailsUpdated: () => void }) => {
    const history = useHistory();
    const costItems = activity.cost_items;
    const displayEditButton = showEditButton(activity);
    const [showRegistrationLinks, setShowRegistrationLinks] =
        React.useState(false);
    const [kikuErrors, setKikuErrors] = React.useState<Array<string>>([]);
    const [kikuWarnings, setKikuWarnings] = React.useState<Array<string>>([]);
    const cancellationReason =
        activity.activity_status === CANCELLED_STATUS &&
        activity.cancellation_reason
            ? ': ' + activity.cancellation_reason
            : '';

    const isILT = activity.activity_modality === ILT_MODALITY;
    const activityDetailsProps: SectionProps = {
        testId: 'ActivityDetailsSection',
        header: {
            label: 'Activity details',
            buttons: displayEditButton ? (
                <div className="awsui-util-action-stripe-group">
                    <Can
                        roles={getUserRoles()}
                        perform={Actions.ACTIVITY_MODIFY}
                        yes={() => (
                            <div className="awsui-util-action-stripe-group">
                                <Button
                                    data-testid="ActivityDetaisEditButton"
                                    onClick={() => {
                                        history.push({
                                            pathname: `/activities/${activity.pk}/edit-activity-details`,
                                        });
                                    }}
                                >
                                    Edit
                                </Button>
                            </div>
                        )}
                        no={() => null}
                    />
                </div>
            ) : null,
        },
        content: {
            type: SectionContentType.Column,
            columnsCount: 4 as ColumnLayout.Columns,
            columns: [
                [
                    {
                        key: 'Activity status',
                        value:
                            formatString(activity.activity_status) +
                            cancellationReason,
                    },
                    {
                        key: 'Modality',
                        value: formatString(activity.activity_modality),
                    },
                ],
                [
                    {
                        key: 'Activity type',
                        value: formatString(activity.activity_type),
                    },
                    {
                        key: 'Provider',
                        value: formatString(activity.provider),
                    },
                ],
                [
                    {
                        key: 'Program',
                        value: formatString(activity.program),
                    },
                    {
                        key: 'Customers',
                        value: formatCustomers(activity.customers),
                    },
                ],
                [
                    {
                        key: 'Audience',
                        value: formatString(activity.activity_audience),
                    },
                ],
            ],
        } as ColumnContentData,
    };

    const publicOperationsDetailsProps: SectionProps = {
        testId: 'OperationDetailsSection',
        className: 'awsui-util-mb-n',
        header: {
            label: 'Operations details',
            buttons: displayEditButton ? (
                <Can
                    roles={getUserRoles()}
                    perform={Actions.ACTIVITY_MODIFY}
                    yes={() => (
                        <div className="awsui-util-action-stripe-group">
                            <Button
                                data-testid="OperationsDetailsSectionEditButton"
                                onClick={() => {
                                    history.push({
                                        pathname: `/activities/${activity.pk}/edit-operations-and-registration-details`,
                                    });
                                }}
                            >
                                Edit
                            </Button>
                        </div>
                    )}
                    no={() => null}
                />
            ) : null,
        },
        content: {
            type: SectionContentType.Column,
            columnsCount: 4 as ColumnLayout.Columns,
            columns: [
                [
                    {
                        key: 'Operations owner',
                        value: formatString(activity.operations_owner),
                    },
                    {
                        key: 'Catering requirement',
                        value: formatBoolean(activity.catering_required, 'Yes'),
                    },
                ],
                [
                    activity.additional_owners?.length ?
                    {
                        key: 'Secondary owner',
                        value: formatStringArray(activity.additional_owners?.map(owner => owner.additional_owner_name))
                    } : null,
                    {
                        key: 'Scheduler',
                        value: formatString(activity.scheduler),
                    },
                    {
                        key: 'Requester',
                        value: formatString(activity.requestor),
                    },
                    {
                        key: 'Class request SIM',
                        value: activity?.class_request_sim ? (
                            activity.class_request_sim.substring(0, 4) ===
                            'http' ? (
                                <HTTPLink
                                    href={activity.class_request_sim}
                                    target="_blank"
                                    data-testid="class-sim-link"
                                >
                                    {formatString(activity.class_request_sim)}
                                </HTTPLink>
                            ) : (
                                formatString(activity.class_request_sim)
                            )
                        ) : (
                            EMPTY_STRING
                        ),
                    },
                ],
                [
                    {
                        key: 'Cost currency',
                        value: formatString(activity.cost_currency),
                    },
                    {
                        key: 'Billed currency',
                        value: formatString(activity.billed_currency),
                    },
                ],
                [
                    {
                        key: 'Attended',
                        value: formatNumber(activity.attended),
                    },
                    {
                        key: 'Total billed revenue',
                        value:
                            activity.total_billed_revenue &&
                            activity.billed_currency
                                ? currencyFormatter(
                                      activity.total_billed_revenue,
                                      activity.billed_currency
                                          ? activity.billed_currency
                                          : 'USD',
                                  )
                                : formatNumber(activity.total_billed_revenue),
                    },
                ],
            ],
        } as ColumnContentData,
    };

    const otherOperationsDetailsProps: SectionProps = {
        testId: 'OperationDetailsSection',
        className: 'awsui-util-mb-n',
        header: {
            label: 'Operations details',
            buttons: displayEditButton ? (
                <Can
                    roles={getUserRoles()}
                    perform={Actions.ACTIVITY_MODIFY}
                    yes={() => (
                        <div className="awsui-util-action-stripe-group">
                            <Button
                                data-testid="OperationsDetailsSectionEditButton"
                                onClick={() => {
                                    history.push({
                                        pathname: `/activities/${activity.pk}/edit-operations-and-registration-details`,
                                    });
                                }}
                            >
                                Edit
                            </Button>
                        </div>
                    )}
                    no={() => null}
                />
            ) : null,
        },
        content: {
            type: SectionContentType.Column,
            columnsCount: 4 as ColumnLayout.Columns,
            columns: [
                [
                    {
                        key: 'Operations owner',
                        value: formatString(activity.operations_owner),
                    },
                    isILT
                        ? {
                              key: 'Catering requirement',
                              value: formatBoolean(
                                  activity.catering_required,
                                  'Yes',
                              ),
                          }
                        : {
                              key: 'Seller Country',
                              value: formatString(
                                  activity.sor_country ||
                                      activity.delivery_country,
                              ),
                          },
                ],
                [
                    activity.additional_owners?.length ?
                    {
                        key: 'Secondary owner',
                        value: formatStringArray(activity.additional_owners?.map(owner => owner.additional_owner_name))
                    } : null,
                    {
                        key: 'Scheduler',
                        value: formatString(activity.scheduler),
                    },
                    {
                        key: 'Requester',
                        value: formatString(activity.requestor),
                    },
                    {
                        key: 'Class request SIM',
                        value: activity?.class_request_sim ? (
                            activity.class_request_sim.substring(0, 4) ===
                            'http' ? (
                                <HTTPLink
                                    href={activity.class_request_sim}
                                    target="_blank"
                                    data-testid="class-sim-link"
                                >
                                    {formatString(activity.class_request_sim)}
                                </HTTPLink>
                            ) : (
                                formatString(activity.class_request_sim)
                            )
                        ) : (
                            EMPTY_STRING
                        ),
                    },
                ],
                [
                    {
                        key: 'Cost currency',
                        value: formatString(activity.cost_currency),
                    },
                ],
                [
                    {
                        key: 'Attended',
                        value: formatNumber(activity.attended),
                    },
                ],
            ],
        } as ColumnContentData,
    };

    const getvILTLink = () => {
        switch (activity.v_ilt_type) {
            case 'Necto':
            case 'WebEx':
                if (
                    activity?.v_ilt_id &&
                    activity.v_ilt_id.substring(0, 4) === 'http'
                ) {
                    return (
                        <HTTPLink
                            href={activity.v_ilt_id}
                            target="_blank"
                            data-testid="vilt-link"
                        >
                            {formatString(activity.v_ilt_id)}
                        </HTTPLink>
                    );
                }
                return formatString(activity.v_ilt_id);
            default:
                return formatString(activity.v_ilt_id);
        }
    };

    const copyOneClickLink = (link: string) => {
        navigator.clipboard.writeText(link);
    };

    const registrationLinkInfo = (linkDetails: RegistrationLinkDisplayInfo) => {
        if (activity.lms_type === 'Kiku') {
            return {
                key: linkDetails.description,
                value: !!activity[linkDetails.attributeName] ? (
                    <Popover
                        size="small"
                        position="top"
                        triggerType="custom"
                        dismissButton={false}
                        content={
                            <StatusIndicator type="success">
                                Link copied
                            </StatusIndicator>
                        }
                    >
                        <button
                            onClick={() =>
                                copyOneClickLink(
                                    activity[linkDetails.attributeName]?.toString() ?? '',
                                )
                            }
                            className="nowrap unstyled-button"
                        >
                            <Icon name={'copy'} />
                            <span className="registration-link">
                                {activity[linkDetails.attributeName]}
                            </span>
                        </button>
                    </Popover>
                ) : (
                    formatString('')
                ),
            };
        }

        return {};
    };

    const CopyLinkButton = (url: string) => {
        return (
            <Popover
                size="small"
                position="top"
                triggerType="custom"
                dismissButton={false}
                content={
                    <StatusIndicator type="success">
                        Link copied
                    </StatusIndicator>
                }
            >
                <Button
                    iconName="copy"
                    variant="normal"
                    onClick={() => copyOneClickLink(url)}
                >
                    Copy
                </Button>
            </Popover>
        );
    };

    const roleSpecificLinks = () => {
        if (activity.lms_type !== 'Kiku') {
            return [];
        }

        return [
            {
                key: 'Role-specific registration links',
                value: !!activity.lms_id ? (
                    <>
                        <button
                            onClick={() => setShowRegistrationLinks(true)}
                            className="view-links"
                        >
                            View links
                        </button>
                        <Modal
                            size={'medium'}
                            visible={showRegistrationLinks}
                            onDismiss={() => {
                                setShowRegistrationLinks(false);
                            }}
                            header={'Registration links'}
                            footer={
                                <Box float="right">
                                    <Button
                                        variant="primary"
                                        onClick={() =>
                                            setShowRegistrationLinks(false)
                                        }
                                    >
                                        Close
                                    </Button>
                                </Box>
                            }
                        >
                            <div className="activity-link-container">
                                <div className="link-label">
                                    Amazon employees
                                </div>
                                <div className="link-url-container">
                                    <div className="link-box">
                                        https://kiku.aws.training/learningobject/ilt?id=
                                        {activity.lms_id}
                                    </div>
                                    {CopyLinkButton(
                                        `https://kiku.aws.training/learningobject/ilt?id=${activity.lms_id}`,
                                    )}
                                </div>
                                <div className="link-label">Anonymous</div>
                                <div className="link-url-container">
                                    <div className="link-box">
                                        https://www.aws.training/learningobject/ilt?id=
                                        {activity.lms_id}
                                    </div>
                                    {CopyLinkButton(
                                        `https://www.aws.training/learningobject/ilt?id=${activity.lms_id}`,
                                    )}
                                </div>
                                <div className="link-label">APN members</div>
                                <div className="link-url-container">
                                    <div className="link-box">
                                        https://partnercentral.awspartner.com/LmsSsoRedirect?RelayState=%2flearningobject%2filt%3fid%3d
                                        {activity.lms_id}
                                    </div>
                                    {CopyLinkButton(
                                        `https://partnercentral.awspartner.com/LmsSsoRedirect?RelayState=%2flearningobject%2filt%3fid%3d${activity.lms_id}`,
                                    )}
                                </div>
                                <div className="link-label">Customers</div>
                                <div className="link-url-container">
                                    <div className="link-box">
                                        https://www.aws.training/account/logonoptions?returnUrl=%2flearningobject%2filt%3fid%3d
                                        {activity.lms_id}
                                    </div>
                                    {CopyLinkButton(
                                        `https://www.aws.training/account/logonoptions?returnUrl=%2flearningobject%2filt%3fid%3d${activity.lms_id}`,
                                    )}
                                </div>
                            </div>
                        </Modal>
                    </>
                ) : (
                    formatString('')
                ),
            },
        ];
    };

    const handleSaveKikuClassResponse = (response: CreateLMSClassResponse) => {
        setKikuErrors(response?.errors ?? []);
        setKikuWarnings(response?.warnings ?? []);

        if (response?.errors.length === 0) {
            onActivityDetailsUpdated();
        }
    };

    const showCreateKikuButton = !['Cancelled','Completed'].includes(activity.activity_status);
    const showKikuWarnings = kikuWarnings.length > 0 && kikuErrors.length === 0;
    const kikuWarningList = React.useMemo(() => {
        return (
            <>
                <span>Warnings:</span>
                <ul>
                    {kikuWarnings.map((warning, i) => (
                        <li key={`kiku-warning-${i}`}>{warning}</li>
                    ))}
                </ul>
            </>
        );
    }, [kikuWarnings]);
    const kikuErrorList = React.useMemo(() => {
        return (
            <>
                <span>Errors:</span>
                <ul>
                    {kikuErrors.map((error, i) => (
                        <li key={`kiku-error-${i}`}>{error}</li>
                    ))}
                </ul>
            </>
        );
    }, [kikuErrors]);

    const registrationDetailsProps: SectionProps = {
        testId: 'RegistrationDetailsSection',
        className: 'awsui-util-mb-n',
        header: {
            label: 'Registration details',
            buttons: displayEditButton ? (
                <Can
                    roles={getUserRoles()}
                    perform={Actions.ACTIVITY_MODIFY}
                    yes={() => (
                        <>
                            { showCreateKikuButton === true && (
                                <div className="awsui-util-action-stripe-group">
                                    <CreateKikuClassDialog activity={activity} onSave={handleSaveKikuClassResponse} />
                                </div>
                            )}
                        </>
                    )}
                    no={() => null}
                />
            ) : null,
        },
        content: {
            type: SectionContentType.Column,
            columnsCount: 4 as ColumnLayout.Columns,
            genericContent: (
                <>
                    {kikuErrors.length > 0 && (
                        <Alert type="error">{kikuErrorList}</Alert>
                    )}
                    {showKikuWarnings  && (
                        <Alert type='warning'>{kikuWarningList}</Alert>
                    )}
                </>
            ),
            columns: [
                [
                    {
                        key: 'Class size',
                        value: formatNumber(activity.class_size),
                    },
                    {
                        key: 'LMS ID',
                        value: activity.lms_id ? (
                            <HTTPLink
                                href={getKikuURL(activity.lms_id)}
                                target="_blank"
                                data-testid="lms-link"
                            >
                                {formatString(activity.lms_id)}
                            </HTTPLink>
                        ) : (
                            EMPTY_STRING
                        ),
                    },
                ],
                [
                    {
                        key: 'Registered',
                        value: formatNumber(activity.registered),
                    },
                    ...formatActivityColumnData(
                        {
                            key: 'vILT link',
                            value: activity.v_ilt_id
                                ? getvILTLink()
                                : EMPTY_STRING,
                        },
                        activity,
                        'HYBRID_MODALITY',
                    ),
                    ...formatActivityColumnData(
                        {
                            key: 'vILT Meeting ID',
                            value: activity.v_ilt_meeting_id || EMPTY_STRING,
                        },
                        activity,
                        'HYBRID_MODALITY',
                    ),
                    ...roleSpecificLinks(),
                ],
                [
                    {
                        key: 'Waitlisted',
                        value: formatNumber(activity.waitlisted),
                    },
                    ...formatActivityColumnData(
                        {
                            key: 'vILT type',
                            value: formatString(activity.v_ilt_type),
                        },
                        activity,
                        'HYBRID_MODALITY',
                    ),
                    ...formatActivityColumnData(
                        {
                            key: 'Builder Labs Classrooms Arn',
                            value: activity.classrooms_arn || EMPTY_STRING,
                        },
                        activity,
                        'HYBRID_MODALITY',
                    ),
                    ...formatActivityColumnData(
                        {
                            key: 'Builder Labs Classrooms Student URL',
                            value: activity.classrooms_student_url || EMPTY_STRING,
                        },
                        activity,
                        'HYBRID_MODALITY',
                    ),
                    registrationLinkInfo({description: "LMS one-click registration link", attributeName: "lms_one_click_registration_link"}),
                ],
                [
                    {
                        key: 'LMS type',
                        value: formatString(activity.lms_type),
                    },
                    registrationLinkInfo({description: "APN Merged Registration Link", attributeName: "apn_merged_registration_link"}),
                    registrationLinkInfo({description: "Commercial Merged Registration Link", attributeName: "commercial_merged_registration_link"}),
                ],
            ],
        } as ColumnContentData,
    };

    const getCostItems = () => {
        return (
            costItems?.map(
                (costItem: CostItem) =>
                    ({
                        costType: formatString(costItem.cost_type),
                        costAmount: costItem.cost_amount,
                        paymentPoNumber: costItem.payment_po_number_or_pcard,
                        paymentStatus: formatPaymentStatus(
                            costItem.payment_status as PaymentStatus,
                        ),
                    } as CostTableItem),
            ) ?? []
        );
    };

    const deliveryDetailsProps: SectionProps = {
        testId: 'DeliverDetailsSection',
        className: 'awsui-util-mb-n',
        header: {
            label: 'Delivery details',
            buttons: displayEditButton ? (
                <Can
                    roles={getUserRoles()}
                    perform={Actions.ACTIVITY_MODIFY}
                    yes={() => (
                        <div className="awsui-util-action-stripe-group">
                            <Button
                                data-testid="DeliveryDetailsSectionEditButton"
                                onClick={() => {
                                    history.push({
                                        pathname: `/activities/${activity.pk}/edit-delivery-details`,
                                    });
                                }}
                            >
                                Edit
                            </Button>
                        </div>
                    )}
                    no={() => null}
                />
            ) : null,
        },
        content: {
            type: SectionContentType.Column,
            columnsCount: 4 as ColumnLayout.Columns,
            columns: [
                [
                    {
                        key: 'Course',
                        value: formatString(activity.course_name),
                    },
                    ...formatActivityColumnData(
                        {
                            key: 'Delivery geo',
                            value: formatString(activity.delivery_geo),
                        },
                        activity,
                        'HYBRID_MODALITY',
                    ),
                ],
                [
                    {
                        key: 'Delivery location',
                        value: (() => {
                            const location = formatStringArray([
                                activity.delivery_city,
                                activity.delivery_state,
                                activity.delivery_country,
                            ]);
                            const isILT =
                                activity.activity_modality === ILT_MODALITY;
                            const isHybrid =
                                activity.activity_modality === HYBRID_MODALITY;
                            const isIltOrHybrid = isILT || isHybrid;

                            return (
                                <>
                                    {!!activity.room && isIltOrHybrid && (
                                        <p>{activity.room}</p>
                                    )}
                                    {!!activity.delivery_address_1 &&
                                        isIltOrHybrid && (
                                            <p>{activity.delivery_address_1}</p>
                                        )}
                                    {!!activity.delivery_address_2 &&
                                        isIltOrHybrid && (
                                            <p>{activity.delivery_address_2}</p>
                                        )}
                                    {!!location && <p>{location}</p>}
                                    {!!activity.delivery_postal_code &&
                                        isIltOrHybrid && (
                                            <p>
                                                {activity.delivery_postal_code}
                                            </p>
                                        )}
                                </>
                            );
                        })(),
                    },
                ],
                [
                    {
                        key: 'Time zone',
                        value: formatString(activity.delivery_timezone),
                    },
                    ...formatActivityColumnData(
                        {
                            key: 'Delivery facility type',
                            value: formatString(
                                activity.delivery_facility_type,
                            ),
                        },
                        activity,
                        'HYBRID_MODALITY',
                    ),
                ],
                [
                    {
                        key: 'Delivery language',
                        value: formatString(activity.delivery_language),
                    },
                    ...formatActivityColumnData(
                        {
                            key: 'Facility size',
                            value: activity.facility_size ?? EMPTY_STRING,
                        },
                        activity,
                        'HYBRID_MODALITY',
                    ),
                ],
            ],
        } as ColumnContentData,
    };

    const getTableHeaderComponent = (titleStr: string, count?: number) => (
        <TableHeader
            title={titleStr}
            actions={<React.Fragment />}
            count={count ? `${count}` : ''}
        />
    );

    const emptyInstructorsTableComponent = (
        <div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
                <b>No Instructors</b>
            </div>
            <p className="awsui-util-mb-s">Instructors will display here.</p>
        </div>
    );

    const emptyCostItemsTableComponent = (
        <div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
                <b>No Cost items</b>
            </div>
            <p className="awsui-util-mb-s">Cost items will display here.</p>
        </div>
    );

    const generateSessionItems = () => {
        const deliverySessions = activity.delivery_sessions;
        return (
            deliverySessions?.map(
                (deliverySession) =>
                    ({
                        date: formatGrimsbyDate(
                            deliverySession.start_timestamp,
                            activity.delivery_timezone,
                        ),
                        time: formatDeliverySessionTime(
                            deliverySession,
                            activity.delivery_timezone,
                        ),
                    } as DeliverySessionItem),
            ) ?? []
        );
    };

    const generateInstructorItems = () => {
        const instructorsList = activity.instructors;
        return (
            instructorsList?.map(
                (instructor) =>
                    ({
                        pk: instructor.pk,
                        name: instructor.name,
                        role: instructor.role,
                        email: instructor.email,
                        type: instructor.type,
                        location: instructor.location,
                        doNotShuffle: formatBoolean(
                            instructor.do_not_shuffle,
                            'Do not shuffle',
                        ),
                    } as InstructorItem),
            ) ?? []
        );
    };

    return (
        <>
            <div data-testid="ActivityDetailsPageDetailsTab">
                <Section {...activityDetailsProps} />
                <Section {...deliveryDetailsProps} />
                <Table
                    data-testid="DeliverySessionsTable"
                    columnDefinitions={deliverySessionColumnDefinition}
                    items={generateSessionItems()}
                    header={getTableHeaderComponent(
                        'Sessions',
                        activity.delivery_sessions.length,
                    )}
                />
                <Table
                    data-testid="DeliveryDetailsInstructorsTable"
                    columnDefinitions={instructorsColumnDefinition}
                    items={generateInstructorItems()}
                    header={getTableHeaderComponent(
                        'Instructors',
                        activity.instructors?.length,
                    )}
                    empty={emptyInstructorsTableComponent}
                />
                <section className="awsui-util-mt-l">
                    {activity.activity_audience === AudienceType.Public && (
                        <Section {...publicOperationsDetailsProps} />
                    )}
                    {activity.activity_audience !== AudienceType.Public && (
                        <Section {...otherOperationsDetailsProps} />
                    )}
                    <Section {...registrationDetailsProps} />
                    {!!costItems?.length && (
                        <Table
                            data-testid="CostItemsTable"
                            columnDefinitions={costItemsColumnDefinition}
                            items={getCostItems()}
                            header={getTableHeaderComponent(
                                'Cost items',
                                activity.cost_items?.length,
                            )}
                            empty={emptyCostItemsTableComponent}
                        />
                    )}
                </section>
            </div>
        </>
    );
};

export default DetailsTab;
