import { Button, SpaceBetween } from '@amzn/awsui-components-react-v3';
import React from 'react';
import { BlockedTimeFormData } from '../../../../common/interfaces/blockedTime';

const EDIT_BLOCK_TIME_TEST = 'Save block time';

interface EditModalFooterProps {
    submitting: boolean;
    handleEdit: (reserveTimeFormValues: BlockedTimeFormData) => void;
    handleDelete: (reserveTimeFormValues: BlockedTimeFormData) => void;
    formValues: BlockedTimeFormData;
    eventSource: any;
}
const EditModalFooter = ({
    handleDelete,
    handleEdit,
    submitting,
    formValues,
}: EditModalFooterProps) => {
    return (
        <SpaceBetween direction="horizontal" size="xs">
            <Button
                data-testid={'DeleteReserveTime'}
                onClick={() => handleDelete(formValues)}
                disabled={submitting}
            >
                Delete block time
            </Button>
            <Button
                variant="primary"
                data-testid={'ReserveTimeConfirm'}
                onClick={() => handleEdit(formValues)}
                disabled={submitting}
            >
                {submitting ? 'Saving' : EDIT_BLOCK_TIME_TEST}
            </Button>
        </SpaceBetween>
    );
};

export default EditModalFooter;
