import { ActivityData } from '../../../interfaces/activity';

type ActivityValidationKeys =
    | 'UPDATE_ILT_HYBRID_DETAILS'
    | 'ACTIVITY_COMPLETED_DETAILS'
    | 'ACTIVITY_COMPLETED_COMMERCIAL_PUBLIC';

export const ACTIVITY_VALIDATION_FIELDS: {
    [key in ActivityValidationKeys]: Array<keyof Partial<ActivityData>>;
} = {
    UPDATE_ILT_HYBRID_DETAILS: ['delivery_address_1', 'delivery_postal_code'],
    ACTIVITY_COMPLETED_DETAILS: ['attended'],
    ACTIVITY_COMPLETED_COMMERCIAL_PUBLIC: [],
};
