import React from 'react';
import { ColumnLayout, Table } from '@amzn/awsui-components-react';
import { updateSelectedActivity } from '../../../../store/slices/selectedActivitySlice';
import {
    ActivityData,
    APNPartnerPrivateCustomer,
    CommercialPrivateCustomer,
    Customer,
    LineItem,
    Revenue,
} from '../../../../interfaces/activity';
import Section, {
    ColumnContentData,
    GenericContentData,
    HeaderData,
    SectionProps,
} from '../../../../../common/components/Section/Section';
import {
    EMPTY_STRING,
    SectionContentType,
} from '../../../../../common/constants/grimsby';
import {
    AudienceType,
    currencyFormatter,
    formatGrimsbyDate,
    formatNumber,
    isCommercialPrivateCustomer,
    isPartnerPrivateCustomer,
    isPublicCustomer,
} from '../../Common/Common';
import { formatString } from '../../../../../common/utils/formatString';
import { formatStatusHelper } from '../../../../../common/utils/formatHelper';
import TableHeader from '../../../../../common/components/TableHeader/TableHeader';
import Can from '../../../../../common/components/Can';
import { Actions } from '../../../../../common/constants/auth';
import { useHistory } from 'react-router';
import {
    Alert,
    Box,
    Modal,
    SpaceBetween,
    Button,
    Link,
    Icon,
    Popover,
    FormField,
    Input,
} from '@amzn/awsui-components-react-v3';
import { useDispatch } from 'react-redux';
import { useNotifications } from '../../../../../common/context/grimsbyNotifications';

import './CustomersTab.scss';

enum TOFStatus {
    NotStarted = 'Not Started',
    WithCustomer = 'With Customer',
    SignatureReceived = 'Signature Received',
    Expired = 'Expired',
}

const getTOFStatusComponent = formatStatusHelper([
    {
        status: TOFStatus.NotStarted,
        statusClass: 'awsui-util-status-inactive',
        iconClass: 'status-stopped',
        label: TOFStatus.NotStarted,
    },
    {
        status: TOFStatus.WithCustomer,
        statusClass: 'awsui-util-status-inactive',
        iconClass: 'status-pending',
        label: TOFStatus.WithCustomer,
    },
    {
        status: TOFStatus.SignatureReceived,
        statusClass: 'awsui-util-status-positive',
        iconClass: 'status-positive',
        label: TOFStatus.SignatureReceived,
    },
    {
        status: TOFStatus.Expired,
        statusClass: 'awsui-util-status-negative',
        iconClass: 'status-negative',
        label: TOFStatus.Expired,
    },
]);

const isCustomerSFDCSynced = (customer: Customer | CommercialPrivateCustomer | APNPartnerPrivateCustomer) => {
    return !!customer
    .sfdc_scheduling_request_id
}

const emptyRevenueTableComponent = (
    <div className="awsui-util-t-c awsui-util-status-inactive">
        <div className="awsui-util-pt-s awsui-util-mb-xs">
            <b>No revenue items</b>
        </div>
        <p className="awsui-util-mb-s">Revenue items will display here.</p>
    </div>
);

const emptyLineItemTableComponent = (
    <div className="awsui-util-t-c awsui-util-status-inactive">
        <div className="awsui-util-pt-s awsui-util-mb-xs">
            <b>No line items</b>
        </div>
        <p className="awsui-util-mb-s">Line items will display here.</p>
    </div>
);

const getCustomerEditButton = (
    index: number,
    onClick: () => void,
    onRemove: () => void,
): Pick<HeaderData, 'buttons'> => ({
    buttons: (
        <Can
            perform={Actions.ACTIVITY_MODIFY}
            yes={() => (
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            data-testid={`DeleteCustomer${index}`}
                            onClick={onRemove}
                        >
                            Delete
                        </Button>
                        <Button
                            data-testid={`EditCustomer${index}`}
                            onClick={onClick}
                        >
                            Edit
                        </Button>
                    </SpaceBetween>
                </Box>
            )}
            no={() => null}
        />
    ),
});

const revenueColumnDefinitions = (customer: CommercialPrivateCustomer) => {
    return [
        {
            id: 'revenueType',
            header: 'Non-billed revenue',
            cell: (item: Revenue) => formatString(item.type),
            width: window.innerWidth / 2,
        },
        {
            id: 'amount',
            header: 'Amount',
            cell: (item: Revenue) =>
                item.amount === undefined
                    ? EMPTY_STRING
                    : currencyFormatter(
                          item.amount,
                          customer.currency ?? 'USD',
                      ),
        },
        {
            id: 'investmentRequestID',
            header: 'Investment request ID',
            cell: (item: Revenue) => formatString(item?.investment_request_id),
        },
    ];
};

const invoiceColumnDefinitions = (customer: CommercialPrivateCustomer) => {
    return [
        {
            id: 'lineItemType',
            header: 'Invoiced revenue',
            cell: (item: LineItem) => formatString(item.item_type),
            width: window.innerWidth / 2,
        },
        {
            id: 'amount',
            header: 'Amount',
            cell: (item: LineItem) =>
                item.item_amount === undefined
                    ? EMPTY_STRING
                    : currencyFormatter(
                          item.item_amount,
                          customer.currency ?? 'USD',
                      ),
        },
    ];
};

const getApnCustomerView = (
    customer: APNPartnerPrivateCustomer,
    index: number,
    onEdit: () => void,
    onRemove: () => void,
) => {
    const apnCustomerSectionProps: SectionProps = {
        testId: `ActivityDetailsApnCustomer${index}`,
        header: {
            label: customer.customer_name,
            ...getCustomerEditButton(
                index,
                onEdit,
                onRemove,
            ),
            component: getCustomerHeaderComponent(customer),
        },
        content: {
            type: SectionContentType.Column,
            columnsCount: 4 as ColumnLayout.Columns,
            columns: [
                [
                    {
                        key: 'Internal contact person',
                        value: formatString(customer.internal_poc),
                    },
                    {
                        key: 'SFDC opportunity ID',
                        value: customer.sfdc_opportunity_id ? (
                            <Link
                                href={`https://aws-crm.my.salesforce.com/${customer.sfdc_opportunity_id}`}
                                target="_blank"
                                data-testid="sfdc-link"
                            >
                                {formatString(customer.sfdc_opportunity_id)}
                            </Link>
                        ) : (
                            EMPTY_STRING
                        ),
                    },
                ],
                [
                    {
                        key: 'Delivery contact name',
                        value: formatString(customer.delivery_contact_name),
                    },
                    {
                        key: 'Delivery contact phone',
                        value: formatString(
                            customer.delivery_contact_phone_number,
                        ),
                    },
                ],
                [
                    {
                        key: 'Delivery contact email',
                        value: formatString(customer.delivery_contact_email),
                    },
                    {
                        key: 'Customer communication language',
                        value: formatString(customer.customer_communication_language),
                    },
                    {
                        key: 'Customer notes',
                        value: formatString(customer.customer_notes),
                    },
                ],
                [
                    {
                        key: 'Number of students committed',
                        value: formatNumber(
                            customer.number_of_students_committed,
                        ),
                    },
                    {
                        key: 'Number of students attended',
                        value: formatNumber(
                            customer.number_of_students_attended,
                        ),
                    },
                ],
            ],
        } as ColumnContentData,
    };

    return (
        <React.Fragment key={index}>
            <Section {...apnCustomerSectionProps} />
        </React.Fragment>
    );
};

const getCustomerHeaderComponent = (customer: any) => {
    return isCustomerSFDCSynced(customer) ? (
            <h2>
                {customer.sfdc_opportunity_id
                    ? `${customer.customer_name} (${customer.sfdc_opportunity_id})`
                    : customer.customer_name}
                <Popover
                    dismissButton={true}
                    position="top"
                    size="large"
                    triggerType="custom"
                    content={`This customer is syncing data with SFDC.`}
                >
                    <span
                        className={`awsui-util-status-positive nowrap synced-customer`}
                    >
                        <Icon name={'status-positive'} />
                        &nbsp;Synced
                    </span>
                </Popover>
            </h2>
        ) : null;
}

const getCommercialPrivateCustomerView = (
    activity: ActivityData,
    customer: CommercialPrivateCustomer,
    index: number,
    onEdit: () => void,
    onRemove: () => void,
) => {
    const commercialPrivateCustomerSectionProps: SectionProps = {
        testId: `ActivityDetailsCommercialPrivateCustomer${index}`,
        className: 'awsui-util-mb-n',
        header: {
            label: customer.sfdc_opportunity_id
                ? `${customer.customer_name} (${customer.sfdc_opportunity_id})`
                : customer.customer_name,
            ...getCustomerEditButton(
                index,
                onEdit,
                onRemove,
            ),
            component: getCustomerHeaderComponent(customer),
        },
        content: {
            type: SectionContentType.Column,
            columnsCount: 4 as ColumnLayout.Columns,
            columns: [
                [
                    {
                        key: 'SFDC opportunity ID',
                        value: customer.sfdc_opportunity_id ? (
                            <Link
                                href={`https://aws-crm.my.salesforce.com/${customer.sfdc_opportunity_id}`}
                                target="_blank"
                                data-testid="sfdc-link"
                            >
                                {formatString(customer.sfdc_opportunity_id)}
                            </Link>
                        ) : (
                            EMPTY_STRING
                        ),
                    },
                    {
                        key: 'Customer AWS Account ID',
                        value: formatString(customer.customer_aws_account_id),
                    },
                    {
                        key: 'Currency',
                        value: formatString(customer.currency),
                    },
                ],
                [
                    {
                        key: 'Delivery contact name',
                        value: formatString(customer.delivery_contact_name),
                    },
                    {
                        key: 'Delivery contact e-mail',
                        value: formatString(customer.delivery_contact_email),
                    },
                    {
                        key: 'Number of students committed',
                        value: formatNumber(
                            customer.number_of_students_committed,
                        ),
                    },
                ],
                [
                    {
                        key: 'TOF status',
                        value: customer.tof_status
                            ? getTOFStatusComponent(customer.tof_status)
                            : '-',
                    },
                    {
                        key: 'Delivery contact phone',
                        value: formatString(
                            customer.delivery_contact_phone_number,
                        ),
                    },
                    {
                        key: 'Number of students attended',
                        value: formatNumber(
                            customer.number_of_students_attended,
                        ),
                    },
                ],
                [
                    {
                        key: 'TOF expiration date',
                        value: customer.tof_expiration_date
                            ? formatGrimsbyDate(customer.tof_expiration_date)
                            : '-',
                    },
                    {
                        key: 'Customer communication language',
                        value: formatString(customer.customer_communication_language),
                    },
                    {
                        key: 'Customer notes',
                        value: formatString(customer.customer_notes),
                    },
                ],
            ],
        } as ColumnContentData,
    };

    const nonBilledItems =
        customer.revenues?.filter(
            (revItem) => !['Billed', 'TnE'].includes(revItem.type),
        ) ?? [];
    const billedItems: Array<LineItem> =
        customer.revenues
            ?.filter((revItem) => ['Billed', 'TnE'].includes(revItem.type))
            .map((revItem) => {
                return {
                    item_amount: revItem.amount,
                    item_type:
                        revItem.type === 'TnE'
                            ? 'Travel & expense'
                            : activity?.activity_audience === 'Public'
                            ? 'Public ILT class'
                            : 'Private ILT class',
                };
            }) ?? [];

    return (
        <div className="awsui-util-mb-l" key={index}>
            <Section {...commercialPrivateCustomerSectionProps} />
            <Table
                data-testid={`CommercialPrivateCustomerRevenueItemsTable${index}`}
                columnDefinitions={revenueColumnDefinitions(customer)}
                items={[
                    ...nonBilledItems,
                    nonBilledItems.reduce(
                        (p, n) => {
                            return {
                                type: 'Total',
                                amount: p.amount + n.amount,
                            };
                        },
                        {
                            type: 'Total',
                            amount: 0,
                        },
                    ),
                ]}
                header={
                    <TableHeader
                        title="Revenue Items"
                        actions={<React.Fragment />}
                        count={
                            customer.revenues?.length
                                ? customer.revenues?.length
                                : ''
                        }
                    />
                }
                empty={emptyRevenueTableComponent}
            />
            <Table
                data-testid={`CommercialPrivateCustomerInvoiceItemsTable${index}`}
                columnDefinitions={invoiceColumnDefinitions(customer)}
                items={[
                    ...billedItems,
                    billedItems.reduce(
                        (p, n) => {
                            return {
                                item_type: 'Total',
                                item_amount: p.item_amount + n.item_amount,
                            };
                        },
                        {
                            item_type: 'Total',
                            item_amount: 0,
                        },
                    ),
                ]}
                empty={emptyLineItemTableComponent}
            />
        </div>
    );
};

const getPublicCustomerView = (
    activity: ActivityData,
    customer: CommercialPrivateCustomer,
    index: number,
    onEdit: () => void,
    onRemove: () => void,
) => {
    const publicCustomerSectionProps: SectionProps = {
        testId: `ActivityDetailsPublicCustomer${index}`,
        className: 'awsui-util-mb-n',
        header: {
            label: customer.customer_name,
            ...getCustomerEditButton(
                index,
                onEdit,
                onRemove,
            ),
            component: getCustomerHeaderComponent(customer),
        },
        content: {
            type: SectionContentType.Column,
            columnsCount: 4 as ColumnLayout.Columns,
            columns: [
                [
                    {
                        key: 'SFDC opportunity ID',
                        value: customer.sfdc_opportunity_id ? (
                            <Link
                                href={`https://aws-crm.my.salesforce.com/${customer.sfdc_opportunity_id}`}
                                target="_blank"
                                data-testid="sfdc-link"
                            >
                                {formatString(customer.sfdc_opportunity_id)}
                            </Link>
                        ) : (
                            EMPTY_STRING
                        ),
                    },
                ],
                [
                    {
                        key: 'Number of students attended',
                        value: formatNumber(
                            customer.number_of_students_attended,
                        ),
                    },
                ],
                [
                    {
                        key: 'Currency',
                        value: formatString(customer.currency),
                    },
                ],
                [
                    {
                        key: 'Customer AWS Account ID',
                        value: formatString(customer.customer_aws_account_id),
                    },
                ],
            ],
        } as ColumnContentData,
    };

    const nonBilledItems =
        customer.revenues?.filter(
            (revItem) => !['Billed', 'TnE'].includes(revItem.type),
        ) ?? [];
    const billedItems: Array<LineItem> =
        customer.revenues
            ?.filter((revItem) => ['Billed', 'TnE'].includes(revItem.type))
            .map((revItem) => {
                return {
                    item_amount: revItem.amount,
                    item_type:
                        revItem.type === 'TnE'
                            ? 'Travel & expense'
                            : activity?.activity_audience === 'Public'
                            ? 'Public ILT class'
                            : 'Private ILT class',
                };
            }) ?? [];

    return (
        <div className="awsui-util-mb-l" key={index}>
            <Section {...publicCustomerSectionProps} />
            <Table
                data-testid={`PublicCustomerRevenueItemsTable${index}`}
                columnDefinitions={revenueColumnDefinitions(customer)}
                items={[
                    ...nonBilledItems,
                    nonBilledItems.reduce(
                        (p: any, n: any) => {
                            return {
                                type: 'Total',
                                amount: p.amount + n.amount,
                            };
                        },
                        {
                            type: 'Total',
                            amount: 0,
                        },
                    ),
                ]}
                header={
                    <TableHeader
                        title="Revenue Items"
                        actions={<React.Fragment />}
                        count={
                            customer.revenues?.length
                                ? customer.revenues?.length
                                : ''
                        }
                    />
                }
                empty={emptyRevenueTableComponent}
            />
            <Table
                data-testid={`CommercialPrivateCustomerInvoiceItemsTable${index}`}
                columnDefinitions={invoiceColumnDefinitions(customer)}
                items={[
                    ...billedItems,
                    billedItems.reduce(
                        (p, n) => {
                            return {
                                item_type: 'Total',
                                item_amount: p.item_amount + n.item_amount,
                            };
                        },
                        {
                            item_type: 'Total',
                            item_amount: 0,
                        },
                    ),
                ]}
                empty={emptyLineItemTableComponent}
            />
        </div>
    );
};

const CustomersTab = ({ activity }: { activity: ActivityData }) => {
    const [activeRemoveIndex, setActiveRemoveIndex] = React.useState<
        number | null
    >(null);
    const [removeCustomerConfirmText, setRemoveCustomerConfirmText] =
        React.useState<string>('');
    const [removeCustomerError, setRemoveCustomerError] =
        React.useState<boolean>(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const { addNotification } = useNotifications();

    const handleRemoveCustomer = async () => {
        // remove from array and save
        if (activeRemoveIndex === null || !activity?.pk) {
            setActiveRemoveIndex(null);
            return;
        }

        let updatedCustomers = [...activity.customers];

        updatedCustomers.splice(activeRemoveIndex, 1);

        const isSuccessful = await dispatch<any>(
            updateSelectedActivity(activity.pk, {
                ...activity,
                customers: updatedCustomers,
            }),
        );

        addNotification({
            id: `customer-tab-${Date.now()}`,
            ...(isSuccessful
                ? {
                      type: 'success',
                      content: 'The customer has been removed.',
                  }
                : {
                      type: 'error',
                      content: 'An error occurred while removing the customer.',
                  }),
        });

        if (isSuccessful) {
            history.push({
                pathname: `/activities/${activity.pk}`,
                search: 'tabId=customers',
            });
        }
    };

    const emptyCustomersSection = () => {
        const sectionProps: SectionProps = {
            testId: 'ActivityDetailsEmptyCustomersSection',
            header: {
                label: 'Customers',
            },
            content: {
                type: SectionContentType.Generic,
                children: (
                    <div className="awsui-util-t-c awsui-util-status-inactive">
                        <div className="awsui-util-pt-s awsui-util-mb-xs">
                            <b>No customers</b>
                        </div>
                        <p className="awsui-util-mb-s">
                            Customers will display here.
                        </p>
                        {addCustomerButtonComponent()}
                    </div>
                ),
            } as GenericContentData,
        };
        return <Section {...sectionProps} />;
    };

    const addCustomerButtonComponent = () => {
        if (isPartnerPrivateCustomer(activity?.activity_audience)) {
            return (
                <Can
                    perform={Actions.ACTIVITY_MODIFY}
                    yes={() => (
                        <Button
                            data-testid="ActivityDetailsAddAPNCustomer"
                            onClick={() =>
                                history.push({
                                    pathname: `/activities/${activity?.pk}/create-apn-customer`,
                                })
                            }
                        >
                            Add customer
                        </Button>
                    )}
                    no={() => null}
                />
            );
        } else {
            if (
                activity?.activity_audience ===
                    AudienceType.CommercialPrivate &&
                (activity?.customers?.length ?? 0) >= 1
            ) {
                return <></>;
            }

            return (
                <Can
                    perform={Actions.ACTIVITY_MODIFY}
                    yes={() => (
                        <Button
                            data-testid="ActivityDetailsAddCustomer"
                            onClick={() =>
                                history.push({
                                    pathname: `/activities/${activity?.pk}/create-customer`,
                                })
                            }
                        >
                            Add customer
                        </Button>
                    )}
                    no={() => null}
                />
            );
        }
    };

    const onRemoveCustomer = () => {
        if (removeCustomerConfirmText.toLowerCase() === `remove customer`) {
            setRemoveCustomerError(false);
            handleRemoveCustomer();
        } else {
            setRemoveCustomerError(true);
        }
    };

    return (
        <div data-testid="ActivityDetailsCustomersTab">
            {(() => {
                if (!activity.customers?.length) {
                    return emptyCustomersSection();
                }

                if (isCommercialPrivateCustomer(activity.activity_audience)) {
                    return (
                        <>
                            {(
                                activity.customers as Array<CommercialPrivateCustomer>
                            ).map((customer, index) =>
                                getCommercialPrivateCustomerView(
                                    activity,
                                    customer,
                                    index,
                                    () =>
                                        history.push({
                                            pathname: `/activities/${activity?.pk}/edit-customer/${index}`,
                                        }),
                                    () => setActiveRemoveIndex(index),
                                ),
                            )}
                            <div className="awsui-util-t-c awsui-util-status-inactive">
                                {addCustomerButtonComponent()}
                            </div>
                        </>
                    );
                } else if (
                    isPartnerPrivateCustomer(activity.activity_audience)
                ) {
                    return (
                        <>
                            {(
                                activity.customers as Array<APNPartnerPrivateCustomer>
                            ).map((customer, index) =>
                                getApnCustomerView(
                                    customer,
                                    index,
                                    () =>
                                        history.push({
                                            pathname: `/activities/${activity?.pk}/edit-apn-customer/${index}`,
                                        }),
                                    () => setActiveRemoveIndex(index),
                                ),
                            )}
                            <div className="awsui-util-t-c awsui-util-status-inactive">
                                {addCustomerButtonComponent()}
                            </div>
                        </>
                    );
                } else if (isPublicCustomer(activity.activity_audience)) {
                    return (
                        <>
                            {(
                                activity.customers as Array<CommercialPrivateCustomer>
                            ).map((customer, index) =>
                                getPublicCustomerView(
                                    activity,
                                    customer,
                                    index,
                                    () =>
                                        history.push({
                                            pathname: `/activities/${activity?.pk}/edit-customer/${index}`,
                                        }),
                                    () => setActiveRemoveIndex(index),
                                ),
                            )}
                            <div className="awsui-util-t-c awsui-util-status-inactive">
                                {addCustomerButtonComponent()}
                            </div>
                        </>
                    );
                }
                return null;
            })()}
            <Can
                perform={Actions.ACTIVITY_MODIFY}
                yes={() => {

                    if (activeRemoveIndex &&
                        isCustomerSFDCSynced(activity.customers[activeRemoveIndex])) {
                        return (
                            <Modal
                                size={'medium'}
                                visible={activeRemoveIndex !== null}
                                onDismiss={() => {
                                    setActiveRemoveIndex(null);
                                }}
                                header={'Remove this customer?'}
                                footer={
                                    <Box float="right">
                                        <SpaceBetween
                                            direction="horizontal"
                                            size="xs"
                                        >
                                            <Button
                                                variant="link"
                                                onClick={() => {
                                                    setActiveRemoveIndex(null);
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="primary"
                                                onClick={onRemoveCustomer}
                                            >
                                                Remove customer
                                            </Button>
                                        </SpaceBetween>
                                    </Box>
                                }
                            >
                                <p>
                                    Are you sure you want to remove{' '}
                                    {
                                        activity.customers[activeRemoveIndex]
                                            .customer_name
                                    }{' '}
                                    from this activity? If you remove this
                                    customer, the product will be cancelled on
                                    SFDC. This cannot be undone.
                                </p>
                                <div className="grimsby-sub-section-divider" />
                                <FormField
                                    label={`To continue, enter "REMOVE CUSTOMER" and press the remove customer button.`}
                                    errorText={
                                        removeCustomerError
                                            ? `This field is required to remove customer`
                                            : null
                                    }
                                >
                                    <Input
                                        value={removeCustomerConfirmText}
                                        placeholder="Enter confirmation message"
                                        onChange={(e) => {
                                            setRemoveCustomerConfirmText(
                                                e.detail.value,
                                            );
                                        }}
                                    />
                                </FormField>
                            </Modal>
                        );
                    }

                    return (
                        <>
                            <Modal
                                onDismiss={() => setActiveRemoveIndex(null)}
                                visible={activeRemoveIndex !== null}
                                closeAriaLabel="Close modal"
                                size="medium"
                                footer={
                                    <Box float="right">
                                        <SpaceBetween
                                            direction="horizontal"
                                            size="xs"
                                        >
                                            <Button
                                                data-testid="ActivityDetailsCanelDelete"
                                                onClick={() =>
                                                    setActiveRemoveIndex(null)
                                                }
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="primary"
                                                data-testid={`ActivityDetailsDeleteCustomer`}
                                                onClick={() =>
                                                    handleRemoveCustomer()
                                                }
                                            >
                                                Delete customer
                                            </Button>
                                        </SpaceBetween>
                                    </Box>
                                }
                                header="Delete this customer?"
                            >
                                {activeRemoveIndex !== null && (
                                    <Alert type="warning">
                                        Are you sure you want to delete{' '}
                                        {
                                            activity.customers[
                                                activeRemoveIndex
                                            ].customer_name
                                        }{' '}
                                        customer from this activity?
                                    </Alert>
                                )}
                            </Modal>
                        </>
                    );
                }}
                no={() => null}
            />
        </div>
    );
};

export default CustomersTab;
