/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    AttributeEditor,
    Checkbox,
    ColumnLayout,
    FormField,
    Input,
    Select,
} from '@amzn/awsui-components-react';
import { PropertyFilterProps } from '@amzn/awsui-components-react-v3';

import {
    getOptionsAndLookupForSelectInput,
    getStatusType,
} from '../../../imt/components/Instructor/FormSections/FormSections.common';
import {
    resetLocationsSlice,
    setRegion as setLocationListRegion,
} from '../../../common/store/slices/locationsSlice';
import { resetCoursesSlice } from '../../../common/store/slices/coursesSlice';
import { resetDeliveryCountriesSlice } from '../../../common/store/slices/deliveryCountriesSlice';
import {
    getRecommendedInstructors,
    selectIsLoading as selectAreRecommendedInstructorsLoading,
    selectRecommendedInstructorLookup,
    selectRecommendedInstructorsActivitiesAsEvents,
    selectRecommendedInstructorsActivityLookup,
    selectRecommendedInstructorsAsResources,
    selectBlockedTimeLookup,
    selectBlockedTimesAsEvents,
    selectPagesCount,
    selectCurrentPageIndex,
    setCurrentPageIndex,
    setFrom,
    selectSize,
    setSize,
} from '../../store/slices/recommendedInstructorsSlice';
import { resetInstructorRoleTypesSlice } from '../../../common/store/slices/instructorRoleTypesSlice';
import {
    ActivitySFDCDeliveryDetailsFormSectionProps,
    getActivityStartDateFromSessions,
} from '../Activity/Common/Common';
import { resetProgramTypesSlice } from '../../../common/store/slices/programTypesSlice';
import { resetRegionsSlice } from '../../../common/store/slices/regionsSlice';
import { resetGeographiesSlice } from '../../../common/store/slices/geographiesSlice';
import { resetInstructorCourseStatusesSlice } from '../../../common/store/slices/instructorCourseStatusesSlice';
import { resetInstructorTypesSlice } from '../../../common/store/slices/instructorTypesSlice';
import handlePreSelectedValue from '../../../common/utils/handlePreSelectedValue';
import { getCityString } from '../../../imt/components/Instructor/FormSections/BasicInfoFormSection';
import { DeliveryInstructor } from '../../interfaces/activity';
import AssignInstructorModal from '../Activity/Edit/AssignInstructorModal/AssignInstructorModal';
import { getDeliverySession } from '../Activity/Create/ActivityCreateForm';
import {
    AssignInstructorModalPropsData,
    InstructorPaginationProps,
} from '../../interfaces/resourceCalendarProps';
import { RecommendedInstructorProfileData } from '../../interfaces/recommendedInstructorProfile';
import { InstructorRoleTypeItemData } from '../../../common/interfaces/businessDataItem/instructorRoleTypeItem';
import { formatStringArray } from '../../../common/utils/formatStringArray';
import { getInstructorAvailabilityStatusPerInstructor } from '../../services/activity-service';
import { InstructorPropertyFilterKeys } from '../../enums/propertyFilterKeys';
import {
    filteringOptions,
    getFiltersFromQuery,
    instructorFilteringProperties,
} from '../../common/resourceFilters/instructors';
import { useBusinessDatum } from '../Common/hooks/useBusinessDatum';
import { buildCalendarEventsFromDeliverySessions } from '../../services/schedule-service';
import { DEFAULT_ACTIVITY_SOURCE_FIELDS } from '../../constants';

const OpportunityInstructorsFormSection = ({
    formValues,
    mode,
    handleFieldEvent,
    sessionAttributeEditorItems,
    instructorAttributeEditorItems,
    handleInstructorItemEvent,
    handleAddInstructor,
    instructorValidationResponse,
    instructorItemErrors,
    controlArrayErrors,
}: ActivitySFDCDeliveryDetailsFormSectionProps) => {
    const recommendedInstructorsActivitiesAsEvents = useSelector(
        selectRecommendedInstructorsActivitiesAsEvents,
    );
    const areRecommendedInstructorsLoading = useSelector(
        selectAreRecommendedInstructorsLoading,
    );
    const recommendedInstructorsAsResources = useSelector(
        selectRecommendedInstructorsAsResources,
    );
    const recommendedInstructorsActivityLookup = useSelector(
        selectRecommendedInstructorsActivityLookup,
    );
    const recommendedInstructorLookup = useSelector(
        selectRecommendedInstructorLookup,
    );
    const instructorReservedTimes = useSelector(selectBlockedTimesAsEvents);
    const instructorReservedTimesLookup = useSelector(selectBlockedTimeLookup);
    const pagesCount: number = useSelector(selectPagesCount);
    const currentPageIndex: number = useSelector(selectCurrentPageIndex);
    const size = useSelector(selectSize);

    const {
        userProfile,
        courseList,
        isLocationListLoaded,
        originalLocationList,
        deliveryCountryList,
        preselectedCityId,
        deliveryLanguageList,
        lifeCycleMethods,
        instructorTypeList,
        programTypeList,
        geographyList,
        regionList,
        instructorCourseStatusList,
        instructorRoleTypeList,
        isInstructorRoleTypeListLoading,
        isInstructorRoleTypeListLoaded,
        instructorRoleTypeListError,
        timezonesList,
    } = useBusinessDatum();

    const dispatch = useDispatch();
    const shouldCheckForValue = !!(
        formValues.delivery_city &&
        formValues.delivery_country &&
        formValues.delivery_geo &&
        formValues.delivery_region
    );
    const locationList = handlePreSelectedValue(
        {
            city: formValues.delivery_city as string,
            state: formValues.delivery_state as string,
            country: formValues.delivery_country,
            geo: formValues.delivery_geo,
            region: formValues.delivery_region,
            active: true,
            pk: `location-${Date.now()}`,
            city_timezone: '',
        },
        shouldCheckForValue && isLocationListLoaded,
        originalLocationList,
        (location) => getCityString(location) === preselectedCityId,
    );

    const [isAssignInstructorModalVisible, setIsAssignInstructorModalVisible] =
        useState(false);

    const handleIsAssignInstructorModalVisibleChange = (isVisible: boolean) => {
        if (!isVisible) {
            setQuery(defaultQuery);
        }
        setIsAssignInstructorModalVisible(isVisible);
    };

    useEffect(() => {
        dispatch(setLocationListRegion(userProfile?.profile.regions || []));

        return () => {
            // reset business data slices
            // this code block should only run once
            dispatch(resetLocationsSlice());
            dispatch(resetDeliveryCountriesSlice());
            dispatch(resetProgramTypesSlice());
            dispatch(resetRegionsSlice());
            dispatch(resetGeographiesSlice());
            dispatch(resetInstructorTypesSlice());
            dispatch(resetInstructorCourseStatusesSlice());
            dispatch(resetCoursesSlice());
            dispatch(resetInstructorRoleTypesSlice());
        };
    }, [dispatch, userProfile]);

    // lifecycle method to fetch (and re-fetch) business data
    useEffect(() => {
        (lifeCycleMethods as ReadonlyArray<[boolean, Function]>).forEach(
            ([shouldFetch, getList]) => {
                if (shouldFetch) {
                    dispatch(getList());
                }
            },
        );
    });

    const defaultQuery: PropertyFilterProps.Query = React.useMemo(() => {
        return {
            tokens: [
                {
                    propertyKey: InstructorPropertyFilterKeys.CourseName,
                    operator: '=',
                    value: formValues.course_name,
                },
                {
                    propertyKey: InstructorPropertyFilterKeys.DeliveryCountry,
                    operator: '=',
                    value: formValues.delivery_country,
                },
                {
                    propertyKey: InstructorPropertyFilterKeys.DeliveryLanguage,
                    operator: '=',
                    value: formValues.delivery_language,
                },
                {
                    propertyKey: InstructorPropertyFilterKeys.DeliveryTimezone,
                    operator: '=',
                    value: formValues.delivery_timezone,
                },
                {
                    propertyKey: InstructorPropertyFilterKeys.CourseStatus,
                    operator: '=',
                    value: 'Approved',
                },
            ],
            operation: 'and', // Polaris wont allow set to null for some reason
        };
    }, [formValues]);

    const [query, setQuery] =
        React.useState<PropertyFilterProps.Query>(defaultQuery);

    useEffect(() => {
        if (isAssignInstructorModalVisible) {
            dispatch(
                getRecommendedInstructors({
                    id: formValues.pk,
                    ...getFiltersFromQuery(query),
                    delivery_sessions: sessionAttributeEditorItems.map((item) =>
                        getDeliverySession(item, formValues.delivery_timezone),
                    ),
                }),
            );
        }
    }, [
        dispatch,
        formValues.pk,
        sessionAttributeEditorItems,
        formValues.delivery_timezone,
        isAssignInstructorModalVisible,
        query,
    ]);

    const assignedInstructors = new Set(
        instructorAttributeEditorItems?.map(
            (instructor) => instructor.pk as string,
        ) || [],
    );

    const handleQueryChange = (query: PropertyFilterProps.Query) => {
        dispatch(setCurrentPageIndex(1));
        dispatch(setFrom(0));
        setQuery(query);
    };

    const {
        valueLookup: instructorRoleTypeLookup,
        valueOptions: instructorRoleTypeOptions,
    } = getOptionsAndLookupForSelectInput<InstructorRoleTypeItemData>(
        instructorRoleTypeList,
        (instructorRoleType: InstructorRoleTypeItemData) => ({
            label: instructorRoleType.instructor_role_type,
            id: instructorRoleType.pk as string,
        }),
    );

    const instructorFilteringOptions = filteringOptions({
        availability: [],
        courseList,
        deliveryLanguageList,
        deliveryCountryList,
        freelancer: [],
        locationList,
        timezonesList,
        instructorTypeList,
        programTypeList,
        geographyList,
        regionList,
        instructorCourseStatusList,
        willing_to_travel: [],
    });

    const handleClickAssignInstructor = () => {
        setIsAssignInstructorModalVisible(true);
        setQuery(defaultQuery);
    };

    const handleAssignInstructorEvent = (
        instructors: Array<RecommendedInstructorProfileData>,
    ) => {
        for (let instructor of instructors) {
            if (instructor.pk) {
                handleAddInstructor(instructor.pk);
            }
        }
        handleInstructorItemEvent([
            ...instructorAttributeEditorItems,
            ...(instructors.map((instructor) => ({
                name: `${instructor.full_name}`,
                pk: instructor.pk,
                email: instructor.email,
                role: null,
                type: instructor.instructor_type,
                location: formatStringArray([
                    instructor.city,
                    instructor.state_province,
                    instructor.country,
                    instructor.instructor_region,
                    instructor.geo,
                ]),
                do_not_shuffle: false,
            })) as Array<DeliveryInstructor>),
        ]);
    };

    const handleTablePaginationChange = async (event: any) => {
        let updatedPageIndex = event;
        let from = (updatedPageIndex - 1) * size;

        dispatch(setCurrentPageIndex(updatedPageIndex));
        dispatch(setFrom(from));

        dispatch(
            getRecommendedInstructors({
                id: formValues.pk,
                ...getFiltersFromQuery(query),
                delivery_sessions: sessionAttributeEditorItems.map((item) =>
                    getDeliverySession(item, formValues.delivery_timezone),
                ),
                activity_source_fields: DEFAULT_ACTIVITY_SOURCE_FIELDS,
            }),
        );
    };

    const handlePreferencesChange = (details: any) => {
        dispatch(setSize(details.pageSize));
        dispatch(setCurrentPageIndex(1));
        dispatch(setFrom(0));

        dispatch(
            getRecommendedInstructors({
                id: formValues.pk,
                ...getFiltersFromQuery(query),
                delivery_sessions: sessionAttributeEditorItems.map((item) =>
                    getDeliverySession(item, formValues.delivery_timezone),
                ),
                activity_source_fields: DEFAULT_ACTIVITY_SOURCE_FIELDS,
            }),
        );
    };

    const getHighlightEvents = () => {
        const sessions = sessionAttributeEditorItems.map((item) =>
            getDeliverySession(item, formValues.delivery_timezone),
        );

        let events = buildCalendarEventsFromDeliverySessions(
            {
                sessions,
                timezone: formValues.delivery_timezone,
            },
            (sessionIndex: string) => {
                return {
                    id: `assign-highlighted-${sessionIndex}`,
                    display: 'background',
                };
            },
        );

        return events;
    };

    const paginationProps: InstructorPaginationProps = {
        onPaginationChange: handleTablePaginationChange,
        pagesCount,
        currentPageIndex: currentPageIndex,
        pageSize: size,
    };

    const assignInstructorModalProps: AssignInstructorModalPropsData = {
        isVisible: isAssignInstructorModalVisible,
        handleIsVisibleChange: handleIsAssignInstructorModalVisibleChange,
        events: (() => {
            return [
                ...getHighlightEvents(),
                ...recommendedInstructorsActivitiesAsEvents,
                ...instructorReservedTimes,
            ];
        })(),
        resources: recommendedInstructorsAsResources.filter((resource: any) => {
            return !assignedInstructors.has(resource.id);
        }),
        eventLookup: (() => {
            return {
                ...recommendedInstructorsActivityLookup,
                ...instructorReservedTimesLookup,
            };
        })(),
        resourceLookup: recommendedInstructorLookup,
        error: null,
        assignedInstructorIds: new Set(
            instructorAttributeEditorItems?.map(
                (instructor) => instructor.pk as string,
            ),
        ),
        handleAssignInstructorEvent,
        deliverySessions: sessionAttributeEditorItems.map((item) =>
            getDeliverySession(item, formValues.delivery_timezone),
        ),
        activityTimezone: formValues.delivery_timezone,
        filteringOptions: instructorFilteringOptions,
        instructorFilteringProperties,
        query,
        handleQueryChange,
        handleFieldEvent,
        areRecommendedInstructorsLoading,
        initialDate: getActivityStartDateFromSessions(
            sessionAttributeEditorItems.map((item) =>
                getDeliverySession(item, formValues.delivery_timezone),
            ),
            formValues.delivery_timezone,
        ),
        formValues,
        paginationProps,
        handlePreferencesChange,
    };

    const instructorAttributeEditorDefinition: Array<AttributeEditor.FieldDefinition> =
        [
            {
                label: 'Instructor',
                control: (instructorItem: DeliveryInstructor) => (
                    <Input value={instructorItem.name} readonly={true} />
                ),
                errorText: (
                    instructorItem: DeliveryInstructor,
                    index: number,
                ) => {
                    const hasValidationResponse =
                        instructorValidationResponse.length > 0;

                    if (
                        hasValidationResponse &&
                        !getInstructorAvailabilityStatusPerInstructor(
                            instructorItem,
                            instructorValidationResponse,
                            ['do_not_shuffle', 'reserved', 'booked'],
                        )
                    ) {
                        return 'The instructor is not available.';
                    }
                },
            },
            {
                label: 'Instructor role',
                control: (
                    instructorItem: DeliveryInstructor,
                    index: number,
                ) => (
                    <Select
                        errorText={
                            'An error occurred while loading instructor roles'
                        }
                        loadingText={'Loading instructor roles'}
                        options={instructorRoleTypeOptions}
                        placeholder={'Select a role'}
                        recoveryText={'Retry'}
                        statusType={getStatusType(
                            isInstructorRoleTypeListLoading,
                            isInstructorRoleTypeListLoaded,
                            instructorRoleTypeListError,
                        )}
                        selectedOption={
                            instructorRoleTypeLookup[
                                instructorItem.role as string
                            ]
                        }
                        onChange={(e) => {
                            const newItems =
                                instructorAttributeEditorItems.slice();
                            newItems.splice(index, 1, {
                                ...instructorItem,
                                role: e.detail.selectedOption.label,
                            });
                            handleInstructorItemEvent(newItems);
                        }}
                        data-testid={`${mode}ActivityInstructorRole${index}`}
                    />
                ),
                errorText: (
                    instructorItem: DeliveryInstructor,
                    index: number,
                ) => {
                    if (
                        instructorItemErrors &&
                        instructorItemErrors[instructorItem.pk as string]?.role
                    ) {
                        return instructorItemErrors[instructorItem.pk as string]
                            .role;
                    }

                    return controlArrayErrors.instructorAttributeEditorItems?.[
                        index
                    ]?.role;
                },
            },
            {
                label: ' ',
                control: (
                    instructorItem: DeliveryInstructor,
                    index: number,
                ) => (
                    <div>
                        <Checkbox
                            checked={!!instructorItem.do_not_shuffle}
                            data-testid={`${mode}ActivityInstructorDoNotShuffle${index}`}
                            onChange={(e) => {
                                const newItems =
                                    instructorAttributeEditorItems.slice();
                                newItems.splice(index, 1, {
                                    ...instructorItem,
                                    do_not_shuffle: e.detail.checked,
                                });
                                handleInstructorItemEvent(newItems);
                            }}
                        >
                            Do not shuffle
                        </Checkbox>
                    </div>
                ),
                errorText: (
                    instructorItem: DeliveryInstructor,
                    index: number,
                ) =>
                    controlArrayErrors.instructorAttributeEditorItems?.[index]
                        ?.do_not_shuffle,
            },
        ];

    return (
        <>
            <ColumnLayout>
                <div data-awsui-column-layout-root="true">
                    <div className="grimsby-sub-section-divider" />
                    <div className="grimsby-sub-section-header">
                        Instructors
                    </div>
                    <FormField>
                        <AttributeEditor
                            data-testid={`${mode}AssignInstructorAttributeEditor`}
                            addButtonText="Assign instructor"
                            removeButtonText="Remove"
                            items={instructorAttributeEditorItems}
                            definition={instructorAttributeEditorDefinition}
                            onAddButtonClick={() =>
                                handleClickAssignInstructor()
                            }
                            onRemoveButtonClick={({
                                detail: { itemIndex },
                            }) => {
                                const newList =
                                    instructorAttributeEditorItems.slice();
                                newList.splice(itemIndex, 1);
                                handleInstructorItemEvent(newList);
                            }}
                            empty={`No instructors have been assigned`}
                        />
                    </FormField>
                </div>
            </ColumnLayout>
            <AssignInstructorModal {...assignInstructorModalProps} />
        </>
    );
};

export default OpportunityInstructorsFormSection;
