import React from 'react';
import { ColumnLayout, Icon } from '@amzn/awsui-components-react';
import { useSelector } from 'react-redux';

import formatDate, { decodeDateNumber } from '../../utils/formatDate';
import getBadgePhoto from '../../utils/getBadgePhoto';
import getPhoneToolUrl from '../../utils/getPhoneToolUrl';
import { UserProfileData } from '../../interfaces/userProfile';
import { checkFeature } from '../../utils/featureFlag';
import { selectFeatures } from '../../store/slices/featureSlice';

const statusActive = {
    statusClass: 'awsui-util-status-positive',
    iconClass: 'status-positive' as Icon.Name,
    statusLabel: ' Active',
};

const statusInactive = {
    statusClass: 'awsui-util-status-negative',
    iconClass: 'status-negative' as Icon.Name,
    statusLabel: ' Inactive',
};

/**
 * Renders the user info widget
 */
const UserInfo = ({
    profile,
    actions,
}: {
    profile: UserProfileData;
    actions?: JSX.Element;
}) => {
    const featureFlags = useSelector(selectFeatures);
    const {
        active,
        alias,
        created_timestamp,
        email,
        full_name,
        job_title,
        last_signin,
        manager,
        user_roles,
        programs,
        regions,
        vias_roles,
    } = profile;
    const status = active ? statusActive : statusInactive;
    return (
        <section data-testid="UserInfo">
            <div className="awsui-util-action-stripe-large awsui-util-mb-m">
                <div className="awsui-util-action-stripe-title">
                    <h1>{full_name || 'Grimsby User'}</h1>
                </div>
                {actions && (
                    <div
                        data-testid="UserInfoActions"
                        className="awsui-util-action-stripe-group"
                    >
                        {actions}
                    </div>
                )}
            </div>
            <div className="awsui-util-container user-profile">
                <div className="awsui-util-container-header">
                    <h2>User Details</h2>
                </div>
                <ColumnLayout columns={4} variant="text-grid">
                    <div data-awsui-column-layout-root="true">
                        <div>
                            <div className="awsui-util-spacing-v-s awsui-util-t-c">
                                <div>
                                    <img
                                        data-testid="UserInfoUserPhoto"
                                        className="profile-image"
                                        src={
                                            alias
                                                ? getBadgePhoto(alias)
                                                : '/defaultUser.png'
                                        }
                                        alt="phonetool-badge"
                                    />
                                </div>
                                {alias && (
                                    <div>
                                        <a
                                            data-testid="UserInfoPhoneToolLink"
                                            href={getPhoneToolUrl(alias)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            View user in Phonetool{' '}
                                            <Icon name="external" />
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div>
                            <div className="awsui-util-spacing-v-s">
                                <div>
                                    <div className="awsui-util-label">
                                        Email address
                                    </div>
                                    <div>{email}</div>
                                </div>
                                <div>
                                    <div className="awsui-util-label">
                                        Status
                                    </div>
                                    <div className={status.statusClass}>
                                        <Icon name={status.iconClass} />
                                        {status.statusLabel}
                                    </div>
                                </div>
                                <div>
                                    <div className="awsui-util-label">
                                        LDAP groups
                                    </div>
                                    <div>
                                        {
                                            <ul>
                                                {user_roles?.map(
                                                    (group, index) => (
                                                        <li key={index}>
                                                            {group}
                                                        </li>
                                                    ),
                                                )}
                                            </ul>
                                        }
                                    </div>
                                </div>
                                {checkFeature(
                                    '',
                                    { featureName: 'gandalf-roles' },
                                    featureFlags?.features,
                                ) && (
                                    <div>
                                        <div
                                            data-testid="gandalf-roles"
                                            className="awsui-util-label"
                                        >
                                            User roles
                                        </div>
                                        <div>
                                            {
                                                <ul>
                                                    {vias_roles?.map(
                                                        (role, index) => (
                                                            <li key={index}>
                                                                {role}
                                                            </li>
                                                        ),
                                                    )}
                                                </ul>
                                            }
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div>
                            <div className="awsui-util-spacing-v-s">
                                <div>
                                    <div className="awsui-util-label">
                                        Job title
                                    </div>
                                    <div>{job_title}</div>
                                </div>
                                <div>
                                    <div className="awsui-util-label">
                                        Last login
                                    </div>
                                    <div>
                                        {formatDate(
                                            decodeDateNumber(last_signin),
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <div className="awsui-util-label">
                                        Programs
                                    </div>
                                    <div>{programs?.join(', ')}</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="awsui-util-spacing-v-s">
                                <div>
                                    <div className="awsui-util-label">
                                        Manager
                                    </div>
                                    <div>{manager}</div>
                                </div>
                                <div>
                                    <div className="awsui-util-label">
                                        Account created
                                    </div>
                                    <div>
                                        {formatDate(
                                            decodeDateNumber(created_timestamp),
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <div className="awsui-util-label">
                                        Supported regions
                                    </div>
                                    <div>{regions?.join(', ')}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ColumnLayout>
            </div>
        </section>
    );
};

export default UserInfo;
