import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getInstructorList,
    selectEndTimeStamp,
    selectStartTimeStamp,
    setIsLoaded,
    selectInstructorActivitiesAsEvents,
    selectInstructorList,
    selectInstructorActivityLookup,
    selectInstructorLookup,
    selectBlockedTimesAsEvents,
    selectBlockedTimeLookup,
    deleteCalendarInstructorReservedTime,
    updateCalendarBlockedTime,
    addBlockedTimesTimeAsEvent,
    selectCurrentPageIndex,
    selectPagesCount,
    setCurrentPageIndex,
    setFrom,
    selectSize,
} from '../../store/slices/instructorListSlice';
import {
    Container,
    PropertyFilterProps,
    Button,
    ButtonDropdown,
} from '@amzn/awsui-components-react-v3';
import CalendarHeader from '../Common/ResourceCalendar/Header';
import {
    CalendarViewPropsData,
    InstructorCalendarHeaderPropsData,
    InstructorPaginationProps,
} from '../../interfaces/resourceCalendarProps';
import { resetCoursesSlice } from '../../../common/store/slices/coursesSlice';

import {
    filteringOptions,
    getFiltersFromQuery,
    getInstructorFilteringProperties,
} from '../../common/resourceFilters/instructors';
import handlePreSelectedValue from '../../../common/utils/handlePreSelectedValue';
import {
    resetLocationsSlice,
    setRegion as setLocationListRegion,
} from '../../../common/store/slices/locationsSlice';
import { getCityString } from '../../../imt/components/Instructor/FormSections/BasicInfoFormSection';
import { resetDeliveryCountriesSlice } from '../../../common/store/slices/deliveryCountriesSlice';
import CalendarResourceView from '../Common/ResourceCalendar/CalendarView';
import { GRIMSBY_PAGE_COUNT } from '../../../common/constants/grimsby';
import { resetInstructorRoleTypesSlice } from '../../../common/store/slices/instructorRoleTypesSlice';
import { useBusinessDatum } from '../Common/hooks/useBusinessDatum';
import { resetProgramTypesSlice } from '../../../common/store/slices/programTypesSlice';
import { resetRegionsSlice } from '../../../common/store/slices/regionsSlice';
import { resetGeographiesSlice } from '../../../common/store/slices/geographiesSlice';
import { resetInstructorTypesSlice } from '../../../common/store/slices/instructorTypesSlice';
import { resetInstructorCourseStatusesSlice } from '../../../common/store/slices/instructorCourseStatusesSlice';
import { RecommendedInstructorProfileData } from '../../interfaces/recommendedInstructorProfile';
import { useNotifications } from '../../../common/context/grimsbyNotifications';

import {
    updatePreferencesAndSavedFilters,
    selectIsLoading as selectIsUserLoading,
    selectUserId,
    selectSMTInstructorListSavedFilterLookup,
    selectSMTInstructorListSavedFilters,
} from '../../../common/store/slices/userSlice';

import {
    addBlockedTimesForInstructor,
    deleteBlockedTimeForInstructor,
    normalizeInstructorsWithBlockedTimeTimeData,
    updateBlockedTimeForInstructor,
    formatFreelancerBlockedTimeAttributes,
} from '../../services/schedule-service';
import BlockedTimeInfoModal, {
    BlockedTimeInfoModalProps,
} from './BlockedTimeModal/BlockedTimeInfoModal';
import BlockedTimeModal, {
    BlockedTimeModalPropsData,
} from './BlockedTimeModal/BlockedTimeModal';
import {
    BlockedTime,
    BlockedTimeFormData,
    BlockedTimeSuccessResponseData,
} from '../../../common/interfaces/blockedTime';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { InstructorPropertyFilterKeys } from '../../enums/propertyFilterKeys';
import { SmtInstructorFilters } from '../../interfaces/smtInstructorFilters';
import handleLocalStorageSetItem from '../../../common/utils/handleLocalStorageSetItem';

import './Instructors.scss';
import SaveFilterModal, {
    SaveFilterModalProps,
} from './SaveFilterModal/SaveFilterModal';
import ManageSavedFiltersModal, {
    ManageSavedFiltersModalProps,
    SavedFilterForm,
} from './ManageSavedFiltersModal/ManageSavedFiltersModal';
import { mapBlockedTimes } from '../../services/calendar-service';
import { DEFAULT_ACTIVITY_SOURCE_FIELDS } from '../../constants';
import { getTimezonesList } from '../../../common/store/slices/timezonesSlice';
import { useHistory } from 'react-router-dom';
import { ACTIVITY_PATH } from '../../constants/path';
import { checkFeature } from '../../../common/utils/featureFlag';
import { selectFeatures } from '../../../common/store/slices/featureSlice';
import Can from '../../../common/components/Can';
import { Actions } from '../../../common/constants/auth';

dayjs.extend(utc);
dayjs.extend(timezone);

export const SMT_INSTRUCTOR_FILTER_STORAGE_KEY = 'smt_instructor_filter';
const MANAGE_FILTER_CONFIG = {
    text: 'Manage filters',
    id: 'manage_filters',
} as const;

const Instructors = () => {
    const history = useHistory();
    const featureFlags = useSelector(selectFeatures);

    const [isBlockedTimeModalVisible, setIsBlockedTimeModalVisible] =
        useState(false);
    const [calendarRef, setCalendarRef] = useState<FullCalendar>();
    const [selectedInstructors, setSelectedInstructors] = useState(
        new Set<RecommendedInstructorProfileData>(),
    );
    const start_timestamp = useSelector(selectStartTimeStamp);
    const end_timestamp = useSelector(selectEndTimeStamp);
    const instructors = useSelector(selectInstructorList);
    const instructorLookup = useSelector(selectInstructorLookup);
    const instructorActivityLookup = useSelector(
        selectInstructorActivityLookup,
    );
    const instructorActivities = useSelector(
        selectInstructorActivitiesAsEvents,
    );
    const instructorBlockedTimes = useSelector(selectBlockedTimesAsEvents);
    const blockedTimeLookup = useSelector(selectBlockedTimeLookup);
    const pagesCount: number = useSelector(selectPagesCount);
    const currentPageIndex: number = useSelector(selectCurrentPageIndex);
    const size = useSelector(selectSize);

    const isUserLoading = useSelector(selectIsUserLoading);
    const userId = useSelector(selectUserId);
    const savedFilters = useSelector(selectSMTInstructorListSavedFilters);
    const savedFilterNames = savedFilters.map((item: any) => item.name);
    const savedFilterLookup = useSelector(
        selectSMTInstructorListSavedFilterLookup,
    );

    const [isSaveFilterModalActive, setIsSaveFilterModalActive] =
        useState(false);

    const [isManageFilterModalActive, setIsManageFilterModalActive] =
        useState(false);
    const [savedFilterName, setSavedFilterName] = useState('');

    const { addNotification } = useNotifications();
    const [submitting, setSubmitting] = useState(false);
    const [isBlockedTimeInfoModalVisible, setIsBlockedTimeInfoModalVisible] =
        useState(false);
    const [eventSource, setEventSource] = useState({} as BlockedTime);
    const [editBlockedTimeItem, setEditBlockedTimeItem] = useState(
        {} as BlockedTime,
    );
    const [blockedTimeErrors, setBlockedTimeErrors] = useState<any>([]);

    const {
        formValues,
        userProfile,
        courseList,
        isLocationListLoaded,
        originalLocationList,
        deliveryCountryList,
        preselectedCityId,
        deliveryLanguageList,
        lifeCycleMethods,
        instructorTypeList,
        programTypeList,
        geographyList,
        regionList,
        instructorCourseStatusList,
        timezonesList,
    } = useBusinessDatum();

    const dispatch = useDispatch();

    const defaultQuery: PropertyFilterProps.Query = React.useMemo(() => {
        return {
            tokens: [
                {
                    propertyKey: InstructorPropertyFilterKeys.InstructorStatus,
                    operator: '=',
                    value: 'Authorized',
                },
            ],
            operation: 'and',
        };
    }, []);

    const [query, setQuery] = React.useState<PropertyFilterProps.Query>(
        localStorage.getItem(SMT_INSTRUCTOR_FILTER_STORAGE_KEY)
            ? JSON.parse(
                  localStorage.getItem(
                      SMT_INSTRUCTOR_FILTER_STORAGE_KEY,
                  ) as string,
              )
            : defaultQuery,
    );

    useEffect(() => {
        let storedFilterString = localStorage.getItem(
            SMT_INSTRUCTOR_FILTER_STORAGE_KEY,
        );
        if (storedFilterString) {
            setQuery(JSON.parse(storedFilterString));
        }
    }, []);

    useEffect(() => {
        dispatch(
            getInstructorList({
                start_timestamp,
                end_timestamp,
                ...getFiltersFromQuery(query),
                activity_source_fields: DEFAULT_ACTIVITY_SOURCE_FIELDS,
            }),
        );
        return () => {
            dispatch(setIsLoaded(false));
        };
    }, [dispatch, query, start_timestamp, end_timestamp]);

    useEffect(() => {
        dispatch(setLocationListRegion(userProfile?.profile.regions || []));
        dispatch(getTimezonesList());

        return () => {
            // reset business data slices
            // this code block should only run once
            dispatch(resetLocationsSlice());
            dispatch(resetDeliveryCountriesSlice());
            dispatch(resetProgramTypesSlice());
            dispatch(resetRegionsSlice());
            dispatch(resetGeographiesSlice());
            dispatch(resetInstructorTypesSlice());
            dispatch(resetInstructorCourseStatusesSlice());
            dispatch(resetCoursesSlice());
            dispatch(resetInstructorRoleTypesSlice());
        };
    }, [dispatch, userProfile]);

    useEffect(() => {
        if (query.tokens.length > 0) {
            handleLocalStorageSetItem(
                SMT_INSTRUCTOR_FILTER_STORAGE_KEY,
                JSON.stringify(query),
            );
        } else {
            localStorage.removeItem(SMT_INSTRUCTOR_FILTER_STORAGE_KEY);
        }

        setSelectedInstructors(new Set([]));
    }, [query]);

    // lifecycle method to fetch (and re-fetch) business data
    useEffect(() => {
        (lifeCycleMethods as ReadonlyArray<[boolean, Function]>).forEach(
            ([shouldFetch, getList]) => {
                if (shouldFetch) {
                    dispatch(getList());
                }
            },
        );
    });

    const onCloseModalSuccess = (clearBlockedTimeValues: () => void) => {
        setIsBlockedTimeModalVisible(false);
        setBlockedTimeErrors([]);
        clearBlockedTimeValues();
        setSubmitting(false);
    };

    const shouldCheckForValue = !!(
        formValues.delivery_city &&
        formValues.delivery_country &&
        formValues.delivery_geo &&
        formValues.delivery_region
    );

    const locationList = handlePreSelectedValue(
        {
            city: formValues.delivery_city as string,
            state: formValues.delivery_state as string,
            country: formValues.delivery_country,
            geo: formValues.delivery_geo,
            region: formValues.delivery_region,
            active: true,
            pk: `location-${Date.now()}`,
            city_timezone: '',
        },
        shouldCheckForValue && isLocationListLoaded,
        originalLocationList,
        (location) => getCityString(location) === preselectedCityId,
    );

    const handleSelectedInstructorsChange = (
        selectedInstructors: Set<RecommendedInstructorProfileData>,
    ) => setSelectedInstructors(new Set(Array.from(selectedInstructors)));

    const handleEventModalChange = (isVisible: boolean, eventSource: any) => {
        setEventSource(eventSource);
        setIsBlockedTimeInfoModalVisible(isVisible);
    };

    const handleEditBlockedTimeModal = (blockedTimeItem: BlockedTime) => {
        const editBlockTimeItem = blockedTimeLookup[blockedTimeItem.pk];
        setEditBlockedTimeItem(editBlockTimeItem);
        setIsBlockedTimeInfoModalVisible(false);
        setIsBlockedTimeModalVisible(true);
    };

    const addCalendarEvents = (blockedTime: BlockedTime) => {
        const instructor = instructorLookup[blockedTime.instructor_pk];
        dispatch(addBlockedTimesTimeAsEvent({ blockedTime, instructor }));
    };

    const handleBlockedTimeSubmit = async ({
        blockedTimeFormValues,
        clearBlockedTimeValues,
    }: {
        blockedTimeFormValues: BlockedTimeFormData;
        clearBlockedTimeValues: () => void;
    }) => {
        const { blockedTimes } = normalizeInstructorsWithBlockedTimeTimeData({
            selectedInstructors: Array.from(selectedInstructors),
            blockedTimeFormValues,
            instructorLookup,
        });

        let addBlockTimePromises: any =
            addBlockedTimesForInstructor(blockedTimes);

        const blockTimeAdds = (await Promise.all(
            addBlockTimePromises,
        )) as unknown as Array<BlockedTimeSuccessResponseData>;

        const successfulUpdates = blockTimeAdds.filter(
            (response: any) => response.status === 200,
        );

        if (successfulUpdates.length > 0) {
            successfulUpdates.forEach((success) => {
                addCalendarEvents(success.blockedTimeType);
            });
        }

        // TODO: Temporary until response error codes are completed on BE
        const checkForErrors = blockTimeAdds.filter(
            (response: any) => response.status !== 200,
        );

        const hasErrors = checkForErrors.length > 0;

        if (hasErrors) {
            setBlockedTimeErrors(checkForErrors);
            setSubmitting(false);
        } else {
            selectedInstructors.clear();
            addNotification({
                id: `add-blocked-times-${Date.now()}`,
                type: 'success',
                content: 'Block time added.',
            });
            onCloseModalSuccess(clearBlockedTimeValues);
        }
    };

    const handleBlockedTimeEdit = async (
        blockedTimeFormValues: BlockedTimeFormData,
        clearBlockedTimeValues: () => void,
        forceDelete: boolean,
    ) => {
        setSubmitting(true);
        const instructor =
            instructorLookup[blockedTimeFormValues.instructor_pk];

        // convert the blocked times into epoch
        const blockedTimesFromModal = blockedTimeFormValues.blockedTimes;

        const calendarViewBlockTimes = blockedTimesFromModal.map(
            (blockedTime) => {
                return formatFreelancerBlockedTimeAttributes({
                    id: blockedTime.id,
                    blockedTime,
                    timezone: instructor.city_timezone,
                    forceDelete,
                });
            },
        );

        const originalBlockedTimes =
            blockedTimeFormValues.blocked_time_timestamps.map(
                (blockedTime) => ({
                    id: blockedTimeFormValues.pk,
                    start_timestamp: blockedTime.start_timestamp,
                    end_timestamp: blockedTime.end_timestamp,
                    removeFlag: false,
                }),
            );

        const originalBlockedTimesAsBlockedTime = originalBlockedTimes.reduce(
            (acc, blockedTime) => {
                if (blockedTime.end_timestamp >= acc.end_timestamp) {
                    return {
                        ...acc,
                        end_timestamp: blockedTime.end_timestamp,
                    };
                }
                return acc;
            },
            originalBlockedTimes[0],
        );

        const unavailableTimeBlocks = mapBlockedTimes({
            calendarViewBlockTimes,
            originalBlockedTime: originalBlockedTimesAsBlockedTime,
        });

        if (unavailableTimeBlocks.mappedBlockedTimes.length === 0) {
            return handleBlockedTimeDelete(
                blockedTimeFormValues,
                clearBlockedTimeValues,
            );
        }

        const newBlockTimes = unavailableTimeBlocks.mappedBlockedTimes.map(
            (newBlockTimeTimestamps) => {
                return {
                    ...eventSource,
                    blocked_time_type: blockedTimeFormValues.blocked_time_type,
                    blocked_time_name: blockedTimeFormValues.blocked_time_name,
                    blocked_time_notes:
                        blockedTimeFormValues.blocked_time_notes,
                    blocked_time_timestamps: [newBlockTimeTimestamps],
                };
            },
        );

        const editFirstBlockTime = newBlockTimes.shift() || ({} as BlockedTime);
        await updateBlockedTimeForInstructor(editFirstBlockTime);
        dispatch(
            updateCalendarBlockedTime({
                instructor,
                blockedTime: {
                    blocked_time_type: editFirstBlockTime.blocked_time_type,
                    blocked_time_name: editFirstBlockTime.blocked_time_name,
                    blocked_time_notes: editFirstBlockTime.blocked_time_notes,
                    pk: editFirstBlockTime.pk,
                    instructor_pk: instructor.pk,
                    blocked_time_timestamps:
                        editFirstBlockTime.blocked_time_timestamps,
                },
            }),
        );

        await new Promise((res) => setTimeout(res, 500));

        if (newBlockTimes.length > 0) {
            const addBlockTimePromises = await addBlockedTimesForInstructor(
                newBlockTimes,
            );

            const blockTimeAdds = (await Promise.all(
                addBlockTimePromises,
            )) as unknown as Array<BlockedTimeSuccessResponseData>;

            const successfulUpdates = blockTimeAdds.filter(
                (response: any) => response.status === 200,
            );

            if (successfulUpdates.length > 0) {
                successfulUpdates.forEach((success) => {
                    addCalendarEvents({
                        ...success.blockedTimeType,
                        blocked_time_timestamps:
                            success.blockedTimeType.blocked_time_timestamps.map(
                                (timestamp) => ({
                                    start_timestamp: timestamp.start_timestamp,
                                    end_timestamp: timestamp.end_timestamp,
                                }),
                            ),
                    });
                });
            } else {
                const checkForErrors = blockTimeAdds.filter(
                    (response: any) => response.status !== 200,
                );
                const hasErrors = checkForErrors.length > 0;
                if (hasErrors) {
                    setBlockedTimeErrors(checkForErrors);
                }
                setSubmitting(false);
                return;
            }
        }

        addNotification({
            id: `add-blocked-times-${Date.now()}`,
            type: 'success',
            content: 'Unavaiblable times updated.',
        });
        onCloseModalSuccess(clearBlockedTimeValues);
    };

    const handleBlockedTimeDelete = async (
        blockedTimeFormValues: BlockedTimeFormData,
        clearBlockedTimeValues: () => void,
    ) => {
        const deleteBlockedTime = await deleteBlockedTimeForInstructor(
            blockedTimeFormValues,
        );
        dispatch(
            deleteCalendarInstructorReservedTime({
                instructor:
                    instructorLookup[blockedTimeFormValues.instructor_pk],
                blockedTime: blockedTimeFormValues,
            }),
        );
        onCloseModalSuccess(clearBlockedTimeValues);
        addNotification({
            id: `set-blocked-times-${Date.now()}`,
            ...(!!deleteBlockedTime
                ? {
                      type: 'success',
                      content: 'Block time deleted.',
                  }
                : {
                      type: 'error',
                      content:
                          'An error occurred while deleting the block time.',
                  }),
        });
    };

    const handleQueryChange = (query: PropertyFilterProps.Query) => {
        dispatch(setCurrentPageIndex(1));
        dispatch(setFrom(0));
        query.tokens = [...query.tokens];
        setQuery(query);
    };

    const handleTablePaginationChange = async (event: any) => {
        let updatedPageIndex = event;
        let from = (updatedPageIndex - 1) * size;

        dispatch(setCurrentPageIndex(updatedPageIndex));
        dispatch(setFrom(from));

        dispatch(
            getInstructorList({
                start_timestamp,
                end_timestamp,
                ...getFiltersFromQuery(query),
            }),
        );
    };

    const getSavedFilterItems = (): Array<any> => {
        const manageItem = {
            text: MANAGE_FILTER_CONFIG.text,
            id: MANAGE_FILTER_CONFIG.id,
            disabled: false,
        };

        return [
            {
                id: 'filter_items',
                text: '',
                items: savedFilterNames.map((name: string) => ({
                    text: name,
                    id: name,
                    disable: false,
                })),
            },
            {
                id: 'manage_items',
                text: '',
                items: [manageItem],
            },
        ];
    };

    const handleSavedFilterChange = (id: string) => {
        if (!savedFilterLookup) {
            return;
        }

        if (id === MANAGE_FILTER_CONFIG.id) {
            // Polaris guidelines: form with multiple fields
            // set validation on change to false when the form loads for the first time.
            //setValidateOnChange(false);

            // show manage filters dialog
            setIsManageFilterModalActive(true);
            return;
        }

        setQuery(savedFilterLookup[id] as PropertyFilterProps.Query);
    };

    const savedFiltersComponent = (
        <>
            <Can
                perform={Actions.RESERVE_TIME_ADMIN}
                yes={() => (
                    <Button
                        variant="normal"
                        data-testid="ActivityImportButton"
                        onClick={() =>
                            history.push({
                                pathname: ACTIVITY_PATH.FREELANCER_IMPORT,
                            })
                        }
                    >
                        Import Freelancer schedule
                    </Button>
                )}
                no={() => <div></div>}
            />

            {!!query?.tokens?.length && (
                <Button
                    variant="normal"
                    disabled={false}
                    data-testid="ActivityInstructorListActionsSaveFilters"
                    onClick={() => setIsSaveFilterModalActive(true)}
                >
                    Save Filters
                </Button>
            )}
            {!!savedFilterNames?.length && savedFilterLookup && (
                <ButtonDropdown
                    data-testid="ActivityListActionsLoadFilters"
                    items={getSavedFilterItems()}
                    onItemClick={({ detail: { id } }) => {
                        handleSavedFilterChange(id);
                    }}
                >
                    Load filters
                </ButtonDropdown>
            )}
        </>
    );

    const updateUserSavedFilters = (updatedFilters?: SavedFilterForm) => {
        if (userId) {
            if (!updatedFilters) {
                return dispatch<any>(
                    updatePreferencesAndSavedFilters(userId, {
                        saved_filters_smt_instructors: [
                            ...savedFilters,
                            {
                                name: savedFilterName.trim(),
                                filter: query,
                            },
                        ],
                    }),
                );
            } else {
                const updatedFilterToSave: Array<{
                    name: string;
                    filter: SmtInstructorFilters;
                }> = updatedFilters.filters.map((filterItem) => {
                    return {
                        name: filterItem.name,
                        filter: filterItem.filter,
                    };
                });

                return dispatch<any>(
                    updatePreferencesAndSavedFilters(userId, {
                        saved_filters_smt_instructors: updatedFilterToSave,
                    }),
                );
            }
        }
        return Promise.resolve(false);
    };

    const saveFilters = async (updatedFilters?: SavedFilterForm) => {
        const filterNameExists = !!savedFilterLookup?.[savedFilterName.trim()];

        // when saving a new filter
        if (!updatedFilters && filterNameExists) {
            return;
        }
        const isSuccessful: boolean = await updateUserSavedFilters(
            updatedFilters,
        );
        addNotification({
            id: `update-user-instructor-list-saved-filters-${Date.now}`,
            ...(isSuccessful
                ? {
                      type: 'success',
                      content:
                          'You have successfully saved the instructor list filters.',
                  }
                : {
                      type: 'error',
                      content:
                          'An error occurred while saving the instructor list filters.',
                  }),
        });

        if (isSuccessful) {
            setSavedFilterName('');
            if (!updatedFilters) {
                setIsSaveFilterModalActive(false);
            } else {
                setIsManageFilterModalActive(false);
            }
        }
    };

    const instructorListViewProps: Omit<
        CalendarViewPropsData,
        'delivery_sessions' | 'error' | 'deliverySessions'
    > = {
        handleCalendarRefChange: (calendarRef: FullCalendar) =>
            setCalendarRef(calendarRef),
        handleSelectedInstructorsChange,
        resources: instructors,
        initialDate: start_timestamp * 1000,
        events: (() => {
            return [...instructorBlockedTimes, ...instructorActivities];
        })(),
        resourceLookup: instructorLookup,
        eventLookup: (() => {
            return {
                ...instructorActivityLookup,
                ...blockedTimeLookup,
            };
        })(),
        selectedInstructors,
        resourceLabelType: 'checkbox',
        calendarHeight: 800,
        handleEventModalChange,
        isBlockedTimeInfoModalVisible,
        handleEditBlockedTimeModal,
    };

    const blockedTimeModalProps: BlockedTimeModalPropsData = {
        isVisible: isBlockedTimeModalVisible,
        handleIsVisibleChange: setIsBlockedTimeModalVisible,
        selectedInstructors,
        handleSelectedInstructorsChange,
        calendarRef,
        setSubmitting,
        submitting,
        handleBlockedTimeSubmit,
        blockedTime: editBlockedTimeItem,
        setBlockedTime: setEditBlockedTimeItem,
        handleBlockedTimeDelete,
        handleBlockedTimeEdit,
        eventSource,
        blockedTimeErrors,
        instructorLookup,
        setBlockedTimeErrors,
    };

    const instructorFilteringOptions = filteringOptions({
        courseList,
        deliveryLanguageList,
        deliveryCountryList,
        locationList,
        instructorTypeList,
        programTypeList,
        geographyList,
        regionList,
        instructorCourseStatusList,
        omitFilters: [InstructorPropertyFilterKeys.Availability],
        timezonesList,
    });

    const paginationProps: InstructorPaginationProps = {
        onPaginationChange: handleTablePaginationChange,
        pagesCount,
        currentPageIndex: currentPageIndex,
        pageSize: GRIMSBY_PAGE_COUNT,
    };

    const instructorListHeaderProps: InstructorCalendarHeaderPropsData = {
        calendarRef,
        handleQueryChange,
        filteringOptions: instructorFilteringOptions,
        instructorFilteringProperties: getInstructorFilteringProperties([
            InstructorPropertyFilterKeys.Availability,
        ]),
        query,
        filteringPlaceholder: 'Filter Instructors',
        handleHeaderAction: setIsBlockedTimeModalVisible,
        selectedInstructors,
        paginationProps,
        savedFiltersComponent,
    };

    const blockedTimeInfoModalProps: BlockedTimeInfoModalProps = {
        eventSource,
        isBlockedTimeInfoModalVisible,
        handleIsVisibleChange: handleEventModalChange,
        handleEditBlockedTimeModal,
        calendarRef,
    };

    const saveFilterModalProps: SaveFilterModalProps = {
        isSaveFilterModalActive,
        setIsSaveFilterModalActive,
        isUserLoading,
        saveFilters,
        savedFilterName,
        setSavedFilterName,
        savedFilterLookup,
    };

    const manageSavedFiltersModalProps: ManageSavedFiltersModalProps = {
        savedFilters,
        saveFilters,
        isManageFilterModalActive,
        setIsManageFilterModalActive,
        isUserLoading,
    };

    return (
        <>
            <Container
                data-testid={`smt-instructor-list`}
                disableContentPaddings
                header={
                    calendarRef && (
                        <CalendarHeader {...instructorListHeaderProps} />
                    )
                }
            >
                <CalendarResourceView {...instructorListViewProps} />
                {(() => {
                    if (isBlockedTimeModalVisible) {
                        return <BlockedTimeModal {...blockedTimeModalProps} />;
                    }
                })()}
                {(() => {
                    if (eventSource && eventSource.pk) {
                        return (
                            <BlockedTimeInfoModal
                                {...blockedTimeInfoModalProps}
                            />
                        );
                    }
                })()}
            </Container>
            <SaveFilterModal {...saveFilterModalProps} />
            {isManageFilterModalActive && (
                <ManageSavedFiltersModal {...manageSavedFiltersModalProps} />
            )}
        </>
    );
};

export default Instructors;
