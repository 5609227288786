import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Form } from '@amzn/awsui-components-react';

import {
    adminCreateUser,
    selectIsLoading,
} from '../../../store/slices/selectedUserSlice';
import { useNotifications } from '../../../../common/context/grimsbyNotifications';
import useFormValidation, {
    ValidationType,
} from '../../../../common/utils/formValidation';
import { UserProfileData } from '../../../../common/interfaces/userProfile';
import { FormSectionProps } from '../../../../common/interfaces/formSectionProps';
import AdminUserManagementFormSection from '../FormSections/AdminUserManagementFormSection';
import { FormSectionMode } from '../../../../common/constants/forms';
import { UserFormData } from '../../../interfaces/userForm';

const createUserValidationConfig: {
    [key in ValidationType]?: Array<keyof UserFormData>;
} = {
    required: ['programs', 'regions', 'email'],
    email: ['email'],
};

const AdminUserManagementCreateForm = ({
    createUserFormState,
}: {
    createUserFormState: UserFormData;
}) => {
    const isLoading = useSelector(selectIsLoading);
    const history = useHistory();
    const dispatch = useDispatch();
    const { addNotification } = useNotifications();

    const { isInvalid, errors, validateForm } = useFormValidation<
        UserFormData
    >();

    const [formValues, setFormValues] = useState<UserFormData>(
        createUserFormState,
    );

    const handleFieldEvent = (changes: Partial<UserFormData>) => {
        setFormValues((values) => ({
            ...values,
            ...changes,
        }));
    };

    const createUserProps: Omit<
        FormSectionProps<UserFormData>,
        'validateAndHandleFieldEvent'
    > & {
        name: string;
    } = {
        formValues,
        errors,
        handleFieldEvent,
        name: 'New User',
        mode: FormSectionMode.Create,
    };

    useEffect(() => {
        if (isInvalid) {
            validateForm(formValues, createUserValidationConfig);
        }
    }, [isInvalid, formValues, validateForm]);

    return (
        <section data-testid="AdminUserManagementCreateForm">
            <Form
                header="Add user"
                actions={
                    <div className="awsui-util-action-stripe awsui-util-mb-m">
                        <div className="awsui-util-action-stripe-group">
                            <Button
                                variant="link"
                                className="admin-user-cancel"
                                data-testid="AdminUserManagementCreateFormCancel"
                                onClick={() => {
                                    history.push({
                                        pathname: `/admin/user-management/users`,
                                    });
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                className="admin-user-save"
                                data-testid="AdminUserManagementCreateFormAdd"
                                loading={isLoading}
                                onClick={async () => {
                                    const invalid = validateForm(
                                        formValues,
                                        createUserValidationConfig,
                                    );

                                    if (!invalid) {
                                        const {
                                            isSuccessful,
                                            createdUserId,
                                        } = await dispatch<any>(
                                            adminCreateUser(
                                                formValues as Pick<
                                                    UserProfileData,
                                                    | 'programs'
                                                    | 'regions'
                                                    | 'email'
                                                >,
                                            ),
                                        );
                                        addNotification({
                                            id: `create-user-${Date.now()}`,
                                            ...(isSuccessful
                                                ? {
                                                      type: 'success',
                                                      content:
                                                          'You have successfully created a user.',
                                                  }
                                                : {
                                                      type: 'error',
                                                      content:
                                                          'An error occurred while creating the user.',
                                                  }),
                                        });
                                        if (isSuccessful) {
                                            history.push({
                                                pathname: `/admin/user-management/users/${createdUserId}`,
                                            });
                                        }
                                    }
                                }}
                            >
                                {`${isLoading ? 'Adding' : 'Add'} user`}
                            </Button>
                        </div>
                    </div>
                }
            >
                <AdminUserManagementFormSection {...createUserProps} />
            </Form>
        </section>
    );
};

export default AdminUserManagementCreateForm;
