import {
    INSTRUCTOR_ADMIN_GROUP,
    SYS_ADMIN_GROUP,
    AJAX_CALL_ERROR,
    SCHEDULE_ADMIN_GROUP,
    SCHEDULE_READ_ONLY,
    INSTRUCTOR_READ_ONLY,
    GANDALF_INSTRUCTOR_ADMIN_GROUP,
    GANDALF_SCHEDULE_ADMIN_GROUP,
    GANDALF_SCHEDULE_READ_ONLY,
    GANDALF_SYS_ADMIN_GROUP,
    GANDALF_INSTRUCTOR_READ_ONLY,
} from '../constants/grimsby';
import policies, { Roles, Actions } from '../constants/auth';
import { USER_MANAGEMENT_API_ERROR_MESSAGE } from '../constants/errorMessage';

import { RedirectionData } from '../interfaces/redirection';
import { UserResponseData } from '../interfaces/userResponse';

/**
 * Session Storage key
 */
export const ROLES_KEY = 'vias_roles';

/**
 * Method to evaluate the frontend role based on ldap groups
 */
export const assignRoles = (ldap_groups: string[]): Array<Roles> => {
    if (!ldap_groups?.length) {
        return [];
    }
    let roles = [];
    if (ldap_groups.includes(GANDALF_INSTRUCTOR_ADMIN_GROUP)) {
        roles.push(Roles.INSTRUCTOR_ADMIN);
    }
    if (ldap_groups.includes(GANDALF_SCHEDULE_ADMIN_GROUP)) {
        roles.push(Roles.SCHEDULE_ADMIN);
    }
    if (ldap_groups.includes(GANDALF_SCHEDULE_READ_ONLY)) {
        roles.push(Roles.SCHEDULE_READ_ONLY);
    }
    if (ldap_groups.includes(GANDALF_SYS_ADMIN_GROUP)) {
        roles.push(Roles.SYSTEM_ADMIN);
    }
    if (ldap_groups.includes(GANDALF_INSTRUCTOR_READ_ONLY)) {
        roles.push(Roles.READ_ONLY);
    }
    setUserRoles(roles);
    return roles;
};

/**
 * Method to write role to session storage
 */
export const setUserRoles = (roles: Array<Roles>): void => {
    sessionStorage.setItem(ROLES_KEY, JSON.stringify(roles));
};

/**
 * Method to read frontend role from session storage
 */
export const getUserRoles = (): Array<Roles> => {
    const roles = sessionStorage.getItem(ROLES_KEY);
    
    if (roles) {
        return JSON.parse(roles);
    }
    return [];
};

/**
 * Check if given role can do given action
 */
export const check = (roles: Array<Roles>, action: Actions): boolean => {
    let isAllowed = false;
    roles.forEach((role) => {
        const permissions = policies[role];
        if (!isAllowed) {
            if (permissions && permissions.includes(action)) {
                isAllowed = true;
            }
        }
    });
    return isAllowed;
};

/**
 * Check if given user details are sufficient
 */
export const checkUserDetails = (
    userDetailsIsLoaded: boolean,
    userDetailsError: any,
    userDetailsStatusCode: number | null,
    userDetails: UserResponseData | null,
): RedirectionData => {
    if (!userDetailsIsLoaded) {
        return {
            redirect: false,
        };
    }
    // userDetailsError is truthy whenever the get user API returned a non-200 statusCode
    if (userDetailsError) {
        const {
            NOT_ACTIVE_USER,
            NO_USER,
        } = USER_MANAGEMENT_API_ERROR_MESSAGE.LOAD_USER;
        switch (userDetailsStatusCode) {
            case NOT_ACTIVE_USER.STATUS_CODE:
                return {
                    redirect: true,
                    message: NOT_ACTIVE_USER.MESSAGE,
                };
            case NO_USER.STATUS_CODE:
                return {
                    redirect: true,
                    message: NO_USER.MESSAGE,
                };
            default:
                return {
                    redirect: true,
                    message: AJAX_CALL_ERROR,
                };
        }
    } else if (
        !(userDetails?.profile?.programs && userDetails?.profile?.regions)
    ) {
        return {
            redirect: true,
            message:
                USER_MANAGEMENT_API_ERROR_MESSAGE.LOAD_USER
                    .NO_PROGRAMS_OR_NO_REGIONS.MESSAGE,
            path: '/welcome',
        };
    } else {
        return {
            redirect: false,
        };
    }
};
