import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { InstructorRoleTypesResponseData } from '../../interfaces/businessDataResponse/instructorRoleTypesResponse';
import { HandleRequestData } from '../../interfaces/handleRequest';
import businessDataApi from '../../api/businessDataApi';
import { BusinessDataApiQueryParams } from '../../interfaces/queryParams';
import { AppDispatch, GlobalState } from '../../../main/store';
import { ExtendedBusinessDataStoreState } from '../../interfaces/businessDataStoreState';
import { InstructorRoleTypeItemData } from '../../interfaces/businessDataItem/instructorRoleTypeItem';
import parseBoolean from '../../utils/parseBoolean';
import {
    ExtendedBusinessDataStoreInitialState,
    getActiveBusinessDataValues,
    resetBasicBusinessDataStoreState,
    resetExtendedBusinessDataStoreState,
    initializeBusinessDataListQueryParamsStoreState,
} from '../store.common';

/**
 * instructorRoleTypesSlice manages all instructorRoleTypes state, and contains instructorRoleTypes actions as well as instructorRoleTypes state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const instructorRoleTypesSlice = createSlice({
    name: 'instructorRoleTypes',
    initialState: {
        ...ExtendedBusinessDataStoreInitialState,
    } as ExtendedBusinessDataStoreState<InstructorRoleTypeItemData>,
    reducers: {
        setInstructorRoleTypesList: (
            state,
            action: PayloadAction<InstructorRoleTypeItemData[]>,
        ) => {
            const byInstructorRoleTypeName = action.payload.reduce(
                (
                    byInstructorRoleTypeName: {
                        [key: string]: InstructorRoleTypeItemData;
                    },
                    instructorRoleType: InstructorRoleTypeItemData,
                ) => {
                    byInstructorRoleTypeName[
                        instructorRoleType.instructor_role_type
                    ] = {
                        ...instructorRoleType,
                        active: parseBoolean(instructorRoleType.active),
                    };
                    return byInstructorRoleTypeName;
                },
                {},
            );
            state.entities = byInstructorRoleTypeName;
            state.keysList = Object.keys(byInstructorRoleTypeName);
        },
        setInstructorRoleType: (
            state,
            action: PayloadAction<InstructorRoleTypeItemData>,
        ) => {
            // if the item is new, add a new key for it and update the count.
            if (!state.entities[action.payload.instructor_role_type]) {
                state.keysList = [
                    ...state.keysList,
                    action.payload.instructor_role_type,
                ];
                state.count = state.keysList.length;
            }
            state.entities[action.payload.instructor_role_type] =
                action.payload;
        },
        setSelectedInstructorRoleType: (
            state,
            action: PayloadAction<string | null>,
        ) => {
            state.selectedItemKey = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
        setCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload;
        },
        setSearchText: (state, action: PayloadAction<string | null>) => {
            state.searchText = action.payload;
        },
        setActive: (
            state,
            action: PayloadAction<BusinessDataApiQueryParams.Active>,
        ) => {
            state.active = action.payload;
        },
        setFrom: (state, action: PayloadAction<number>) => {
            state.from = action.payload;
        },
        setPagesCount: (state, action: PayloadAction<number>) => {
            state.pagesCount = action.payload;
        },
        setCurrentPageIndex: (state, action: PayloadAction<number>) => {
            state.currentPageIndex = action.payload;
        },
        setSize: (state, action: PayloadAction<number>) => {
            state.size = action.payload;
        },
        resetPartialInstructorRoleTypesSlice: resetBasicBusinessDataStoreState,
        resetInstructorRoleTypesSlice: resetExtendedBusinessDataStoreState,
        initializeInstructorRoleTypesListQueryParams: initializeBusinessDataListQueryParamsStoreState,
    },
});

export const {
    setIsLoading,
    setIsLoaded,
    setInstructorRoleTypesList,
    setInstructorRoleType,
    setSelectedInstructorRoleType,
    setError,
    setCount,
    setSearchText,
    setActive,
    setFrom,
    setPagesCount,
    setCurrentPageIndex,
    setSize,
    resetPartialInstructorRoleTypesSlice,
    resetInstructorRoleTypesSlice,
    initializeInstructorRoleTypesListQueryParams,
} = instructorRoleTypesSlice.actions;

const handleInstructorRoleTypesListRequest = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        const {
            from,
            size,
            searchText: instructor_role_type,
            active,
        } = state.instructorRoleTypes;

        const params: BusinessDataApiQueryParams.GetInstructorRoleTypes = {
            active,
        };

        // if both "from" and "size" equal to 0, avoid adding them into the query parameters
        if (from !== 0 || size !== 0) {
            params.from = from;
            params.size = size;
        }
        if (instructor_role_type) {
            params.instructor_role_type = instructor_role_type;
        }

        try {
            const {
                result: { INSTRUCTOR_ROLE_TYPE, total_count },
            }: HandleRequestData<InstructorRoleTypesResponseData> = await businessDataApi.getInstructorRoleTypes(
                params,
            );

            dispatch(setInstructorRoleTypesList(INSTRUCTOR_ROLE_TYPE));
            dispatch(setCount(total_count));
            dispatch(setPagesCount(Math.ceil(total_count / size)));
        } catch (error: any) {
            dispatch(
                setError(error?.message || 'getInstructorRoleTypes API error'),
            );
        }
    };
};

export const getInstructorRoleTypesList = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));
        await dispatch(handleInstructorRoleTypesListRequest());
        if (!state.instructorRoleTypes.isLoaded) {
            dispatch(setIsLoaded(true));
        }
        dispatch(setIsLoading(false));
    };
};

/**
 * action to update instructor role type
 * before update api call, fetch instructor role types and prepare payload containing only the changed fields
 * after update api call, fetch instructor role types and update the current item in that keysList with the item data we updated
 * @param data
 */
export const updateInstructorRoleType = (data: InstructorRoleTypeItemData) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        // immediately put the keysList into loading while we finish updating the item/fetching the keysList
        dispatch(setIsLoading(true));
        if (!data.pk || data?.pk.length <= 0) {
            // eslint-disable-next-line no-console
            console.error(
                `Error updating instructor role type: required field pk was missing`,
            );
            dispatch(setIsLoading(false));
            return false;
        }
        try {
            await businessDataApi.updateInstructorRoleType(
                data.pk,
                data.active,
            );
            await dispatch(handleInstructorRoleTypesListRequest());
            // force-update the item in the keysList for now, since the keysList we fetched may not have this item's update yet
            dispatch(
                setInstructorRoleType({
                    ...state.instructorRoleTypes.entities[
                        data.instructor_role_type
                    ],
                    active: data.active,
                }),
            );
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const addInstructorRoleType = (
    active = true,
    instructorRoleTypeName: string,
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        dispatch(setIsLoading(true));
        try {
            await businessDataApi.addInstructorRoleType(
                instructorRoleTypeName,
                active,
            );
            // now refresh the keysList
            await dispatch(handleInstructorRoleTypesListRequest());
            if (!state.instructorRoleTypes?.entities[instructorRoleTypeName]) {
                // shove the item into the keysList, but only if it's not there already
                const newInstructorRoleTypeData: InstructorRoleTypeItemData = {
                    instructor_role_type: instructorRoleTypeName,
                    active: active,
                };
                dispatch(setInstructorRoleType(newInstructorRoleTypeData));
            }
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const selectAllInstructorRoleTypes = (state: GlobalState) => {
    return state.instructorRoleTypes.keysList.map(
        (instructorRoleTypeName) =>
            state.instructorRoleTypes.entities[instructorRoleTypeName],
    );
};

export const selectAllActiveInstructorRoleTypes = (state: GlobalState) => {
    return getActiveBusinessDataValues(
        state.instructorRoleTypes.keysList,
        state.instructorRoleTypes.entities,
    );
};

export const selectIsLoading = (state: GlobalState) =>
    state.instructorRoleTypes.isLoading;

export const selectIsLoaded = (state: GlobalState) =>
    state.instructorRoleTypes.isLoaded;

export const selectSelectedInstructorRoleType = (state: GlobalState) => {
    return state?.instructorRoleTypes?.selectedItemKey
        ? state?.instructorRoleTypes?.entities[
              state?.instructorRoleTypes?.selectedItemKey
          ]
        : null;
};

export const selectError = (state: GlobalState) =>
    state.instructorRoleTypes.error;

export const selectCount = (state: GlobalState) =>
    state.instructorRoleTypes.count;

export const selectSearchText = (state: GlobalState) =>
    state.instructorRoleTypes.searchText;

export const selectPagesCount = (state: GlobalState) =>
    state.instructorRoleTypes.pagesCount;

export const selectCurrentPageIndex = (state: GlobalState) =>
    state.instructorRoleTypes.currentPageIndex;

export default instructorRoleTypesSlice.reducer;
