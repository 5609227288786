/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    AttributeEditor,
    Checkbox,
    ColumnLayout,
    CustomDetailEvent,
    DatePicker,
    FormField,
    FormSection,
    Input,
    Select,
    TimeInput,
} from '@amzn/awsui-components-react';
import { PropertyFilterProps, Toggle } from '@amzn/awsui-components-react-v3';

import {
    FORM_ERROR_SELECTOR,
    getOptionsAndLookupForSelectInput,
    getStatusType,
} from '../../../../imt/components/Instructor/FormSections/FormSections.common';
import {
    FormSectionMode,
    LocationFormFieldText,
} from '../../../../common/constants/forms';
import {
    getLocationsList,
    resetLocationsSlice,
    resetPartialLocationsSlice,
    selectError as selectLocationListError,
    selectIsLoading as selectIsLocationListLoading,
    selectSearchText as selectLocationListSearchText,
    setRegion as setLocationListRegion,
    setSearchText as setLocationListSearchText,
} from '../../../../common/store/slices/locationsSlice';
import {
    getDeliveryLanguagesList,
    resetDeliveryLanguagesSlice,
} from '../../../../common/store/slices/deliveryLanguagesSlice';
import { resetCoursesSlice } from '../../../../common/store/slices/coursesSlice';
import { resetDeliveryCountriesSlice } from '../../../../common/store/slices/deliveryCountriesSlice';
import {
    getRecommendedInstructors,
    selectError as selectRecommendedInstructorsListError,
    selectIsLoading as selectAreRecommendedInstructorsLoading,
    selectRecommendedInstructorLookup,
    selectRecommendedInstructorsActivitiesAsEvents,
    selectRecommendedInstructorsActivityLookup,
    selectRecommendedInstructorsAsResources,
    selectBlockedTimeLookup,
    selectBlockedTimesAsEvents,
    setCurrentPageIndex,
    setFrom,
    selectSize,
    selectPagesCount,
    selectCurrentPageIndex,
    setSize,
} from '../../../store/slices/recommendedInstructorsSlice';
import { resetInstructorRoleTypesSlice } from '../../../../common/store/slices/instructorRoleTypesSlice';
import { getActivityCityString } from './ActivityFormSection';
import {
    ActivityDeliveryDetailsFormSectionProps,
    getActivityStartDateFromSessions,
    HYBRID_MODALITY,
    ILT_MODALITY,
    SessionAttributeEditorItem,
} from '../Common/Common';
import { resetProgramTypesSlice } from '../../../../common/store/slices/programTypesSlice';
import { resetRegionsSlice } from '../../../../common/store/slices/regionsSlice';
import { resetGeographiesSlice } from '../../../../common/store/slices/geographiesSlice';
import { resetInstructorCourseStatusesSlice } from '../../../../common/store/slices/instructorCourseStatusesSlice';
import { resetInstructorTypesSlice } from '../../../../common/store/slices/instructorTypesSlice';
import handlePreSelectedValue from '../../../../common/utils/handlePreSelectedValue';
import { getCityString } from '../../../../imt/components/Instructor/FormSections/BasicInfoFormSection';
import { DeliveryInstructor } from '../../../interfaces/activity';
import { LocationItemData } from '../../../../common/interfaces/businessDataItem/locationItem';
import { DeliveryLanguageItemData } from '../../../../common/interfaces/businessDataItem/deliveryLanguageItem';
import AssignInstructorModal from '../Edit/AssignInstructorModal/AssignInstructorModal';
import { getDeliverySession } from '../Create/ActivityCreateForm';
import {
    AssignInstructorModalPropsData,
    InstructorPaginationProps,
} from '../../../interfaces/resourceCalendarProps';
import { RecommendedInstructorProfileData } from '../../../interfaces/recommendedInstructorProfile';
import { InstructorRoleTypeItemData } from '../../../../common/interfaces/businessDataItem/instructorRoleTypeItem';
import { formatStringArray } from '../../../../common/utils/formatStringArray';
import { getInstructorAvailabilityStatusPerInstructor } from '../../../services/activity-service';
import { InstructorPropertyFilterKeys } from '../../../enums/propertyFilterKeys';
import {
    DeliveryFacilityType,
    deliveryFacilityTypeList,
    filteringOptions,
    getFiltersFromQuery,
    instructorFilteringProperties,
} from '../../../common/resourceFilters/instructors';
import { useBusinessDatum } from '../../Common/hooks/useBusinessDatum';
import {
    meridiemFieldOptionLookup,
    meridiemFieldOptions,
    MeridiemFieldValue,
} from '../../../interfaces/meridiem';
import { buildCalendarEventsFromDeliverySessions } from '../../../services/schedule-service';
import { DEFAULT_ACTIVITY_SOURCE_FIELDS } from '../../../constants';
import { getTimezonesList } from '../../../../common/store/slices/timezonesSlice';
import {
    getFeatureFlags,
    selectFeatures,
    selectIsLoaded as selectIsFeaturesLoaded
} from "../../../../common/store/slices/featureSlice";
import { checkFeature } from "../../../../common/utils/featureFlag";

const EditDeliveryDetailsFormSection = ({
    formValues,
    errors,
    handleFieldEvent,
    mode,
    handleSessionItemEvent,
    sessionAttributeEditorItems,
    controlArrayErrors,
    instructorAttributeEditorItems,
    handleInstructorItemEvent,
    instructorValidationResponse,
    handleAddInstructor,
    instructorItemErrors,
    areSessionsInvalid,
    handleAutoAssignInstructorChange,
}: ActivityDeliveryDetailsFormSectionProps & {
    handleAutoAssignInstructorChange: (autoAssignInstructor: boolean | undefined) => void;
}) => {
    const citySearchText = useSelector(selectLocationListSearchText);
    const isLocationListLoading = useSelector(selectIsLocationListLoading);
    const locationListError = useSelector(selectLocationListError);
    const recommendedInstructorsActivitiesAsEvents = useSelector(
        selectRecommendedInstructorsActivitiesAsEvents,
    );
    const areRecommendedInstructorsLoading = useSelector(
        selectAreRecommendedInstructorsLoading,
    );
    const recommendedInstructorsListError = useSelector(
        selectRecommendedInstructorsListError,
    );
    const recommendedInstructorsAsResources = useSelector(
        selectRecommendedInstructorsAsResources,
    );
    const recommendedInstructorsActivityLookup = useSelector(
        selectRecommendedInstructorsActivityLookup,
    );
    const recommendedInstructorLookup = useSelector(
        selectRecommendedInstructorLookup,
    );
    const instructorReservedTimes = useSelector(selectBlockedTimesAsEvents);
    const instructorReservedTimesLookup = useSelector(selectBlockedTimeLookup);
    const {
        userProfile,
        courseList,
        isLocationListLoaded,
        originalLocationList,
        deliveryCountryList,
        preselectedCityId,
        deliveryLanguageList,
        lifeCycleMethods,
        instructorTypeList,
        programTypeList,
        geographyList,
        regionList,
        timezonesList,
        instructorCourseStatusList,
        instructorRoleTypeList,
        isInstructorRoleTypeListLoading,
        isInstructorRoleTypeListLoaded,
        instructorRoleTypeListError,
        isDeliveryLanguageListLoading,
        isDeliveryLanguageListLoaded,
        deliveryLanguageListError,
    } = useBusinessDatum();

    const dispatch = useDispatch();
    const shouldCheckForValue = !!(
        formValues.delivery_city &&
        formValues.delivery_country &&
        formValues.delivery_geo &&
        formValues.delivery_region
    );
    const locationList = handlePreSelectedValue(
        {
            city: formValues.delivery_city as string,
            state: formValues.delivery_state as string,
            country: formValues.delivery_country,
            geo: formValues.delivery_geo,
            region: formValues.delivery_region,
            active: true,
            pk: `location-${Date.now()}`,
            city_timezone: '',
        },
        shouldCheckForValue && isLocationListLoaded,
        originalLocationList,
        (location) => getCityString(location) === preselectedCityId,
    );
    const isILT = formValues.activity_modality === ILT_MODALITY;
    const isHybrid = formValues.activity_modality === HYBRID_MODALITY;
    const isIltOrHybrid = isILT || isHybrid;

    const [isAssignInstructorModalVisible, setIsAssignInstructorModalVisible] =
        useState(false);

    const [isAutoAssignInstructorToggled, setIsAutoAssignInstructorToggled] = useState(true);
    const [isAutoAssignInstructorToggleVisible, setIsAutoAssignInstructorToggleVisible] = useState(false);

    const featureFlags = useSelector(selectFeatures);
    const featuresLoaded = useSelector(selectIsFeaturesLoaded);
    const autoAssignInstructorFeatureFlag = useMemo(() => {
        const result = featuresLoaded && checkFeature(
            '',
            { featureName: 'auto-assign-instructor' },
            featureFlags?.features,
        )
        return result !== undefined ? result : false;
    }, [featuresLoaded, featureFlags]);

    useEffect(() => {
        dispatch(getFeatureFlags());
    }, [dispatch]);

    useEffect(() => {
        const isVilt = formValues.activity_modality === "vILT";
        const isCommercial = formValues.program === "Commercial";
        const isPartnerProgram = formValues.program === "Partner Program";
        const isNamer = formValues.delivery_region === "NAMER"
        const isViltCommercialOrPartnerProgramInNamer = isVilt && (isCommercial || isPartnerProgram) && isNamer;
        const autoAssignInstructorIsVisible = isViltCommercialOrPartnerProgramInNamer && autoAssignInstructorFeatureFlag
        setIsAutoAssignInstructorToggleVisible(autoAssignInstructorIsVisible)
    },[formValues, autoAssignInstructorFeatureFlag]);

    useEffect(() => {
        if (autoAssignInstructorFeatureFlag) {
            handleAutoAssignInstructorChange(isAutoAssignInstructorToggled && isAutoAssignInstructorToggleVisible)
        }
    },[isAutoAssignInstructorToggled, isAutoAssignInstructorToggleVisible]);

    const handleIsAssignInstructorModalVisibleChange = (isVisible: boolean) => {
        if (!isVisible) {
            setQuery(defaultQuery);
        }
        setIsAssignInstructorModalVisible(isVisible);
    };
    const size = useSelector(selectSize);
    const pagesCount: number = useSelector(selectPagesCount);
    const currentPageIndex: number = useSelector(selectCurrentPageIndex);

    useEffect(() => {
        dispatch(setLocationListRegion(userProfile?.profile.regions || []));
        dispatch(getLocationsList());
        dispatch(getTimezonesList());

        return () => {
            // reset business data slices
            // this code block should only run once
            dispatch(resetLocationsSlice());
            dispatch(resetDeliveryCountriesSlice());
            dispatch(resetProgramTypesSlice());
            dispatch(resetRegionsSlice());
            dispatch(resetGeographiesSlice());
            dispatch(resetInstructorTypesSlice());
            dispatch(resetInstructorCourseStatusesSlice());
            dispatch(resetCoursesSlice());
            dispatch(resetInstructorRoleTypesSlice());
        };
    }, [dispatch, userProfile]);

    // lifecycle method to fetch (and re-fetch) business data
    useEffect(() => {
        (lifeCycleMethods as ReadonlyArray<[boolean, Function]>).forEach(
            ([shouldFetch, getList]) => {
                if (shouldFetch) {
                    dispatch(getList());
                }
            },
        );
    });

    const defaultQuery: PropertyFilterProps.Query = React.useMemo(() => {
        return {
            tokens: [
                {
                    propertyKey: InstructorPropertyFilterKeys.CourseName,
                    operator: '=',
                    value: formValues.course_name,
                },
                {
                    propertyKey: InstructorPropertyFilterKeys.DeliveryCountry,
                    operator: '=',
                    value: formValues.delivery_country,
                },
                {
                    propertyKey: InstructorPropertyFilterKeys.DeliveryLanguage,
                    operator: '=',
                    value: formValues.delivery_language,
                },
                {
                    propertyKey: InstructorPropertyFilterKeys.DeliveryTimezone,
                    operator: '=',
                    value: formValues.delivery_timezone,
                },
                {
                    propertyKey: InstructorPropertyFilterKeys.CourseStatus,
                    operator: '=',
                    value: 'Approved',
                },
                {
                    propertyKey: InstructorPropertyFilterKeys.InstructorStatus,
                    operator: '=',
                    value: 'Authorized',
                },
            ],
            operation: 'and', // Polaris wont allow set to null for some reason
        };
    }, [formValues]);

    const [query, setQuery] =
        React.useState<PropertyFilterProps.Query>(defaultQuery);

    useEffect(() => {
        const areSessionDatesValid = () => {
            for (let item of sessionAttributeEditorItems) {
                let delivery_sessions = getDeliverySession(
                    item,
                    formValues.delivery_timezone,
                );
                if (
                    !delivery_sessions.start_timestamp ||
                    !delivery_sessions.end_timestamp
                ) {
                    // other fields can be blank and we still get timestamps
                    return false;
                }
            }

            return true;
        };

        if (areSessionDatesValid()) {
            dispatch(
                getRecommendedInstructors({
                    id: formValues.pk,
                    ...getFiltersFromQuery(query),
                    delivery_sessions: sessionAttributeEditorItems.map((item) =>
                        getDeliverySession(item, formValues.delivery_timezone),
                    ),
                    activity_source_fields: DEFAULT_ACTIVITY_SOURCE_FIELDS,
                }),
            );
        }
    }, [
        dispatch,
        formValues.pk,
        sessionAttributeEditorItems,
        formValues.delivery_timezone,
        query,
    ]);

    const handleClickAssignInstructor = () => {
        const isInvalid = areSessionsInvalid();
        if (!isInvalid) {
            setIsAssignInstructorModalVisible(true);
        }
    };

    const handleDeliveryLanguageChange = (deliveryLanguage: string) => {
        setQuery({
            tokens: [
                ...defaultQuery.tokens.filter(
                    (token) =>
                        token.propertyKey !==
                        InstructorPropertyFilterKeys.DeliveryLanguage,
                ),
                {
                    propertyKey: InstructorPropertyFilterKeys.DeliveryLanguage,
                    operator: '=',
                    value: deliveryLanguage,
                },
            ],
            operation: 'and', // Polaris wont allow set to null for some reason
        });
    };

    const assignedInstructors = new Set(
        instructorAttributeEditorItems?.map(
            (instructor) => instructor.pk as string,
        ) || [],
    );

    const handleQueryChange = (query: PropertyFilterProps.Query) => {
        dispatch(setCurrentPageIndex(1));
        dispatch(setFrom(0));
        setQuery(query);
    };

    const handlePreferencesChange = (details: any) => {
        dispatch(setSize(details.pageSize));
        dispatch(setCurrentPageIndex(1));
        dispatch(setFrom(0));

        dispatch(
            getRecommendedInstructors({
                id: formValues.pk,
                ...getFiltersFromQuery(query),
                delivery_sessions: sessionAttributeEditorItems.map((item) =>
                    getDeliverySession(item, formValues.delivery_timezone),
                ),
                activity_source_fields: DEFAULT_ACTIVITY_SOURCE_FIELDS,
            }),
        );
    };

    const handleTablePaginationChange = async (event: any) => {
        let updatedPageIndex = event;
        let from = (updatedPageIndex - 1) * size;

        dispatch(setCurrentPageIndex(updatedPageIndex));
        dispatch(setFrom(from));

        dispatch(
            getRecommendedInstructors({
                id: formValues.pk,
                ...getFiltersFromQuery(query),
                delivery_sessions: sessionAttributeEditorItems.map((item) =>
                    getDeliverySession(item, formValues.delivery_timezone),
                ),
                activity_source_fields: DEFAULT_ACTIVITY_SOURCE_FIELDS,
            }),
        );
    };

    const handleAssignInstructorEvent = (
        instructors: Array<RecommendedInstructorProfileData>,
    ) => {
        for (let instructor of instructors) {
            if (instructor.pk) {
                handleAddInstructor(instructor.pk);
            }
        }
        handleInstructorItemEvent([
            ...instructorAttributeEditorItems,
            ...(instructors.map((instructor) => ({
                name: `${instructor.full_name}`,
                pk: instructor.pk,
                email: instructor.email,
                role: null,
                type: instructor.instructor_type,
                location: formatStringArray([
                    instructor.city,
                    instructor.state_province,
                    instructor.country,
                    instructor.instructor_region,
                    instructor.geo,
                ]),
                do_not_shuffle: false,
            })) as Array<DeliveryInstructor>),
        ]);
    };

    const handleAutoAssignInstructorToggled = () => {
        setIsAutoAssignInstructorToggled(!isAutoAssignInstructorToggled);
    };

    const instructorFilteringOptions = filteringOptions({
        availability: [],
        courseList,
        deliveryLanguageList,
        deliveryCountryList,
        freelancer: [],
        locationList,
        instructorTypeList,
        programTypeList,
        timezonesList,
        geographyList,
        regionList,
        instructorCourseStatusList,
        willing_to_travel: [],
    });

    const getHighlightEvents = () => {
        const sessions = sessionAttributeEditorItems.map((item) =>
            getDeliverySession(item, formValues.delivery_timezone),
        );

        let events = buildCalendarEventsFromDeliverySessions(
            {
                sessions,
                timezone: formValues.delivery_timezone,
            },
            (sessionIndex: string) => {
                return {
                    id: `assign-highlighted-${sessionIndex}`,
                    display: 'background',
                };
            },
        );

        return events;
    };

    const paginationProps: InstructorPaginationProps = {
        onPaginationChange: handleTablePaginationChange,
        pagesCount: pagesCount,
        currentPageIndex: currentPageIndex,
        pageSize: size,
    };

    const assignInstructorModalProps: AssignInstructorModalPropsData = {
        isVisible: isAssignInstructorModalVisible,
        handleIsVisibleChange: handleIsAssignInstructorModalVisibleChange,
        events: (() => {
            return [
                ...getHighlightEvents(),
                ...recommendedInstructorsActivitiesAsEvents,
                ...instructorReservedTimes,
            ];
        })(),
        resources: recommendedInstructorsAsResources.filter((resource: any) => {
            return !assignedInstructors.has(resource.id);
        }),
        eventLookup: (() => {
            return {
                ...recommendedInstructorsActivityLookup,
                ...instructorReservedTimesLookup,
            };
        })(),
        resourceLookup: recommendedInstructorLookup,
        error: recommendedInstructorsListError,
        assignedInstructorIds: new Set(
            instructorAttributeEditorItems?.map(
                (instructor) => instructor.pk as string,
            ),
        ),
        handleAssignInstructorEvent,
        deliverySessions: sessionAttributeEditorItems.map((item) =>
            getDeliverySession(item, formValues.delivery_timezone),
        ),
        activityTimezone: formValues.delivery_timezone,
        filteringOptions: instructorFilteringOptions,
        instructorFilteringProperties,
        query,
        handleQueryChange,
        handleFieldEvent,
        areRecommendedInstructorsLoading,
        initialDate: getActivityStartDateFromSessions(
            sessionAttributeEditorItems.map((item) =>
                getDeliverySession(item, formValues.delivery_timezone),
            ),
            formValues.delivery_timezone,
        ),
        formValues,
        paginationProps,
        handlePreferencesChange,
    };

    const {
        valueLookup: deliveryLanguageLookup,
        valueOptions: deliveryLanguageOptions,
    } = getOptionsAndLookupForSelectInput<DeliveryLanguageItemData>(
        deliveryLanguageList,
        (deliveryLanguage: DeliveryLanguageItemData) => ({
            label: deliveryLanguage.delivery_language,
            id: deliveryLanguage.pk as string,
        }),
    );

    const {
        valueLookup: facilityTypeLookup,
        valueOptions: facilityTypeOptions,
    } = getOptionsAndLookupForSelectInput<DeliveryFacilityType>(
        deliveryFacilityTypeList,
        (facilityType: DeliveryFacilityType) => ({
            label: facilityType,
            id: `${facilityType}-${Date.now()}`,
        }),
    );

    const { citiesOptions, citiesOptionLookup, citiesEntityLookup } =
        locationList.reduce(
            (acc, city) => {
                const option = {
                    label: getCityString(city),
                    id: getCityString(city),
                };
                acc.citiesEntityLookup[option.id] = city;
                acc.citiesOptionLookup[option.id] = option;

                const shouldAddOptionToList = !(
                    citySearchText && option.id === preselectedCityId
                );

                if (shouldAddOptionToList) {
                    acc.citiesOptions.push(option);
                }

                return acc;
            },
            {
                citiesOptions: [] as Array<Select.Option>,
                citiesOptionLookup: {} as {
                    [key: string]: Select.Option;
                },
                citiesEntityLookup: {} as {
                    [key: string]: LocationItemData;
                },
            },
        );

    const {
        valueLookup: instructorRoleTypeLookup,
        valueOptions: instructorRoleTypeOptions,
    } = getOptionsAndLookupForSelectInput<InstructorRoleTypeItemData>(
        instructorRoleTypeList,
        (instructorRoleType: InstructorRoleTypeItemData) => ({
            label: instructorRoleType.instructor_role_type,
            id: instructorRoleType.pk as string,
        }),
    );

    const handleLocationDelayedFilteringChange = (
        e: CustomDetailEvent<Select.DelayedFilteringChangeDetail>,
    ) => {
        if (e.detail.value !== citySearchText) {
            dispatch(setLocationListSearchText(e.detail.value));
            dispatch(getLocationsList());
        }
    };

    const handleLocationChange = (
        e: CustomDetailEvent<Select.ChangeDetail>,
    ) => {
        if (e.detail.selectedOption) {
            dispatch(setLocationListSearchText(null));
            const {
                city: delivery_city,
                state: delivery_state,
                country: delivery_country,
                geo: delivery_geo,
                region: delivery_region,
                city_timezone: delivery_timezone,
            } = citiesEntityLookup[e.detail.selectedOption.id];
            handleFieldEvent({
                delivery_city,
                delivery_state,
                delivery_country,
                delivery_geo,
                delivery_region,
                ...(delivery_timezone ? { delivery_timezone } : {}),
            });
        }
    };

    const handleSessionDateChange = (
        val: string,
        sessionItem: SessionAttributeEditorItem,
        index: number,
    ) => {
        const newItems = sessionAttributeEditorItems.slice();
        newItems.splice(index, 1, {
            dateString: val,
            startTime: sessionItem.startTime ? sessionItem.startTime : '09:00',
            endTime: sessionItem.endTime ? sessionItem.endTime : '05:00',
            startTimeMeridiemFieldOption: MeridiemFieldValue.Am,
            endTimeMeridiemFieldOption: MeridiemFieldValue.Pm,
        });

        handleSessionItemEvent(newItems);
    };

    const instructorAttributeEditorDefinition: Array<AttributeEditor.FieldDefinition> =
        [
            {
                label: 'Instructor',
                control: (instructorItem: DeliveryInstructor) => (
                    <Input value={instructorItem.name} readonly={true} />
                ),
                errorText: (
                    instructorItem: DeliveryInstructor,
                    index: number,
                ) => {
                    const hasValidationResponse =
                        instructorValidationResponse.length > 0;

                    if (
                        hasValidationResponse &&
                        !getInstructorAvailabilityStatusPerInstructor(
                            instructorItem,
                            instructorValidationResponse,
                            ['do_not_shuffle', 'reserved', 'booked'],
                        )
                    ) {
                        return 'The instructor is not available.';
                    }
                },
            },
            {
                label: 'Instructor role',
                control: (
                    instructorItem: DeliveryInstructor,
                    index: number,
                ) => (
                    <Select
                        errorText={
                            'An error occurred while loading instructor roles'
                        }
                        loadingText={'Loading instructor roles'}
                        options={instructorRoleTypeOptions}
                        placeholder={'Select a role'}
                        recoveryText={'Retry'}
                        statusType={getStatusType(
                            isInstructorRoleTypeListLoading,
                            isInstructorRoleTypeListLoaded,
                            instructorRoleTypeListError,
                        )}
                        selectedOption={
                            instructorRoleTypeLookup[
                                instructorItem.role as string
                            ]
                        }
                        onChange={(e) => {
                            const newItems =
                                instructorAttributeEditorItems.slice();
                            newItems.splice(index, 1, {
                                ...instructorItem,
                                role: e.detail.selectedOption.label,
                            });
                            handleInstructorItemEvent(newItems);
                        }}
                        data-testid={`${mode}ActivityInstructorRole${index}`}
                    />
                ),
                errorText: (
                    instructorItem: DeliveryInstructor,
                    index: number,
                ) => {
                    if (
                        instructorItemErrors &&
                        instructorItemErrors[instructorItem.pk as string]?.role
                    ) {
                        return instructorItemErrors[instructorItem.pk as string]
                            .role;
                    }

                    return controlArrayErrors.instructorAttributeEditorItems?.[
                        index
                    ]?.role;
                },
            },
            {
                label: ' ',
                control: (
                    instructorItem: DeliveryInstructor,
                    index: number,
                ) => (
                    <div>
                        <Checkbox
                            checked={!!instructorItem.do_not_shuffle}
                            data-testid={`${mode}ActivityInstructorDoNotShuffle${index}`}
                            onChange={(e) => {
                                const newItems =
                                    instructorAttributeEditorItems.slice();
                                newItems.splice(index, 1, {
                                    ...instructorItem,
                                    do_not_shuffle: e.detail.checked,
                                });
                                handleInstructorItemEvent(newItems);
                            }}
                        >
                            Do not shuffle
                        </Checkbox>
                    </div>
                ),
                errorText: (
                    instructorItem: DeliveryInstructor,
                    index: number,
                ) =>
                    controlArrayErrors.instructorAttributeEditorItems?.[index]
                        ?.do_not_shuffle,
            },
        ];

    const sessionAttributeEditorDefinition: Array<AttributeEditor.FieldDefinition> =
        [
            {
                label: 'Session dates',
                control: (
                    sessionItem: SessionAttributeEditorItem,
                    index: number,
                ) => (
                    <DatePicker
                        placeholder="YYYY/MM/DD"
                        todayLabel="Today"
                        nextMonthLabel="Next month"
                        previousMonthLabel="Previous month"
                        value={
                            sessionItem.dateString ? sessionItem.dateString : ''
                        }
                        onChange={(e) => {
                            handleSessionDateChange(
                                e.detail.value,
                                sessionItem,
                                index,
                            );
                        }}
                        data-testid={`${mode}ActivityDate${index}`}
                    />
                ),
                errorText: (
                    sessionItem: SessionAttributeEditorItem,
                    index: number,
                ) =>
                    controlArrayErrors.sessionAttributeEditorItems?.[index]
                        ?.dateString,
            },
            {
                label: 'Start time',
                control: (
                    sessionItem: SessionAttributeEditorItem,
                    index: number,
                ) => (
                    <div className="time-selector">
                        <div>
                            <TimeInput
                                data-testid={`${mode}ActivityStartTime${index}`}
                                format="hh:mm"
                                placeholder="hh:mm"
                                use24Hour={false}
                                value={
                                    sessionItem.startTime
                                        ? sessionItem.startTime
                                        : ''
                                }
                                onChange={(e) => {
                                    const newItems =
                                        sessionAttributeEditorItems.slice();
                                    newItems.splice(index, 1, {
                                        ...sessionItem,
                                        startTime: e.detail.value,
                                    });
                                    handleSessionItemEvent(newItems);
                                }}
                            />
                        </div>
                        <div>
                            <Select
                                options={meridiemFieldOptions}
                                selectedOption={
                                    sessionItem.startTimeMeridiemFieldOption
                                        ? meridiemFieldOptionLookup[
                                              sessionItem
                                                  .startTimeMeridiemFieldOption
                                          ]
                                        : meridiemFieldOptionLookup[
                                              MeridiemFieldValue.Am
                                          ]
                                }
                                onChange={(e) => {
                                    const newItems =
                                        sessionAttributeEditorItems.slice();
                                    newItems.splice(index, 1, {
                                        ...sessionItem,
                                        startTimeMeridiemFieldOption: e.detail
                                            .selectedOption
                                            .label as MeridiemFieldValue,
                                    });
                                    handleSessionItemEvent(newItems);
                                }}
                                data-testid={`${mode}ActivityEnd${index}`}
                            />
                        </div>
                    </div>
                ),
                errorText: (
                    sessionItem: SessionAttributeEditorItem,
                    index: number,
                ) =>
                    controlArrayErrors.sessionAttributeEditorItems?.[index]
                        ?.startTime ||
                    controlArrayErrors.sessionAttributeEditorItems?.[index]
                        ?.startTimeMeridiemFieldOption,
            },
            {
                label: 'End time',
                control: (
                    sessionItem: SessionAttributeEditorItem,
                    index: number,
                ) => (
                    <div className="time-selector">
                        <div>
                            <TimeInput
                                data-testid={`${mode}ActivityEndTime${index}`}
                                format="hh:mm"
                                placeholder="hh:mm"
                                use24Hour={false}
                                value={
                                    sessionItem.endTime
                                        ? sessionItem.endTime
                                        : ''
                                }
                                onChange={(e) => {
                                    const newItems =
                                        sessionAttributeEditorItems.slice();
                                    newItems.splice(index, 1, {
                                        ...sessionItem,
                                        endTime: e.detail.value,
                                    });
                                    handleSessionItemEvent(newItems);
                                }}
                            />
                        </div>
                        <div>
                            <Select
                                options={meridiemFieldOptions}
                                selectedOption={
                                    sessionItem.endTimeMeridiemFieldOption
                                        ? meridiemFieldOptionLookup[
                                              sessionItem
                                                  .endTimeMeridiemFieldOption
                                          ]
                                        : meridiemFieldOptionLookup[
                                              MeridiemFieldValue.Am
                                          ]
                                }
                                onChange={(e) => {
                                    const newItems =
                                        sessionAttributeEditorItems.slice();
                                    newItems.splice(index, 1, {
                                        ...sessionItem,
                                        endTimeMeridiemFieldOption: e.detail
                                            .selectedOption
                                            .label as MeridiemFieldValue,
                                    });
                                    handleSessionItemEvent(newItems);
                                }}
                                data-testid={`${mode}ActivityEnd${index}`}
                            />
                        </div>
                    </div>
                ),
                errorText: (
                    sessionItem: SessionAttributeEditorItem,
                    index: number,
                ) =>
                    controlArrayErrors.sessionAttributeEditorItems?.[index]
                        ?.endTime ||
                    controlArrayErrors.sessionAttributeEditorItems?.[index]
                        ?.endTimeMeridiemFieldOption,
            },
        ];

    return (
        <>
            <FormSection
                data-testid="EditDeliveryDetailsFormSection"
                header="Delivery details"
            >
                <ColumnLayout>
                    <div data-awsui-column-layout-root="true">
                        <FormField
                            errorText={errors?.course_name}
                            label={
                                <div className="awsui-util-status-inactive">
                                    Course
                                </div>
                            }
                        >
                            {formValues.course_name}
                        </FormField>
                        {isIltOrHybrid && (
                            <>
                                <FormField
                                    label={
                                        <span>
                                            Facility type <i>- optional</i>
                                        </span>
                                    }
                                    errorText={errors?.delivery_facility_type}
                                >
                                    <Select
                                        className={
                                            errors?.delivery_facility_type &&
                                            FORM_ERROR_SELECTOR
                                        }
                                        placeholder="Select a facility type"
                                        options={facilityTypeOptions}
                                        selectedOption={
                                            !!formValues.delivery_facility_type
                                                ? facilityTypeLookup[
                                                      formValues
                                                          .delivery_facility_type
                                                  ]
                                                : null
                                        }
                                        onChange={(e) =>
                                            handleFieldEvent({
                                                delivery_facility_type:
                                                    e.detail.selectedOption
                                                        .label,
                                            })
                                        }
                                        data-testid={`${mode}FacilityType`}
                                    />
                                </FormField>
                                <FormField
                                    label={
                                        <span>
                                            Facility size <i>- optional</i>
                                        </span>
                                    }
                                    errorText={errors?.facility_size}
                                >
                                    <Input
                                        className={
                                            errors?.facility_size &&
                                            FORM_ERROR_SELECTOR
                                        }
                                        value={`${formValues.facility_size}`}
                                        type="number"
                                        placeholder="Enter a facility size"
                                        onInput={(e) =>
                                            handleFieldEvent({
                                                facility_size: !!e.detail.value
                                                    ? parseFloat(e.detail.value)
                                                    : null,
                                            })
                                        }
                                        data-testid={`${mode}FacilitySize`}
                                    />
                                </FormField>
                                <FormField
                                    label={<span>Delivery address 1</span>}
                                    errorText={errors?.delivery_address_1}
                                >
                                    <Input
                                        className={
                                            errors?.delivery_address_1 &&
                                            FORM_ERROR_SELECTOR
                                        }
                                        value={formValues.delivery_address_1}
                                        onInput={(e) =>
                                            handleFieldEvent({
                                                delivery_address_1:
                                                    e.detail.value,
                                            })
                                        }
                                        data-testid={`${mode}DeliveryAddress1`}
                                    />
                                </FormField>
                                <FormField
                                    label={
                                        <span>
                                            Delivery address 2 <i>- optional</i>
                                        </span>
                                    }
                                    errorText={errors?.delivery_address_2}
                                >
                                    <Input
                                        className={
                                            errors?.delivery_address_2 &&
                                            FORM_ERROR_SELECTOR
                                        }
                                        value={formValues.delivery_address_2}
                                        onInput={(e) =>
                                            handleFieldEvent({
                                                delivery_address_2:
                                                    e.detail.value,
                                            })
                                        }
                                        data-testid={`${mode}DeliveryAddress2`}
                                    />
                                </FormField>
                                <FormField
                                    label={<span>Delivery postal code</span>}
                                    errorText={errors?.delivery_postal_code}
                                >
                                    <Input
                                        className={
                                            errors?.delivery_postal_code &&
                                            FORM_ERROR_SELECTOR
                                        }
                                        value={formValues.delivery_postal_code}
                                        onInput={(e) =>
                                            handleFieldEvent({
                                                delivery_postal_code:
                                                    e.detail.value,
                                            })
                                        }
                                        data-testid={`${mode}DeliveryPostalCode`}
                                    />
                                </FormField>
                                <FormField
                                    label={
                                        <span>
                                            Room <i>- optional</i>
                                        </span>
                                    }
                                    errorText={errors?.room}
                                >
                                    <Input
                                        className={
                                            errors?.room && FORM_ERROR_SELECTOR
                                        }
                                        value={formValues.room}
                                        onInput={(e) =>
                                            handleFieldEvent({
                                                room: e.detail.value,
                                            })
                                        }
                                        data-testid={`${mode}Room`}
                                    />
                                </FormField>
                            </>
                        )}
                        <FormField
                            label="Delivery location"
                            errorText={
                                errors?.delivery_city ||
                                errors?.delivery_region ||
                                errors?.delivery_geo ||
                                errors?.delivery_country
                            }
                        >
                            <Select
                                className={
                                    (errors?.delivery_city ||
                                        errors?.delivery_region ||
                                        errors?.delivery_geo ||
                                        errors?.delivery_country) &&
                                    FORM_ERROR_SELECTOR
                                }
                                placeholder={
                                    mode === FormSectionMode.Edit &&
                                    isLocationListLoading
                                        ? LocationFormFieldText.Loading
                                        : LocationFormFieldText.Placeholder
                                }
                                empty={LocationFormFieldText.Empty}
                                loadingText={LocationFormFieldText.Loading}
                                filteringType="manual"
                                errorText={LocationFormFieldText.Error}
                                recoveryText={LocationFormFieldText.Recovery}
                                statusType={getStatusType(
                                    isLocationListLoading,
                                    isLocationListLoaded,
                                    locationListError,
                                )}
                                options={citiesOptions}
                                selectedOption={(() => {
                                    const cityString =
                                        getActivityCityString(formValues);
                                    return cityString
                                        ? citiesOptionLookup[cityString]
                                        : null;
                                })()}
                                filteringPlaceholder={
                                    LocationFormFieldText.FilteringPlaceholder
                                }
                                onDelayedFilteringChange={
                                    handleLocationDelayedFilteringChange
                                }
                                onChange={handleLocationChange}
                                onRecoveryClick={() => {
                                    dispatch(setLocationListSearchText(''));
                                    dispatch(resetPartialLocationsSlice());
                                    dispatch(getLocationsList());
                                }}
                                data-testid={`${mode}ActivityCity`}
                                disabled={
                                    mode === FormSectionMode.Edit &&
                                    !isLocationListLoaded
                                }
                            />
                        </FormField>
                        <FormField
                            label={
                                <div className="awsui-util-status-inactive">
                                    Timezone
                                </div>
                            }
                        >
                            {formValues.delivery_timezone}
                        </FormField>
                        <FormField>
                            <AttributeEditor
                                data-testid={`${mode}SessionAttributeEditor`}
                                empty="No session dates have been added"
                                addButtonText="Add date"
                                removeButtonText="Remove"
                                items={sessionAttributeEditorItems}
                                definition={sessionAttributeEditorDefinition}
                                onAddButtonClick={() =>
                                    handleSessionItemEvent([
                                        ...sessionAttributeEditorItems,
                                        {
                                            dateString: '',
                                            startTime: '',
                                            endTime: '',
                                            endTimeMeridiemFieldOption:
                                                MeridiemFieldValue.Am,
                                            startTimeMeridiemFieldOption:
                                                MeridiemFieldValue.Am,
                                        },
                                    ])
                                }
                                onRemoveButtonClick={({
                                    detail: { itemIndex },
                                }) => {
                                    const newList =
                                        sessionAttributeEditorItems.slice();
                                    newList.splice(itemIndex, 1);
                                    handleSessionItemEvent(newList);
                                }}
                                isItemRemovable={() =>
                                    sessionAttributeEditorItems.length > 1
                                }
                            />
                        </FormField>
                        <FormField
                            errorText={errors?.delivery_language}
                            label="Delivery language"
                        >
                            <Select
                                className={
                                    errors?.delivery_language &&
                                    FORM_ERROR_SELECTOR
                                }
                                placeholder={
                                    isDeliveryLanguageListLoading
                                        ? 'Loading delivery languages'
                                        : 'Select a delivery language'
                                }
                                options={deliveryLanguageOptions}
                                selectedOption={
                                    deliveryLanguageLookup[
                                        formValues.delivery_language
                                    ]
                                }
                                onChange={(e) => {
                                    handleFieldEvent({
                                        delivery_language:
                                            e.detail.selectedOption.label,
                                    });
                                    handleDeliveryLanguageChange(
                                        e.detail.selectedOption.label,
                                    );
                                }}
                                onRecoveryClick={() => {
                                    dispatch(resetDeliveryLanguagesSlice());
                                    dispatch(getDeliveryLanguagesList());
                                }}
                                loadingText="Loading delivery Languages"
                                errorText="An error occurred while loading audiences"
                                recoveryText="Retry"
                                empty="No delivery languages found"
                                statusType={getStatusType(
                                    isDeliveryLanguageListLoading,
                                    isDeliveryLanguageListLoaded,
                                    deliveryLanguageListError,
                                )}
                                data-testid={`${mode}DeliveryLanguages`}
                                disabled={
                                    mode === FormSectionMode.Edit &&
                                    !isDeliveryLanguageListLoaded
                                }
                            />
                        </FormField>
                        <div className="grimsby-sub-section-divider" />
                        <div className="grimsby-sub-section-header">
                            Instructors
                        </div>
                        {isAutoAssignInstructorToggleVisible && (
                            <FormField>
                                <Toggle
                                    onChange={handleAutoAssignInstructorToggled}
                                    checked={isAutoAssignInstructorToggled}
                                    data-testid="auto_assign_toggle"
                                >
                                    Auto Assign Instructor
                                </Toggle>
                            </FormField>
                        )}
                        <FormField>
                            <AttributeEditor
                                data-testid={`${mode}AssignInstructorAttributeEditor`}
                                addButtonText="Assign instructor"
                                removeButtonText="Remove"
                                items={instructorAttributeEditorItems}
                                definition={instructorAttributeEditorDefinition}
                                onAddButtonClick={() =>
                                    handleClickAssignInstructor()
                                }
                                onRemoveButtonClick={({
                                    detail: { itemIndex },
                                }) => {
                                    const newList =
                                        instructorAttributeEditorItems.slice();
                                    newList.splice(itemIndex, 1);
                                    handleInstructorItemEvent(newList);
                                }}
                                empty={`No instructors have been assigned`}
                            />
                        </FormField>
                    </div>
                </ColumnLayout>
            </FormSection>
            <AssignInstructorModal {...assignInstructorModalProps} />
        </>
    );
};

export default EditDeliveryDetailsFormSection;
