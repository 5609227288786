import React from 'react';

import { UserFormData } from '../../../interfaces/userForm';
import AdminUserManagementCreateForm from './AdminUserManagementCreateForm';

const createUserFormState: UserFormData = {
    programs: [],
    regions: [],
    email: '',
    vias_roles: [],
};

const AdminUserManagementCreate = () => (
    <AdminUserManagementCreateForm createUserFormState={createUserFormState} />
);

export default AdminUserManagementCreate;
