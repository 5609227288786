import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DomainSkillsResponseData } from '../../interfaces/businessDataResponse/domainSkillsResponse';
import { HandleRequestData } from '../../interfaces/handleRequest';
import businessDataApi from '../../api/businessDataApi';
import { AppDispatch, GlobalState } from '../../../main/store';
import { BusinessDataStoreState } from '../../interfaces/businessDataStoreState';
import { DomainSkillItemData } from '../../interfaces/businessDataItem/domainSkillItem';
import parseBoolean from '../../utils/parseBoolean';
import {
    BusinessDataStoreInitialState,
    getActiveBusinessDataValues,
    resetBasicBusinessDataStoreState,
} from '../store.common';

/**
 * domainSkillsSlice manages all domainSkills state, and contains domainSkills actions as well as domainSkills state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const domainSkillsSlice = createSlice({
    name: 'domainSkills',
    initialState: {
        ...BusinessDataStoreInitialState,
    } as BusinessDataStoreState<DomainSkillItemData>,
    reducers: {
        setDomainSkillsList: (
            state,
            action: PayloadAction<DomainSkillItemData[]>,
        ) => {
            const byDomainSkillName = action.payload.reduce(
                (
                    byDomainSkillName: {
                        [key: string]: DomainSkillItemData;
                    },
                    domainSkill: DomainSkillItemData,
                ) => {
                    byDomainSkillName[domainSkill.domain_skill] = {
                        ...domainSkill,
                        active: parseBoolean(domainSkill.active),
                    };
                    return byDomainSkillName;
                },
                {},
            );
            state.entities = byDomainSkillName;
            state.keysList = Object.keys(byDomainSkillName);
        },
        setDomainSkill: (state, action: PayloadAction<DomainSkillItemData>) => {
            // this reducer may be used when adding a new domain skill or updating an existing one.
            // only add to keysList if adding a new domain skill
            if (!state.entities[action.payload.domain_skill]) {
                state.keysList = [
                    ...state.keysList,
                    action.payload.domain_skill,
                ];
                state.count = state.keysList.length;
            }
            state.entities[action.payload.domain_skill] = action.payload;
        },
        setSelectedDomainSkill: (
            state,
            action: PayloadAction<string | null>,
        ) => {
            state.selectedItemKey = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
        setCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload;
        },
        resetDomainSkillsSlice: resetBasicBusinessDataStoreState,
    },
});

export const {
    setIsLoading,
    setIsLoaded,
    setDomainSkillsList,
    setDomainSkill,
    setSelectedDomainSkill,
    setError,
    setCount,
    resetDomainSkillsSlice,
} = domainSkillsSlice.actions;

const handleDomainSkillsListRequest = () => async (dispatch: AppDispatch) => {
    try {
        const {
            result: { DOMAIN_SKILL, total_count },
        }: HandleRequestData<DomainSkillsResponseData> = await businessDataApi.getDomainSkills(
            { active: 'all' },
        );
        dispatch(setDomainSkillsList(DOMAIN_SKILL));
        dispatch(setCount(total_count));
    } catch (error: any) {
        dispatch(setError(error?.message || 'getDomainSkills API error'));
    }
};

export const getDomainSkillsList = () => async (
    dispatch: AppDispatch,
    getState: () => GlobalState,
) => {
    const state = getState();
    dispatch(setIsLoading(true));
    await dispatch(handleDomainSkillsListRequest());
    if (!state.domainSkills.isLoaded) {
        dispatch(setIsLoaded(true));
    }
    dispatch(setIsLoading(false));
};

/**
 * action to update domain skill
 * before update api call, fetch domain skill types and prepare payload containing only the changed fields
 * after update api call, fetch domain skill types and update the current item in that keysList with the item data we updated
 * @param data
 */
export const updateDomainSkill = (data: DomainSkillItemData) => async (
    dispatch: AppDispatch,
    getState: () => GlobalState,
): Promise<boolean> => {
    const state = getState();
    // immediately put the keysList into loading while we finish updating the item/fetching the keysList
    dispatch(setIsLoading(true));
    if (!data.pk || data?.pk.length <= 0) {
        // eslint-disable-next-line no-console
        console.error(
            `Error updating new domain skill: required field pk was missing`,
        );
        dispatch(setIsLoading(false));
        return false;
    }
    try {
        await businessDataApi.updateDomainSkill(data.pk, data.active);
        await dispatch(handleDomainSkillsListRequest());
        // force-update the item in the keysList for now, since the keysList we fetched may not have this item's update yet
        dispatch(
            setDomainSkill({
                ...state.domainSkills.entities[data.domain_skill],
                active: data.active,
            }),
        );
        dispatch(setIsLoading(false));
        return true;
    } catch (error: any) {
        dispatch(setIsLoading(false));
        return false;
    }
};

export const addDomainSkill = (
    active = true,
    domainSkillName: string,
) => async (
    dispatch: AppDispatch,
    getState: () => GlobalState,
): Promise<boolean> => {
    const state = getState();
    dispatch(setIsLoading(true));
    try {
        await businessDataApi.addDomainSkill(domainSkillName, active);
        // now refresh the keysList
        await dispatch(handleDomainSkillsListRequest());
        if (!state.domainSkills?.entities[domainSkillName]) {
            // shove the item into the keysList, but only if it's not there already
            const newDomainSkillData: DomainSkillItemData = {
                domain_skill: domainSkillName,
                active: active,
            };
            dispatch(setDomainSkill(newDomainSkillData));
        }
        dispatch(setIsLoading(false));
        return true;
    } catch (error: any) {
        dispatch(setIsLoading(false));
        return false;
    }
};

export const selectAllDomainSkills = (state: GlobalState) =>
    state.domainSkills.keysList.map(
        (domainSkillName) => state.domainSkills.entities[domainSkillName],
    );

export const selectAllActiveDomainSkills = (
    state: GlobalState,
): Array<DomainSkillItemData> => {
    return getActiveBusinessDataValues(
        state.domainSkills.keysList,
        state.domainSkills.entities,
    );
};
export const selectIsLoading = (state: GlobalState) =>
    state.domainSkills.isLoading;

export const selectIsLoaded = (state: GlobalState) =>
    state.domainSkills.isLoaded;

export const selectSelectedDomainSkill = (state: GlobalState) =>
    state?.domainSkills?.selectedItemKey
        ? state?.domainSkills?.entities[state?.domainSkills?.selectedItemKey]
        : null;

export const selectError = (state: GlobalState) => state.domainSkills.error;

export const selectCount = (state: GlobalState) => state.domainSkills.count;

export default domainSkillsSlice.reducer;
