export const ADMIN_PATH = {
    INSTRUCTOR_ROLE_TYPES: '/admin/business-data/instructor-role-types',
    COST_TYPES: '/admin/business-data/cost-types',
    REVENUE_TYPES: '/admin/business-data/revenue-types',
    BASE: '/admin',
    USER_MANAGEMENT: '/admin/user-management',
    USER_LIST: '/admin/user-management/users',
    USER_CREATE: '/admin/user-management/users/create',
    USER_DETAIL: '/admin/user-management/users/:id',
    USER_EDIT: '/admin/user-management/users/:id/edit',
    BUSINESS_DATA: '/admin/business-data',
    INSTRUCTOR_TYPES: '/admin/business-data/instructor-types',
    INSTRUCTOR_STATUSES: '/admin/business-data/instructor-statuses',
    INSTRUCTOR_COURSE_STATUSES:
        '/admin/business-data/instructor-course-statuses',
    PROGRAM_TYPES: '/admin/business-data/program-types',
    ATP_COMPANIES: '/admin/business-data/atp-companies',
    DELIVERY_COUNTRIES: '/admin/business-data/delivery-countries',
    CERTIFICATIONS: '/admin/business-data/certifications',
    COURSES: '/admin/business-data/courses',
    FREELANCER_STATUSES: '/admin/business-data/freelancer-statuses',
    DOMAIN_SKILLS: '/admin/business-data/domain-skills',
    DELIVERY_LANGUAGES: '/admin/business-data/delivery-languages',
    LOCATIONS: '/admin/business-data/locations',
    ACTIVITY_AUDIENCES: '/admin/business-data/activity-audiences',
    ACTIVITY_STATUSES: '/admin/business-data/activity-statuses',
    ACTIVITY_MODALITIES: '/admin/business-data/activity-modalities',
    ACTIVITY_TYPES: '/admin/business-data/activity-types',
} as const;
