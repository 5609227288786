export const AJAX_CALL_ERROR = 'Something went wrong. Please try again.';
export const APP_NAME = 'GRIMSBY';
export const AUDIT_EMPTY = 'No Audit Log';
export const ACTIVITY_TABLE_TITLE = 'Activities';
export const CREATE_ACTIVITY_BUTTON = 'Add activity';
export const IMPORT_ACTIVITIES_BUTTON = 'Import activities';
export const AUDIT_PAGE_SIZE = 25;
export const BADGE_PHOTO_URL =
    'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=';
export const CREATE_BUTTON = 'Add instructor';
export const EDIT_BUTTON = 'Edit';
export const ERROR_404 = '404 Page Not Found';
export const INSTRUCTOR_TABLE_TITLE = 'Instructors';
export const ACTIVITIES_TABLE_TITLE = 'Activities';
export const LOADING_TEXT = 'Loading..';
export const LOAD_MORE_TEXT = 'Load More';
export const PHONETOOL_URL = 'https://phonetool.amazon.com/users/';
export const GRIMSBY_PAGE_COUNT = 25;
export const GRIMSBY_FILTER_RESULTS_COUNT = 10;
export const EMPTY_STRING = '-';
export const FEEDBACK_TEXT = 'Feedback';
export const FEEDBACK_URL =
    'https://w.amazon.com/bin/view/LSO_Business_Operations_Tools/GRIMSBY/product/give-feedback/';
export const USER_GUIDE_TEXT = 'User Guide';
export const USER_GUIDE_URL =
    'https://w.amazon.com/bin/view/AWS_Training_and_Certification/Product_Development/ILT/Grimsby/User_Guide';
export const CHANGE_LOG_TEXT = 'Change Log';
export const CHANGE_LOG_URL =
    'https://w.amazon.com/bin/view/LSO_Business_Operations_Tools/GRIMSBY/ChangeLog/';
export const INSTRUCTOR_TYPE_DETAILS_DESC =
    'Please provide additional details for this instructor type';

export enum SectionContentType {
    Column = 'ColumnContentData',
    Paragraph = 'ParagraphContentData',
    Generic = 'GenericContentData',
    TieredColumn = 'TieredColumnContentData',
}

// TODO: convert this to Enum
export const INSTRUCTOR_ADMIN_GROUP = 'AwsBotsGrimsby-instructor-administrator';
export const SYS_ADMIN_GROUP = 'AwsBotsGrimsby-system-administrator';
export const SCHEDULE_ADMIN_GROUP = 'AwsBotsGrimsby-schedule-administrator';
export const SCHEDULE_READ_ONLY = 'AwsBotsGrimsby-schedule-readonly';
export const INSTRUCTOR_READ_ONLY = 'AwsBotsGrimsby-read-only';

export const GANDALF_INSTRUCTOR_ADMIN_GROUP =
    'InstructorManagementAdministrator';
export const GANDALF_SYS_ADMIN_GROUP = 'SystemAdministrator';
export const GANDALF_SCHEDULE_ADMIN_GROUP = 'ScheduleManagementAdministrator';
export const GANDALF_SCHEDULE_READ_ONLY = 'ScheduleManagementReadOnly';
export const GANDALF_INSTRUCTOR_READ_ONLY = 'InstructorManagementReadOnly';

export type PermissionGroups =
    | typeof INSTRUCTOR_ADMIN_GROUP
    | typeof SYS_ADMIN_GROUP
    | typeof SCHEDULE_ADMIN_GROUP
    | typeof SCHEDULE_READ_ONLY
    | typeof INSTRUCTOR_READ_ONLY
    | typeof GANDALF_INSTRUCTOR_ADMIN_GROUP
    | typeof GANDALF_SYS_ADMIN_GROUP
    | typeof GANDALF_SCHEDULE_ADMIN_GROUP
    | typeof GANDALF_SCHEDULE_READ_ONLY
    | typeof GANDALF_INSTRUCTOR_READ_ONLY;

export const API_MAIN_PATH = {
    ADMIN_USER_MANAGEMENT: 'admin-user-management-v2',
    SCHEDULE_MANAGEMENT: 'schedule-management-v2',
    USER_MANAGEMENT: 'user-management-v2',
    INSTRUCTOR_MANAGEMENT: 'instructor-management-v2',
    AUDIT_MANAGEMENT: 'audit-management-v2',
    BUSINESS_DATA_MANAGEMENT: 'business-data-management-v2',
    LOGGING_API: 'logging-service-v2',
    LMS_MANAGEMENT: 'lms-management-v2',
};

export const API_MAIN_PATH_V2 = {
    ADMIN_USER_MANAGEMENT: 'admin-user-management-v2',
    SCHEDULE_MANAGEMENT: 'schedule-management-v2',
    USER_MANAGEMENT: 'user-management-v2',
    INSTRUCTOR_MANAGEMENT: 'instructor-management-v2',
    AUDIT_MANAGEMENT: 'audit-management-v2',
    BUSINESS_DATA_MANAGEMENT: 'business-data-management-v2',
    LOGGING_API: 'logging-service-v2',
    LMS_MANAGEMENT: 'lms-management-v2',
};

export const MAX_RETRY_TIME = 5;
export const RETRY_DELAY = 1000;

export const PAGE_SELECTOR_OPTIONS = [
    { value: 25, label: '25 results' },
    { value: 50, label: '50 results' },
    { value: 100, label: '100 results' },
    { value: 200, label: '200 results' },
];

export const INVOICE_PAYMENT_TERM_KEYS = new Map([
    ['30 NET', '30 Days'],
    ['45 NET', '45 Days'],
    ['60 NET', '60 Days'],
    ['90 NET', '90 Days'],
]);
