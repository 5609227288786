import { Feature } from '../interfaces/navigation';
import { FeatureFlags } from '../interfaces/featureFlags';

export const checkFeature = (
    user: string,
    feature: Feature,
    features: FeatureFlags | undefined,
): boolean => {
    if (!features) {
        return true;
    }
    const featureKeys = Object.keys(features);

    if (!feature.featureName) {
        return true;
    } else {
        if (!featureKeys.includes(feature.featureName)) {
            return true;
        }

        if (features[feature.featureName]?.users === '*') {
            return true;
        }

        if (
            features[feature.featureName]?.open_hosts &&
            features[feature.featureName]?.open_hosts?.includes(
                window.location.hostname,
            )
        ) {
            return true;
        }

        if (
            features[feature.featureName]?.stages &&
            features[feature.featureName]?.stages?.includes(
                window.location.hostname.split('.')[0]
            )
        ) {
            return true;
        }

        return features[feature.featureName]?.users?.includes(user) as boolean;
    }
};
