import {
    Modal,
    ColumnLayout,
    FormField,
    DatePicker,
    AttributeEditor,
    TimeInput,
    Textarea,
    Select,
} from '@amzn/awsui-components-react';
import {
    Alert,
    Box,
    Button,
    SpaceBetween,
    Toggle,
} from '@amzn/awsui-components-react-v3';
import FullCalendar from '@fullcalendar/react';
import React, { useCallback, useEffect, useState } from 'react';
import {
    BlockedTimeFormData,
    BlockedTimeType,
    BlockedTime,
    BlockTimeTimeAttributeEditorItem,
    BlockedTimeTimestamp,
} from '../../../../common/interfaces/blockedTime';
import useFormValidation, {
    ValidationType,
} from '../../../../common/utils/formValidation';
import { LookupData } from '../../../interfaces/lookup';
import {
    meridiemFieldOptionLookup,
    meridiemFieldOptions,
    MeridiemFieldValue,
} from '../../../interfaces/meridiem';
import { RecommendedInstructorProfileData } from '../../../interfaces/recommendedInstructorProfile';
import { buildEditTimesForInstructorType } from '../../../services/schedule-service';
import './BlockedTimeModal.scss';
import { mapTimestampsToCalendarView } from '../../../services/calendar-service';
import BlockedTimeModalFooter, {
    BlockedTimeModalFooterProps,
} from './BlockedTimeModalFooter';
import { formatUnixTimeInTimezone } from '../../../../common/utils/date-time.utils';

export const BLOCK_TIME_TEXT = 'Block time';
export const MIXED_INSTRUCTOR_ALERT =
    'Blocked times cannot be added to a mix of internal and freelancer instructors. Please select one group to continue.';
export const CONTINUE_WITH_INTERNAL_INSTRUCTORS_BTN =
    'Continue with internal instructors';
export const CONTINUE_WITH_FREELANCE_INSTRUCTORS_BTN =
    'Continue with freelancer instructors';

export interface BlockedTimeModalPropsData {
    isVisible: boolean;
    handleIsVisibleChange: (isVisible: boolean) => void;
    calendarRef: FullCalendar | undefined;
    handleBlockedTimeSubmit: ({
        blockedTimeFormValues,
        clearBlockedTimeValues,
    }: {
        blockedTimeFormValues: BlockedTimeFormData;
        clearBlockedTimeValues: () => void;
    }) => void;
    selectedInstructors: Set<RecommendedInstructorProfileData>;
    handleSelectedInstructorsChange: (
        selectedInstructors: Set<RecommendedInstructorProfileData>,
    ) => void;
    setSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
    submitting: boolean;
    blockedTime: BlockedTime;
    handleBlockedTimeEdit: (
        blockedTimeFormValues: BlockedTimeFormData,
        clearBlockedTimeValues: () => void,
        forceDelete: boolean,
    ) => Promise<void | Error>;
    handleBlockedTimeDelete: (
        blockTimeFormValues: BlockedTimeFormData,
        clearBlockedTimeValues: () => void,
    ) => Promise<void>;
    eventSource: BlockedTime;
    setBlockedTime: React.Dispatch<React.SetStateAction<BlockedTime>>;
    blockedTimeErrors: any;
    setBlockedTimeErrors: React.Dispatch<React.SetStateAction<[]>>;
    instructorLookup: LookupData<RecommendedInstructorProfileData>;
}

interface BlockedTimesControlArrayFormValues {
    blockedTimeAttributeEditorItems: BlockTimeTimeAttributeEditorItem;
}

const BlockedTimeModal = ({
    handleIsVisibleChange,
    handleBlockedTimeSubmit,
    handleBlockedTimeDelete,
    handleBlockedTimeEdit,
    handleSelectedInstructorsChange,
    setSubmitting,
    isVisible,
    submitting,
    eventSource,
    blockedTime,
    setBlockedTime,
    blockedTimeErrors,
    setBlockedTimeErrors,
    instructorLookup,
    calendarRef,
    selectedInstructors,
}: BlockedTimeModalPropsData) => {
    const initialFormAttributeEditorState = {
        id: '',
        startTime: '',
        endTime: '',
        startDateString: '',
        endDateString: '',
        endTimeMeridiemFieldOption: MeridiemFieldValue.Pm,
        startTimeMeridiemFieldOption: MeridiemFieldValue.Am,
        removeFlag: false,
    };

    const initialFormState = {
        pk: '',
        blocked_time_type: BlockedTimeType.UNAVAILABLE,
        blocked_time_name: '',
        blocked_time_notes: '',
        blocked_time_timestamps: [],
        instructor_pk: '',
        blockedTimes: [] as Array<BlockTimeTimeAttributeEditorItem>,
    };

    const [formValues, setFormValues] =
        useState<BlockedTimeFormData>(initialFormState);

    const [isEditForm, setIsEditForm] = useState(false);
    const [isFreelancer, setIsFreelancer] = useState(false);
    const [isDateRangeToggled, setDateRangeToggled] = useState(false);
    const [isDateRange, setIsDateRange] = useState(false);
    const [
        blockedTimeAttributeEditorItems,
        setBlockedTimeAttributeEditorItems,
    ] = useState<Array<BlockTimeTimeAttributeEditorItem>>([
        initialFormAttributeEditorState,
    ]);

    const {
        validateForm,
        errors,
        controlArrayErrors,
        validateFormControlArray,
        isInvalid,
        isControlArrayInvalid,
    } = useFormValidation<Partial<BlockedTime>>();

    const checkEditTimesAsDateRange = (
        timestamps: BlockedTimeTimestamp[],
        timezone: string,
    ) => {
        if (timestamps.length === 1) {
            const timestamp = timestamps[0];
            const startTime = formatUnixTimeInTimezone({
                timestamp: timestamp.start_timestamp,
                timezone,
                format: 'MM-DD-YYY',
            });
            const endTime = formatUnixTimeInTimezone({
                timestamp: timestamp.end_timestamp,
                timezone,
                format: 'MM-DD-YYY',
            });
            return startTime !== endTime;
        } else {
            return false;
        }
    };

    const clearBlockedTimeValues = () => {
        setBlockedTimeAttributeEditorItems([initialFormAttributeEditorState]);
        setBlockedTime(() => ({} as BlockedTime));
        setFormValues((values) => ({
            ...values,
            ...initialFormState,
        }));
        setBlockedTimeErrors([]);
    };

    const handleFieldEvent = useCallback((changes: Partial<BlockedTime>) => {
        setFormValues((values) => ({
            ...values,
            ...changes,
        }));
    }, []);

    const formatSelectedInstructors = () => {
        return Array.from(selectedInstructors)
            .map((item) => item.full_name)
            .join(', ');
    };

    const hasMixedInstructors = React.useMemo(() => {
        let hasInternal = false;
        let hasFreelancer = false;

        for (let instructor of Array.from(selectedInstructors)) {
            if (instructor.is_freelancer) {
                hasFreelancer = true;
            } else {
                hasInternal = true;
            }

            if (hasFreelancer && hasInternal) {
                break;
            }
        }

        return hasFreelancer && hasInternal;
    }, [selectedInstructors]);

    const handleBlockedTimeDateItemEvent = (
        blockedTimes: Array<BlockTimeTimeAttributeEditorItem>,
    ) => {
        setBlockedTimeAttributeEditorItems(blockedTimes);
        setFormValues((values) => ({
            ...values,
            blockedTimes,
        }));
    };

    const handleReserveTimeDateChange = (val: string, index: number) => {
        const blockedTimes = blockedTimeAttributeEditorItems.slice();
        blockedTimes.splice(index, 1, {
            id: '',
            startDateString: val,
            endDateString: val,
            startTime: '09:00',
            endTime: '05:00',
            startTimeMeridiemFieldOption: MeridiemFieldValue.Am,
            endTimeMeridiemFieldOption: MeridiemFieldValue.Pm,
            removeFlag: false,
        });
        handleBlockedTimeDateItemEvent(blockedTimes);
    };

    const handleUnavailableTimeDateChange = (
        val: string,
        index: number,
        type: string,
    ) => {
        const blockedTimes = blockedTimeAttributeEditorItems.slice();
        const blockedTime = blockedTimes[index];
        const startTime = '12:00';
        const endTime = '11:59:59';
        const startDateString =
            type === 'startDateString' ? val : blockedTime.startDateString;
        let endDateString;
        if (isDateRange) {
            endDateString =
                type === 'endDateString' ? val : blockedTime.endDateString;
        } else {
            endDateString = val;
        }

        blockedTimes.splice(index, 1, {
            ...blockedTime,
            startDateString,
            endDateString,
            startTime: startTime,
            endTime: endTime,
            startTimeMeridiemFieldOption: MeridiemFieldValue.Am,
            endTimeMeridiemFieldOption: MeridiemFieldValue.Pm,
        });
        handleBlockedTimeDateItemEvent(blockedTimes);
    };

    const getValidationConfig = useCallback((): {
        [key in ValidationType]?: Array<keyof BlockedTime>;
    } => {
        return {
            required: ['blocked_time_name'],
        };
    }, []);

    const blockedTimeAttributeValidationConfig: {
        [blockedTimeControlArrayFormValuesKey in keyof BlockedTimesControlArrayFormValues]: {
            [key in ValidationType]?: Array<
                keyof BlockedTimesControlArrayFormValues[blockedTimeControlArrayFormValuesKey]
            >;
        };
    } = {
        blockedTimeAttributeEditorItems: {
            required: [
                'startDateString',
                'startTime',
                'endTime',
                'endTimeMeridiemFieldOption',
                'startTimeMeridiemFieldOption',
            ],
        },
    };

    const blockedTimeNames = isFreelancer
        ? [{ label: 'Unavailable', id: 'Unavailable' }]
        : [
              { label: 'Class prep / followup', id: 'Class prep / followup' },
              {
                  label: 'Curriculum development & review',
                  id: 'Curriculum development & review',
              },
              { label: 'Academy', id: 'Academy' },
              { label: 'Enterprise Skills Transformation (EST)', id: 'Enterprise Skills Transformation (EST)' },
              { label: 'Corporate Holiday', id: 'Corporate Holiday' },
              { label: 'Digital ILT/Digital Subscription Recording', id: 'Digital ILT/Digital Subscription Recording' },
              { label: 'Internal meetings', id: 'Internal meetings' },
              { label: 'Mentoring', id: 'Mentoring'},
              { label: 'Padawan', id: 'Padawan' },
              { label: 'Presenter', id: 'Presenter' },
              { label: 'Podium Review - Lead Trainer', id: 'Podium Review - Lead Trainer' },
              {
                  label: 'Pre-sales /account management',
                  id: 'Pre-sales /account management',
              },
              { label: 'Leave', id: 'Leave' },
              { label: 'Skills acquisition', id: 'Skills acquisition' },
              { label: 'Skills Center', id: 'SKills Center' },
              { label: 'Tech U', id: 'Tech U' },
              { label: 'Travel', id: 'Travel' },
              { label: 'Other', id: 'Other' },
              { label: 'Validating Localized Content', id: 'Validating Localized Content'},
          ];

    useEffect(
        () => {
            if (isInvalid) {
                validateForm(formValues, getValidationConfig());
            }

            if (isControlArrayInvalid) {
                validateFormControlArray(
                    { blockedTimeAttributeEditorItems },
                    blockedTimeAttributeValidationConfig,
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            isInvalid,
            formValues,
            validateForm,
            validateFormControlArray,
            blockedTimeAttributeEditorItems,
            isControlArrayInvalid,
            getValidationConfig,
            // TODO - This is causing max depth errors
            // blockedTimeAttributeValidationConfig,
        ],
    );

    useEffect(() => {
        if (blockedTime?.pk) {
            setIsEditForm(true);
            const instructor = instructorLookup[blockedTime.instructor_pk];
            const { city_timezone, is_freelancer } = instructor;
            const freelancer = is_freelancer ? true : false;
            setIsFreelancer(freelancer);
            const timestamps = mapTimestampsToCalendarView({
                calendarRef,
                timestamps: blockedTime.blocked_time_timestamps,
                city_timezone,
            });
            const editTimes = timestamps.map((timestamp) =>
                buildEditTimesForInstructorType({
                    timestamp,
                    city_timezone,
                    blockedTime,
                    isFreelancer: freelancer,
                }),
            );
            const editTimesAreDateRange = checkEditTimesAsDateRange(
                blockedTime.blocked_time_timestamps,
                instructor.city_timezone,
            );
            setIsDateRange(editTimesAreDateRange);
            setBlockedTimeAttributeEditorItems(editTimes);
            setFormValues((values) => {
                return {
                    ...values,
                    blockedTimes: editTimes,
                    ...blockedTime,
                };
            });
        } else {
            let hasFreelancer = false;
            selectedInstructors.forEach((item) =>
                item.is_freelancer ? (hasFreelancer = true) : false,
            );
            hasFreelancer ? setIsFreelancer(true) : setIsFreelancer(false);

            setIsEditForm(false);
        }
    }, [blockedTime, instructorLookup, calendarRef, selectedInstructors]);

    const deselectMixedInstructors = (deselectFreelancers: boolean): void => {
        // deselect freelancers
        handleSelectedInstructorsChange(
            new Set(
                Array.from(selectedInstructors).filter((instructor) => {
                    if (
                        instructor.is_freelancer === deselectFreelancers ||
                        (!deselectFreelancers && !instructor.is_freelancer)
                    ) {
                        return true;
                    }

                    return false;
                }),
            ),
        );
    };

    const freelancerUnavailableTimeAttributeEditorDefinition: Array<AttributeEditor.FieldDefinition> =
        [
            {
                label: 'Dates',
                control: (
                    blockedTimeAllDayAttributeEditorItem: BlockTimeTimeAttributeEditorItem,
                    index: number,
                ) => {
                    if (blockedTimeAllDayAttributeEditorItem.removeFlag) {
                        return (
                            <Alert
                                visible={
                                    blockedTimeAllDayAttributeEditorItem.removeFlag
                                }
                                type="success"
                            >
                                {
                                    blockedTimeAllDayAttributeEditorItem.startDateString
                                }{' '}
                                removed.
                            </Alert>
                        );
                    } else {
                        return (
                            <DatePicker
                                onChange={(e) => {
                                    handleUnavailableTimeDateChange(
                                        e.detail.value,
                                        index,
                                        'startDateString',
                                    );
                                }}
                                value={
                                    blockedTimeAllDayAttributeEditorItem.startDateString
                                        ? blockedTimeAllDayAttributeEditorItem.startDateString
                                        : ''
                                }
                                nextMonthLabel="Next month"
                                placeholder="YYYY/MM/DD"
                                previousMonthLabel="Previous month"
                                todayLabel="Today"
                                data-testid={`pickStartDateString-${index}`}
                                disabled={isEditForm ? true : false}
                            />
                        );
                    }
                },
            },
        ];

    const dateRangeBlockAttributeEditorDefinition: Array<AttributeEditor.FieldDefinition> =
        [
            {
                label: 'Start Date',
                control: (
                    blockedTimeAttributeEditorItem: BlockTimeTimeAttributeEditorItem,
                    index: number,
                ) => {
                    if (blockedTimeAttributeEditorItem.removeFlag) {
                        return (
                            <Alert
                                visible={
                                    blockedTimeAttributeEditorItem.removeFlag
                                }
                                type="success"
                            >
                                {blockedTimeAttributeEditorItem.startDateString}{' '}
                                removed.
                            </Alert>
                        );
                    } else {
                        return (
                            <DatePicker
                                onChange={(e) => {
                                    handleUnavailableTimeDateChange(
                                        e.detail.value,
                                        index,
                                        'startDateString',
                                    );
                                }}
                                value={
                                    blockedTimeAttributeEditorItem.startDateString
                                        ? blockedTimeAttributeEditorItem.startDateString
                                        : ''
                                }
                                nextMonthLabel="Next month"
                                placeholder="YYYY/MM/DD"
                                previousMonthLabel="Previous month"
                                todayLabel="Today"
                                data-testid={`pickDateString-${index}`}
                                disabled={isEditForm ? true : false}
                            />
                        );
                    }
                },
                errorText: (_, index: number) =>
                    controlArrayErrors.blockedTimeAttributeEditorItems?.[index]
                        ?.dateString,
            },
            {
                label: 'End time',
                control: (
                    blockedTimeAttributeEditorItem: BlockTimeTimeAttributeEditorItem,
                    index: number,
                ) => {
                    if (!blockedTimeAttributeEditorItem.removeFlag) {
                        return (
                            <DatePicker
                                onChange={(e) => {
                                    handleUnavailableTimeDateChange(
                                        e.detail.value,
                                        index,
                                        'endDateString',
                                    );
                                }}
                                value={
                                    blockedTimeAttributeEditorItem.endDateString
                                        ? blockedTimeAttributeEditorItem.endDateString
                                        : ''
                                }
                                nextMonthLabel="Next month"
                                placeholder="YYYY/MM/DD"
                                previousMonthLabel="Previous month"
                                todayLabel="Today"
                                data-testid={`pickDateString-${index}`}
                                disabled={isEditForm ? true : false}
                            />
                        );
                    }
                },
                errorText: (_, index: number) =>
                    controlArrayErrors.blockedTimeAttributeEditorItems?.[index]
                        ?.dateString,
            },
        ];

    const reserveTimeAttributeEditorDefinition: Array<AttributeEditor.FieldDefinition> =
        [
            {
                label: 'Start Date',
                control: (
                    blockedTimeAttributeEditorItem: BlockTimeTimeAttributeEditorItem,
                    index: number,
                ) => (
                    <DatePicker
                        onChange={(e) => {
                            handleReserveTimeDateChange(e.detail.value, index);
                        }}
                        value={
                            blockedTimeAttributeEditorItem.startDateString
                                ? blockedTimeAttributeEditorItem.startDateString
                                : ''
                        }
                        nextMonthLabel="Next month"
                        placeholder="YYYY/MM/DD"
                        previousMonthLabel="Previous month"
                        todayLabel="Today"
                        data-testid={`pickDateString-${index}`}
                    />
                ),
                errorText: (_, index: number) =>
                    controlArrayErrors.blockedTimeAttributeEditorItems?.[index]
                        ?.dateString,
            },
            {
                label: 'Start time',
                control: (
                    blockedTimeAttributeEditorItem: BlockTimeTimeAttributeEditorItem,
                    index: number,
                ) => (
                    <div className="time-selector">
                        <div>
                            <TimeInput
                                data-testid={`ReserveTimeStartTime`}
                                format="hh:mm"
                                placeholder="hh:mm"
                                use24Hour={false}
                                value={
                                    blockedTimeAttributeEditorItem.startTime
                                        ? blockedTimeAttributeEditorItem.startTime
                                        : ''
                                }
                                onChange={(e) => {
                                    const newItems =
                                        blockedTimeAttributeEditorItems.slice();
                                    newItems.splice(index, 1, {
                                        ...blockedTimeAttributeEditorItem,
                                        startTime: e.detail.value,
                                    });
                                    handleBlockedTimeDateItemEvent(newItems);
                                }}
                            />
                        </div>
                        <div>
                            <Select
                                options={meridiemFieldOptions}
                                selectedOption={
                                    blockedTimeAttributeEditorItem.startTimeMeridiemFieldOption
                                        ? meridiemFieldOptionLookup[
                                              blockedTimeAttributeEditorItem
                                                  .startTimeMeridiemFieldOption
                                          ]
                                        : meridiemFieldOptionLookup[
                                              MeridiemFieldValue.Am
                                          ]
                                }
                                onChange={(e) => {
                                    const newItems =
                                        blockedTimeAttributeEditorItems.slice();
                                    newItems.splice(index, 1, {
                                        ...blockedTimeAttributeEditorItem,
                                        startTimeMeridiemFieldOption: e.detail
                                            .selectedOption
                                            .label as MeridiemFieldValue,
                                    });
                                    handleBlockedTimeDateItemEvent(newItems);
                                }}
                                data-testid={`reserveTimeStart-${index}`}
                            />
                        </div>
                    </div>
                ),
                errorText: (_, index: number) =>
                    controlArrayErrors.blockedTimeAttributeEditorItems?.[index]
                        ?.startTime ||
                    controlArrayErrors.blockedTimeAttributeEditorItems?.[index]
                        ?.startTimeMeridiemFieldOption,
            },
            {
                label: 'End time',
                control: (
                    blockedTimeAtributeEditorItem: BlockTimeTimeAttributeEditorItem,
                    index: number,
                ) => (
                    <div className="time-selector">
                        <div>
                            <TimeInput
                                data-testid={`ReserveTimeEndTime`}
                                format="hh:mm"
                                placeholder="hh:mm"
                                use24Hour={false}
                                value={
                                    blockedTimeAtributeEditorItem.endTime
                                        ? blockedTimeAtributeEditorItem.endTime
                                        : ''
                                }
                                onChange={(e) => {
                                    const newItems =
                                        blockedTimeAttributeEditorItems.slice();
                                    newItems.splice(index, 1, {
                                        ...blockedTimeAtributeEditorItem,
                                        endTime: e.detail.value,
                                    });
                                    handleBlockedTimeDateItemEvent(newItems);
                                }}
                            />
                        </div>
                        <div>
                            <Select
                                options={meridiemFieldOptions}
                                selectedOption={
                                    blockedTimeAtributeEditorItem.endTimeMeridiemFieldOption
                                        ? meridiemFieldOptionLookup[
                                              blockedTimeAtributeEditorItem
                                                  .endTimeMeridiemFieldOption
                                          ]
                                        : meridiemFieldOptionLookup[
                                              MeridiemFieldValue.Am
                                          ]
                                }
                                onChange={(e) => {
                                    const newItems =
                                        blockedTimeAttributeEditorItems.slice();
                                    newItems.splice(index, 1, {
                                        ...blockedTimeAtributeEditorItem,
                                        endTimeMeridiemFieldOption: e.detail
                                            .selectedOption
                                            .label as MeridiemFieldValue,
                                    });
                                    handleBlockedTimeDateItemEvent(newItems);
                                }}
                                data-testid={`reserveTimeEnd-${index}`}
                            />
                        </div>
                    </div>
                ),
                errorText: (_, index: number) =>
                    controlArrayErrors.blockedTimeAttributeEditorItems?.[index]
                        ?.endTime ||
                    controlArrayErrors.blockedTimeAttributeEditorItems?.[index]
                        ?.endTimeMeridiemFieldOption,
            },
        ];

    const getAttributeEditorDefinitions = () => {
        if (isDateRangeToggled || isDateRange) {
            return dateRangeBlockAttributeEditorDefinition;
        } else {
            if (isFreelancer) {
                return freelancerUnavailableTimeAttributeEditorDefinition;
            } else {
                return reserveTimeAttributeEditorDefinition;
            }
        }
    };

    const blockedTimeModalProps: BlockedTimeModalFooterProps = {
        handleIsVisibleChange,
        clearBlockedTimeValues,
        submitting,
        hasMixedInstructors,
        isEditForm,
        handleBlockedTimeDelete,
        isFreelancer,
        handleBlockedTimeEdit,
        formValues,
        eventSource,
        setSubmitting,
        validateForm,
        getValidationConfig,
        validateFormControlArray,
        blockedTimeAttributeEditorItems,
        blockedTimeAttributeValidationConfig,
        handleBlockedTimeSubmit,
    };

    return (
        <>
            <Modal
                onDismiss={() => {
                    clearBlockedTimeValues();
                    handleIsVisibleChange(false);
                }}
                visible={isVisible}
                header={BLOCK_TIME_TEXT}
                footer={<BlockedTimeModalFooter {...blockedTimeModalProps} />}
                size="large"
                data-testId="blockedTimeModal"
            >
                <ColumnLayout>
                    <SpaceBetween size="l">
                        <div>
                            <Box margin={{ bottom: 'xxxs' }} color="text-label">
                                {isEditForm
                                    ? 'Instructor'
                                    : (selectedInstructors &&
                                          selectedInstructors.size) > 1
                                    ? 'Instructors'
                                    : 'Instructor'}
                            </Box>
                            {!isEditForm
                                ? formatSelectedInstructors()
                                : instructorLookup &&
                                  instructorLookup[blockedTime.instructor_pk]
                                ? instructorLookup[blockedTime.instructor_pk]
                                      .full_name
                                : ''}
                        </div>
                        {hasMixedInstructors && (
                            <div style={{ flexDirection: 'column' }}>
                                <SpaceBetween size="l">
                                    <Alert type="warning">
                                        {MIXED_INSTRUCTOR_ALERT}
                                    </Alert>
                                    <Button
                                        variant="normal"
                                        onClick={() => {
                                            deselectMixedInstructors(false);
                                        }}
                                    >
                                        {CONTINUE_WITH_INTERNAL_INSTRUCTORS_BTN}
                                    </Button>
                                    <Button
                                        variant="normal"
                                        onClick={() => {
                                            deselectMixedInstructors(true);
                                        }}
                                    >
                                        {
                                            CONTINUE_WITH_FREELANCE_INSTRUCTORS_BTN
                                        }
                                    </Button>
                                </SpaceBetween>
                            </div>
                        )}
                        {!hasMixedInstructors && (
                            <>
                                <FormField
                                    errorText={errors?.blocked_time_name}
                                    stretch
                                    label="Select a blocked time name"
                                >
                                    <Select
                                        placeholder={
                                            'Select a blocked time name'
                                        }
                                        options={blockedTimeNames}
                                        data-testid="select-blocked-time-name"
                                        onChange={(e) => {
                                            handleFieldEvent({
                                                blocked_time_name:
                                                    e.detail.selectedOption
                                                        .label,
                                            });
                                        }}
                                        className={errors?.blocked_time_name}
                                        selectedOption={(() => {
                                            return formValues.blocked_time_name
                                                ? {
                                                      label: formValues.blocked_time_name,
                                                      id: formValues.blocked_time_name,
                                                  }
                                                : null;
                                        })()}
                                    />
                                </FormField>
                                {!isEditForm && (
                                    <Toggle
                                        onChange={({ detail }) => {
                                            setDateRangeToggled(detail.checked);
                                            getAttributeEditorDefinitions();
                                        }}
                                        checked={isDateRangeToggled}
                                    >
                                        Date range
                                    </Toggle>
                                )}
                                <AttributeEditor
                                    className="attribute-editor-bottom-spacing"
                                    data-testid="reserve-time-attribute-editor"
                                    addButtonText="Add date"
                                    removeButtonText="Remove"
                                    items={blockedTimeAttributeEditorItems}
                                    definition={getAttributeEditorDefinitions()}
                                    onAddButtonClick={() =>
                                        handleBlockedTimeDateItemEvent([
                                            ...blockedTimeAttributeEditorItems,
                                            {
                                                id: '',
                                                startDateString: '',
                                                endDateString: '',
                                                startTime: '',
                                                endTime: '',
                                                endTimeMeridiemFieldOption:
                                                    MeridiemFieldValue.Am,
                                                startTimeMeridiemFieldOption:
                                                    MeridiemFieldValue.Am,
                                                removeFlag: false,
                                            },
                                        ])
                                    }
                                    disableAddButton={
                                        isEditForm || isDateRangeToggled
                                    }
                                    onRemoveButtonClick={({
                                        detail: { itemIndex },
                                    }) => {
                                        blockedTimeAttributeEditorItems[
                                            itemIndex
                                        ].removeFlag = true;
                                        handleBlockedTimeDateItemEvent(
                                            blockedTimeAttributeEditorItems,
                                        );
                                    }}
                                    isItemRemovable={(
                                        item: BlockTimeTimeAttributeEditorItem,
                                    ) => {
                                        if (!isDateRangeToggled) {
                                            return !item.removeFlag;
                                        } else {
                                            return (
                                                blockedTimeAttributeEditorItems.length >
                                                1
                                            );
                                        }
                                    }}
                                />
                                {blockedTimeErrors.length > 0 &&
                                    blockedTimeErrors.map(
                                        (error: any, i: number) => {
                                            if (error.message) {
                                                const pk =
                                                    error.message.split(':')[1];
                                                const { full_name } =
                                                    instructorLookup[
                                                        pk as string
                                                    ];

                                                return (
                                                    <Alert
                                                        key={pk}
                                                        className="attribute-editor-bottom-spacing"
                                                        onDismiss={() => false}
                                                        visible={true}
                                                        dismissAriaLabel="Close alert"
                                                        type="error"
                                                    >
                                                        {`${full_name} is booked.`}
                                                    </Alert>
                                                );
                                            } else {
                                                return (
                                                    <Alert
                                                        key={`block-time-general-error-${i}`}
                                                        className="attribute-editor-bottom-spacing"
                                                        onDismiss={() => false}
                                                        visible={true}
                                                        dismissAriaLabel="Close alert"
                                                        type="error"
                                                    >
                                                        {`There was an issue while updating block times.`}
                                                    </Alert>
                                                );
                                            }
                                        },
                                    )}
                                <FormField
                                    stretch
                                    label={
                                        <span>
                                            Block time notes <i>- optional</i>{' '}
                                        </span>
                                    }
                                >
                                    <Textarea
                                        data-testid="reserveTimeNotes"
                                        onChange={(e) => {
                                            handleFieldEvent({
                                                blocked_time_notes:
                                                    e.detail.value,
                                            });
                                        }}
                                        value={(() => {
                                            return formValues.blocked_time_notes;
                                        })()}
                                    />
                                </FormField>
                            </>
                        )}
                    </SpaceBetween>
                </ColumnLayout>
            </Modal>
        </>
    );
};

export default BlockedTimeModal;
