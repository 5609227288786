import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { HandleRequestData } from '../../interfaces/handleRequest';
import businessDataApi from '../../api/businessDataApi';
import { AppDispatch, GlobalState } from '../../../main/store';
import { BusinessDataStoreState } from '../../interfaces/businessDataStoreState';
import parseBoolean from '../../utils/parseBoolean';
import { ProgramTypeItemData } from '../../interfaces/businessDataItem/programTypeItem';
import { ProgramTypesResponseData } from '../../interfaces/businessDataResponse/programTypesResponse';
import {
    BusinessDataStoreInitialState,
    getActiveBusinessDataValues,
    resetBasicBusinessDataStoreState,
} from '../store.common';

/**
 * programTypesSlice manages all programTypes state, and contains programTypes actions as well as programTypes state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const programTypesSlice = createSlice({
    name: 'programTypes',
    initialState: {
        ...BusinessDataStoreInitialState,
    } as BusinessDataStoreState<ProgramTypeItemData>,
    reducers: {
        setProgramTypesList: (
            state,
            action: PayloadAction<ProgramTypeItemData[]>,
        ) => {
            const byProgramTypeName = action.payload.reduce(
                (
                    byProgramTypeName: {
                        [key: string]: ProgramTypeItemData;
                    },
                    programType: ProgramTypeItemData,
                ) => {
                    byProgramTypeName[programType.program_type] = {
                        ...programType,
                        active: parseBoolean(programType.active),
                    };
                    return byProgramTypeName;
                },
                {},
            );
            state.entities = byProgramTypeName;
            state.keysList = Object.keys(byProgramTypeName);
        },
        setProgramType: (state, action: PayloadAction<ProgramTypeItemData>) => {
            // this reducer may be used when adding a new program or updating an existing one.
            // only add to keysList and update count if adding a new program
            if (!state.entities[action.payload.program_type]) {
                state.keysList = [
                    ...state.keysList,
                    action.payload.program_type,
                ];
                state.count = state.keysList.length;
            }
            state.entities[action.payload.program_type] = action.payload;
        },
        setSelectedProgramType: (
            state,
            action: PayloadAction<string | null>,
        ) => {
            state.selectedItemKey = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
        setCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload;
        },
        resetProgramTypesSlice: resetBasicBusinessDataStoreState,
    },
});

export const {
    setIsLoading,
    setIsLoaded,
    setProgramTypesList,
    setProgramType,
    setSelectedProgramType,
    setError,
    setCount,
    resetProgramTypesSlice,
} = programTypesSlice.actions;

const handleProgramTypesListRequest = () => {
    return async (dispatch: AppDispatch) => {
        try {
            const {
                result: { PROGRAM_TYPE, total_count },
            }: HandleRequestData<ProgramTypesResponseData> = await businessDataApi.getProgramTypes(
                { active: 'all' },
            );
            dispatch(setProgramTypesList(PROGRAM_TYPE));
            dispatch(setCount(total_count));
        } catch (error: any) {
            dispatch(setError(error?.message || 'getProgramTypes API error'));
        }
    };
};

export const getProgramTypesList = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));
        await dispatch(handleProgramTypesListRequest());
        dispatch(setIsLoading(false));
        if (!state.programTypes.isLoaded) {
            dispatch(setIsLoaded(true));
        }
    };
};

/**
 * action to update program
 * before update api call, fetch program types and prepare payload containing only the changed fields
 * after update api call, fetch program types and update the current item in that keysList with the item data we updated
 * @param data
 */
export const updateProgramType = (data: ProgramTypeItemData) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        // immediately put the keysList into loading while we finish updating the item/fetching the keysList
        dispatch(setIsLoading(true));
        if (!data.pk || data?.pk.length <= 0) {
            // eslint-disable-next-line no-console
            console.error(
                `Error updating new program: required field pk was missing`,
            );
            dispatch(setIsLoading(false));
            return false;
        }
        try {
            await businessDataApi.updateProgramType(data.pk, data.active);
            await dispatch(handleProgramTypesListRequest());
            // force-update the item in the keysList for now, since the keysList we fetched may not have this item's update yet
            dispatch(
                setProgramType({
                    ...state.programTypes.entities[data.program_type],
                    active: data.active,
                }),
            );
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const addProgramType = (active = true, programTypeName: string) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        dispatch(setIsLoading(true));
        try {
            await businessDataApi.addProgramType(programTypeName, active);
            // now refresh the keysList
            await dispatch(handleProgramTypesListRequest());
            if (!state.programTypes?.entities[programTypeName]) {
                // shove the item into the keysList, but only if it's not there already
                const newProgramData: ProgramTypeItemData = {
                    program_type: programTypeName,
                    active: active,
                };
                dispatch(setProgramType(newProgramData));
            }
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const selectAllProgramTypes = (state: GlobalState) => {
    return state.programTypes.keysList.map(
        (programTypeName) => state.programTypes.entities[programTypeName],
    );
};

export const selectAllActiveProgramTypes = (
    state: GlobalState,
): Array<ProgramTypeItemData> => {
    return getActiveBusinessDataValues(
        state.programTypes.keysList,
        state.programTypes.entities,
    );
};

export const selectActivePlusCourseSelection = (state: GlobalState) => {
    const courseSelectionProgramTypes: string[] = state?.courses
        ?.selectedItemKey
        ? state?.courses?.entities[state?.courses?.selectedItemKey]
              .associated_programs
        : [];

    return state.programTypes.keysList.reduce(
        (acc: Array<ProgramTypeItemData>, key: string) => {
            if (state.programTypes.entities[key].active) {
                acc.push(state.programTypes.entities[key]);
            } else if (courseSelectionProgramTypes.includes(key)) {
                acc.push(state.programTypes.entities[key]);
            }
            return acc;
        },
        [],
    );
};

export const selectActivePlusInstructorSelection = (state: GlobalState) => {
    const instructorSelectionProgramTypes: string[] = state?.selectedInstructor
        ?.selectedInstructor
        ? state?.selectedInstructor?.selectedInstructor.instructor.programs
        : [];

    return state.programTypes.keysList.reduce(
        (acc: Array<ProgramTypeItemData>, key: string) => {
            if (
                state.programTypes.entities[key].active ||
                instructorSelectionProgramTypes.includes(key)
            ) {
                acc.push(state.programTypes.entities[key]);
            }
            return acc;
        },
        [],
    );
};

export const selectActivePlusUserSelections = (state: GlobalState) => {
    const userSelectedRegions: ReadonlySet<string> = new Set(
        state.selectedUser.selectedUser?.user?.programs
            ? state.selectedUser.selectedUser?.user?.programs
            : [],
    );

    return state.programTypes.keysList.reduce(
        (acc: Array<ProgramTypeItemData>, key: string) => {
            if (
                state.programTypes.entities[key].active ||
                userSelectedRegions.has(key)
            ) {
                acc.push(state.programTypes.entities[key]);
            }
            return acc;
        },
        [],
    );
};

export const selectIsLoading = (state: GlobalState) =>
    state.programTypes.isLoading;

export const selectIsLoaded = (state: GlobalState) =>
    state.programTypes.isLoaded;

export const selectSelectedProgramType = (state: GlobalState) => {
    return state?.programTypes?.selectedItemKey
        ? state?.programTypes?.entities[state?.programTypes?.selectedItemKey]
        : null;
};

export const selectError = (state: GlobalState) => state.programTypes.error;

export const selectCount = (state: GlobalState) => state.programTypes.count;

export default programTypesSlice.reducer;
