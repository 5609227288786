import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Select, Table } from '@amzn/awsui-components-react';

import {
    resetCoursesSlice,
    initializeCoursesListQueryParams,
    getCoursesList,
    selectIsLoading,
    selectIsLoaded,
    selectAllCourses,
    selectError,
    updateCourse,
    selectSelectedCourse,
    setSelectedCourse,
    addCourse,
    selectCount,
} from '../../../common/store/slices/coursesSlice';
import { CourseItemData } from '../../../common/interfaces/businessDataItem/courseItem';
import {
    AdminBusinessDataFormInputType,
    AdminBusinessDataFormSchema,
} from '../../interfaces/adminBusinessDataFormSchema';
import { AdminBusinessDataSelectors } from '../../interfaces/adminBusinessDataSelectors';
import AdminBusinessData from '../AdminBusinessData';
import { formatStatus } from '../AdminBusinessData/AdminBusinessData.Status';
import { useNotifications } from '../../../common/context/grimsbyNotifications';
import handleBusinessDataNotification from '../../../common/utils/handleBusinessDataNotification';
import parseBoolean from '../../../common/utils/parseBoolean';
import { ProgramTypeItemData } from '../../../common/interfaces/businessDataItem/programTypeItem';
import { CertificationItemData } from '../../../common/interfaces/businessDataItem/certificationItem';
import {
    getProgramTypesList,
    selectActivePlusCourseSelection as selectProgramTypes,
} from '../../../common/store/slices/programTypesSlice';
import {
    getCertificationsList,
    selectActivePlusCourseSelection as selectCertifications,
} from '../../../common/store/slices/certificationsSlice';
import { formatStringArray } from '../../../common/utils/formatStringArray';
import { formatString } from '../../../common/utils/formatString';

const COURSES_TABLE_TITLE = 'Courses';
const COURSES_DISPLAY_SINGULAR = 'Course';
export const COURSES_NAME_KEY = 'course';
const COURSE_DAYS_PRECISION = 2;

export const formatCourseDays = (value?: string | number) => {
    if (!value) {
        return '-';
    }

    if (typeof value === 'string') {
        try {
            value = parseFloat(value);
        } catch {
            // eslint-disable-next-line no-console
            console.warn('cannot parse number', value);
            value = 1;
        }
    }

    return value === 1 ? `${value} day` : `${value} days`;
};

const parseCourseDays = (val: string) => {
    const valueWithPrecision = parseFloat(val).toFixed(COURSE_DAYS_PRECISION);

    // parse float again to remove unnecessary decimal points in the string return by toFixed
    return `${parseFloat(valueWithPrecision)}`;
};

// this array must exclude the "name" column because
// we need to define it inside AdminBusinessData so we can attach an eventHandler to that link
export const columnDefinitions: Array<Table.ColumnDefinition> = [
    {
        id: 'active',
        header: 'Status',
        cell: (course: CourseItemData) =>
            formatStatus(course.active.toString()),
    },
    {
        id: 'associated_programs',
        header: 'Associated programs',
        cell: (course: CourseItemData) =>
            formatStringArray(course.associated_programs),
    },
    {
        id: 'associated_certifications',
        header: 'Associated certifications',
        cell: (course: CourseItemData) =>
            formatStringArray(course.associated_certifications),
    },
    {
        id: 'course_days',
        header: 'Course duration',
        cell: (course: CourseItemData) => formatCourseDays(course.course_days),
    },
    {
        id: 'short_name',
        header: 'Short name',
        cell: (course: CourseItemData) =>
            formatString(course.short_name),
    },
    {
        id: 'sku_id',
        header: 'SKU Id',
        cell: (course: CourseItemData) => formatString(course.sku_id),
    },
];

const AdminCourses = () => {
    const dispatch = useDispatch();
    const programs: ProgramTypeItemData[] = useSelector(selectProgramTypes);
    const certifications: CertificationItemData[] = useSelector(
        selectCertifications,
    );
    const { addNotification } = useNotifications();
    const currentItem: CourseItemData | null = useSelector(
        selectSelectedCourse,
    );

    function useCourses(): AdminBusinessDataSelectors<CourseItemData> {
        const dispatch = useDispatch();

        const items: CourseItemData[] = useSelector(selectAllCourses);
        const itemCount: number = useSelector(selectCount);

        const isLoading: boolean = useSelector(selectIsLoading);
        const isLoaded: boolean = useSelector(selectIsLoaded);
        const error = useSelector(selectError);

        useEffect(() => {
            dispatch(
                initializeCoursesListQueryParams({
                    active: 'all',
                    size: 0,
                }),
            );
            dispatch(getCoursesList());

            return () => {
                dispatch(resetCoursesSlice());
            };
        }, [dispatch]);
        return {
            items,
            itemCount,
            isLoading,
            isLoaded,
            error,
            currentItem,
        };
    }

    const handleItemSelect = async (course: CourseItemData) => {
        if (course) {
            dispatch(setSelectedCourse(course.course));
            await Promise.all([
                dispatch(getCoursesList()),
                dispatch(getProgramTypesList()),
                dispatch(getCertificationsList()),
            ]);
        }
    };

    const handleItemUpdate = async (data: CourseItemData) => {
        dispatch(setSelectedCourse(null));
        const dispatchPromise = dispatch<any>(
            updateCourse(data, currentItem?.course),
        );
        await handleBusinessDataNotification({
            businessDataType: COURSES_NAME_KEY,
            businessDataDisplayNameLower: COURSES_DISPLAY_SINGULAR.toLowerCase(),
            itemName: data.course,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'update',
        });
    };

    const onCreateModalOpenHandler = async () => {
        await Promise.all([
            dispatch(getProgramTypesList()),
            dispatch(getCertificationsList()),
        ]);
    };

    const handleItemCreate = async (newCourse: CourseItemData) => {
        const dispatchPromise = dispatch<any>(
            addCourse({
                ...newCourse,
                course_days: `${newCourse.course_days}`,
            }),
        );
        await handleBusinessDataNotification({
            businessDataType: COURSES_NAME_KEY,
            businessDataDisplayNameLower: COURSES_DISPLAY_SINGULAR.toLowerCase(),
            itemName: newCourse.course,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'create',
        });
    };

    const { programOptions, programLookup } = programs.reduce(
        (acc, program) => {
            const opt = {
                label: program.program_type,
                id: program.program_type,
            } as Select.Option;
            if (!program.active) {
                opt.description = 'inactive';
            }
            acc.programLookup[program.program_type] = opt;
            acc.programOptions.push(opt);
            return acc;
        },
        {
            programLookup: {} as {
                [key: string]: Select.Option;
            },
            programOptions: [] as Array<Select.Option>,
        },
    );

    const { certificationOptions, certificationLookup } = certifications.reduce(
        (acc, cert) => {
            const opt = {
                label: cert.certification,
                id: cert.certification,
            } as Select.Option;
            if (!cert.active) {
                opt.description = 'inactive';
            }
            acc.certificationLookup[cert.certification] = opt;
            acc.certificationOptions.push(opt);
            return acc;
        },
        {
            certificationLookup: {} as {
                [key: string]: Select.Option;
            },
            certificationOptions: [] as Array<Select.Option>,
        },
    );

    /**
     * Editable form fields for Course
     * The keys must map to CourseItemData properties
     */
    const createFormSchema: AdminBusinessDataFormSchema<CourseItemData> = {
        // the keys must match ProgramDataType properties
        course: {
            type: AdminBusinessDataFormInputType.Text,
            label: `${COURSES_DISPLAY_SINGULAR} name`,
            required: true,
            defaultValue: '',
            disabled: false,
            formDataTransform: (val: string): Partial<CourseItemData> => {
                return { course: val };
            },
        },
        associated_programs: {
            type: AdminBusinessDataFormInputType.Multiselect,
            label: 'Associated Programs',
            placeholder: 'Select Programs',
            required: false,
            defaultValue: [],
            disabled: false,
            options: programOptions,
            lookup: programLookup,
            formDataTransform: (
                val: Array<Select.Option>,
            ): Partial<CourseItemData> => {
                return { associated_programs: val.map((v) => v.label) };
            },
        },
        associated_certifications: {
            type: AdminBusinessDataFormInputType.Multiselect,
            label: 'Associated Certifications',
            placeholder: 'Select Certifications',
            required: false,
            defaultValue: [],
            disabled: false,
            options: certificationOptions,
            lookup: certificationLookup,
            formDataTransform: (
                val: Array<Select.Option>,
            ): Partial<CourseItemData> => {
                return { associated_certifications: val.map((v) => v.label) };
            },
        },
        course_days: {
            type: AdminBusinessDataFormInputType.Number,
            label: 'Course duration',
            required: true,
            defaultValue: 1,
            disabled: false,
            step: 0.25,
            min: 0.25,
            precision: COURSE_DAYS_PRECISION,
            formDataTransform: (val: string): Partial<CourseItemData> => {
                return {
                    course_days: parseCourseDays(val),
                };
            },
        },
        active: {
            type: AdminBusinessDataFormInputType.StatusRadio,
            label: 'Status',
            required: true,
            defaultValue: true,
            disabled: false,
            formDataTransform: (
                val: 'true' | 'false',
            ): Partial<CourseItemData> => {
                return { active: parseBoolean(val) };
            },
        },
        short_name: {
            type: AdminBusinessDataFormInputType.Text,
            label: `Short name`,
            required: false,
            defaultValue: '',
            disabled: false,
            formDataTransform: (val: string): Partial<CourseItemData> => {
                return { short_name: val };
            },
        },
        sku_id: {
            type: AdminBusinessDataFormInputType.Text,
            label: `SKU Id`,
            required: false,
            defaultValue: '',
            disabled: false,
            formDataTransform: (val: string): Partial<CourseItemData> => {
                return { sku_id: val };
            },
        },
    };

    // The update form schema is the same as the create form schema except the "name" field is disabled
    const updateFormSchema: AdminBusinessDataFormSchema<CourseItemData> = {
        ...createFormSchema,
        course: {
            ...createFormSchema.course,
            disabled: false,
        },
    };

    return (
        <div data-testid="AdminBusinessDataManagementCourses">
            <AdminBusinessData
                columnDefinitions={columnDefinitions}
                createFormSchema={createFormSchema}
                updateFormSchema={updateFormSchema}
                handleCreateModalOpen={onCreateModalOpenHandler}
                handleItemCreate={handleItemCreate}
                handleItemSelect={handleItemSelect}
                handleItemUpdate={handleItemUpdate}
                itemDisplayNameSingular={COURSES_DISPLAY_SINGULAR}
                itemNameKey={COURSES_NAME_KEY}
                title={COURSES_TABLE_TITLE}
                useItemList={useCourses}
            />
        </div>
    );
};

export default AdminCourses;
