import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CertificationsResponseData } from '../../interfaces/businessDataResponse/certificationsResponse';
import { HandleRequestData } from '../../interfaces/handleRequest';
import businessDataApi from '../../api/businessDataApi';
import { AppDispatch, GlobalState } from '../../../main/store';
import { CertificationItemData } from '../../interfaces/businessDataItem/certificationItem';
import parseBoolean from '../../utils/parseBoolean';
import { BusinessDataStoreState } from '../../interfaces/businessDataStoreState';
import {
    BusinessDataStoreInitialState,
    resetBasicBusinessDataStoreState,
} from '../store.common';

/**
 * certificationsSlice manages all certifications state, and contains certifications actions as well as certifications state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const certificationsSlice = createSlice({
    name: 'certifications',
    initialState: {
        ...BusinessDataStoreInitialState,
    } as BusinessDataStoreState<CertificationItemData>,
    reducers: {
        setCertificationsList: (
            state,
            action: PayloadAction<CertificationItemData[]>,
        ) => {
            const byCertificationName = action.payload.reduce(
                (
                    byCertificationName: {
                        [key: string]: CertificationItemData;
                    },
                    certification: CertificationItemData,
                ) => {
                    byCertificationName[certification.certification] = {
                        ...certification,
                        active: parseBoolean(certification.active),
                    };
                    return byCertificationName;
                },
                {},
            );
            state.entities = byCertificationName;
            state.keysList = Object.keys(byCertificationName);
        },
        setCertification: (
            state,
            action: PayloadAction<CertificationItemData>,
        ) => {
            // this reducer may be used when adding a new certification or updating an existing one.
            // only add to list and update count if adding a new certification
            if (!state.entities[action.payload.certification]) {
                state.keysList = [
                    ...state.keysList,
                    action.payload.certification,
                ];
                state.count = state.keysList.length;
            }
            state.entities[action.payload.certification] = action.payload;
        },
        setSelectedCertification: (
            state,
            action: PayloadAction<string | null>,
        ) => {
            state.selectedItemKey = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
        setCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload;
        },
        resetCertificationsSlice: resetBasicBusinessDataStoreState,
    },
});

export const {
    setIsLoading,
    setIsLoaded,
    setCertificationsList,
    setCertification,
    setSelectedCertification,
    setError,
    setCount,
    resetCertificationsSlice,
} = certificationsSlice.actions;

const handleCertificationsListRequest = () => {
    return async (dispatch: AppDispatch) => {
        try {
            const {
                result: { CERTIFICATION, total_count },
            }: HandleRequestData<CertificationsResponseData> = await businessDataApi.getCertifications(
                { active: 'all' },
            );
            dispatch(setCertificationsList(CERTIFICATION));
            dispatch(setCount(total_count));
        } catch (error: any) {
            dispatch(setError(error?.message || 'getCertifications API error'));
        }
    };
};

export const getCertificationsList = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));
        await dispatch(handleCertificationsListRequest());
        dispatch(setIsLoading(false));
        if (!state.certifications.isLoaded) {
            dispatch(setIsLoaded(true));
        }
    };
};

/**
 * action to update certification
 * before update api call, fetch certificationtypes and prepare payload containing only the changed fields
 * after update api call, fetch certificationtypes and update the current item in that list with the item data we updated
 * @param data
 * @param originalCertificationData
 */
export const updateCertification = (
    data: CertificationItemData,
    originalCertificationData: CertificationItemData | null,
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        if (!originalCertificationData) {
            throw new Error('orignial Certification is required');
        }

        const state = getState();
        // immediately put the list into loading while we finish updating the item/fetching the list
        dispatch(setIsLoading(true));
        if (!data.pk || data?.pk.length <= 0) {
            // eslint-disable-next-line no-console
            console.error(
                `Error updating new certification: required field pk was missing`,
            );
            dispatch(setIsLoading(false));
            return false;
        }
        try {
            await businessDataApi.updateCertification(
                data.pk,
                data.active,
                data.certification,
            );
            await dispatch(handleCertificationsListRequest());
            // force-update the item in the list for now, since the list we fetched may not have this item's update yet
            const originalIndex = state.certifications.keysList.indexOf(
                originalCertificationData.certification,
            );

            const certificationsList = selectAllCertifications(state);

            certificationsList.splice(originalIndex, 1, data);

            dispatch(setCertificationsList(certificationsList));
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const addCertification = (active = true, certificationName: string) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        dispatch(setIsLoading(true));
        try {
            await businessDataApi.addCertification(certificationName, active);
            // now refresh the list
            await dispatch(handleCertificationsListRequest());
            if (!state.certifications?.entities[certificationName]) {
                // shove the item into the list, but only if it's not there already
                const newInstructorData: CertificationItemData = {
                    certification: certificationName,
                    active: active,
                };
                dispatch(setCertification(newInstructorData));
            }
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const selectAllCertifications = (state: GlobalState) => {
    return state.certifications.keysList.map(
        (certificationName) => state.certifications.entities[certificationName],
    );
};

export const selectActiveCertifications = (state: GlobalState) => {
    return state.certifications.keysList.reduce(
        (acc: Array<CertificationItemData>, key: string) => {
            if (state.certifications.entities[key].active) {
                acc.push(state.certifications.entities[key]);
            }
            return acc;
        },
        [],
    );
};

export const selectActivePlusCourseSelection = (state: GlobalState) => {
    const courseSelectionCertifications: string[] = state?.courses
        ?.selectedItemKey
        ? state?.courses?.entities[state?.courses?.selectedItemKey]
              .associated_certifications
        : [];

    return state.certifications.keysList.reduce(
        (acc: Array<CertificationItemData>, key: string) => {
            if (state.certifications.entities[key].active) {
                acc.push(state.certifications.entities[key]);
            } else if (courseSelectionCertifications.includes(key)) {
                acc.push(state.certifications.entities[key]);
            }
            return acc;
        },
        [],
    );
};

export const selectIsLoading = (state: GlobalState) =>
    state.certifications.isLoading;

export const selectIsLoaded = (state: GlobalState) =>
    state.certifications.isLoaded;

export const selectSelectedCertification = (state: GlobalState) => {
    return state?.certifications?.selectedItemKey
        ? state?.certifications?.entities[
              state?.certifications?.selectedItemKey
          ]
        : null;
};

export const selectError = (state: GlobalState) => state.certifications.error;

export const selectCount = (state: GlobalState) => state.certifications.count;

export default certificationsSlice.reducer;
