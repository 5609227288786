import React, { useEffect } from 'react';
import {
    ColumnLayout,
    FormField,
    FormSection,
    Input,
    Select,
} from '@amzn/awsui-components-react';
import { FORM_ERROR_SELECTOR, getOptionsAndLookupForSelectInput, getStatusType } from '../../../../../imt/components/Instructor/FormSections/FormSections.common';
import {
    ActivityData,
    APNPartnerPrivateCustomer,
} from '../../../../interfaces/activity';
import {
    getDeliveryLanguagesList as getLanguagesList,
    resetDeliveryLanguagesSlice as resetLanguagesSlice,
    selectAllActiveDeliveryLanguages as selectAllActiveLanguages,
    selectError as selectLanguageListError,
    selectIsLoaded as selectIsLanguageListLoaded,
    selectIsLoading as selectIsLanguageListLoading,
} from '../../../../../common/store/slices/deliveryLanguagesSlice';
import { DeliveryLanguageItemData as LanguageItemData } from '../../../../../common/interfaces/businessDataItem/deliveryLanguageItem';

import './CustomerForm.scss';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_LANGUAGE } from '../../../../constants';

interface CustomerFormSectionProps {
    formValues: APNPartnerPrivateCustomer;
    errors: { [key in keyof Partial<APNPartnerPrivateCustomer>]: string };
    handleFieldEvent: (values: Partial<APNPartnerPrivateCustomer>) => void;
    activity?: ActivityData;
}

export const APNCustomerFormSection = ({
    formValues,
    errors,
    handleFieldEvent,
    activity,
}: CustomerFormSectionProps) => {
    const dispatch = useDispatch();
    const isLanguageListLoading = useSelector(
        selectIsLanguageListLoading,
    );
    const isLanguageListLoaded = useSelector(
        selectIsLanguageListLoaded,
    );
    const languageListError = useSelector(
        selectLanguageListError,
    );
    const languageList = useSelector(selectAllActiveLanguages);

    // lifecycle method to fetch (and re- fetch) business data
    useEffect(() => {
        (
            [
                [
                    !isLanguageListLoaded &&
                        !isLanguageListLoading,
                    getLanguagesList,
                ],
            ] as ReadonlyArray<[boolean, Function]>
        ).forEach(([shouldFetch, getList]) => {
            if (shouldFetch) {
                dispatch(getList());
            }
        });
    });

    const {
        valueLookup: languageLookup,
        valueOptions: languageOptions,
    } = getOptionsAndLookupForSelectInput<LanguageItemData>(
        languageList,
        (language: LanguageItemData) => ({
            label: language.delivery_language,
            id: language.pk as string,
        }),
    );
    return (
        <FormSection
            data-testid="EditCustomerDetailsFormSection"
            header="APN customer details"
        >
            <ColumnLayout>
                <div data-awsui-column-layout-root="true">
                    <FormField
                        errorText={errors?.customer_name}
                        label="Partner name"
                    >
                        <Input
                            className={
                                errors?.customer_name && FORM_ERROR_SELECTOR
                            }
                            value={formValues.customer_name}
                            placeholder="Customer name"
                            onInput={(e) =>
                                handleFieldEvent({
                                    customer_name: e.detail.value,
                                })
                            }
                            data-testid={`EditCustomerName`}
                        />
                    </FormField>
                    <FormField
                        errorText={errors?.sfdc_opportunity_id}
                        label={
                            <span>
                                SFDC opportunity ID - <em>optional</em>
                            </span>
                        }
                    >
                        <Input
                            className={
                                errors?.sfdc_opportunity_id &&
                                FORM_ERROR_SELECTOR
                            }
                            value={formValues.sfdc_opportunity_id}
                            placeholder="SFDC opportunity ID"
                            onInput={(e) =>
                                handleFieldEvent({
                                    sfdc_opportunity_id: e.detail.value,
                                })
                            }
                            data-testid={`EditCSFDCID`}
                        />
                    </FormField>
                    <FormField
                        errorText={errors?.number_of_students_committed}
                        label={
                            <span>
                                Number of students committed - <em>optional</em>
                            </span>
                        }
                    >
                        <Input
                            className={
                                errors?.number_of_students_committed &&
                                FORM_ERROR_SELECTOR
                            }
                            value={`${formValues.number_of_students_committed}`}
                            placeholder="Number of students committed"
                            type="number"
                            onInput={(e) =>
                                handleFieldEvent({
                                    number_of_students_committed: !!e.detail
                                        .value
                                        ? parseInt(e.detail.value)
                                        : null,
                                })
                            }
                            data-testid={`EditNumberOfStudentsCommmitted`}
                        />
                    </FormField>
                    <FormField
                        errorText={errors?.number_of_students_attended}
                        label={
                            <span>
                                Number of students attended - <em>optional</em>
                            </span>
                        }
                    >
                        <Input
                            className={
                                errors?.number_of_students_attended &&
                                FORM_ERROR_SELECTOR
                            }
                            value={`${formValues.number_of_students_attended}`}
                            placeholder="Number of students attended"
                            type="number"
                            onInput={(e) =>
                                handleFieldEvent({
                                    number_of_students_attended: !!e.detail
                                        .value
                                        ? parseInt(e.detail.value)
                                        : null,
                                })
                            }
                            data-testid={`EditNumberOfStudentsAttended`}
                        />
                    </FormField>
                    <FormField
                        errorText={errors?.internal_poc}
                        label={
                            <span>
                                Internal AWS person contact email -{' '}
                                <em>optional</em>
                            </span>
                        }
                    >
                        <Input
                            className={
                                errors?.internal_poc && FORM_ERROR_SELECTOR
                            }
                            value={formValues.internal_poc}
                            placeholder="Delivery contact name"
                            onInput={(e) =>
                                handleFieldEvent({
                                    internal_poc: e.detail.value,
                                })
                            }
                            data-testid={`EditDeliveryInternalPOC`}
                        />
                    </FormField>
                    <FormField
                        label="Customer communication language"
                        errorText={errors?.customer_communication_language}
                    >
                        <Select
                            className={
                                errors?.customer_communication_language && FORM_ERROR_SELECTOR
                            }
                            placeholder={
                                isLanguageListLoading
                                    ? 'Loading languages'
                                    : 'Select a language'
                            }
                            options={languageOptions}
                            selectedOption={
                                languageLookup[
                                    formValues.customer_communication_language || DEFAULT_LANGUAGE
                                ]
                            }
                            onChange={(e) => {
                                handleFieldEvent({
                                    customer_communication_language:
                                        e.detail.selectedOption.label,
                                });
                            }}
                            onRecoveryClick={() => {
                                dispatch(resetLanguagesSlice());
                                dispatch(getLanguagesList());
                            }}
                            loadingText="Loading languages"
                            errorText="An error occurred while loading languages"
                            recoveryText="Retry"
                            empty="No languages found"
                            statusType={getStatusType(
                                isLanguageListLoading,
                                isLanguageListLoaded,
                                languageListError,
                            )}
                            data-testid={`CustomerCommunicationLanguage`}
                            disabled={
                                !isLanguageListLoaded
                            }
                        />
                    </FormField>
                    <FormField
                        errorText={errors?.delivery_contact_name}
                        label={
                            <span>
                                Delivery contact name - <em>optional</em>
                            </span>
                        }
                    >
                        <Input
                            className={
                                errors?.delivery_contact_name &&
                                FORM_ERROR_SELECTOR
                            }
                            value={formValues.delivery_contact_name}
                            placeholder="Delivery contact name"
                            onInput={(e) =>
                                handleFieldEvent({
                                    delivery_contact_name: e.detail.value,
                                })
                            }
                            data-testid={`EditDeliveryContactName`}
                        />
                    </FormField>
                    <FormField
                        errorText={errors?.delivery_contact_email}
                        label={
                            <span>
                                Delivery contact email - <em>optional</em>
                            </span>
                        }
                    >
                        <Input
                            className={
                                errors?.delivery_contact_email &&
                                FORM_ERROR_SELECTOR
                            }
                            value={formValues.delivery_contact_email}
                            placeholder="Delivery contact email"
                            onInput={(e) =>
                                handleFieldEvent({
                                    delivery_contact_email: e.detail.value,
                                })
                            }
                            data-testid={`EditDeliveryContactEmail`}
                        />
                    </FormField>
                    <FormField
                        errorText={errors?.delivery_contact_phone_number}
                        label={
                            <span>
                                Delivery contact phone number -{' '}
                                <em>optional</em>
                            </span>
                        }
                    >
                        <Input
                            className={
                                errors?.delivery_contact_phone_number &&
                                FORM_ERROR_SELECTOR
                            }
                            value={formValues.delivery_contact_phone_number}
                            placeholder="Delivery contact phone number"
                            onInput={(e) =>
                                handleFieldEvent({
                                    delivery_contact_phone_number:
                                        e.detail.value,
                                })
                            }
                            data-testid={`EditDeliveryContactPhone`}
                        />
                    </FormField>
                </div>
            </ColumnLayout>
        </FormSection>
    );
};
