import {
    Box,
    Button,
    Modal,
    SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import FullCalendar from '@fullcalendar/react';
import React from 'react';
import { BlockedTime } from '../../../../common/interfaces/blockedTime';
import BlockedTimePopoverTemplate from '../../Common/ResourceCalendar/Popovers/BlockedTimePopoverTemplate';
import { BLOCK_TIME_TEXT } from './BlockedTimeModal';
import {Actions} from "../../../../common/constants/auth";
import Can from '../../../../common/components/Can';

export interface BlockedTimeInfoModalProps {
    eventSource: BlockedTime;
    isBlockedTimeInfoModalVisible?: boolean;
    handleIsVisibleChange: (isVisible: boolean, eventSource: any) => void;
    handleEditBlockedTimeModal?: (blockedTimeItem: BlockedTime) => void;
    calendarRef: FullCalendar | undefined;
}

const BlockedTimeInfoModal = ({
    eventSource,
    isBlockedTimeInfoModalVisible = false,
    handleIsVisibleChange,
    handleEditBlockedTimeModal = (eventSource) => {},
    calendarRef,
}: BlockedTimeInfoModalProps) => (
    <Modal
        onDismiss={() => handleIsVisibleChange(false, null)}
        visible={isBlockedTimeInfoModalVisible}
        header={BLOCK_TIME_TEXT}
        footer={
            <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                    <Can
                        perform={Actions.RESERVE_TIME_ADMIN}
                        yes={() => (
                                <Button
                                    onClick={() => handleEditBlockedTimeModal(eventSource)}
                                    data-testid={'btnEditReserveTime'}
                                >
                                    Edit block time
                                </Button>
                        )}
                        no={() => <div></div>}
                    />
                                <Button
                                    variant="primary"
                                    onClick={() => handleIsVisibleChange(false, null)}
                                    data-testid={'closeReserveTimeModal'}
                                >
                                    Close
                                </Button>
                </SpaceBetween>
            </Box>
        }
    >
        <BlockedTimePopoverTemplate
            blockedTime={eventSource}
            calendarRef={calendarRef}
        />
    </Modal>
);

export default BlockedTimeInfoModal;
