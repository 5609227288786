export enum ProgramFormFieldText {
    Placeholder = 'Select programs',
    PlaceholderSingular = 'Select program',
    Loading = 'Loading programs',
    Recovery = 'Retry',
    Empty = 'No programs found',
    Error = 'An error occurred while loading programs',
}
export enum FormSectionMode {
    Create = 'Create',
    Edit = 'Edit',
    InlineEdit = 'InlineEdit',
}

export enum LocationFormFieldText {
    Placeholder = 'Select a city name',
    FilteringPlaceholder = 'Enter a city name',
    Recovery = 'Retry',
    Loading = 'Loading cities',
    Empty = 'No matching city or cities found',
    Error = 'An error occurred while loading cities',
}
