import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { HandleRequestData } from '../../../common/interfaces/handleRequest';
import scheduleManagementApi from '../../api/scheduleManagementApi';
import { AppDispatch, GlobalState } from '../../../main/store';
import { GenericStoreState } from '../../../common/interfaces/genericStoreState';
import { ActivityListResponseData } from '../../interfaces/activityListResponse';
import { ActivityData } from '../../interfaces/activity';
import { selectSFDCListPreference } from '../../../common/store/slices/userSlice';
import { ScheduleManagementAPIQueryParams } from '../../interfaces/queryParams';
import { DateRangePickerProps } from '@amzn/awsui-components-react-v3';
import { GRIMSBY_PAGE_COUNT } from '../../../common/constants/grimsby';

export interface SFDCListState extends GenericStoreState {
    activityList: ActivityData[];
    from: number;
    pagesCount: number;
    currentPageIndex: number;
    totalActivitiesCount: number;
    size: number;
    searchText: string | null;
    savedFilter: ScheduleManagementAPIQueryParams.PropertyFilterParams | null;
    sourceFields: Array<ScheduleManagementAPIQueryParams.SourceFieldsItem> | null;
    sortFields: Array<ScheduleManagementAPIQueryParams.SortFieldsItem> | null;
    isLoaded: boolean;
    visibleColumns: Array<string> | undefined;
    dateRangeFilter: DateRangePickerProps.Value | null;
    savedQuery: any;
}

export const defaultVisibleColumns = [
    'customer_name',
    'course_name',
    'sales_geo',
    'start_date',
    'end_date',
    'activity_type',
    'activity_audience',
    'activity_modality',
    'operations_owner',
    'program',
    'location',
    'requester',
    'sfdc_opportunity_id',
    'tof_status',
];

/**
 * instructorListSlice manages all app instructor list state, and contains instructor list actions as well as instructor list state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const sfdcListSlice = createSlice({
    name: 'sfdcList',
    initialState: {
        activityList: [],
        error: null,
        isLoading: false,
        from: 0,
        pagesCount: 0,
        currentPageIndex: 1,
        totalActivitiesCount: 0,
        size: GRIMSBY_PAGE_COUNT,
        searchText: null,
        savedFilter: null,
        sourceFields: null,
        sortFields: null,
        isLoaded: false,
        visibleColumns: defaultVisibleColumns,
        startTimestamp: null,
        endTimestamp: null,
        dateRangeFilter: null,
        savedQuery: null,
    } as SFDCListState,
    reducers: {
        setActivityList: (state, action: PayloadAction<ActivityData[]>) => {
            state.activityList = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setFrom: (state, action: PayloadAction<number>) => {
            state.from = action.payload;
        },
        setPagesCount: (state, action: PayloadAction<number>) => {
            state.pagesCount = action.payload;
        },
        setCurrentPageIndex: (state, action: PayloadAction<number>) => {
            state.currentPageIndex = action.payload;
        },
        setTotalActivitiesCount: (state, action: PayloadAction<number>) => {
            state.totalActivitiesCount = action.payload;
        },
        setSize: (state, action: PayloadAction<number>) => {
            state.size = action.payload;
        },
        setSearchText: (state, action: PayloadAction<string | null>) => {
            state.searchText = action.payload;
        },
        setSavedFilter: (
            state,
            action: PayloadAction<ScheduleManagementAPIQueryParams.PropertyFilterParams | null>,
        ) => {
            state.savedFilter = action.payload;
        },
        setSavedQuery: (state, action: any) => {
            state.savedQuery = action.payload;
        },
        setSourceFields: (
            state,
            action: PayloadAction<
                ScheduleManagementAPIQueryParams.SourceFieldsItem[] | null
            >,
        ) => {
            state.sourceFields = action.payload;
        },
        setSortFields: (
            state,
            action: PayloadAction<
                ScheduleManagementAPIQueryParams.SortFieldsItem[] | null
            >,
        ) => {
            state.sortFields = action.payload;
        },
        setIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
        setVisibleColumns: (state, action: PayloadAction<Array<string>>) => {
            if (action.payload) {
                state.visibleColumns = action.payload;
            } else {
                state.visibleColumns = defaultVisibleColumns;
            }
        },
        setDateRangeFilter: (
            state,
            action: PayloadAction<DateRangePickerProps.Value | null>,
        ) => {
            state.dateRangeFilter = action.payload;
        },
    },
});

export const {
    setActivityList,
    setError,
    setIsLoading,
    setFrom,
    setPagesCount,
    setCurrentPageIndex,
    setTotalActivitiesCount,
    setSize,
    setSearchText,
    setSavedFilter,
    setSavedQuery,
    setSourceFields,
    setSortFields,
    setIsLoaded,
    setVisibleColumns,
    setDateRangeFilter,
} = sfdcListSlice.actions;

export const initializeUserPreference = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));

        // activity list preferences will go here
        const preference = selectSFDCListPreference(state);
        if (preference?.page_size) {
            dispatch(setSize(preference.page_size));
        }
        if (preference?.visible_columns) {
            dispatch(
                setVisibleColumns(
                    preference.visible_columns.includes('customer_name')
                        ? preference.visible_columns
                        : ['customer_name', ...preference.visible_columns],
                ),
            );
        }

        dispatch(setIsLoading(false));
    };
};

/**
 * getActivityList is an async action used to fetch activity list data.
 * There is no explicit inclusion of redux-thunk logic, as the redux toolkit takes care of this for us.
 */
export const getActivityList = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));

        const {
            from,
            size,
            searchText: search_text,
            savedFilter: saved_filter,
            sourceFields: source_fields,
            sortFields: sort_fields,
        } = state.sfdcList;

        let appliedSortFields = sort_fields;

        if (!sort_fields) {
            appliedSortFields = [
                {
                    'delivery_sessions.end_timestamp': {
                        order: 'asc',
                    },
                },
            ];
        }

        let filters = {
            ...saved_filter,
        } as any;

        try {
            const {
                result: { activities = [], total_activities = 0 },
            }: HandleRequestData<ActivityListResponseData> = await scheduleManagementApi.getActivities(
                {
                    from,
                    size,
                    search_text,
                    ...filters,
                    source_fields,
                    sort_fields: appliedSortFields,
                    activity_status: ['Pending Review'],
                } as ScheduleManagementAPIQueryParams.GetActivities,
            );
            dispatch(setActivityList(activities));
            dispatch(setTotalActivitiesCount(total_activities));
            dispatch(setPagesCount(Math.ceil(total_activities / size)));
        } catch (error: any) {
            dispatch(setError(error.toString()));
        } finally {
            if (!state.sfdcList.isLoaded) {
                dispatch(setIsLoaded(true));
            }
            dispatch(setIsLoading(false));
        }
    };
};

export const selectActivityList = (state: GlobalState) =>
    state.sfdcList.activityList;
export const selectError = (state: GlobalState) => state.sfdcList.error;
export const selectIsLoading = (state: GlobalState) => state.sfdcList.isLoading;
export const selectIsLoaded = (state: GlobalState) => state.sfdcList.isLoaded;
export const selectPagesCount = (state: GlobalState) =>
    state.sfdcList.pagesCount;
export const selectCurrentPageIndex = (state: GlobalState) =>
    state.sfdcList.currentPageIndex;
export const selectTotalActivitiesCount = (state: GlobalState) =>
    state.sfdcList.totalActivitiesCount;
export const selectSize = (state: GlobalState) => state.sfdcList.size;
export const selectVisibleColumns = (state: GlobalState) =>
    state.sfdcList.visibleColumns;
export const selectSearchText = (state: GlobalState) =>
    state.sfdcList.searchText;
export const selectSavedFilter = (state: GlobalState) =>
    state.sfdcList.savedFilter;
export const selectDateRangeFilter = (state: GlobalState) =>
    state.sfdcList.dateRangeFilter;
export const selectSavedQuery = (state: GlobalState) =>
    state.sfdcList.savedQuery;

export default sfdcListSlice.reducer;
