import { Icon } from '@amzn/awsui-components-react';
import React from 'react';

export const formatBoolean = (
    ok: boolean | null,
    successfulLabel: string,
): JSX.Element =>
    ok ? (
        <span className="awsui-util-status-positive nowrap">
            <Icon name="status-positive" />
            &nbsp;{successfulLabel}
        </span>
    ) : (
        <span>No</span>
    );
