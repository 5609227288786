import React, { useEffect, useState } from 'react';
import {
    Alert,
    Box,
    Button,
    Container,
    Header,
    Icon,
    Link,
    SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import FileUpload, { FileType } from '../../../../common/components/FileUpload';
import scheduleManagementApi from '../../../api/scheduleManagementApi';
import { json2csv } from 'json-2-csv';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { useNotifications } from '../../../../common/context/grimsbyNotifications';

export const PAGE_TITLE = 'Import activites';
export const CONTAINER_HEADER = 'File select';
export const CONTAINER_CONTENT =
    'To import activities download and fill out the template below, and select it from your computer. Your file must include only valid data to import successfully.';
export const DOWNLOAD_TEXT = 'Download Excel template';
const IMPORT_ACTIVITIES_BUTTON = 'Import';
const IMPORTING_ACTIVITIES_BUTTON = 'Importing';
const CANCEL_IMPORT_ACTIVITIES_BUTTON = 'Cancel';
const DOWNLOAD_URL = '/templates/bulk_activity_upload_template_v1.10.xlsx';
const FILE_ERROR_MESSAGE = 'Selected file has errors and cannot be imported.';

const headers = [
    'Activity Status',
    'Activity Type',
    'Program',
    'Activity Audience',
    'Activity Modality',
    'Course Name',
    'Delivery City',
    'Delivery State',
    'Delivery Country',
    'Delivery Language',
    'Delivery Start Date',
    'Delivery Start Time',
    'Delivery End Time',
    'Operations Owner Email',
    'Class Size',
];

interface BulkActivityErrors {
    [key: string]: string;
}
interface BulkActivity {
    [key: string]: string | number | boolean | BulkActivityErrors | undefined;
    Errors?: BulkActivityErrors;
}

const ActivityImport = () => {
    const [file, setFile] = useState<FileType>(null);
    const [, setFileTooLarge] = useState(false);
    const [importing, setImporting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [resetKey, setResetKey] = useState('');

    const history = useHistory();
    const { addNotification, removeAllNotifications } = useNotifications();
    const maxFileSize = 4194304; // 4mb to bytes

    useEffect(() => {
        removeAllNotifications();
    }, [removeAllNotifications]);

    const handleFileUpload = async () => {
        setErrorMessage('');
        const reader = new FileReader();
        reader.onload = async function (readerEvt) {
            const binaryString = readerEvt.target?.result as string;
            const base64 = btoa(binaryString);
            try {
                setImporting(true);
                const {
                    result: { activities = [], batch_request_id, message },
                } = await scheduleManagementApi.batchAddActivity(base64);

                if (
                    message.includes('Limit Exceeded') ||
                    message.includes('Empty file')
                ) {
                    setErrorMessage(message);
                    return setImporting(false);
                }

                setImporting(false);
                if (batch_request_id) {
                    history.push({
                        pathname: `/activities`,
                    });

                    addNotification({
                        id: `update-activity`,
                        type: 'success',
                        content: message,
                    });
                }

                if (activities.length) {
                    setErrorMessage(FILE_ERROR_MESSAGE);
                    const options = {
                        emptyFieldValue: '',
                    };
                    const DATETIME_FORMAT = 'YYYY-MM-DD';
                    const todaysDate = dayjs().format(DATETIME_FORMAT);
                    const formattedResultSet: Array<any> = [];

                    activities.forEach((activity: BulkActivity) => {
                        const json: BulkActivity = {};
                        headers.forEach((header) => {
                            const headerKeyName = header;
                            const displayHeader = header;
                            const val = activity.Errors![headerKeyName];
                            if (val) {
                                json['Row No'] =
                                    Number(activity.Errors!['Row No']) + 1; // Accounts for header in excel file
                                json[displayHeader] = `Error: ${val}`;
                            }
                        });
                        if (json.hasOwnProperty('Row No')) {
                            formattedResultSet.push(json);
                        }
                    });
                    json2csv(
                        formattedResultSet,
                        (err, csv) => {
                            if (err) {
                                throw err;
                            }

                            // Prepending the '\uFEFF' prefix is required to force MS Excel to
                            // recognize UTF-8 encoding, see https://code-examples.net/en/q/25dd9
                            const blob = new Blob(['\uFEFF', csv as BlobPart], {
                                type: 'text/csv',
                            });

                            // create file url
                            const fileURL = window.URL.createObjectURL(blob);
                            const fileLink = document.createElement('a');

                            fileLink.href = fileURL;
                            fileLink.setAttribute(
                                'download',
                                `ActivityErrors_${todaysDate}.csv`,
                            );
                            fileLink.setAttribute('type', 'text/csv');
                            fileLink.innerText = 'Download the error report';
                            fileLink.id = 'error-file-download';
                            const tempAnchor = document.getElementById(
                                'error-file-download',
                            );
                            tempAnchor!.parentNode!.replaceChild(
                                fileLink,
                                tempAnchor as Node,
                            );
                        },
                        options,
                    );
                }
            } catch (error: any) {
                if (error) {
                    setErrorMessage(
                        'Something went wrong. Download the provided Excel template.',
                    );
                    setImporting(false);
                }
            }
        };
        reader.readAsBinaryString(file as Blob);
        setFile(null);
        setResetKey('');
    };

    const handleFileChange = (event: CustomEvent<any>) => {
        const value: FileType = event.detail.value;
        setResetKey(Math.random().toString(36));
        setFile(value);
    };

    const handleCancelImportActivities = () => {
        history.push({
            pathname: '/activities',
        });
    };

    useEffect(() => {
        if (file && file instanceof File) {
            setFileTooLarge(file.size > maxFileSize);
        } else {
            setFileTooLarge(false);
        }
    }, [file, maxFileSize]);

    return (
        <>
            <Header variant="h1">{PAGE_TITLE}</Header>
            <Box margin={{ top: 'l' }}>
                <Container
                    header={<Header variant="h2">{CONTAINER_HEADER}</Header>}
                >
                    <Box variant="p">{CONTAINER_CONTENT}</Box>
                    <SpaceBetween direction="vertical" size="xs">
                        <Link
                            data-testid="template-download-btn"
                            href={DOWNLOAD_URL}
                        >
                            <>
                                <Icon name="download" variant="link" />{' '}
                                {DOWNLOAD_TEXT}
                            </>
                        </Link>
                        <Box margin={{ bottom: 'l' }}>
                            <FileUpload
                                buttonText={'Choose file'}
                                value={file}
                                onChange={(event) => handleFileChange(event)}
                                constraintText={
                                    'File size limit: 4mb, 100 rows'
                                }
                                accept={'.xlsx,.xls'}
                                key={resetKey}
                            />
                        </Box>
                        <Alert visible={!!errorMessage} type="warning">
                            {errorMessage}{' '}
                            <a href="/" id="error-file-download">
                                {' '}
                            </a>
                        </Alert>
                    </SpaceBetween>
                </Container>
                <Box margin={{ top: 'l', bottom: 'l' }} float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            onClick={handleCancelImportActivities}
                            data-testid="cancel-import-activities"
                        >
                            {CANCEL_IMPORT_ACTIVITIES_BUTTON}
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleFileUpload}
                            disabled={!file || importing}
                            data-testid="import-activities-btn"
                        >
                            {importing
                                ? IMPORTING_ACTIVITIES_BUTTON
                                : IMPORT_ACTIVITIES_BUTTON}
                        </Button>
                    </SpaceBetween>
                </Box>
            </Box>
        </>
    );
};

export default ActivityImport;
