import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ActivityAudiencesResponseData } from '../../interfaces/businessDataResponse/activityAudiencesResponse';
import { HandleRequestData } from '../../interfaces/handleRequest';
import businessDataApi from '../../api/businessDataApi';
import { BusinessDataApiQueryParams } from '../../interfaces/queryParams';
import { AppDispatch, GlobalState } from '../../../main/store';
import { ExtendedBusinessDataStoreState } from '../../interfaces/businessDataStoreState';
import { ActivityAudienceItemData } from '../../interfaces/businessDataItem/activityAudienceItem';
import parseBoolean from '../../utils/parseBoolean';
import {
    ExtendedBusinessDataStoreInitialState,
    getActiveBusinessDataValues,
    resetBasicBusinessDataStoreState,
    resetExtendedBusinessDataStoreState,
    initializeBusinessDataListQueryParamsStoreState,
} from '../store.common';

/**
 * activityAudiencesSlice manages all activityAudiences state, and contains activityAudiences actions as well as activityAudiences state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const activityAudiencesSlice = createSlice({
    name: 'activityAudiences',
    initialState: {
        ...ExtendedBusinessDataStoreInitialState,
    } as ExtendedBusinessDataStoreState<ActivityAudienceItemData>,
    reducers: {
        setActivityAudiencesList: (
            state,
            action: PayloadAction<ActivityAudienceItemData[]>,
        ) => {
            const byActivityAudienceName = action.payload.reduce(
                (
                    byActivityAudienceName: {
                        [key: string]: ActivityAudienceItemData;
                    },
                    activityAudience: ActivityAudienceItemData,
                ) => {
                    byActivityAudienceName[
                        activityAudience.activity_audience
                    ] = {
                        ...activityAudience,
                        active: parseBoolean(activityAudience.active),
                    };
                    return byActivityAudienceName;
                },
                {},
            );
            state.entities = byActivityAudienceName;
            state.keysList = Object.keys(byActivityAudienceName);
        },
        setActivityAudience: (
            state,
            action: PayloadAction<ActivityAudienceItemData>,
        ) => {
            // if the item is new, add a new key for it and update the count.
            if (!state.entities[action.payload.activity_audience]) {
                state.keysList = [
                    ...state.keysList,
                    action.payload.activity_audience,
                ];
                state.count = state.keysList.length;
            }
            state.entities[action.payload.activity_audience] = action.payload;
        },
        setSelectedActivityAudience: (
            state,
            action: PayloadAction<string | null>,
        ) => {
            state.selectedItemKey = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
        setCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload;
        },
        setSearchText: (state, action: PayloadAction<string | null>) => {
            state.searchText = action.payload;
        },
        setActive: (
            state,
            action: PayloadAction<BusinessDataApiQueryParams.Active>,
        ) => {
            state.active = action.payload;
        },
        setFrom: (state, action: PayloadAction<number>) => {
            state.from = action.payload;
        },
        setPagesCount: (state, action: PayloadAction<number>) => {
            state.pagesCount = action.payload;
        },
        setCurrentPageIndex: (state, action: PayloadAction<number>) => {
            state.currentPageIndex = action.payload;
        },
        setSize: (state, action: PayloadAction<number>) => {
            state.size = action.payload;
        },
        resetPartialActivityAudiencesSlice: resetBasicBusinessDataStoreState,
        resetActivityAudiencesSlice: resetExtendedBusinessDataStoreState,
        initializeActivityAudiencesListQueryParams: initializeBusinessDataListQueryParamsStoreState,
    },
});

export const {
    setIsLoading,
    setIsLoaded,
    setActivityAudiencesList,
    setActivityAudience,
    setSelectedActivityAudience,
    setError,
    setCount,
    setSearchText,
    setActive,
    setFrom,
    setPagesCount,
    setCurrentPageIndex,
    setSize,
    resetPartialActivityAudiencesSlice,
    resetActivityAudiencesSlice,
    initializeActivityAudiencesListQueryParams,
} = activityAudiencesSlice.actions;

const handleActivityAudiencesListRequest = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        const {
            from,
            size,
            searchText: activity_audience,
            active,
        } = state.activityAudiences;

        const params: BusinessDataApiQueryParams.GetActivityAudiences = {
            active,
        };

        // if both "from" and "size" equal to 0, avoid adding them into the query parameters
        if (from !== 0 || size !== 0) {
            params.from = from;
            params.size = size;
        }
        if (activity_audience) {
            params.activity_audience = activity_audience;
        }

        try {
            const {
                result: { ACTIVITY_AUDIENCE, total_count },
            }: HandleRequestData<ActivityAudiencesResponseData> = await businessDataApi.getActivityAudiences(
                params,
            );

            dispatch(setActivityAudiencesList(ACTIVITY_AUDIENCE));
            dispatch(setCount(total_count));
            dispatch(setPagesCount(Math.ceil(total_count / size)));
        } catch (error: any) {
            dispatch(
                setError(error?.message || 'getActivityAudiences API error'),
            );
        }
    };
};

export const getActivityAudiencesList = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));
        await dispatch(handleActivityAudiencesListRequest());
        if (!state.activityAudiences.isLoaded) {
            dispatch(setIsLoaded(true));
        }
        dispatch(setIsLoading(false));
    };
};

/**
 * action to update program
 * before update api call, fetch program types and prepare payload containing only the changed fields
 * after update api call, fetch program types and update the current item in that keysList with the item data we updated
 * @param data
 */
export const updateActivityAudience = (data: ActivityAudienceItemData) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        // immediately put the keysList into loading while we finish updating the item/fetching the keysList
        dispatch(setIsLoading(true));
        if (!data.pk || data?.pk.length <= 0) {
            // eslint-disable-next-line no-console
            console.error(
                `Error updating new program: required field pk was missing`,
            );
            dispatch(setIsLoading(false));
            return false;
        }
        try {
            await businessDataApi.updateActivityAudience(data.pk, data.active);
            await dispatch(handleActivityAudiencesListRequest());
            // force-update the item in the keysList for now, since the keysList we fetched may not have this item's update yet
            dispatch(
                setActivityAudience({
                    ...state.activityAudiences.entities[data.activity_audience],
                    active: data.active,
                }),
            );
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const addActivityAudience = (
    active = true,
    activityAudienceName: string,
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        dispatch(setIsLoading(true));
        try {
            await businessDataApi.addActivityAudience(
                activityAudienceName,
                active,
            );
            // now refresh the keysList
            await dispatch(handleActivityAudiencesListRequest());
            if (!state.activityAudiences?.entities[activityAudienceName]) {
                // shove the item into the keysList, but only if it's not there already
                const newActivityAudienceData: ActivityAudienceItemData = {
                    activity_audience: activityAudienceName,
                    active: active,
                };
                dispatch(setActivityAudience(newActivityAudienceData));
            }
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const selectAllActivityAudiences = (state: GlobalState) => {
    return state.activityAudiences.keysList.map(
        (activityAudienceName) =>
            state.activityAudiences.entities[activityAudienceName],
    );
};

export const selectAllActiveActivityAudiences = (state: GlobalState) => {
    return getActiveBusinessDataValues(
        state.activityAudiences.keysList,
        state.activityAudiences.entities,
    );
};

export const selectIsLoading = (state: GlobalState) =>
    state.activityAudiences.isLoading;

export const selectIsLoaded = (state: GlobalState) =>
    state.activityAudiences.isLoaded;

export const selectSelectedActivityAudience = (state: GlobalState) => {
    return state?.activityAudiences?.selectedItemKey
        ? state?.activityAudiences?.entities[
              state?.activityAudiences?.selectedItemKey
          ]
        : null;
};

export const selectError = (state: GlobalState) =>
    state.activityAudiences.error;

export const selectCount = (state: GlobalState) =>
    state.activityAudiences.count;

export const selectSearchText = (state: GlobalState) =>
    state.activityAudiences.searchText;

export const selectPagesCount = (state: GlobalState) =>
    state.activityAudiences.pagesCount;

export const selectCurrentPageIndex = (state: GlobalState) =>
    state.activityAudiences.currentPageIndex;

export default activityAudiencesSlice.reducer;
