import React from 'react';
import {
    Box,
    ColumnLayout,
    SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import { formatString } from '../../../../../common/utils/formatString';
import dayjs from 'dayjs';
import {
    BlockedTime,
    BlockedTimeTimestamp,
} from '../../../../../common/interfaces/blockedTime';
import { selectInstructorLookup } from '../../../../store/slices/instructorListSlice';
import { useSelector } from 'react-redux';
import { RecommendedInstructorProfileData } from '../../../../interfaces/recommendedInstructorProfile';
import FullCalendar from '@fullcalendar/react';
import { formatUnixTimeInTimezone } from '../../../../../common/utils/date-time.utils';
import { mapTimestampsToCalendarView } from '../../../../services/calendar-service';

const FreelancerDateString = ({
    orderedTimestamps,
    instructor,
}: {
    orderedTimestamps: BlockedTimeTimestamp[];
    instructor: RecommendedInstructorProfileData;
}) => {
    const startTime = orderedTimestamps[0].start_timestamp;
    const endTime =
        orderedTimestamps[orderedTimestamps.length - 1].end_timestamp;

    const starting_timestamp = formatUnixTimeInTimezone({
        timestamp: startTime,
        timezone: instructor.city_timezone,
        format: 'MMMM D, YYYY',
    });
    const ending_timestamp = formatUnixTimeInTimezone({
        timestamp: endTime,
        timezone: instructor.city_timezone,
        format: 'MMMM D, YYYY',
    });

    return (
        <Box variant="p">
            {starting_timestamp}
            {starting_timestamp !== ending_timestamp && (
                <>
                    {` - `}
                    {ending_timestamp}
                </>
            )}
        </Box>
    );
};

const InstructorDateString = ({
    timestamp,
    instructor,
}: {
    timestamp: BlockedTimeTimestamp;
    instructor: RecommendedInstructorProfileData;
}) => {
    const startTime = timestamp.start_timestamp;
    const endTime = timestamp.end_timestamp;

    const starting_timestamp = formatUnixTimeInTimezone({
        timestamp: startTime,
        timezone: instructor.city_timezone,
        format: 'MMMM D, YYYY',
    });
    const ending_timestamp = formatUnixTimeInTimezone({
        timestamp: endTime,
        timezone: instructor.city_timezone,
        format: 'MMMM D, YYYY',
    });
    const startTimeString =
        starting_timestamp === ending_timestamp
            ? dayjs
                  .unix(timestamp.start_timestamp)
                  .tz(instructor.city_timezone)
                  .format('MMMM D, YYYY h:mm a')
            : dayjs
                  .unix(timestamp.start_timestamp)
                  .tz(instructor.city_timezone)
                  .format('MMMM D, YYYY');
    const endTimeString =
        starting_timestamp === ending_timestamp
            ? dayjs
                  .unix(timestamp.end_timestamp)
                  .tz(instructor.city_timezone)
                  .format('h:mm a')
            : dayjs
                  .unix(timestamp.end_timestamp)
                  .tz(instructor.city_timezone)
                  .format('MMMM D, YYYY');
    return (
        <Box variant="p">
            {startTimeString}
            {` - `}
            {endTimeString}
        </Box>
    );
};

export interface BlockTimeDatesProps {
    timestamp: BlockedTimeTimestamp;
    instructor: RecommendedInstructorProfileData;
}

interface BlockedTimePopoverTemplateProps {
    blockedTime: BlockedTime;
    calendarRef: FullCalendar | undefined;
}

const BlockedTimePopoverTemplate = ({
    blockedTime,
    calendarRef,
}: BlockedTimePopoverTemplateProps) => {
    // Get Instructor and order blocked times
    const instructorLookup = useSelector(selectInstructorLookup);
    let instructor = {} as RecommendedInstructorProfileData;
    if (blockedTime.instructor_pk) {
        instructor = instructorLookup[blockedTime.instructor_pk];
    }
    /**
     * Using calendarRef, get calendar current view(day, week, month)
     * With the calendarRef's initialDate layout the timestamps by day starting
     * dates with initialDate to current view end
     */
    const timestamps = blockedTime.blocked_time_timestamps;
    let orderedTimestamps = [] as BlockedTimeTimestamp[];
    if (instructor?.is_freelancer) {
        orderedTimestamps = mapTimestampsToCalendarView({
            calendarRef,
            timestamps,
            city_timezone: instructor.city_timezone,
        });
    } else {
        orderedTimestamps = [...timestamps].sort(
            (x, y) => x.start_timestamp - y.start_timestamp,
        );
    }

    if (!instructor) {
        return <></>;
    }

    return (
        <>
            <ColumnLayout columns={1} variant="text-grid">
                <SpaceBetween size="l">
                    <div>
                        <Box margin={{ bottom: 'xxxs' }} color="text-label">
                            Instructor name
                        </Box>
                        {instructor?.full_name && (
                            <div>{formatString(instructor.full_name)}</div>
                        )}
                    </div>
                    <div>
                        <Box margin={{ bottom: 'xxxs' }} color="text-label">
                            Block time name
                        </Box>
                        <div>{formatString(blockedTime.blocked_time_name)}</div>
                    </div>
                    <div>
                        <Box margin={{ bottom: 'xxxs' }} color="text-label">
                            Block time dates
                        </Box>
                        {instructor?.is_freelancer &&
                            orderedTimestamps.length > 0 && (
                                <FreelancerDateString
                                    {...{ orderedTimestamps, instructor }}
                                />
                            )}
                        {!instructor.is_freelancer &&
                            orderedTimestamps.map((timestamp, i) => {
                                return (
                                    <InstructorDateString
                                        {...{ timestamp, instructor, key: i }}
                                    />
                                );
                            })}
                    </div>
                    <div>
                        <Box margin={{ bottom: 'xxxs' }} color="text-label">
                            Timezone
                        </Box>
                        <Box variant="p">
                            {instructor && !!instructor.city_timezone
                                ? instructor.city_timezone
                                : ''}
                        </Box>
                    </div>
                    <div>
                        <Box margin={{ bottom: 'xxxs' }} color="text-label">
                            Block notes
                        </Box>
                        <Box variant="p">
                            {!!blockedTime.blocked_time_notes
                                ? blockedTime.blocked_time_notes
                                : ''}
                        </Box>
                    </div>
                </SpaceBetween>
            </ColumnLayout>
        </>
    );
};

export default BlockedTimePopoverTemplate;
