import FullCalendar from '@fullcalendar/react';
import dayjs from 'dayjs';
import { BlockedTimeTimestamp } from '../../common/interfaces/blockedTime';
import {
    convertTimeStampToLocalUnix,
    endOfDayInTimezone,
    formatUnixTimeInTimezone,
    getUnixTimeInTimezoneFromDatestring,
    startOfDayInTimezone,
} from '../../common/utils/date-time.utils';
import { RecommendedInstructorProfileData } from '../interfaces/recommendedInstructorProfile';

export interface BlockedTimeTimestampWithId extends BlockedTimeTimestamp {
    id: string;
}

export const mapBlockedTimes = ({
    calendarViewBlockTimes,
    originalBlockedTime,
}: {
    calendarViewBlockTimes: BlockedTimeTimestampWithId[];
    originalBlockedTime: BlockedTimeTimestampWithId;
}) => {
    return calendarViewBlockTimes.reduce(
        (acc, blockedTime, currentIndex, calendarViewBlockTimes) => {
            const isFirst = currentIndex === 0;
            const isLast = currentIndex === calendarViewBlockTimes.length - 1;

            if (isFirst) {
                if (blockedTime.removeFlag === true) {
                    if (
                        blockedTime.start_timestamp >
                        originalBlockedTime.start_timestamp
                    ) {
                        acc.mappedBlockedTimes.push({
                            id: originalBlockedTime.id,
                            start_timestamp:
                                originalBlockedTime.start_timestamp,
                            end_timestamp: blockedTime.start_timestamp - 1,
                            removeFlag: blockedTime.removeFlag,
                        });
                    } else {
                        return acc;
                    }
                } else {
                    acc.mappedBlockedTimes.push({
                        id: originalBlockedTime.id,
                        start_timestamp: originalBlockedTime.start_timestamp,
                        end_timestamp: blockedTime.end_timestamp,
                        removeFlag: blockedTime.removeFlag,
                    });
                }
            } else if (isLast) {
                if (blockedTime.removeFlag === true) {
                    if (
                        originalBlockedTime.end_timestamp >
                        blockedTime.end_timestamp
                    ) {
                        acc.mappedBlockedTimes.push({
                            id: originalBlockedTime.id,
                            start_timestamp: blockedTime.end_timestamp + 1,
                            end_timestamp: originalBlockedTime.end_timestamp,
                        });
                    } else {
                        return acc;
                    }
                } else {
                    let previousAccBlockedTime =
                        acc.mappedBlockedTimes.pop() ||
                        ({} as BlockedTimeTimestampWithId);
                    if (
                        previousAccBlockedTime.end_timestamp + 1 ===
                        blockedTime.start_timestamp
                    ) {
                        previousAccBlockedTime!.end_timestamp =
                            originalBlockedTime.end_timestamp;
                        acc.mappedBlockedTimes.push(previousAccBlockedTime);
                    } else {
                        if (previousAccBlockedTime.hasOwnProperty('id')) {
                            acc.mappedBlockedTimes.push(
                                previousAccBlockedTime,
                                {
                                    id: originalBlockedTime.id,
                                    start_timestamp:
                                        blockedTime.start_timestamp,
                                    end_timestamp:
                                        originalBlockedTime.end_timestamp,
                                    removeFlag: originalBlockedTime.removeFlag,
                                },
                            );
                        } else {
                            acc.mappedBlockedTimes.push(blockedTime);
                        }
                    }
                }
            } else {
                if (blockedTime.removeFlag === true) {
                    return acc;
                } else {
                    let previousAccBlockedTime =
                        acc.mappedBlockedTimes.pop() ||
                        ({} as BlockedTimeTimestampWithId);
                    if (
                        previousAccBlockedTime.end_timestamp + 1 ===
                        blockedTime.start_timestamp
                    ) {
                        previousAccBlockedTime.end_timestamp =
                            blockedTime.end_timestamp;
                        acc.mappedBlockedTimes.push(previousAccBlockedTime);
                    } else {
                        if (previousAccBlockedTime.hasOwnProperty('id')) {
                            acc.mappedBlockedTimes.push(
                                previousAccBlockedTime,
                                blockedTime,
                            );
                        } else {
                            acc.mappedBlockedTimes.push(blockedTime);
                        }
                    }
                }
            }
            return acc;
        },
        {
            originalBlockedTime,
            mappedBlockedTimes: [] as BlockedTimeTimestampWithId[],
        },
    );
};

export const getBlockedTimeStartAndEndTimesByInstructorType = (
    blockedTimestamps: BlockedTimeTimestamp,
    instructor: RecommendedInstructorProfileData,
) => {
    const { is_freelancer, city_timezone } = instructor;
    const startTimestamp = is_freelancer
        ? startOfDayInTimezone(blockedTimestamps.start_timestamp, city_timezone)
        : blockedTimestamps.start_timestamp;
    const start =
        convertTimeStampToLocalUnix({
            timestamp: startTimestamp,
            timezone: city_timezone,
        }) * 1000;
    const endTimestamp = is_freelancer
        ? endOfDayInTimezone(blockedTimestamps.end_timestamp, city_timezone)
        : blockedTimestamps.end_timestamp;
    const end =
        convertTimeStampToLocalUnix({
            timestamp: endTimestamp,
            timezone: city_timezone,
        }) * 1000;
    return {
        start,
        end,
    };
};

export const mapTimestampsToCalendarView = ({
    calendarRef,
    timestamps,
    city_timezone,
}: {
    calendarRef: FullCalendar | undefined;
    timestamps: BlockedTimeTimestamp[];
    city_timezone: string;
}) => {
    const calendarApi = calendarRef?.getApi();
    const viewIndex: any = {
        day: 0,
        week: 6,
        month: dayjs(calendarApi?.view.title).daysInMonth() - 1,
    };
    const currentViewState = calendarApi
        ? calendarApi?.getCurrentData().viewSpec.durationUnit
        : 'week';
    const currentCalendarIndex = viewIndex[currentViewState];
    let orderedTimestamps = [] as BlockedTimeTimestamp[];
    const browserTime = (calendarRef?.props.initialDate as number) / 1000;
    const browserTimeAsDateString = formatUnixTimeInTimezone({
        timestamp: browserTime,
        timezone: dayjs.tz.guess(),
        format: 'YYYY-MM-DDTHH:mm:ss',
    });
    const browserTimeAsInstructorUnix = getUnixTimeInTimezoneFromDatestring({
        datestring: browserTimeAsDateString,
        timezone: city_timezone,
    });

    for (let i = 0; i <= currentCalendarIndex; i++) {
        const calendarDayStartTime = dayjs(
            dayjs.unix(browserTimeAsInstructorUnix),
        )
            .add(i, 'day')
            .unix();
        const calendarDayEndTime = endOfDayInTimezone(
            calendarDayStartTime,
            city_timezone,
        );
        for (let time = 0; time < [...timestamps].length; time++) {
            const calendarEventTimestamps = [...timestamps][time];
            const startTimeCalendarEvent =
                calendarEventTimestamps.start_timestamp;
            const endTimeCalendarEvent = calendarEventTimestamps.end_timestamp;
            if (
                startTimeCalendarEvent <= calendarDayEndTime &&
                endTimeCalendarEvent >= calendarDayStartTime
            ) {
                orderedTimestamps.push({
                    start_timestamp: calendarDayStartTime,
                    end_timestamp: calendarDayEndTime,
                });
            }
        }
    }
    return orderedTimestamps;
};
