import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SelectedActivityResponseData } from '../../interfaces/selectedActivityResponse';
import { ActivityData, CreateActivityData } from '../../interfaces/activity';
import { HandleRequestData } from '../../../common/interfaces/handleRequest';
import scheduleManagementApi from '../../api/scheduleManagementApi';
import { AppDispatch, GlobalState } from '../../../main/store';
import { GenericStoreState } from '../../../common/interfaces/genericStoreState';
import { CreateActivityResponseData } from '../../interfaces/createActivityResponse';
import { ApiError } from '../../../common/classes/ApiError';
import { ScheduleManagementAPIQueryParams } from '../../interfaces/queryParams';

export interface ActivityState extends GenericStoreState {
    selectedActivity: SelectedActivityResponseData | null;
    isLoaded: boolean;
    isNewActivity: boolean;
    requireUpdate: boolean;
    previousTimeStamp: number | null;
}

export const initialState = {
    selectedActivity: null,
    error: null,
    isLoading: false,
    isLoaded: false,
    isNewActivity: false,
    requireUpdate: false,
    previousTimeStamp: null,
} as ActivityState;

/**
 * activitySlice manages all activity state, and contains activity actions as well as activity state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const selectedActivitySlice = createSlice({
    name: 'selectedActivity',
    initialState: initialState,
    reducers: {
        setSelectedActivity: (
            state,
            action: PayloadAction<SelectedActivityResponseData | null>,
        ) => {
            state.selectedActivity = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
        setRequireUpdate: (state, action: PayloadAction<boolean>) => {
            state.requireUpdate = action.payload;
        },
        setIsNewActivity: (state, action: PayloadAction<boolean>) => {
            state.isNewActivity = action.payload;
        },
        setPreviousBETimeStamp: (
            state,
            action: PayloadAction<number | null>,
        ) => {
            state.previousTimeStamp = action.payload;
        },
    },
});

export const {
    setSelectedActivity,
    setError,
    setIsLoading,
    setIsLoaded,
    setRequireUpdate,
    setIsNewActivity,
    setPreviousBETimeStamp,
} = selectedActivitySlice.actions;

export const createActivity = (formValues: CreateActivityData) => {
    return async (dispatch: AppDispatch): Promise<any> => {
        dispatch(setIsLoading(true));

        let newActivityData = {
            ...formValues,
        } as CreateActivityData;

        try {
            const {
                result: { activity_id },
            }: HandleRequestData<CreateActivityResponseData> = await scheduleManagementApi.createActivity(
                newActivityData as CreateActivityData,
            );
            dispatch(setIsLoading(false));
            return {
                isSuccessful: true,
                createdActivityId: activity_id,
            };
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return {
                isSuccessful: false,
                statusCode: (error as ApiError)?.statusCode,
            };
        }
    };
};

/**
 * getSelectedActivity is an async action used to fetch activity data.
 * There is no explicit inclusion of redux-thunk logic, as the redux toolkit takes care of this for us.
 */
export const getSelectedActivity = (id: string) => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        dispatch(setIsLoading(true));
        const state = getState();
        try {
            const {
                result,
            }: HandleRequestData<SelectedActivityResponseData> = await scheduleManagementApi.getActivityById(
                { id } as ScheduleManagementAPIQueryParams.GetActivityById,
            );
            if (result.activity) {
                dispatch(setSelectedActivity(result));
                dispatch(setRequireUpdate(false));
            }
        } catch (error: any) {
            dispatch(setError(error.toString()));
        }
        if (!state.selectedActivity.isLoaded) {
            dispatch(setIsLoaded(true));
        }
        dispatch(setIsLoading(false));
    };
};

export const updateSelectedActivity = (
    id: string,
    activity: Partial<ActivityData>,
) => async (
    dispatch: AppDispatch,
    getState: () => GlobalState,
): Promise<boolean> => {
    const state = getState();
    dispatch(setIsLoading(true));

    if (!id) {
        // eslint-disable-next-line no-console
        console.error(`Error updating activity: required field id was missing`);
        dispatch(setIsLoading(false));
        return false;
    }

    // remove properties that aren't allowed in update
    const {
        record_type,
        created_by,
        created_timestamp,
        modified_timestamp,
        modified_by,
        record_ttl,
        pk,
        ...data
    } = activity;

    try {
        await scheduleManagementApi.updateActivityById({
            id,
            activity: data as ActivityData,
        });

        const newActivity = {
            message: state.selectedActivity.selectedActivity?.message,
            activity: {
                ...state.selectedActivity.selectedActivity?.activity,
                ...activity,
            },
        } as SelectedActivityResponseData;

        dispatch(setSelectedActivity(newActivity));
        dispatch(setIsLoading(false));
        return true;
    } catch (error: any) {
        dispatch(setIsLoading(false));
        return false;
    }
};

export const selectSelectedActivity = (state: GlobalState) =>
    state.selectedActivity.selectedActivity;
export const selectError = (state: GlobalState) => state.selectedActivity.error;
export const selectIsLoading = (state: GlobalState) =>
    state.selectedActivity.isLoading;
export const selectIsLoaded = (state: GlobalState) =>
    state.selectedActivity.isLoaded;

export default selectedActivitySlice.reducer;
