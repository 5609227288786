import {
    ColumnLayout,
    FormField,
    FormSection,
    Input,
    Multiselect,
    Select,
} from '@amzn/awsui-components-react';
import {
    FORM_ERROR_SELECTOR,
    getOptionsAndLookupForSelectInput,
    getStatusType,
} from '../../../../imt/components/Instructor/FormSections/FormSections.common';
import {
    getProgramTypesList,
    resetProgramTypesSlice,
    selectActivePlusUserSelections as selectProgramList,
    selectError as selectProgramListError,
    selectIsLoaded as selectIsProgramListLoaded,
    selectIsLoading as selectIsProgramListLoading,
} from '../../../../common/store/slices/programTypesSlice';
import {
    getRegionsList,
    resetRegionsSlice,
    selectActivePlusUserSelections as selectRegionList,
    selectError as selectRegionListError,
    selectIsLoaded as selectIsRegionListLoaded,
    selectIsLoading as selectIsRegionListLoading,
} from '../../../../common/store/slices/regionsSlice';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProgramTypeItemData } from '../../../../common/interfaces/businessDataItem/programTypeItem';
import { RegionItemData } from '../../../../common/interfaces/businessDataItem/regionItem';
import { FormSectionProps } from '../../../../common/interfaces/formSectionProps';
import {
    FormSectionMode,
    ProgramFormFieldText,
} from '../../../../common/constants/forms';
import { UserFormData } from '../../../interfaces/userForm';
import {
    GANDALF_INSTRUCTOR_ADMIN_GROUP,
    GANDALF_INSTRUCTOR_READ_ONLY,
    GANDALF_SCHEDULE_ADMIN_GROUP,
    GANDALF_SCHEDULE_READ_ONLY,
    GANDALF_SYS_ADMIN_GROUP,
} from '../../../../common/constants/grimsby';
import { v4 as uuidv4 } from 'uuid';
import { checkFeature } from '../../../../common/utils/featureFlag';
import { selectFeatures } from '../../../../common/store/slices/featureSlice';

const parseValuesIntoSelectedOptions = (
    values: Array<string>,
    lookup: { [p: string]: Select.Option },
) => {
    return values.reduce(
        (acc: Array<{ label: string; id: string }>, program: string) => {
            if (lookup[program]) {
                acc.push(lookup[program]);
            }
            return acc;
        },
        [],
    );
};
const regionsLoadingText = 'Loading regions';
export const regionsErrorText = 'An error occurred while loading regions';
const rolesErrorText = 'An error occured while loading roles';

const AdminUserManagementFormSection = ({
    formValues,
    errors,
    handleFieldEvent,
    name,
    mode,
}: Omit<FormSectionProps<UserFormData>, 'validateAndHandleFieldEvent'> & {
    name: string;
}) => {
    const isProgramListLoading = useSelector(selectIsProgramListLoading);
    const isProgramListLoaded = useSelector(selectIsProgramListLoaded);
    const programListError = useSelector(selectProgramListError);
    const programList = useSelector(selectProgramList);
    const isRegionListLoading = useSelector(selectIsRegionListLoading);
    const isRegionListLoaded = useSelector(selectIsRegionListLoaded);
    const regionListError = useSelector(selectRegionListError);
    const regionList = useSelector(selectRegionList);
    const featureFlags = useSelector(selectFeatures);
    const dispatch = useDispatch();

    const businessDataInitList: ReadonlyArray<[boolean, () => Function]> = [
        [!isProgramListLoaded && !isProgramListLoading, getProgramTypesList],
        [!isRegionListLoaded && !isRegionListLoading, getRegionsList],
    ];

    useEffect(() => {
        businessDataInitList.forEach(([shouldFetch, getList]) => {
            if (shouldFetch) {
                dispatch(getList());
            }
        });
    });

    const { valueLookup: programLookup, valueOptions: programOptions } =
        getOptionsAndLookupForSelectInput<ProgramTypeItemData>(
            programList,
            (program: ProgramTypeItemData) => ({
                label: program.program_type,
                id: program.pk as string,
            }),
        );
    const { valueLookup: regionLookup, valueOptions: regionOptions } =
        getOptionsAndLookupForSelectInput<RegionItemData>(
            regionList,
            (region: RegionItemData) => ({
                label: region.region,
                id: region.pk as string,
            }),
        );

    const userRoleList = [
        {
            role: GANDALF_INSTRUCTOR_ADMIN_GROUP,
            pk: uuidv4(),
        },
        {
            role: GANDALF_SYS_ADMIN_GROUP,
            pk: uuidv4(),
        },
        {
            role: GANDALF_SCHEDULE_ADMIN_GROUP,
            pk: uuidv4(),
        },
        {
            role: GANDALF_SCHEDULE_READ_ONLY,
            pk: uuidv4(),
        },
        {
            role: GANDALF_INSTRUCTOR_READ_ONLY,
            pk: uuidv4(),
        },
    ];

    const { valueLookup: roleLookup, valueOptions: roleOptions } =
        getOptionsAndLookupForSelectInput<any>(userRoleList, (role: any) => ({
            label: role.role,
            id: role.pk as string,
        }));

    return (
        <FormSection data-testid="AdminUserManagementFormSection" header={name}>
            <ColumnLayout>
                <div data-awsui-column-layout-root="true">
                    {mode === FormSectionMode.Create && (
                        <FormField
                            label="Email address"
                            errorText={errors?.email}
                        >
                            <Input
                                className={errors?.email && FORM_ERROR_SELECTOR}
                                value={formValues.email}
                                onInput={(e) =>
                                    handleFieldEvent({
                                        email: e.detail.value,
                                    })
                                }
                                data-testid={`AdminUserManagement${mode}Email`}
                            />
                        </FormField>
                    )}
                    <FormField errorText={errors?.programs} label="Programs">
                        <Multiselect
                            className={errors?.programs && FORM_ERROR_SELECTOR}
                            data-testid={`AdminUserManagement${mode}SelectPrograms`}
                            options={programOptions}
                            placeholder={
                                isProgramListLoading
                                    ? ProgramFormFieldText.Loading
                                    : ProgramFormFieldText.Placeholder
                            }
                            checkboxes={true}
                            selectedOptions={
                                isProgramListLoaded && !isProgramListLoading
                                    ? parseValuesIntoSelectedOptions(
                                          formValues?.programs || [],
                                          programLookup,
                                      )
                                    : []
                            }
                            selectedLabel="Selected"
                            deselectLabel={(programOption) =>
                                `Deselect ${programOption.label}`
                            }
                            onChange={(event) =>
                                handleFieldEvent({
                                    programs: event.detail.selectedOptions.map(
                                        (program) => program.label,
                                    ),
                                })
                            }
                            onRecoveryClick={() => {
                                dispatch(resetProgramTypesSlice());
                                dispatch(getProgramTypesList());
                            }}
                            loadingText={ProgramFormFieldText.Loading}
                            errorText={ProgramFormFieldText.Error}
                            recoveryText="Retry"
                            empty={ProgramFormFieldText.Empty}
                            statusType={getStatusType(
                                isProgramListLoading,
                                isProgramListLoaded,
                                programListError,
                            )}
                            disabled={isProgramListLoading}
                        />
                    </FormField>
                    <FormField errorText={errors?.regions} label="Regions">
                        <Multiselect
                            className={errors?.regions && FORM_ERROR_SELECTOR}
                            data-testid={`AdminUserManagement${mode}SelectRegions`}
                            options={regionOptions}
                            placeholder={
                                isProgramListLoading
                                    ? regionsLoadingText
                                    : 'Select regions'
                            }
                            checkboxes={true}
                            selectedOptions={
                                isRegionListLoaded && !isRegionListLoading
                                    ? parseValuesIntoSelectedOptions(
                                          formValues?.regions || [],
                                          regionLookup,
                                      )
                                    : []
                            }
                            selectedLabel="Selected"
                            deselectLabel={(regionOption) =>
                                `Deselect ${regionOption.label}`
                            }
                            onChange={(event) =>
                                handleFieldEvent({
                                    regions: event.detail.selectedOptions.map(
                                        (regions) => regions.label,
                                    ),
                                })
                            }
                            onRecoveryClick={() => {
                                dispatch(resetRegionsSlice());
                                dispatch(getRegionsList());
                            }}
                            loadingText="Loading regions"
                            errorText={regionsErrorText}
                            recoveryText="Retry"
                            empty="No regions found"
                            statusType={getStatusType(
                                isRegionListLoading,
                                isRegionListLoaded,
                                regionListError,
                            )}
                            disabled={isRegionListLoading}
                        />
                    </FormField>
                    {checkFeature(
                        '',
                        { featureName: 'gandalf-roles' },
                        featureFlags?.features,
                    ) && (
                        <FormField errorText={errors?.vias_roles} label="Roles">
                            <Multiselect
                                className={
                                    errors?.vias_roles && FORM_ERROR_SELECTOR
                                }
                                data-testid={`AdminUserManagement${mode}SelectRoles`}
                                options={roleOptions}
                                checkboxes={true}
                                placeholder="Select roles"
                                selectedOptions={parseValuesIntoSelectedOptions(
                                    formValues?.vias_roles || [],
                                    roleLookup,
                                )}
                                selectedLabel="Selected"
                                deselectLabel={(rolesOption) =>
                                    `Deselect ${rolesOption.label}`
                                }
                                onChange={(event) =>
                                    handleFieldEvent({
                                        vias_roles:
                                            event.detail.selectedOptions.map(
                                                (roles) => roles.label,
                                            ),
                                    })
                                }
                                errorText={rolesErrorText}
                                recoveryText="Retry"
                                empty="No roles found"
                                statusType={getStatusType(
                                    isRegionListLoading,
                                    isRegionListLoaded,
                                    regionListError,
                                )}
                            />
                        </FormField>
                    )}
                </div>
            </ColumnLayout>
        </FormSection>
    );
};

export default AdminUserManagementFormSection;
