import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form } from '@amzn/awsui-components-react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import {
    selectIsLoading,
    updateSelectedActivity,
} from '../../../store/slices/selectedActivitySlice';
import { useNotifications } from '../../../../common/context/grimsbyNotifications';
import useFormValidation, {
    ValidationType,
} from '../../../../common/utils/formValidation';
import { ActivityData } from '../../../interfaces/activity';
import { FORM_ERROR_SELECTOR } from '../../../../imt/components/Instructor/FormSections/FormSections.common';
import {
    ActivitySessionControlArrayFormValues,
    BasicActivityFormSectionProps,
    CANCELLED_STATUS,
} from '../Common/Common';
import { OptionDefinition } from '@amzn/awsui-components-react-v3/polaris/internal/components/option/interfaces';
import EditBaseDetailsFormSection from '../FormSections/EditBaseDetailsFormSection';
import EditBaseDetailsCancellationModal from './EditBaseDetailsModals/EditBaseDetailsCancellationModal';
import CancelModal, {
    CancelModalProps,
} from '../../../../common/components/CancelModal/CancelModal';
import { ACTIVITY_VALIDATION_FIELDS } from '../Common/Validations';
import { UpdateActivityErrorMessage } from '../../Common/constants/flashContent';

const editBaseDetailsValidationConfig: {
    [key in ValidationType]?: Array<keyof ActivityData>;
} = {
    required: [
        'activity_status',
        'activity_audience',
        'activity_modality',
        'activity_name',
    ],
};

const EditBaseDetailsForm = ({
    initialFormState,
}: {
    initialFormState: ActivityData;
}) => {
    const match = useRouteMatch<{ id: string }>();
    const isLoading = useSelector(selectIsLoading);
    const history = useHistory();
    const dispatch = useDispatch();
    const { addNotification, removeAllNotifications } = useNotifications();

    const { isInvalid, errors, validateForm } = useFormValidation<
        ActivityData,
        ActivitySessionControlArrayFormValues
    >();

    const [formValues, setFormValues] =
        useState<ActivityData>(initialFormState);
    const [submitting, setSubmitting] = useState(false);
    const [cancelModalVisible, setCancelModalVisible] = useState(false);
    const [cancellationReason, setCancellationReason] =
        useState<OptionDefinition | null>(null);
    const [showCancelationReason, setShowCancelationReason] = useState(false);
    const defaultShowAdditionalFields = {
        showDeliveryAddress: false,
        showNumberOfStudents: false,
    };
    const [showAdditionalFields, setShowAdditionalFields] = useState(
        defaultShowAdditionalFields,
    );
    const [dontRemoveData, setDontRemoveData] = useState(false);
    const [cancelFeeType, setCancelFeeType] = useState<string | null>(null)
    const showRemoveDataCheckbox =
        initialFormState.billing_invoices?.length > 0 ||
        initialFormState.customers?.length > 0;

    useEffect(() => {
        removeAllNotifications();
    }, [removeAllNotifications]);

    const handleFieldEvent = useCallback((changes: Partial<ActivityData>) => {
        setFormValues((values) => ({
            ...values,
            ...changes,
        }));
    }, []);

    const getValidationConfig = useCallback(
        (
            formValues,
        ): {
            [key in ValidationType]?: Array<keyof ActivityData>;
        } => {
            if (formValues.activity_status === 'Completed') {
                if (
                    formValues.program === 'Commercial' &&
                    formValues.activity_audience === 'Public'
                ) {
                    return {
                        required:
                            ACTIVITY_VALIDATION_FIELDS.ACTIVITY_COMPLETED_COMMERCIAL_PUBLIC,
                    };
                }

                return {
                    required:
                        ACTIVITY_VALIDATION_FIELDS.ACTIVITY_COMPLETED_DETAILS,
                };
            } else {
                return {
                    required:
                        ACTIVITY_VALIDATION_FIELDS.UPDATE_ILT_HYBRID_DETAILS,
                };
            }
        },
        [],
    );

    const validateAndHandleFieldEvent = useCallback(
        (changes: Partial<ActivityData>) => {
            const iltOrHybridModality =
                initialFormState.activity_modality === 'ILT' ||
                initialFormState.activity_modality === 'Hybrid';
            // An Activity is moved from `Active` to `Completed` and no attended value
            if (
                changes.activity_status === 'Completed' &&
                !initialFormState.attended
            ) {
                setShowAdditionalFields({
                    showDeliveryAddress: false,
                    showNumberOfStudents: true,
                });
                handleFieldEvent(changes);
                return;
            }
            // An ILT or Hybrid activity is moved from `Tentative` to `Active`
            if (changes.activity_status === 'Active' && iltOrHybridModality) {
                if (
                    !initialFormState.delivery_address_1 ||
                    !initialFormState.delivery_postal_code
                ) {
                    setShowAdditionalFields({
                        showDeliveryAddress: true,
                        showNumberOfStudents: false,
                    });
                }
            } else {
                setShowAdditionalFields({
                    showDeliveryAddress: false,
                    showNumberOfStudents: false,
                });
            }
            handleFieldEvent(changes);
        },
        [
            initialFormState.activity_modality,
            initialFormState.delivery_address_1,
            initialFormState.delivery_postal_code,
            handleFieldEvent,
            initialFormState.attended,
        ],
    );

    const handleScrollToError = () => {
        // this may require attention later for consistent experience in all browsers
        const topMostError = document.querySelector(`.${FORM_ERROR_SELECTOR}`);

        topMostError?.scrollIntoView({
            behavior: 'smooth',
        });
    };

    const handleAddCancellationReason = (e: any) => {
        handleFieldEvent({
            cancellation_reason: e.label,
        });
        setCancellationReason(e);
    };

    const navigateToDetailPage = () => {
        history.push({
            pathname: `/activities/${match.params.id}`,
        });
    };

    const getSuccessfulUpdateText = () => {
        let updateText = 'The activity has been updated.';

        if (formValues.activity_status === CANCELLED_STATUS) {
            if (!dontRemoveData) {
                updateText = 'Activity has been canceled and assigned instructors have been released, and customer and invoice data has been removed.';
            } else {
                updateText = 'Activity has been canceled and assigned instructors have been released.';
            }
        }

        return updateText;
    }

    const handleSubmitEvent = async () => {
        setSubmitting(true);
        let invalid;
        if (
            showAdditionalFields.showDeliveryAddress ||
            showAdditionalFields.showNumberOfStudents
        ) {
            invalid = validateForm(formValues, getValidationConfig(formValues));
        } else {
            invalid = validateForm(formValues, editBaseDetailsValidationConfig);
        }
        if (invalid) {
            handleScrollToError();
            setSubmitting(false);
        } else {
            const shouldRemoveRevenues =
                !dontRemoveData &&
                formValues.activity_status === CANCELLED_STATUS;

            if (cancelFeeType !== null) {
                formValues.is_late_cancellation_fee = cancelFeeType === "late_cancellation_fee" ? true : false;
                formValues.is_late_reschedule_fee = cancelFeeType === "late_reschedule_fee" ? true : false;
            }
                
            if (shouldRemoveRevenues) {
                formValues.customers = formValues.customers
                    ? formValues.customers.map((customer) => {
                          return {
                              ...customer,
                              revenues: [],
                          };
                      })
                    : [];
                formValues.billing_invoices = [];
            }
            const isSuccessful = await dispatch<any>(
                updateSelectedActivity(match.params.id, formValues),
            );

            const updateText = getSuccessfulUpdateText();

            addNotification({
                id: `update-activity`,
                ...(isSuccessful
                    ? {
                          type: 'success',
                          content: updateText,
                      }
                    : {
                          type: 'error',
                          content: <UpdateActivityErrorMessage />,
                      }),
            });

            if (isSuccessful) {
                navigateToDetailPage();
            }
        }
    };

    const getCancellationModal = async () => {
        if (
            formValues.activity_status === CANCELLED_STATUS &&
            initialFormState.activity_status !== CANCELLED_STATUS
        ) {
            setShowCancelationReason(true);
        } else {
            handleSubmitEvent();
        }
    };

    const editBaseDetailsFormSectionProps: BasicActivityFormSectionProps = {
        formValues,
        errors,
        handleFieldEvent,
        validateAndHandleFieldEvent,
        showAdditionalFields,
    };

    const cancelModalProps: CancelModalProps = {
        cancelModalVisible,
        setCancelModalVisible,
        submitting,
        onCancelConfirm: navigateToDetailPage,
        testPrefix: 'EditBaseDetails',
    };

    useEffect(() => {
        if (isInvalid) {
            validateForm(formValues, editBaseDetailsValidationConfig);
        }
    }, [isInvalid, formValues, validateForm]);

    useEffect(() => {
        const iltOrHybridModality =
            initialFormState.activity_modality === 'ILT' ||
            initialFormState.activity_modality === 'Hybrid';
        const needsDeliveryAddress =
            initialFormState.activity_status === 'Active' &&
            !initialFormState.delivery_address_1;
        const needsNumberOfStudents =
            initialFormState.activity_status === 'Completed' &&
            !initialFormState.attended;

        setShowAdditionalFields({
            showDeliveryAddress: iltOrHybridModality && needsDeliveryAddress,
            showNumberOfStudents: needsNumberOfStudents,
        });
    }, [
        initialFormState.activity_status,
        initialFormState.activity_modality,
        initialFormState.attended,
        initialFormState.delivery_address_1,
    ]);

    const cancelCancellationModal = () => {
        setShowCancelationReason(false);
        setCancelFeeType(null);
    };

    return (
        <>
            <Form
                header={formValues.activity_name}
                data-testid="ActivityEditBaseDetailsForm"
                actions={
                    <div className="awsui-util-action-stripe awsui-util-mb-m">
                        <div className="awsui-util-action-stripe-group">
                            <Button
                                variant="link"
                                className="admin-activity-cancel"
                                data-testid="EditBaseDetailsCancel"
                                disabled={submitting}
                                onClick={() => {
                                    setCancelModalVisible(true);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                className="admin-activity-save"
                                data-testid="EditBaseDetailsSave"
                                disabled={submitting}
                                loading={isLoading}
                                onClick={getCancellationModal}
                            >
                                {submitting ? 'Saving' : 'Save'}
                            </Button>
                        </div>
                    </div>
                }
            >
                <EditBaseDetailsFormSection
                    {...editBaseDetailsFormSectionProps}
                />
            </Form>
            <CancelModal {...cancelModalProps} />
            <EditBaseDetailsCancellationModal
                visible={showCancelationReason}
                data-testid="EditBaseDetailsCancellationModal"
                onCancel={cancelCancellationModal}
                onConfirm={handleSubmitEvent}
                setCancellationReason={handleAddCancellationReason}
                cancellationReason={cancellationReason}
                dontRemoveData={dontRemoveData}
                showRemoveDataCheckbox={showRemoveDataCheckbox}
                setDontRemoveData={setDontRemoveData}
                cancelFeeType={cancelFeeType}
                setCancelFeeType={setCancelFeeType}
            />
        </>
    );
};

export default EditBaseDetailsForm;
