import React, { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {
    createActivity,
    selectIsLoading,
} from '../../../store/slices/selectedActivitySlice';
import { useNotifications } from '../../../../common/context/grimsbyNotifications';
import useFormValidation from '../../../../common/utils/formValidation';
import {
    CreateActivityData,
    DeliverySession,
} from '../../../interfaces/activity';
import { FormSectionMode } from '../../../../common/constants/forms';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Form } from '@amzn/awsui-components-react';
import ActivityFormSection from '../FormSections/ActivityFormSection';
import { selectUser } from '../../../../common/store/slices/userSlice';
import { FORM_ERROR_SELECTOR } from '../../../../imt/components/Instructor/FormSections/FormSections.common';
import {
    ActivitySessionControlArrayFormValues,
    ActivityFormSectionProps,
    createActivityValidationConfig,
    SessionAttributeEditorItem,
    sessionAttributeValidationConfig,
} from '../Common/Common';
import { ACTIVITY_PATH } from '../../../constants/path';
import { MeridiemFieldValue } from '../../../interfaces/meridiem';
import { generateActivityName, getActivityById } from '../../../services/activity-service';
import ActivityCreateAutoAssignInstructorOptOutModalProps
    from "./ActivityCreateModals/ActivityCreateAutoAssignInstructorOptOutModal";
import {
    getFeatureFlags,
    selectFeatures,
    selectIsLoaded as selectIsFeaturesLoaded
} from "../../../../common/store/slices/featureSlice";
import { checkFeature } from "../../../../common/utils/featureFlag";
import { OptionDefinition } from "@amzn/awsui-components-react-v3/polaris/internal/components/option/interfaces";

const dayInMilliseconds = 86400000;

export const getUTCMilliseconds = (
    dateString: string,
    timeString: string,
    deliveryTimezone: string,
) => {
    // if emtpy, return invalid number, so session date is set empty
    if (dateString === '') return NaN;
    // get the utc time for the delivery location's time
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const utcTime = dayjs
        .tz(dateString + ' ' + timeString, deliveryTimezone)
        .utc();
    return utcTime.toDate().getTime();
};

export const convertTo24HourFormat = (
    time: string,
    meridiem: MeridiemFieldValue,
) => {
    if (!time) {
        return '';
    }

    let [hours, minutes] = time.split(':');

    if (hours === '12') {
        hours = '00';
    }

    if (meridiem === MeridiemFieldValue.Pm) {
        hours = `${parseInt(hours, 10) + 12}`;
    }

    return `${hours}:${minutes}`;
};

export const getDeliverySession = (
    sessionItem: SessionAttributeEditorItem,
    timeZone: string,
): DeliverySession => {
    const militaryStartTime = convertTo24HourFormat(
        sessionItem.startTime,
        sessionItem.startTimeMeridiemFieldOption,
    );
    const militaryEndTime = convertTo24HourFormat(
        sessionItem.endTime,
        sessionItem.endTimeMeridiemFieldOption,
    );

    const millisecondStartTime = getUTCMilliseconds(
        sessionItem.dateString,
        militaryStartTime,
        timeZone,
    );
    let millisecondEndTime = getUTCMilliseconds(
        sessionItem.dateString,
        militaryEndTime,
        timeZone,
    );

    // if the end time is earlier than the start time
    // it means we're out of the bounds of the previous 24 hours
    // we need to offset by a day
    if (millisecondStartTime > millisecondEndTime) {
        millisecondEndTime = millisecondEndTime + dayInMilliseconds;
    }

    return {
        start_timestamp: millisecondStartTime / 1000,
        end_timestamp: millisecondEndTime / 1000,
    };
};

const ActivityCreateForm = ({
    createActivityFormState,
}: {
    createActivityFormState: Omit<CreateActivityData, 'delivery_sessions'>;
}) => {
    const isLoading = useSelector(selectIsLoading);
    const userProfile = useSelector(selectUser);
    const history = useHistory();
    const dispatch = useDispatch();
    const { addNotification } = useNotifications();

    const {
        isInvalid,
        isControlArrayInvalid,
        errors,
        controlArrayErrors,
        validateForm,
        validateFormControlArray,
    } = useFormValidation<
        Omit<CreateActivityData, 'delivery_sessions'>,
        ActivitySessionControlArrayFormValues
    >();

    const createActivityStateWithDefaultValues: Omit<
        CreateActivityData,
        'delivery_sessions'
    > = {
        ...createActivityFormState,
        operations_owner: userProfile?.profile.full_name || '',
        operations_owner_email: userProfile?.profile.email || '',
        scheduler: userProfile?.profile.full_name || '',
        scheduler_email: userProfile?.profile.email || '',
    };

    const [formValues, setFormValues] = useState<
        Omit<CreateActivityData, 'delivery_sessions'>
    >(createActivityStateWithDefaultValues);

    const [shortName, setShortName] = useState<string>('');
    const [countryCode, setCountryCode] = useState<string | undefined>('');

    const [courseDays, setCourseDays] = useState<number>();

    const [autoAssignInstructor, setAutoAssignInstructor] = useState<boolean | undefined>(false);
    const [showOptOutReason, setShowOptOutReason] = useState(false);
    const featureFlags = useSelector(selectFeatures);
    const featuresLoaded = useSelector(selectIsFeaturesLoaded);
    const autoAssignInstructorFeatureFlag = useMemo(() => {
        return  featuresLoaded && checkFeature(
            '',
            { featureName: 'auto-assign-instructor' },
            featureFlags?.features,
        )
    }, [featuresLoaded, featureFlags]);
    
    const [optOutReason, setOptOutReason] =
        useState<OptionDefinition | null>(null);

    const handleCourseDaysChange = (courseDays: number) => {
        setCourseDays(courseDays);
    };

    const handleCountryCodeChange = (countryCode: string | undefined) => {
        setCountryCode(countryCode);
    };

    const handleShortNameChange = (shortName: string) => {
        setShortName(shortName);
    };

    const handleAutoAssignInstructorChange = (autoAssignInstructor: boolean | undefined) => {
        setAutoAssignInstructor(autoAssignInstructor);
    };

    const [
        sessionAttributeEditorItems,
        setSessionAttributeEditorItems,
    ] = useState([
        {
            dateString: '',
            startTime: '',
            endTime: '',
            startTimeMeridiemFieldOption: MeridiemFieldValue.Am as MeridiemFieldValue,
            endTimeMeridiemFieldOption: MeridiemFieldValue.Am as MeridiemFieldValue,
        },
    ] as Array<SessionAttributeEditorItem>);

    const handleFieldEvent = useCallback(
        (changes: Partial<Omit<CreateActivityData, 'delivery_sessions'>>) => {
            setFormValues((values) => ({
                ...values,
                ...changes,
            }));
        },
        [],
    );

    const validateAndHandleFieldEvent = useCallback(
        (changes: Partial<Omit<CreateActivityData, 'delivery_sessions'>>) => {
            setFormValues((values) => ({
                ...values,
                ...changes,
            }));
        },
        [],
    );

    const handleSessionItemEvent = (
        sessionItems: Array<SessionAttributeEditorItem>,
    ) => {
        setSessionAttributeEditorItems(sessionItems);
    };

    const handleScrollToError = () => {
        // this may require attention later for consistent experience in all browsers
        const topMostError = document.querySelector(`.${FORM_ERROR_SELECTOR}`);

        topMostError?.scrollIntoView({
            behavior: 'smooth',
        });
    };

    const cancelModal = () => {
        setShowOptOutReason(false);
    };

    const handleAddOptOutReason = (e: any) => {
        handleFieldEvent({
            auto_assign_instructor_opt_out_reason: e.label,
        });
        setOptOutReason(e);
    };

    const getOptOutModal = async () => {
        const isVilt = formValues.activity_modality === "vILT";
        const isCommercial = formValues.program === "Commercial";
        const isPartnerProgram = formValues.program === "Partner Program";
        const isNamer = formValues.delivery_region === "NAMER"
        const isAutoAssignInstructorDisabled = autoAssignInstructor === false;
        const isViltCommercialOrPartnerProgramInNamer = isVilt
            && (isCommercial || isPartnerProgram)
            && isNamer;

        const autoAssignInstructorOptOutIsVisible = isViltCommercialOrPartnerProgramInNamer
            && isAutoAssignInstructorDisabled
            && autoAssignInstructorFeatureFlag
        if (autoAssignInstructorOptOutIsVisible) {
            setShowOptOutReason(true);
        } else {
            handleCreateActivity();
        }
    };

    const handleCreateActivity = async () => {
        const invalid = validateForm(
            formValues,
            createActivityValidationConfig,
        );
        const arrayInvalid = validateFormControlArray(
            { sessionAttributeEditorItems },
            sessionAttributeValidationConfig,
        );
        const isActivityFormInvalid = invalid || arrayInvalid;
        formValues.course_days = courseDays ? courseDays : 1;
        if (!isActivityFormInvalid) {
            const activityData: CreateActivityData = {
                ...formValues,
                activity_name: generateActivityName(formValues, countryCode, shortName),
                delivery_sessions: sessionAttributeEditorItems
                    .filter(
                        (item) =>
                            item.startTime && item.endTime && item.dateString,
                    )
                    .map((item) =>
                        getDeliverySession(item, formValues.delivery_timezone),
                    ),
                auto_assign_instructor: autoAssignInstructor,
            };
            const { isSuccessful, createdActivityId } = await dispatch<any>(
                createActivity(activityData),
            );
            if (autoAssignInstructor === true) {
                const activityData = await getActivityById(createdActivityId);
                const instructorAssigned = activityData?.activity
                    && activityData.activity?.instructors;
                addNotification({
                    id: `create-activity-${Date.now()}`,
                    ...(isSuccessful
                        ?
                        instructorAssigned ?
                            {
                                type: 'success',
                                content: 'You have successfully created an activity. An instructor was auto assigned.',
                            }
                            :{
                                type: 'success',
                                content: 'You have successfully created an activity. No instructors available for auto assignment. Please manually assign an instructor.',
                            }
                        : {
                            type: 'error',
                            content:
                                'An error occurred while creating the activity.',
                        }),
                });
            } else {
                addNotification({
                    id: `create-activity-${Date.now()}`,
                    ...(isSuccessful
                        ? {
                            type: 'success',
                            content: 'You have successfully created an activity.',
                        }
                        : {
                            type: 'error',
                            content:
                                'An error occurred while creating the activity.',
                        }),
                });
            }
            if (isSuccessful) {
                history.push({
                    pathname: `/activities/${createdActivityId}`,
                });
            }
        } else {
            handleScrollToError();
        }
    };

    const createActivityProps: ActivityFormSectionProps<
        Omit<CreateActivityData, 'delivery_sessions'>
    > & {
        handleShortNameChange: (shortName: string) => void;
        handleCountryCodeChange: (countryCode: string | undefined) => void;
        handleCourseDaysChange: (courseDays: number) => void;
        handleAutoAssignInstructorChange: (autoAssignInstructor: boolean | undefined) => void;
    } = {
        formValues,
        errors,
        handleFieldEvent,
        validateAndHandleFieldEvent,
        handleSessionItemEvent,
        sessionAttributeEditorItems,
        mode: FormSectionMode.Create,
        controlArrayErrors,
        handleShortNameChange,
        handleCourseDaysChange,
        handleCountryCodeChange,
        handleAutoAssignInstructorChange,
    };

    useEffect(() => {
        if (isInvalid) {
            validateForm(formValues, createActivityValidationConfig);
        }

        if (isControlArrayInvalid) {
            validateFormControlArray(
                { sessionAttributeEditorItems },
                sessionAttributeValidationConfig,
            );
        }
    }, [
        isInvalid,
        formValues,
        validateForm,
        validateFormControlArray,
        sessionAttributeEditorItems,
        isControlArrayInvalid,
    ]);

    useEffect(() => {
        dispatch(getFeatureFlags());
    }, [dispatch]);

    return (
        <section data-testid="ActivityCreateForm">
            <Form
                header="Add activity"
                actions={
                    <div className="awsui-util-action-stripe awsui-util-mb-m">
                        <div className="awsui-util-action-stripe-group">
                            <Button
                                variant="link"
                                className="admin-activity-cancel"
                                data-testid="ActivityCreateFormCancel"
                                onClick={() => {
                                    history.push({
                                        pathname: ACTIVITY_PATH.BASE,
                                    });
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                className="admin-activity-save"
                                data-testid="ActivityCreateFormAdd"
                                loading={isLoading}
                                onClick={getOptOutModal}
                            >
                                {`${isLoading ? 'Adding' : 'Add'} activity`}
                            </Button>
                        </div>
                    </div>
                }
            >
                <ActivityFormSection {...createActivityProps} />
            </Form>
            <ActivityCreateAutoAssignInstructorOptOutModalProps
                visible={showOptOutReason}
                data-testid="ActivityCreateAutoAssignInstructorOptOutModalProps"
                onCancel={cancelModal}
                onConfirm={handleCreateActivity}
                setOptOutReason={handleAddOptOutReason}
                optOutReason={optOutReason}
            />
        </section>
    );
};

export default ActivityCreateForm;
