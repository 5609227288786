import { GenericStoreState } from '../../../common/interfaces/genericStoreState';
import { RecommendedInstructorProfileData } from '../../interfaces/recommendedInstructorProfile';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, GlobalState } from '../../../main/store';
import { ScheduleManagementAPIQueryParams } from '../../interfaces/queryParams';
import scheduleManagementApi from '../../api/scheduleManagementApi';
import { ActivityData } from '../../interfaces/activity';
import {
    CalendarEvent,
    CalendarResource,
    InstructorEventTypes,
} from '../../interfaces/resourceCalendarProps';
import { CALENDAR_EVENT_COLOR, UNAVAILABLE_EVENT_COLOR } from '../../constants';
import { LookupData } from '../../interfaces/lookup';
import { buildCalendarEventsFromDeliverySessions } from '../../services/schedule-service';
import { BlockedTime } from '../../../common/interfaces/blockedTime';
import { convertTimeStampToLocalUnix } from '../../../common/utils/date-time.utils';

export interface RecommendedInstructorsState extends GenericStoreState {
    recommendedInstructorsAsResources: Array<CalendarResource>;
    recommendedInstructorLookup: LookupData<RecommendedInstructorProfileData>;
    recommendedInstructorsActivityLookup: LookupData<ActivityData>;
    recommendedInstructorsActivitiesAsEvents: Array<CalendarEvent>;
    blockedTimeLookup: LookupData<BlockedTime>;
    blockedTimesAsEvents: Array<CalendarEvent>;
    isLoaded: boolean;
    pagesCount: number;
    currentPageIndex: number;
    count: number;
    from: number;
    size: number;
}

const ASSIGN_INSTRUCTOR_PAGE_SIZE_KEY = 'assign_instructor_page_size';

export const getRecommendedInstructorState = (
    recommendedInstructors: Array<RecommendedInstructorProfileData>,
) =>
    recommendedInstructors.reduce(
        (acc, instructor) => {
            acc.recommendedInstructorsAsResources.push({
                id: instructor.pk as string,
                title: `${instructor.full_name}`,
            });
            acc.recommendedInstructorLookup[instructor.pk as string] =
                instructor;
            for (const [activity] of instructor.activities) {
                let calEvents = buildCalendarEventsFromDeliverySessions(
                    {
                        sessions: activity.delivery_sessions,
                        timezone: activity.delivery_timezone,
                    },
                    () => {
                        return {
                            id: activity.pk as string,
                            resourceId: instructor.pk as string,
                            title: activity.activity_name,
                        };
                    },
                );
                acc.activityLookup[activity.pk as string] = activity;

                acc.activitiesAsEvents = [
                    ...acc.activitiesAsEvents,
                    ...calEvents,
                ];
            }

            if (instructor.blocked_times) {
                let instructorBlockedTimesWithId = [];
                for (const blockedTime of instructor.blocked_times) {
                    for (const blockedTimeTimestamp of blockedTime.blocked_time_timestamps) {
                        const backgroundColor = instructor.is_freelancer
                            ? UNAVAILABLE_EVENT_COLOR
                            : CALENDAR_EVENT_COLOR;
                        acc.blockedTimesAsEvents.push({
                            id: blockedTime.pk,
                            start:
                                convertTimeStampToLocalUnix({
                                    timestamp:
                                        blockedTimeTimestamp.start_timestamp,
                                    timezone: instructor.city_timezone,
                                }) * 1000,
                            end:
                                convertTimeStampToLocalUnix({
                                    timestamp:
                                        blockedTimeTimestamp.end_timestamp,
                                    timezone: instructor.city_timezone,
                                }) * 1000,
                            resourceId: instructor.pk as string,
                            backgroundColor,
                            title: `Blocked - ${blockedTime.blocked_time_name}`,
                            eventType: InstructorEventTypes.blockedTime,
                        });
                        acc.blockedTimeLookup[blockedTime.pk as string] = {
                            ...blockedTime,
                            instructor_pk: instructor.pk,
                        };
                        instructorBlockedTimesWithId.push(blockedTime);
                    }
                }
            }
            return acc;
        },
        {
            recommendedInstructorsAsResources: [] as Array<CalendarResource>,
            recommendedInstructorLookup:
                {} as LookupData<RecommendedInstructorProfileData>,
            activitiesAsEvents: [] as Array<CalendarEvent>,
            activityLookup: {} as LookupData<any>,
            blockedTimesAsEvents: [] as Array<CalendarEvent>,
            blockedTimeLookup: {} as LookupData<BlockedTime>,
        },
    );

/**
 * recommendedInstructorsSlice manages all recommended instructor state, and contains recommended instructor actions as well as recommended instructor state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const recommendedInstructorsSlice = createSlice({
    name: 'recommendedInstructor',
    initialState: {
        recommendedInstructorLookup: {},
        recommendedInstructorsAsResources: [],
        recommendedInstructorsActivityLookup: {},
        recommendedInstructorsActivitiesAsEvents: [],
        blockedTimesAsEvents: [],
        blockedTimeLookup: {},
        error: null,
        isLoading: false,
        isLoaded: false,
        pagesCount: 1,
        currentPageIndex: 1,
        count: 0,
        from: 0,
        size: parseInt(
            localStorage.getItem(ASSIGN_INSTRUCTOR_PAGE_SIZE_KEY) ?? '25',
        ),
    } as RecommendedInstructorsState,
    reducers: {
        setRecommendedInstructors: (
            state: RecommendedInstructorsState,
            action: PayloadAction<Array<RecommendedInstructorProfileData>>,
        ) => {
            const {
                recommendedInstructorsAsResources,
                recommendedInstructorLookup,
                activitiesAsEvents,
                activityLookup,
                blockedTimesAsEvents,
                blockedTimeLookup,
            } = getRecommendedInstructorState(action.payload);

            state.recommendedInstructorsAsResources =
                recommendedInstructorsAsResources;
            state.recommendedInstructorLookup = recommendedInstructorLookup;
            state.recommendedInstructorsActivitiesAsEvents = activitiesAsEvents;
            state.recommendedInstructorsActivityLookup = activityLookup;
            state.blockedTimeLookup = blockedTimeLookup;
            state.blockedTimesAsEvents = blockedTimesAsEvents;
        },
        setError: (
            state: RecommendedInstructorsState,
            action: PayloadAction<any>,
        ) => {
            state.error = action.payload;
        },
        setIsLoading: (
            state: RecommendedInstructorsState,
            action: PayloadAction<boolean>,
        ) => {
            state.isLoading = action.payload;
        },
        setIsLoaded: (
            state: RecommendedInstructorsState,
            action: PayloadAction<boolean>,
        ) => {
            state.isLoaded = action.payload;
        },
        setFrom: (
            state: RecommendedInstructorsState,
            action: PayloadAction<number>,
        ) => {
            state.from = action.payload;
        },
        setCount: (
            state: RecommendedInstructorsState,
            action: PayloadAction<number>,
        ) => {
            state.count = action.payload;
        },
        setPagesCount: (
            state: RecommendedInstructorsState,
            action: PayloadAction<number>,
        ) => {
            state.pagesCount = action.payload;
        },
        setCurrentPageIndex: (
            state: RecommendedInstructorsState,
            action: PayloadAction<number>,
        ) => {
            state.currentPageIndex = action.payload;
        },
        setSize: (
            state: RecommendedInstructorsState,
            action: PayloadAction<number>,
        ) => {
            localStorage.setItem(
                ASSIGN_INSTRUCTOR_PAGE_SIZE_KEY,
                `${action.payload}`,
            );
            state.size = action.payload;
        },
    },
});

export const {
    setRecommendedInstructors,
    setError,
    setIsLoading,
    setIsLoaded,
    setCurrentPageIndex,
    setPagesCount,
    setCount,
    setFrom,
    setSize,
} = recommendedInstructorsSlice.actions;

/**
 * getRecommendedInstructors is an async action used to fetch recommended instructors
 * for a given activity
 */
export const getRecommendedInstructors = (
    params: ScheduleManagementAPIQueryParams.GetRecommendedInstructors,
) => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        const { size, from } = state.recommendedInstructors;
        dispatch(setIsLoading(true));

        const nonNullParams: any = Object.fromEntries(Object.entries(params).filter(([_, v]) => v != null));

        let cleanParams = {
            ...nonNullParams,
            delivery_sessions: params.delivery_sessions
                ? params.delivery_sessions?.filter((session) => {
                      if (session.start_timestamp && session.end_timestamp) {
                          return session;
                      }

                      return false;
                  })
                : null,
        };

        try {
            const { result } =
                await scheduleManagementApi.getRecommendedInstructors({
                    ...cleanParams,
                    from,
                    size,
                });

            dispatch(setRecommendedInstructors(result.instructors));
            dispatch(setCount(result.total_instructors));
            dispatch(setPagesCount(Math.ceil(result.total_instructors / size)));
        } catch (error: any) {
            dispatch(setError(error.toString()));
        }

        if (!state.selectedActivity.isLoaded) {
            dispatch(setIsLoaded(true));
        }

        dispatch(setIsLoading(false));
    };
};

export const selectRecommendedInstructorLookup = (state: GlobalState) =>
    state.recommendedInstructors.recommendedInstructorLookup;
export const selectRecommendedInstructorsAsResources = (state: GlobalState) =>
    state.recommendedInstructors.recommendedInstructorsAsResources;
export const selectRecommendedInstructorsActivityLookup = (
    state: GlobalState,
) => state.recommendedInstructors.recommendedInstructorsActivityLookup;
export const selectRecommendedInstructorsActivitiesAsEvents = (
    state: GlobalState,
) => state.recommendedInstructors.recommendedInstructorsActivitiesAsEvents;
export const selectError = (state: GlobalState) =>
    state.recommendedInstructors.error;
export const selectIsLoading = (state: GlobalState) =>
    state.recommendedInstructors.isLoading;
export const selectIsLoaded = (state: GlobalState) =>
    state.recommendedInstructors.isLoaded;
export const selectBlockedTimeLookup = (state: GlobalState) =>
    state.recommendedInstructors.blockedTimeLookup;
export const selectBlockedTimesAsEvents = (state: GlobalState) =>
    state.recommendedInstructors.blockedTimesAsEvents;
export const selectPagesCount = (state: GlobalState) =>
    state.recommendedInstructors.pagesCount;
export const selectCurrentPageIndex = (state: GlobalState) =>
    state.recommendedInstructors.currentPageIndex;
export const selectSize = (state: GlobalState) =>
    state.recommendedInstructors.size;
export const selectCount = (state: GlobalState) =>
    state.recommendedInstructors.count;

export default recommendedInstructorsSlice.reducer;
