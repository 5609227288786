import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FreelancerStatusesResponseData } from '../../interfaces/businessDataResponse/freelancerStatusesResponse';
import { HandleRequestData } from '../../interfaces/handleRequest';
import businessDataApi from '../../api/businessDataApi';
import { AppDispatch, GlobalState } from '../../../main/store';
import { BusinessDataStoreState } from '../../interfaces/businessDataStoreState';
import { FreelancerStatusItemData } from '../../interfaces/businessDataItem/freelancerStatusItem';
import parseBoolean from '../../utils/parseBoolean';
import {
    BusinessDataStoreInitialState,
    getActiveBusinessDataValues,
    resetBasicBusinessDataStoreState,
} from '../store.common';

/**
 * freelancerStatusesSlice manages all freelancerStatuses state, and contains freelancerStatuses actions as well as freelancerStatuses state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const freelancerStatusesSlice = createSlice({
    name: 'freelancerStatuses',
    initialState: {
        ...BusinessDataStoreInitialState,
    } as BusinessDataStoreState<FreelancerStatusItemData>,
    reducers: {
        setFreelancerStatusesList: (
            state,
            action: PayloadAction<FreelancerStatusItemData[]>,
        ) => {
            const byFreelancerStatusName = action.payload.reduce(
                (
                    byFreelancerStatusName: {
                        [key: string]: FreelancerStatusItemData;
                    },
                    freelancerStatus: FreelancerStatusItemData,
                ) => {
                    byFreelancerStatusName[
                        freelancerStatus.freelancer_status
                    ] = {
                        ...freelancerStatus,
                        active: parseBoolean(freelancerStatus.active),
                    };
                    return byFreelancerStatusName;
                },
                {},
            );
            state.entities = byFreelancerStatusName;
            state.keysList = Object.keys(byFreelancerStatusName);
        },
        setFreelancerStatus: (
            state,
            action: PayloadAction<FreelancerStatusItemData>,
        ) => {
            // if the item is new, add a new key for it and update the count.
            if (!state.entities[action.payload.freelancer_status]) {
                state.keysList = [
                    ...state.keysList,
                    action.payload.freelancer_status,
                ];
                state.count = state.keysList.length;
            }
            state.entities[action.payload.freelancer_status] = action.payload;
        },
        setSelectedFreelancerStatus: (
            state,
            action: PayloadAction<string | null>,
        ) => {
            state.selectedItemKey = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
        setCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload;
        },
        resetFreelancerStatusesSlice: resetBasicBusinessDataStoreState,
    },
});

export const {
    setIsLoading,
    setIsLoaded,
    setFreelancerStatusesList,
    setFreelancerStatus,
    setSelectedFreelancerStatus,
    setError,
    setCount,
    resetFreelancerStatusesSlice,
} = freelancerStatusesSlice.actions;

const handleFreelancerStatusesListRequest = () => {
    return async (dispatch: AppDispatch) => {
        try {
            const {
                result: { FREELANCER_STATUS, total_count },
            }: HandleRequestData<FreelancerStatusesResponseData> = await businessDataApi.getFreelancerStatuses(
                { active: 'all' },
            );
            dispatch(setFreelancerStatusesList(FREELANCER_STATUS));
            dispatch(setCount(total_count));
        } catch (error: any) {
            dispatch(
                setError(error?.message || 'getFreelancerStatuses API error'),
            );
        }
    };
};

export const getFreelancerStatusesList = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));
        await dispatch(handleFreelancerStatusesListRequest());
        dispatch(setIsLoading(false));
        if (!state.freelancerStatuses.isLoaded) {
            dispatch(setIsLoaded(true));
        }
    };
};

/**
 * action to update instructor
 * before update api call, fetch instructor statuses and prepare payload containing only the changed fields
 * after update api call, fetch instructor statuses and update the current item in that keysList with the item data we updated
 * @param data
 */
export const updateFreelancerStatus = (data: FreelancerStatusItemData) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        // immediately put the keysList into loading while we finish updating the item/fetching the keysList
        dispatch(setIsLoading(true));
        if (!data.pk || data?.pk.length <= 0) {
            // eslint-disable-next-line no-console
            console.error(
                `Error updating new instructor: required field pk was missing`,
            );
            dispatch(setIsLoading(false));
            return false;
        }
        try {
            await businessDataApi.updateFreelancerStatus(data.pk, data.active);
            await dispatch(handleFreelancerStatusesListRequest());
            // force-update the item in the keysList for now, since the keysList we fetched may not have this item's update yet
            dispatch(
                setFreelancerStatus({
                    ...state.freelancerStatuses.entities[
                        data.freelancer_status
                    ],
                    active: data.active,
                }),
            );
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const addFreelancerStatus = (
    active = true,
    freelancerStatusName: string,
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        dispatch(setIsLoading(true));
        try {
            await businessDataApi.addFreelancerStatus(
                freelancerStatusName,
                active,
            );
            // now refresh the keysList
            await dispatch(handleFreelancerStatusesListRequest());
            if (!state.freelancerStatuses?.entities[freelancerStatusName]) {
                // shove the item into the keysList, but only if it's not there already
                const newFreelancerStatusData: FreelancerStatusItemData = {
                    freelancer_status: freelancerStatusName,
                    active: active,
                };
                dispatch(setFreelancerStatus(newFreelancerStatusData));
            }
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const selectAllFreelancerStatuses = (state: GlobalState) => {
    return state.freelancerStatuses.keysList.map(
        (freelancerStatusName) =>
            state.freelancerStatuses.entities[freelancerStatusName],
    );
};

export const selectAllActiveFreelancerStatuses = (
    state: GlobalState,
): Array<FreelancerStatusItemData> => {
    return getActiveBusinessDataValues(
        state.freelancerStatuses.keysList,
        state.freelancerStatuses.entities,
    );
};

export const selectIsLoading = (state: GlobalState) =>
    state.freelancerStatuses.isLoading;

export const selectIsLoaded = (state: GlobalState) =>
    state.freelancerStatuses.isLoaded;

export const selectSelectedFreelancerStatus = (state: GlobalState) => {
    return state?.freelancerStatuses?.selectedItemKey
        ? state?.freelancerStatuses?.entities[
              state?.freelancerStatuses?.selectedItemKey
          ]
        : null;
};

export const selectError = (state: GlobalState) =>
    state.freelancerStatuses.error;

export const selectCount = (state: GlobalState) =>
    state.freelancerStatuses.count;

export default freelancerStatusesSlice.reducer;
